import { openAlertModal } from 'components/ui'
import { Translate } from 'next-translate'

export default function openConfirmModal(t: Translate, accountName: string, onConfirm: () => void) {
  openAlertModal({
    title: t('componentCampaignBuilder:styles.branding.alert.title'),
    content: t('componentCampaignBuilder:styles.branding.alert.desc', { accountName }),
    hideIcon: true,
    zIndex: 300,
    onConfirm,
  })
}
