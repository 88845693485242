import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Anchor, Grid, Group, MultiSelect, SelectItem, Text } from '@mantine/core'
import { includes, isArray, isEmpty, size, uniq } from 'lodash-es'

import DataContext from '../../DataContext'
import ConditionContext from '../ConditionContext'
import { onChangeCallback } from './type'
import { debouncedWaitTime } from 'components/business/Filter/FieldFilterPopover/Filter/constants'
import { useDebouncedValue } from '@mantine/hooks'
import BatchEditor from 'components/business/Filter/DefaultFilter/Conditions/SecondNode/BatchEditor'
import { getBorderColor } from 'config/themeOverride/getColor'
import { useEnterBlurMultiSelect } from 'components/business/hooks'

type Props = {
  onChange: onChangeCallback
  batchEdit?: boolean
}

const BATCH_THRESHOLD_VALUE = 50

const MultiSelector: FC<Props> = (props) => {
  const { t } = useTranslation('common')
  const { onChange, batchEdit } = props
  const { searchValue, onSearchChange, handleBlur, handleKeyDown } = useEnterBlurMultiSelect((createValue) =>
    handleChange(uniq([...currentValue, createValue]))
  )
  const { condition } = useContext(ConditionContext)
  const {
    firstNode: { fields },
    secondNode: { getCreateLabel, getFields },
  } = useContext(DataContext)

  const secondNodeValue = condition?.secondNode?.value as string | string[]
  const [debounceSearchValue] = useDebouncedValue(searchValue, debouncedWaitTime)

  const firstNodeFieldCodes = useMemo(() => {
    return fields?.map((field) => field.code)
  }, [fields])

  const currentValue = useMemo(() => {
    return secondNodeValue ? (isArray(secondNodeValue) ? secondNodeValue : [secondNodeValue]) : []
  }, [secondNodeValue])

  const [secondNodeFields, setSecondNodeFields] = useState<string[]>([])
  const [batched, setBatched] = useState(false)
  const [batchEditorVisible, setBatchEditorVisible] = useState(false)

  useEffect(() => {
    setSecondNodeFields(uniq(secondNodeFields.concat(currentValue)))
    setBatched(batchEdit == true && size(currentValue) > BATCH_THRESHOLD_VALUE)
    // eslint-disable-next-line
  }, [currentValue])

  useEffect(() => {
    const getSecondNodeFields = async () => {
      if (!getFields || !condition.firstNode.value || !includes(firstNodeFieldCodes, condition.firstNode.value)) {
        return []
      }
      return await getFields(condition.firstNode.value, debounceSearchValue)
    }
    getSecondNodeFields().then((data) => setSecondNodeFields(uniq(data.concat(currentValue))))
    // eslint-disable-next-line
  }, [condition.firstNode.value, fields, debounceSearchValue])

  const handleCreate = (search: string) => {
    const query = search.trim()
    setSecondNodeFields((current) => [...current, query])
    return { value: query, label: query }
  }

  const handleChange = (value: string[]) => {
    const error = ''
    const { value: originValue, error: originError } = condition.secondNode ?? {}
    if (value !== originValue || error !== originError) {
      onChange({
        value,
        error,
      })
    }
  }

  const shouldCreate = (search: string, data: SelectItem[]) => {
    const query = search.trim()
    const isExisted = data?.some(({ label }) => label === query)
    return !isExisted && !isEmpty(query)
  }
  return (
    <Grid gutter="sm" justify="flex-end" align="flex-start" sx={{ flexWrap: 'nowrap' }}>
      <Grid.Col span="auto" className="w-[310px]">
        {!batched && (
          <MultiSelect
            creatable={!!getCreateLabel}
            searchable
            clearable
            searchValue={searchValue}
            data={secondNodeFields}
            value={currentValue}
            onSearchChange={onSearchChange}
            placeholder={t('placeholders.pleaseSelect')}
            onChange={handleChange}
            // @ts-ignore
            getCreateLabel={getCreateLabel}
            shouldCreate={shouldCreate}
            onCreate={handleCreate}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            error={condition?.secondNode?.error}
            styles={{
              value: {
                margin: '3px 5px',
              },
              input: {
                paddingLeft: '12px',
                paddingRight: '30px',
              },
              values: {
                '&[data-clearable]': {
                  marginRight: 0,
                },
              },
            }}
          />
        )}
        {batched && (
          <Group
            spacing={0}
            sx={(theme) => ({
              textAlign: 'left',
              padding: theme.spacing.sm,
              borderRadius: theme.radius.sm,
              fontSize: theme.fontSizes.sm,
              border: `1px solid ${getBorderColor('gray.3', theme)}`,
              cursor: 'pointer',
            })}
            onClick={() => setBatchEditorVisible(true)}
          >
            <Text color="gray.7" className="truncate w-[200px]" style={{ flex: '1 0 auto' }}>
              {currentValue.join(', ')}
            </Text>
            <Text align="right" lineClamp={1} style={{ flex: '0 0 auto' }} color="gray.7">
              {t('common:batchEdit.totalSize', { size: size(currentValue) })}
            </Text>
          </Group>
        )}
      </Grid.Col>
      {batchEdit && (
        <Grid.Col span="content" mt="xs">
          <Anchor onClick={() => setBatchEditorVisible(true)} type="button">
            {t('common:actions.batchEdit')}
          </Anchor>
          {batchEditorVisible && (
            <BatchEditor value={currentValue} onSave={handleChange} onClose={() => setBatchEditorVisible(false)} />
          )}
        </Grid.Col>
      )}
    </Grid>
  )
}

export default MultiSelector
