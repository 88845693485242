import React from 'react'
import { isNil } from 'lodash-es'
import { LoadingOverlay, ScrollArea, Stack } from '@mantine/core'
import { initializeApollo } from 'plugins/apollo'
import useTranslation from 'next-translate/useTranslation'

import CustomerSegments from 'components/business/Customer/CustomerSegments'
import { CustomerFragmentDoc, useCustomerQuery } from 'types/domain'
import { formatISOTime, getDisplayRegion } from 'utils'
import CustomerCard from 'components/business/CustomerCard'
import { LabelWithValue } from 'components/ui'

import { basicInfoHeight } from '../constant'
import { CustomerDetailBasicProps } from '../type'
import CustomerAttributes from './CustomerAttributes'

const BasicInfo = (props: CustomerDetailBasicProps) => {
  const { t } = useTranslation('pageCustomerSegment')
  const { id, customerAttributes, manageable } = props
  const { data } = useCustomerQuery({
    skip: !id,
    variables: {
      id,
    },
  })

  const customer =
    initializeApollo().readFragment({
      id: `Customer:${id}`,
      fragment: CustomerFragmentDoc,
    }) ?? data?.customer

  return (
    <ScrollArea.Autosize scrollbarSize={5} mah={`calc(100vh - ${basicInfoHeight}px)`}>
      <Stack spacing="3xl" w={640} m="3xl">
        {isNil(customer) ? (
          <LoadingOverlay visible />
        ) : (
          <>
            <CustomerCard UID={customer?.uid} size="md">
              <LabelWithValue label={t('field.lastSubmittedAt')} value={formatISOTime(customer?.lastSubmittedAt)} />
              <LabelWithValue
                label={t('field.lastSubmittedRegion')}
                value={getDisplayRegion(customer?.lastSubmittedRegion)}
              />
              <LabelWithValue label={t('field.lastSubmittedIp')} value={customer?.lastSubmittedIp} />
            </CustomerCard>
            <CustomerSegments id={id} manageable={manageable} />
            <CustomerAttributes customer={customer} customerAttributes={customerAttributes} />
          </>
        )}
      </Stack>
    </ScrollArea.Autosize>
  )
}

export default BasicInfo
