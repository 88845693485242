import { useContext, useMemo } from 'react'

import { UrlConditionOperator } from 'types/domain'
import { UrlFilterConditionType } from '../../type'
import ConditionContext from '../ConditionContext'
import { NODE_TYPE } from './constant'

function isUrl(operator?: UrlConditionOperator | string) {
  return operator === UrlConditionOperator.Equal || operator === UrlConditionOperator.StartWith
}

function getNodeType(condition: UrlFilterConditionType) {
  const operator = condition?.operator as string | undefined
  if (isUrl(operator)) {
    return NODE_TYPE.URL
  }
  return NODE_TYPE.TEXT
}

export function useNodeType() {
  const { condition } = useContext(ConditionContext)

  return useMemo(() => {
    return getNodeType(condition)
  }, [condition])
}
