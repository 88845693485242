import React, { FC, PropsWithChildren } from 'react'
import { Group, Navbar } from '@mantine/core'
import { ApplicationDefault } from 'config/constants'
import { Logo } from 'components/ui'
import HelpCenter from './components/HelpCenter'
import AvatarMenu from './components/AvatarMenu'
import ThemeSwitcher from './components/ThemeSwitcher'
import Divider from 'components/ui/Divider'

const Nav: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Navbar
      pb="sm"
      width={{ base: 252 }}
      height="100vh"
      styles={{
        root: {
          position: 'absolute',
          bottom: 'unset',
          overflow: 'auto',
        },
      }}
    >
      <Navbar.Section px="sm" pt="lg">
        <Group position="apart">
          <Logo href={ApplicationDefault} />
          <ThemeSwitcher />
        </Group>
      </Navbar.Section>
      <Divider mt="md" mb="md" />
      {children}
      <Navbar.Section px="sm">
        <HelpCenter />
      </Navbar.Section>
      <Divider my="sm" />
      <Navbar.Section px="sm">
        <AvatarMenu />
      </Navbar.Section>
    </Navbar>
  )
}

export default Nav
