import { createContext, ReactNode } from 'react'
import { Account, AccountBase, Application, User } from 'types/domain'

export interface MainContextProps {
  me: User | null
  account: Pick<
    Account,
    'id' | 'setting' | 'features' | 'subscription' | 'vasBalance' | 'subscriptionLastEndDate'
  > | null
  nav?: ReactNode
}

const MainContext = createContext<MainContextProps>({
  me: null,
  account: null,
  nav: null,
})

export default MainContext
