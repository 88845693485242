import { Button, Group, Modal, Space, Text, Title, useMantineTheme } from '@mantine/core'
import React, { useContext, useEffect, useState } from 'react'
import MTextarea from 'components/business/MTextarea'
import ConditionContext from 'components/business/Filter/DefaultFilter/Conditions/ConditionContext'
import { compact, isArray, size, trim, uniq } from 'lodash-es'
import useTranslation from 'next-translate/useTranslation'
import { getTextColor } from 'config/themeOverride/getColor'

const MAXSIZE = 30000

const BatchEditor = ({
  value,
  onSave,
  onClose,
}: {
  value: string[]
  onSave(value: string[]): void
  onClose: () => void
}) => {
  const { t } = useTranslation('common')
  const theme = useMantineTheme()
  const [currentValue, setCurrentValue] = useState(value)
  const { condition } = useContext(ConditionContext)

  const secondNodeValue = condition?.secondNode?.value as string | string[]
  useEffect(() => {
    setCurrentValue(secondNodeValue ? (isArray(secondNodeValue) ? secondNodeValue : [secondNodeValue]) : [])
  }, [secondNodeValue])

  const handleSave = () => {
    onClose()
    onSave(uniq(compact(currentValue.map((item) => trim(item)))))
  }

  return (
    <>
      <Modal
        size="lg"
        opened={true}
        onClose={onClose}
        title={<Title order={4}>{t('common:batchEdit.title')}</Title>}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <Text c={getTextColor('gray.9', theme)}>{t('common:batchEdit.desc', { maxsize: MAXSIZE })}</Text>
        <Space h="sm" />
        <MTextarea
          maxsize={MAXSIZE}
          minRows={10}
          maxRows={10}
          value={currentValue}
          onChange={setCurrentValue}
          placeholder={t('common:batchEdit.placeholder')}
        />
        <Space h="xl" />
        <Group>
          <Button onClick={handleSave} disabled={size(currentValue) > MAXSIZE} color="brandBlue.6">
            {t('common:actions.confirm')}
          </Button>
          <Button variant="default" onClick={onClose}>
            {t('common:actions.cancel')}
          </Button>
        </Group>
      </Modal>
    </>
  )
}

export default BatchEditor
