import {
  ButtonStyle,
  ButtonVariant,
  CampaignLocaleSettingInput,
  CampaignTargetingDevice,
  CampaignType,
  CampaignWidgetWidth,
  ColorScheme,
  FormSetting,
  Locale,
  TextAlign,
} from 'types/domain'
import { Duration, parse } from 'duration-fns'

const defaultButtonStyle: ButtonStyle = {
  variant: ButtonVariant.Filled,
  borderRadius: 20,
}

const defaultTextStyle = {
  align: TextAlign.Left,
}

export const defaultColorScheme: ColorScheme = {
  accent: '#1D55C4',
  background: '#FFFFFF',
}

export const defaultFormSetting: FormSetting = {
  hideBranding: false,
  backNavigation: false,
  showProgressBar: false,
  colorScheme: defaultColorScheme,
  buttonStyle: defaultButtonStyle,
  textStyle: defaultTextStyle,
}

export const defaultOpenStrategy = {
  enabled: true,
  distributionLimit: 7,
  interval: parse({ days: 3 }) as Duration,
}

export const defaultTargeting = {
  device: CampaignTargetingDevice.All,
  urlFilter: null,
  customerFilter: null,
}

export const defaultLocaleSetting: CampaignLocaleSettingInput = {
  locale: Locale.ZhCn,
}

export const defaultWidgetWidth = {
  [CampaignType.Feedback]: CampaignWidgetWidth.Small,
  [CampaignType.Survey]: CampaignWidgetWidth.Normal,
  [CampaignType.Notification]: CampaignWidgetWidth.Xlarge,
}

export const MAX_FIELDS_COUNT = 50
