import { appNameMaxlength } from 'config/constants'
import { Protocol } from 'config/constants/link'
import { isNil } from 'lodash-es'
import { Translate } from 'next-translate'
import { isUrl, length } from 'utils'

export function validateName(t: Translate, value?: string | null) {
  if (isNil(value)) {
    return null
  }
  if (value.trim().length === 0) {
    return t('pageAppNew:name.emptyError')
  }
  if (length(value.trim()) > appNameMaxlength * 2) {
    return t('pageAppNew:name.maxLengthError')
  }
}

export function validateUrl(value: string, t: any) {
  const isProtocol = isEmptyUrl(value)
  if (isProtocol) {
    return t('pageAppNew:websiteUrl.emptyError')
  }
  if (!isUrl(value?.trim())) {
    return t('pageAppNew:websiteUrl.patternError')
  }
  return null
}

export function isEmptyUrl(value: string | undefined) {
  if (!value) {
    return true
  }
  return Object.values(Protocol).includes(value.trim() as Protocol)
}
