import React, { PropsWithChildren, ReactNode } from 'react'
import { Accordion, Group, Stack, Text } from '@mantine/core'
import RadioBtn from '../RadioBtn'
import { TaskType } from '../type'

type Props = PropsWithChildren & {
  value: TaskType
  checked: boolean
  title: ReactNode
  descriptions: ReactNode[]
}

const Task = (props: Props) => {
  const { value, checked, title, descriptions, children } = props

  return (
    <Accordion.Item value={value}>
      <Accordion.Control>
        <Group spacing="md">
          <RadioBtn checked={checked} />
          <Text size="sm">{title}</Text>
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <Stack spacing={0}>
          {descriptions.map((desc, index) => (
            <Text size="xs" key={index} c="dimmed">
              {desc}
            </Text>
          ))}
          {children}
        </Stack>
      </Accordion.Panel>
    </Accordion.Item>
  )
}

export default Task
