import omitDeep from 'omit-deep-lodash'
import React, { createContext, useReducer, useEffect } from 'react'
import { isEqual } from 'lodash-es'

import { ConditionGroupType } from '../../type'
import conditionGroupReducer from './conditionGroupReducer'
import { Dispatch } from './type'

export const ConditionGroupContext = createContext<{ state: ConditionGroupType; dispatch: Dispatch } | undefined>(
  undefined
)

type ConditionsProviderProps = {
  children: React.ReactNode
  defaultValue: ConditionGroupType
  onChange: (state: ConditionGroupType) => void
}

const compareIgnoreKeys = ['__typename', 'error']

export function ConditionGroupProvider({ defaultValue, onChange, children }: ConditionsProviderProps) {
  const [state, dispatch] = useReducer(conditionGroupReducer, {
    operator: defaultValue.operator,
    conditions: defaultValue.conditions,
  })

  useEffect(() => {
    if (!isEqual(omitDeep(state, compareIgnoreKeys), omitDeep(defaultValue, compareIgnoreKeys))) {
      onChange(state)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const value = { state, dispatch }

  return <ConditionGroupContext.Provider value={value}>{children}</ConditionGroupContext.Provider>
}
