import React, { FC, PropsWithChildren } from 'react'
import { Group, Text, Title } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { CampaignType } from 'types/domain'

type Props = PropsWithChildren & {
  totalCount: number
  campaignType: CampaignType
}

const Header: FC<Props> = ({ totalCount, campaignType, children }) => {
  const { t } = useTranslation('componentCampaignList')

  return (
    <Group position="apart">
      <Group spacing="md">
        <Title>{t(`${campaignType}`)}</Title>
        <Text color="dimmed">
          {t('list.total', { count: totalCount })}
          {t(`${campaignType}`)}
        </Text>
      </Group>
      {children}
    </Group>
  )
}

export default Header
