import React, { FC, PropsWithChildren } from 'react'
import { Box, Center, Grid, MantineNumberSize, Stack } from '@mantine/core'
import { ContainerWithDotBg, Logo, SessionPaper } from 'components/ui'
import { useRouter } from 'next/router'

import OnboardingSteps from './Steps'
import Preview from './Preview'
import { AccountComplete, ApplicationNew } from 'config/constants'
import Back from 'components/ui/Button/Back'
import { showBackBtnQueryKey, showStepQueryKey } from 'components/business/Layouts/OnboardingLayout/constant'
import { useMediaQuery } from '@mantine/hooks'
import { useOnboardingLayoutContext } from 'components/business/Layouts/OnboardingLayout/context'

type Props = {
  back?: boolean
}

export const stepRoutes = [AccountComplete, ApplicationNew]
const Content: FC<PropsWithChildren<Props>> = ({ back, children }) => {
  const router = useRouter()
  const matches = useMediaQuery('(min-width: 890px)', true, { getInitialValueInEffect: false })
  const { onboarding } = useOnboardingLayoutContext().values
  const showStep = onboarding || router.query?.[showStepQueryKey]
  const onStepClick = (step: number) => {
    router.replace({ pathname: stepRoutes[step], query: getStepJumpQuery() })
  }

  const getStepJumpQuery = () => {
    const query: any = {}
    const showStepQuery = router.query?.[showStepQueryKey]
    const showBackBtnQuery = router.query?.[showBackBtnQueryKey]
    if (showStepQuery) {
      query[showStepQueryKey] = '1'
    }
    if (showBackBtnQuery) {
      query[showBackBtnQueryKey] = '1'
    }
    return query
  }

  return (
    <ContainerWithDotBg className="max-w-none">
      {back && <Back />}
      <Center className="min-h-screen" py="2xl">
        <Stack spacing={'2xl' as MantineNumberSize} w="100%" maw={1120}>
          <Center>
            <Logo width={112} height={44} />
          </Center>
          <SessionPaper minHeight={640} radius="lg" p={0} height="auto" width="100%" py="0!important">
            <Grid m={0}>
              <Grid.Col p={0} span={matches ? 6 : 12}>
                <Box maw={420} mx="auto" py={64}>
                  {showStep && <OnboardingSteps onStepClick={onStepClick} stepCount={stepRoutes.length} />}
                  {children}
                </Box>
              </Grid.Col>
              {matches && (
                <Grid.Col p={0} span={6}>
                  <Preview />
                </Grid.Col>
              )}
            </Grid>
          </SessionPaper>
        </Stack>
      </Center>
    </ContainerWithDotBg>
  )
}

export default Content
