import React, { useContext, useRef } from 'react'
import { Text, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { AnchorLink } from 'components/ui'
import { ApplicationSettingsCodeTracking } from 'config/constants'
import StatusPopover from './components/StatusPopover'
import StatusContent from './components/StatusContent'
import { getTextColor } from 'config/themeOverride/getColor'
import { IconAccessPoint, IconAccessPointOff } from '@tabler/icons-react'
import { ApplicationWebDistributionSetting } from 'types/domain'
import { getDistributionChannelGroup, isApplicationStatusNormal, isMobileDistribution } from 'utils/applicationUtils'
import ApplicationContext from 'components/business/Layouts/ApplicationLayout/ApplicationContext'

const ApplicationStatus = () => {
  const theme = useMantineTheme()
  const { t } = useTranslation('layoutApplication')
  const ref = useRef<HTMLButtonElement>(null)
  const appId = useRouter().query.appId as string
  const application = useContext(ApplicationContext).application!
  const { pingedAt, setting, channel } = application.distribution
  const isCurrentMobileDistribution = isMobileDistribution(application!)
  const currentChannelGroup = getDistributionChannelGroup(channel)

  const { websiteUrl } = setting as ApplicationWebDistributionSetting
  const { name } = application

  return (
    <>
      {isApplicationStatusNormal(pingedAt) ? (
        <StatusPopover
          ref={ref}
          targetIcon={<IconAccessPoint size={18} color={getTextColor('teal.6', theme)} />}
          color="teal.6"
        >
          <StatusContent
            title={t(`status.${currentChannelGroup}.active`)}
            icon={<IconAccessPoint size={18} color={getTextColor('teal.6', theme)} />}
            content={t(`status.${currentChannelGroup}.appActiveDescription`, { url: websiteUrl, name: name })}
          />
        </StatusPopover>
      ) : (
        <StatusPopover
          ref={ref}
          targetIcon={<IconAccessPointOff size={18} color={getTextColor('red.6', theme)} />}
          color="red.6"
        >
          {isCurrentMobileDistribution ? (
            <StatusContent
              title={t(`status.${currentChannelGroup}.inactive`)}
              icon={<IconAccessPointOff size={18} color={getTextColor('red.6', theme)} />}
              content={t(`status.${currentChannelGroup}.appInactiveDescription`, {
                name: name,
              })}
              link={
                <AnchorLink href={ApplicationSettingsCodeTracking.replace('[appId]', appId)}>
                  <Text
                    onClick={() => {
                      ref?.current?.click()
                    }}
                    color="brandBlue.6"
                  >
                    {t(`status.${currentChannelGroup}.appCheck`)}
                  </Text>
                </AnchorLink>
              }
            />
          ) : (
            <StatusContent
              title={t(`status.${currentChannelGroup}.inactive`)}
              icon={<IconAccessPointOff size={18} color={getTextColor('red.6', theme)} />}
              content={
                pingedAt
                  ? t('status.web.noRecentResponseDescription', {
                      url: websiteUrl,
                    })
                  : t('status.web.noResponseDescription', {
                      url: websiteUrl,
                    })
              }
              link={
                <AnchorLink href={ApplicationSettingsCodeTracking.replace('[appId]', appId)}>
                  <Text
                    onClick={() => {
                      ref?.current?.click()
                    }}
                    color="brandBlue.6"
                  >
                    {t('status.check')}
                  </Text>
                </AnchorLink>
              }
            />
          )}
        </StatusPopover>
      )}
    </>
  )
}

export default ApplicationStatus
