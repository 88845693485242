import { ProductCode } from 'config/constants/products'

export const recommendBadgeOverflowSize = 4
export const recommendProduct = ProductCode.Pro
export const productsModalId = 'productsModal'

export enum CustomizedFeature {
  EntriesCount = 'ENTRIES_COUNT',
  ApplicationsCount = 'APPLICATIONS_COUNT',
  CampaignsCountPerApplication = 'CAMPAIGNS_COUNT_PER_APPLICATION',
  AppApplication = 'APP_APPLICATION',
  Teamwork = 'TEAMWORK',
  WebSupport = 'WEB_SUPPORT',
  Webhook = 'WEBHOOK',
  ImPush = 'IM_PUSH',
  Customer = 'CUSTOMER',
  CampaignTargetingCustomer = 'CAMPAIGN_TARGETING_CUSTOMER',
  CampaignTriggerByEvent = 'CAMPAIGN_TRIGGER_BY_EVENT',
  HideBranding = 'HIDE_BRANDING',
}
