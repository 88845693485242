import React, { useContext, useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useElementSize, usePrevious } from '@mantine/hooks'
import { Collapse, Paper, px, Radio, Stack, useMantineTheme } from '@mantine/core'
import { isNil } from 'lodash-es'
import { WebhookEventType } from 'types/domain'
import WebhookContext from 'components/business/Integration/WebhookContext'
import { useWebhookBuilderFormContext } from '../../../formContext'
import useIsBuilderEditable from '../../../hooks/useIsBuilderEditable'
import Filter from './Filter'
import CampaignSelect from './CampaignSelect'

enum Mode {
  All = 'all',
  Conditional = 'conditional',
}

interface Props {
  onHeightChange: (height: number) => void
}

const Trigger = ({ onHeightChange }: Props) => {
  const { t } = useTranslation('integration')
  const theme = useMantineTheme()
  const form = useWebhookBuilderFormContext()
  const { isAppScope } = useContext(WebhookContext)
  const { ref, height } = useElementSize()
  const previousHeight = usePrevious(height)
  const [mode, setMode] = useState<Mode>(isNil(form.values.conditionGroup) ? Mode.All : Mode.Conditional)
  const editable = useIsBuilderEditable()
  const padding = theme.spacing.xl

  useEffect(() => {
    previousHeight !== height && onHeightChange(height + px(padding) * 2 + 2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, previousHeight])

  const handleModeChange = (value: Mode) => {
    if (editable) {
      setMode(value)
      value === Mode.All && form.setFieldValue('conditionGroup', null)
    }
  }

  const events = form.values.eventTypes
    .map((eventType: WebhookEventType) => t(`webhook.eventType.${eventType}`))
    .join(t('common:punctuation.comma'))
  return (
    <Paper withBorder p={padding} ref={ref}>
      {isAppScope && <CampaignSelect />}
      <Radio.Group value={mode} onChange={handleModeChange}>
        <Stack spacing="sm">
          <Radio value={Mode.All} label={t('webhook.builder.stepTrigger.mode.all', { events })} />
          <Radio value={Mode.Conditional} label={t('webhook.builder.stepTrigger.mode.conditional', { events })} />
        </Stack>
      </Radio.Group>
      <Collapse in={mode === Mode.Conditional} mt="lg">
        <Filter />
      </Collapse>
    </Paper>
  )
}

export default Trigger
