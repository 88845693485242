import React, { FC, useMemo } from 'react'
import { Group, Title } from '@mantine/core'
import { useStyles } from './styles'
import useTranslation from 'next-translate/useTranslation'
import { useOnboardingLayoutContext } from 'components/business/Layouts/OnboardingLayout/context'

type Props = {
  stepCount: number
  onStepClick?(step: number): void
}

const OnboardingSteps: FC<Props> = ({ stepCount = 2, onStepClick }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  const { step } = useOnboardingLayoutContext().values

  const stepElements = useMemo(() => {
    return Array.from({ length: stepCount }).map((_, i) => {
      const selected = step === i
      const label = selected ? `${t('actions.step')}${i + 1}` : `${i + 1}`
      const clickable = i === 0
      return (
        <Title
          key={label}
          className={cx(classes.step, selected && classes.selected, clickable && 'cursor-pointer')}
          onClick={() => clickable && onStepClick?.(i)}
          size="sm"
        >
          {label}
        </Title>
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes.selected, classes.step, step, cx, stepCount, t])

  return (
    <Group p={0} mb="lg" spacing="sm">
      {stepElements}
    </Group>
  )
}

export default OnboardingSteps
