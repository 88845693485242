import React, { FC } from 'react'
import { CloseButton, Grid, Group, useMantineTheme } from '@mantine/core'
import FirstNode from './FirstNode'
import Operator from './Operator'
import SecondNode from './SecondNode'
import { getTextColor } from 'config/themeOverride/getColor'
import { ConditionInput, ConditionOperator, Field } from 'types/domain'
import { Mine } from 'components/business/Filter/DefaultFilter/Conditions/Operator/getOperatorOptions'
import { conditionDataNamePrefix } from './utils/keepConditionIntoView'

type OnChangeEvent = (index: number) => (value: any) => void

type Props = {
  index: number
  condition: ConditionInput
  fields: Field[]
  onFirstNodeChange: OnChangeEvent
  onOperatorChange: OnChangeEvent
  onSecondNodeChange: OnChangeEvent
  onDelete?: (idx: number) => void
  readOnly?: boolean
}

const Condition: FC<Props> = ({
  index,
  condition,
  fields,
  onFirstNodeChange,
  onOperatorChange,
  onSecondNodeChange,
  onDelete,
  readOnly,
}) => {
  const theme = useMantineTheme()
  const valueNodeHidden = [ConditionOperator.IsNull, ConditionOperator.IsNotNull, Mine].includes(condition.operator)

  return (
    <Group key={condition.firstNode.value + `${index}`} noWrap spacing={0} data-name={conditionDataNamePrefix + index}>
      <div className="flex-1">
        <Grid gutter="sm" my={0} p={0} columns={10}>
          <Grid.Col span={3}>
            <FirstNode
              fields={fields}
              defaultValue={condition.firstNode.value}
              onChange={onFirstNodeChange(index)}
              readOnly={readOnly}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Operator
              fieldCode={condition.firstNode.value}
              fields={fields}
              value={condition.operator}
              onChange={onOperatorChange(index)}
              readOnly={readOnly}
            />
          </Grid.Col>
          {!valueNodeHidden && (
            <Grid.Col span={5}>
              <SecondNode
                condition={condition}
                fields={fields}
                onChange={onSecondNodeChange(index)}
                index={index}
                readOnly={readOnly}
              />
            </Grid.Col>
          )}
        </Grid>
      </div>
      {!readOnly && (
        <Group>
          <CloseButton
            size={24}
            iconSize={16}
            onClick={() => onDelete?.(index)}
            sx={{ color: getTextColor('gray.7', theme) }}
          />
        </Group>
      )}
    </Group>
  )
}

export default Condition
