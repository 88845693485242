import { DynamicField, FieldType } from 'components/business'
import DataContext from 'components/business/Filter/DefaultFilter/DataContext'
import { useContext, useMemo } from 'react'

function getStaticOptions(fields: FieldType[]) {
  return fields.map((item) => ({
    value: item.code,
    label: item.label,
    type: item.type,
    group: item.group,
  }))
}

function getDynamicOptions(firstNodeFields: DynamicField[]) {
  return firstNodeFields.map((field) => {
    return {
      id: field?.id,
      value: field.code,
      label: field.label,
      group: field.group,
    }
  })
}

export default function useOptions(shadowFirstNodeFields: DynamicField[]) {
  const { fields, dynamic } = useContext(DataContext)

  return useMemo(() => {
    return dynamic ? getDynamicOptions(shadowFirstNodeFields) : getStaticOptions(fields)
  }, [dynamic, fields, shadowFirstNodeFields])
}
