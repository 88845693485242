import { Feature } from 'types/domain'
import { isMobileDistribution } from 'utils/applicationUtils'
import { useCheckFeature } from 'components/business'
import { useContext } from 'react'
import ApplicationContext from 'components/business/Layouts/ApplicationLayout/ApplicationContext'
import useCheckQuota from 'components/business/hooks/useCheckQuota'

export default function useCheckFeatureAlert() {
  const application = useContext(ApplicationContext).application
  const checkFeature = useCheckFeature(true)
  const checkQuota = useCheckQuota(true)
  if (!checkQuota(Feature.EntriesCount, false)) {
    return Feature.EntriesCount
  }
  if (isMobileDistribution(application) && !checkFeature(Feature.AppApplication)) {
    return Feature.AppApplication
  }
  if (!checkQuota(Feature.ApplicationsCount, true)) {
    return Feature.ApplicationsCount
  }
}
