import React, { useEffect, useRef, useState } from 'react'
import { Anchor, Button, ButtonProps, Text } from '@mantine/core'
import { GraphQLError } from 'graphql'

import { CaptchaType, useGeetestConfigurationLazyQuery, useSendCaptchaMutation } from 'types/domain'
import { getGeetestResult } from 'components/ui/Geetest'
import { graphqlErrorCatch, Storage } from 'utils'
import { useGeetest } from 'hooks'

export type SenderDisplayType = 'button' | 'link'

export interface SenderProps extends ButtonProps {
  dateId: string
  getValidIdentifier: () => Promise<string>
  captchaType: CaptchaType
  countDown?: number
  onError?: (graphQLErrors: GraphQLError[], defaultErrorHandler: () => void) => void
  onSend?: () => void
  getCountDownText?: (countDown: number) => string
  defaultText?: string
  displayType?: SenderDisplayType
}

let timer: NodeJS.Timer

const Sender = (props: SenderProps) => {
  const {
    dateId,
    countDown: countDownProp = 60,
    captchaType,
    getValidIdentifier,
    onError,
    onSend,
    getCountDownText,
    defaultText,
    displayType = 'button',
    disabled,
    ...buttonProps
  } = props
  const [countDown, setCountDown] = useState(0)
  const [sendCaptchaMutation] = useSendCaptchaMutation()
  const [getGeetestConfiguration, captchaConfigurationRes] = useGeetestConfigurationLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const identifierRef = useRef<string | null>(null)

  useEffect(() => {
    getGeetestConfiguration().then()
    //  eslint-disable-next-line
  }, [])

  const handleSendCode = async (captcha: any) => {
    try {
      await sendCaptchaMutation({
        variables: {
          input: {
            identifier: identifierRef.current!,
            type: captchaType,
            geetestInfo: captcha && getGeetestResult(captcha),
          },
        },
      })
      timer && clearInterval(timer)
      timer = setInterval(() => setCountDown((countDown) => (countDown > 0 ? countDown - 1 : 0)), 1000)
      setCountDown(countDownProp)
      Storage.setItem(dateId, { date: `${new Date().valueOf()}` })
      onSend?.()
    } catch (error: any) {
      const { graphQLErrors } = error
      const defaultErrorHandler = () => {
        graphqlErrorCatch({
          graphQLErrors,
          mapping: new Map([['error.frequent', '验证码请求太频繁，请稍后重试']]),
          defaultMessage: '验证码获取失败',
        })
      }

      onError ? onError(graphQLErrors, defaultErrorHandler) : defaultErrorHandler()
    }
  }

  const [captcha, captchaConfiguration] = useGeetest(handleSendCode, captchaConfigurationRes)

  const handleButtonClick = () => {
    getValidIdentifier()
      .then((value) => {
        identifierRef.current = value
        if (captchaConfiguration?.enabled) {
          captcha?.showBox()
        } else {
          handleSendCode(null).then()
        }
      })
      .catch(() => {})
  }

  useEffect(() => {
    const now = new Date().valueOf()
    const lastTime = parseInt(Storage.getItem(dateId)?.date || '0', 10)
    const gap = Math.round((now - lastTime) / 1000)

    if (gap < countDownProp) {
      timer = setInterval(() => setCountDown((countDown) => (countDown > 0 ? countDown - 1 : 0)), 1000)
      setCountDown(countDownProp - gap)
    } else {
      setCountDown(0)
    }

    return () => clearInterval(timer)
    // eslint-disable-next-line
  }, [])

  const text = countDown ? getCountDownText?.(countDown) ?? `${countDown}s 后重发` : defaultText ?? '获取验证码'

  if (displayType === 'link') {
    const { color, size } = buttonProps
    return countDown ? (
      <Text color={color} size={size} styles={{ display: 'inline' }}>
        {text}
      </Text>
    ) : (
      <Anchor size={size} onClick={handleButtonClick}>
        {text}
      </Anchor>
    )
  }

  return (
    <Button fullWidth {...buttonProps} disabled={disabled || !!countDown} onClick={handleButtonClick}>
      {text}
    </Button>
  )
}

export default Sender
