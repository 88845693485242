import React, { FC, PropsWithChildren } from 'react'
import { Application, CampaignType } from 'types/domain'
import BlankDesktopCampaigns from './BlankDesktopCampaigns'
import BlankMobileCampaigns from './BlankMobileCampaigns'
import { MobileAppDistributionChannel } from 'config/constants'
import useApplicationInfoBaseInfoCache from 'components/business/hooks/useApplicationInfoBaseInfoCache'
import { isMobileDistribution } from 'utils/applicationUtils'

type Props = PropsWithChildren & {
  type: CampaignType
  title: string
  description: string[]
}

const BlankCampaigns: FC<Props> = (props) => {
  const { application, channel } = useApplicationInfoBaseInfoCache()
  const isCurrentMobileDistribution = isMobileDistribution(application as Application)

  if (!channel) {
    return null
  }

  if (isCurrentMobileDistribution) {
    return <BlankMobileCampaigns {...props} channel={channel as MobileAppDistributionChannel} />
  }
  return <BlankDesktopCampaigns {...props} />
}

export default BlankCampaigns
