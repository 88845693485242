import DateTimeField from './dateTimeField'
import RadioButton from './radioButton'
import CheckBox from './checkBox'
import DropDown from './dropDown'
import RatingField from './ratingField'
import MobileField from './mobileField'
import CsatField from './csatField'

type FieldDefinition = {
  format: (value: any, field: any) => HTMLElement | string
  formatDetail?: (value: any, field: any) => HTMLElement | JSX.Element | JSX.Element[] | string
  formatAsText?: (value: any, field: any) => string | string[]
}

const Fields: { [key: string]: FieldDefinition } = {
  DateTimeField,
  RadioButton,
  CheckBox,
  DropDown,
  RatingField,
  MobileField,
  CsatField,
  CtaField: RadioButton,
}

export default Fields
