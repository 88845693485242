import React, { memo, useCallback, useMemo, useState } from 'react'
import { isEmpty } from 'lodash-es'

import filterEmptyCondition from './scripts/filterEmptyCondition'
import formatFields from './scripts/formatFields'
import {
  AdderType,
  ConditionGroupType,
  ConditionType,
  FieldType,
  FilterValueType,
  FirstNodeType,
  SecondNodeType,
} from '../type'
import { ConditionGroupProvider } from './ConditionGroup'
import ConditionGroupOperator from './ConditionGroupOperator'
import DataContext from './DataContext'
import Conditions from './Conditions'
import { defaultFirstNode, defaultSecondNode, defaultValue } from './constants'
import ConditionAdder from './Adder'
import omitDeep from 'omit-deep-lodash'

interface Props {
  value?: FilterValueType | null
  fields?: FieldType[]
  onChange?: (data: any, hasIncompleteCondition?: boolean) => void
  dynamic?: boolean
  firstNode?: FirstNodeType
  secondNode?: SecondNodeType
  keepInvalidCondition?: boolean
  maxLength?: number
  defaultCondition?: ConditionType
  collapseCondition?: boolean
  batchEdit?: boolean
  adder?: AdderType
}

const DefaultFilter = (props: Props) => {
  const {
    fields,
    value,
    onChange,
    dynamic = false,
    firstNode = defaultFirstNode,
    secondNode = defaultSecondNode,
    keepInvalidCondition = false,
    maxLength = 1,
    defaultCondition,
    collapseCondition = false,
    batchEdit = false,
    adder,
  } = props
  const resultFields = formatFields(fields)

  const initialValue = useMemo(() => {
    if (value?.conditions?.length) {
      const group = omitDeep(value, ['firstNode.type']) as ConditionGroupType
      if (firstNode.getFieldType) {
        group.conditions.forEach((condition) => {
          if (condition.firstNode.value) {
            condition.firstNode.type = firstNode.getFieldType!(condition.firstNode.value)
          }
        })
      }
      return group
    }
    return defaultValue
  }, [firstNode.getFieldType, value])
  const [showConditionGroupOperator, setShowConditionGroupOperator] = useState(initialValue.conditions.length > 1)

  const handleChange = useCallback(
    (data: ConditionGroupType) => {
      try {
        const conditions = !isEmpty(data.conditions) ? data.conditions : []
        const result = {
          operator: data.operator,
          conditions,
        }
        if (conditions.length > 0 || conditions.length === data.conditions.length) {
          if (keepInvalidCondition) {
            onChange?.(result, conditions.length < data.conditions.length)
          } else {
            onChange?.(filterEmptyCondition(result), conditions.length < data.conditions.length)
          }
          setShowConditionGroupOperator(conditions.length > 1)
        }
      } catch (e: any) {
        onChange?.(initialValue)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields]
  )

  return (
    <DataContext.Provider
      value={{
        fields: resultFields,
        dynamic,
        firstNode,
        secondNode,
        maxLength,
        defaultCondition,
      }}
    >
      <ConditionGroupProvider defaultValue={initialValue} onChange={handleChange}>
        {showConditionGroupOperator && <ConditionGroupOperator />}
        <Conditions collapseCondition={collapseCondition} batchEdit={batchEdit} />
        {maxLength > 1 && <ConditionAdder {...adder} />}
      </ConditionGroupProvider>
    </DataContext.Provider>
  )
}

export default memo(DefaultFilter)
