import { Avatar, Group, Menu, Stack, Text } from '@mantine/core'
import { openModal } from '@mantine/modals'
import { IconBuilding, IconLogout, IconSwitchHorizontal, IconUserCircle } from '@tabler/icons-react'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useLogoutMutation } from 'types/domain'
import { Login, SystemAccount, SystemProfile } from 'config/constants'
import AccountSwitcher from '../AccountSwitcher'
import { getAvatarUrlByUser } from 'utils'
import appContext from 'service'
import useCurrentUserCache from 'components/business/hooks/useCurrentUserCache'
import usePersistorClient from 'components/business/hooks/usePersistorClient'
import useApollo from 'hooks/useApollo'
import { XM_DATA } from 'config/constants/localStorage'
import { NextLink } from 'components/ui'

const MenuContent = () => {
  const { t } = useTranslation('common')
  const [logout] = useLogoutMutation()
  const { user, account } = useCurrentUserCache()
  const client = useApollo({})
  const { clearCache } = usePersistorClient({ client: client, key: XM_DATA, restoreOnInit: false })

  const handleOpenSwitcherModal = () => {
    openModal({
      modalId: 'accountSwitcherModal',
      title: (
        <Text size="md" weight={600}>
          {t('switchAccount')}
        </Text>
      ),
      children: <AccountSwitcher />,
      centered: true,
      size: 380,
    })
  }

  const handleLogout = () => {
    logout().then(() => {
      window.location.href = Login
    })
    clearCache()
    appContext.reset()
  }

  return (
    <>
      <Group align="center" spacing="sm" py="md" px="md" noWrap>
        <Avatar size={28} radius="xl" src={getAvatarUrlByUser(user)} />
        <Stack spacing={0}>
          <Text weight="600" lineClamp={1} className="break-all">
            {user?.name}
          </Text>
          {!!account && (
            <Text size="xs" color="dimmed" lineClamp={1} className="break-all">
              {account.name}
            </Text>
          )}
        </Stack>
      </Group>
      <Menu.Divider my="xs" />
      <Menu.Item
        px="lg"
        py="sm"
        icon={<IconUserCircle size={20} stroke={1.5} />}
        component={NextLink}
        href={SystemProfile}
      >
        {t('userInfo')}
      </Menu.Item>
      <Menu.Item
        px="lg"
        py="sm"
        icon={<IconBuilding size={20} stroke={1.5} />}
        component={NextLink}
        href={SystemAccount}
      >
        {t('accountInfo')}
      </Menu.Item>
      <Menu.Item
        px="lg"
        py="sm"
        closeMenuOnClick={false}
        icon={<IconSwitchHorizontal size={20} stroke={1.5} />}
        onClick={handleOpenSwitcherModal}
      >
        {t('switchAccount')}
      </Menu.Item>
      <Menu.Divider my="xs" />
      <Menu.Item px="lg" py="sm" icon={<IconLogout size={20} stroke={1.5} />} color="red" onClick={handleLogout}>
        {t('logout')}
      </Menu.Item>
    </>
  )
}

export default MenuContent
