import { useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { CampaignDistributionChannel, Field, FormFieldType } from 'types/domain'
import { commentedCode, deliveryMethodCode, refererCode, submitOnCode } from 'config/constants/fieldType'
import { CUSTOMER_EMAIL, CUSTOMER_MOBILE, CUSTOMER_NAME, CUSTOMER_UID } from 'config/constants'
import { UNFILTERABLE_FIELD_TYPES } from '../../Entries/constants'
import { first } from 'lodash-es'
import useApplicationInfoBaseInfoCache from 'components/business/hooks/useApplicationInfoBaseInfoCache'

const baseCodes = [submitOnCode, deliveryMethodCode, commentedCode, refererCode]
const customerCodes = [CUSTOMER_UID, CUSTOMER_MOBILE, CUSTOMER_EMAIL, CUSTOMER_NAME]

export default function useEntryFilterableFields() {
  const { t } = useTranslation('componentEntryFilter')
  const { channel } = useApplicationInfoBaseInfoCache()
  const systemFields = useMemo(() => {
    return baseCodes
      .filter((code) => {
        return channel !== CampaignDistributionChannel.Link || ![deliveryMethodCode].includes(code)
      })
      .map((code) => {
        return {
          label: t(`componentEntryFilter:systemFields.entry.${code}`),
          type: `entry.${code}`,
          code,
        }
      }) as Field[]
    // eslint-disable-next-line
  }, [])

  const customerField = customerCodes.map(
    (code) =>
      ({
        label: t(`customer:${code}`),
        type: code,
        code,
      } as Field)
  )

  return (
    fields: Field[],
    customerAttributes: { code: string; aliases?: string[] | null }[],
    excludeCodes?: string[]
  ) => {
    const fieldsWithAlias = fields.map((f) => ({
      ...f,
      // 为了用户属性的alias支持搜索
      alias: first(customerAttributes.find((attributeItem) => attributeItem.code === f.code)?.aliases),
    }))
    return [...fieldsWithAlias, ...customerField, ...systemFields]
      .filter((field) => !UNFILTERABLE_FIELD_TYPES.includes(field.type as FormFieldType))
      .filter((field) => !excludeCodes?.includes(field.code))
  }
}
