import { PropsWithChildren } from 'react'
import { Title } from '@mantine/core'

export const subTitleLineHeightPx = 20

const SubTitle = ({ children }: PropsWithChildren) => {
  return (
    <Title order={6} color="gray.7" lh={`${subTitleLineHeightPx}px`}>
      {children}
    </Title>
  )
}

export default SubTitle
