import React from 'react'
import { Anchor, Box, Group, Skeleton, Stack, Title, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { SegmentsByIdDocument, useSegmentsByIdQuery } from 'types/domain'

import { isCacheHit } from 'plugins/apollo/cacheUtils'
import useAddToSegments from 'components/business/Customer/AddToSegments'
import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'
import { CustomerDetailBasicProps } from '../CustomerDetail/type'

type Props = Pick<CustomerDetailBasicProps, 'id' | 'manageable'>

const CustomerSegments = ({ id, manageable }: Props) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('pageCustomerSegment')
  const { open } = useAddToSegments()
  const { data, loading, variables } = useSegmentsByIdQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  })

  const segments = data?.customer?.segments ?? []

  const handleAddClick = () => {
    open({
      customerIds: [id],
      existedSegmentIds: segments.map((segment) => segment.id),
    })
  }

  return (
    <Stack spacing="md">
      <Group position="apart">
        <Title order={6}>{t('pageTitle')}</Title>
        {manageable && (
          <Anchor underline={false} onClick={handleAddClick}>
            {t('customersActions.addToSegment')}
          </Anchor>
        )}
      </Group>
      <Group spacing="md">
        {loading && !isCacheHit(variables, SegmentsByIdDocument || true) ? (
          <Skeleton height={26} radius="xl" width="50%" />
        ) : (
          <>
            {segments?.map((segment) => (
              <Box
                key={segment.id}
                sx={{
                  padding: '2px 4px',
                  background: getBackgroundColor('gray.1', theme),
                  color: getTextColor('gray.9', theme),
                  fontSize: theme.fontSizes.sm,
                  borderRadius: theme.radius.xs,
                }}
              >
                {segment.name}
              </Box>
            ))}
          </>
        )}
      </Group>
    </Stack>
  )
}

export default CustomerSegments
