import useTranslation from 'next-translate/useTranslation'
import { StatusBadge } from 'components/ui'

type Props = {
  enabled: boolean
}

const StatusIndicator = ({ enabled }: Props) => {
  const { t } = useTranslation('componentCampaignList')

  return <StatusBadge value={enabled} onText={t('statusSwitcher.opened')} offText={t('statusSwitcher.closed')} />
}
export default StatusIndicator
