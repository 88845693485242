import React, { FC, useEffect, useState } from 'react'
import { Group, Text, Title } from '@mantine/core'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { BackButton } from 'components/ui/Button'
import { ApplicationFeedback, ApplicationNotification, ApplicationSurvey } from 'config/constants'
import { DeliveryUrl, DistributionReview } from 'components/business'
import { Campaign, CampaignDistributionChannel, CampaignType, CampaignWidgetType } from 'types/domain'
import { useCampaign } from 'components/business/Campaign/CampaignContext'
import { DistributionAffectionIndicator, StatusIndicator } from 'components/business/Campaign'
import { formatISODate } from 'utils'
import useApplicationInfoBaseInfoCache from 'components/business/hooks/useApplicationInfoBaseInfoCache'
import LinkWithAction from 'components/ui/LinkWithAction'
import { getXFormUrl } from 'utils/getXFormUrl'
import CampaignFormStatusSwitch from '../../CampaignFormStatusSwitch'
import ActionButton from 'components/ui/Button/ActionButton'
import ActionMenus from 'components/business/Campaign/ActionMenus'
import useGroupCache from 'components/business/Campaign/hooks/useGroupCache'
import { isSystemGroup, SYSTEM_GROUP } from 'components/business/Campaign/List/Filter'
import { ca } from 'date-fns/locale'
import useCampaignBaseInfoCache from 'components/business/hooks/useCampaignBaseInfoCache'

const campaignPathMapping: { [x: string]: string } = {
  [CampaignType.Survey]: ApplicationSurvey,
  [CampaignType.Feedback]: ApplicationFeedback,
  [CampaignType.Notification]: ApplicationNotification,
}

const Header: FC = () => {
  const router = useRouter()
  const { appId } = router.query
  const { t } = useTranslation('common')
  const { channel } = useApplicationInfoBaseInfoCache()
  const campaign = useCampaign().campaign!
  const { campaign: cachedCampaign } = useCampaignBaseInfoCache(campaign.id)
  const campaignId = campaign.id
  const url = getXFormUrl(campaign.token, campaign.type, true)
  const campaignPath = campaignPathMapping[campaign.type].replace('[appId]', appId as string)
  const { getDefaultGroup } = useGroupCache()
  const [groupId, setGroupId] = useState<string | null>(null)

  useEffect(() => {
    const defaultGroupId = getDefaultGroup(campaign.type)
    if (
      !defaultGroupId ||
      defaultGroupId === (SYSTEM_GROUP.ALL as string) ||
      campaign.groupIds.includes(defaultGroupId)
    ) {
      return
    }
    if (defaultGroupId !== (campaign.enabled ? SYSTEM_GROUP.ENABLED : SYSTEM_GROUP.DISABLED)) {
      setGroupId(SYSTEM_GROUP.ALL)
    }
  }, [campaign.enabled])

  return (
    <Group position="apart">
      <Group spacing="xs">
        <BackButton
          href={{
            pathname: campaignPathMapping[campaign?.type!],
            query: {
              appId,
              groupId,
            },
          }}
        />
        <Title order={5}>{campaign?.name}</Title>
        <ActionButton>
          <ActionMenus
            campaign={campaign as Campaign}
            onDelete={() => {
              router.push(campaignPath).then()
            }}
          />
        </ActionButton>
      </Group>
      <Group>
        <Text color="dimmed">{`${campaign?.creator?.name} ${t('createdAt', {
          dateTime: formatISODate(campaign?.createdAt),
        })}`}</Text>
        {channel === CampaignDistributionChannel.Link ? (
          <>
            <LinkWithAction url={url} />
            <CampaignFormStatusSwitch id={campaignId} />
          </>
        ) : (
          <>
            <DistributionReview id={campaignId} />
            {campaign.widget?.type !== CampaignWidgetType.Banner && <DeliveryUrl id={campaignId} />}
            <StatusIndicator enabled={campaign?.enabled!} />
            <DistributionAffectionIndicator id={campaignId} />
          </>
        )}
      </Group>
    </Group>
  )
}

export default Header
