import React, { FC, useEffect, useRef } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { throttle } from 'lodash-es'

import { AnchorLink, openAlertModal } from 'components/ui'
import { useCreateEntryReplyMutation } from 'types/domain'
import { graphqlErrorCatch } from 'utils'
import MessageAdder, { MessageAdderApi } from '../components/MessageAdder'
import Trans from 'next-translate/Trans'
import { replyHelpLink } from 'config'

type Props = {
  entryId: string
  disabled: boolean
  onSave: () => void
}

const ReplyAdder: FC<Props> = ({ entryId, disabled, onSave }) => {
  const { t } = useTranslation('entry')
  const [senEntryReplyMutation] = useCreateEntryReplyMutation()

  const messageAdderRef = useRef<MessageAdderApi | null>(null)

  useEffect(() => {
    messageAdderRef?.current?.reset()
  }, [entryId])

  const handleSave = throttle(async (entryId: string, value: string, afterSave: () => void) => {
    try {
      const { data } = await senEntryReplyMutation({
        variables: {
          input: {
            entryId,
            content: value?.trim()!,
          },
        },
        update: (cache, { data }) => {
          if (!data?.createEntryReply) {
            return
          }
          cache.modify({
            id: cache.identify({ __typename: 'Entry', id: entryId }),
            fields: {
              replies: (existingRepliesRef) => {
                return [...existingRepliesRef, data?.createEntryReply]
              },
            },
          })
        },
      })
      if (data?.createEntryReply) {
        onSave()
        afterSave()
      }
    } catch (e: any) {
      const { graphQLErrors } = e
      graphqlErrorCatch({
        graphQLErrors,
        mapping: new Map([
          [
            'error.create_conflict',
            <Trans
              key="entry:entryModal.reply.sent.existed"
              i18nKey="entry:entryModal.reply.sent.existed"
              components={{
                component: <AnchorLink href={replyHelpLink} target="_blank" />,
              }}
            />,
          ],
        ]),
        defaultMessage: `${t('common:actions.send')}${t('common:message.failed')}`,
      })
    }
  }, 1000)

  const handleConfirmSend = (entryId: string, value: string, afterSave: () => void) => {
    openAlertModal({
      hideIcon: true,
      title: t('entryModal.reply.confirm.title'),
      content: t('entryModal.reply.confirm.content'),
      labels: { confirm: t('common:actions.send'), cancel: t('common:actions.cancel') },
      onConfirm: async () => {
        await handleSave(entryId, value, afterSave)
      },
    })
  }

  return (
    <MessageAdder
      minRows={3}
      ref={messageAdderRef}
      disabled={disabled}
      enterSaveEnabled={false}
      disabledTooltip={
        <Trans
          i18nKey="entry:entryModal.reply.sent.existed"
          components={{
            component: <AnchorLink href={replyHelpLink} target="_blank" />,
          }}
        />
      }
      placeholder={t('entryModal.reply.placeholder')}
      onSave={(value, afterSave) => handleConfirmSend(entryId, value, afterSave)}
    />
  )
}

export default ReplyAdder
