import React, { FC, ReactNode } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Group, NavLink } from '@mantine/core'
import { Center } from '@mantine/core'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import { Icon } from 'components/ui'

interface MainLinkProps {
  pathname: string
  icon: ReactNode
  iconActive: ReactNode
  label: string
  activePathnames?: string[]
  className?: string
  isNewFeature?: boolean
}

const MainLink: FC<MainLinkProps> = (props) => {
  const { pathname, icon, iconActive, label, activePathnames, className = '', isNewFeature } = props
  const {
    pathname: currentPath,
    query: { appId },
  } = useRouter()
  const active = currentPath === pathname || activePathnames?.includes(currentPath)

  return (
    <Link href={{ pathname, query: appId ? { appId } : {} }} passHref legacyBehavior>
      <NavLink
        component="a"
        px="md"
        py="xs"
        mb="xs"
        variant="light"
        label={
          <Group>
            <span>{label}</span>
            {isNewFeature && <Icon name="newFeature" className="h-[22px] items-center" />}
          </Group>
        }
        active={active}
        icon={<Center pr="xs">{active ? iconActive : icon}</Center>}
        className={className}
        styles={(theme) => ({
          root: {
            borderRadius: theme.radius.sm,
            '&:hover': {
              backgroundColor: getBackgroundColor('gray.1', theme),
            },
          },
        })}
      />
    </Link>
  )
}

export default MainLink
