import React from 'react'
import { Center, Group, Text, useMantineTheme } from '@mantine/core'
import { getBackgroundColor, getBorderColor, getTextColor } from 'config/themeOverride/getColor'
import { IconCheck } from '@tabler/icons-react'

type Props = {
  checked?: boolean
  label?: string
}

const RadioBtn = (props: Props) => {
  const theme = useMantineTheme()
  const { checked, label } = props

  return (
    <Group spacing="sm">
      <Center
        className="xm-onboarding-radio"
        data-testid="radio"
        sx={(theme) => ({
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          border: checked ? 'none !important' : `1px solid ${getBorderColor('gray.3', theme)}`,
          background: checked
            ? `${getBackgroundColor('green.1', theme)} !important`
            : getBackgroundColor('white', theme),
        })}
      >
        {checked && <IconCheck stroke={2} size={16} color={getTextColor('green.6', theme)} />}
      </Center>
      {label && <Text>{label}</Text>}
    </Group>
  )
}

export default RadioBtn
