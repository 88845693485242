import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useMemo } from 'react'
import { useSetState } from '@mantine/hooks'
import { isEmpty } from 'lodash-es'

import { closeAddToSegmentModal } from 'components/business/Customer/AddToSegments'
import { EntryModal } from 'components/business/Campaign/EntryModal'
import { EntryDetailApi, EntryDetailProps, ModalState } from './type'

const initModalState = {
  index: 0,
  opened: false,
  data: null,
  isCommentColumn: false,
}

const EntryDetail: ForwardRefRenderFunction<EntryDetailApi, EntryDetailProps> = (props, ref) => {
  const { page, pageSize, totalPages, tableData, onPageChange, onRemove } = props
  const [state, setState] = useSetState<ModalState>(initModalState)
  const { index, data, opened, targetAside } = state

  const previousDisabled = useMemo(() => page === 1 && index === 0, [index, page])
  const nextDisabled = useMemo(
    () => page === totalPages && tableData.length - 1 === index,
    [index, page, tableData.length, totalPages]
  )

  useEffect(() => {
    if (!isEmpty(tableData) && opened) {
      setState({
        data: tableData[index],
      })
    }
  }, [index, opened, setState, tableData])

  useImperativeHandle(ref, () => ({
    openModal: (index, targetAside) => {
      setState({
        index,
        data: tableData[index],
        opened: true,
        targetAside,
      })
    },
  }))

  const handlePrevious = () => {
    shouldLoadNewPage(index - 1)
  }

  const handleNext = () => {
    shouldLoadNewPage(index + 1)
  }

  const shouldLoadNewPage = (newIndex: number) => {
    if (newIndex < 0) {
      onPageChange(page - 1)
      setState({ index: pageSize - 1 })
    }

    if (newIndex >= pageSize) {
      onPageChange(page + 1)
      setState({
        index: 0,
      })
    }

    if (newIndex > -1 && newIndex <= tableData.length - 1) {
      setState({
        index: newIndex,
        data: tableData[newIndex],
      })
    }
  }

  const handleClose = () => {
    setState(initModalState)
    closeAddToSegmentModal()
  }

  return (
    <>
      {data && (
        <EntryModal
          data={data}
          opened={opened}
          onClose={handleClose}
          previousDisabled={previousDisabled}
          nextDisabled={nextDisabled}
          onPrevious={handlePrevious}
          onNext={handleNext}
          targetAside={targetAside}
          onRemove={onRemove}
        />
      )}
    </>
  )
}

export default forwardRef(EntryDetail)
