import React, { FC } from 'react'
import { ConditionGroupInput, ConditionOperator, Field } from 'types/domain'
import update from 'immutability-helper'
import { DATETIME_FIELD } from 'config/constants/fieldType'
import Condition from 'components/business/Filter/FieldFilterPopover/Filter/Conditions/Condition'
import { getOperatorsByFirstNodeCode } from './Operator/getOperatorOptions'

export interface ConditionsProps {
  conditions?: ConditionGroupInput['conditions']
  onChange?(freshConditions: ConditionGroupInput['conditions']): void
  fields?: Field[]
  onDelete?(index: number): void
  readOnly?: boolean
}

const Conditions: FC<ConditionsProps> = ({ fields = [], conditions = [], onDelete, onChange, readOnly }) => {
  const onFirstNodeChange = (conditionIndex: number) => (value: string) => {
    const defaultOperator = getOperatorsByFirstNodeCode(fields, value)[0]
    onChange?.(
      update(conditions, {
        [conditionIndex]: {
          firstNode: { value: { $set: value } },
          operator: { $set: defaultOperator as ConditionOperator },
          secondNode: { $set: { value: '' } },
        },
      })
    )
  }
  const onOperatorNodeChange = (conditionIndex: number) => (value: string) => {
    onChange?.(
      update(conditions, {
        [conditionIndex]: {
          operator: { $set: value as ConditionOperator },
          secondNode: { $set: { value: '' } },
        },
      })
    )
  }

  const onSecondNodeChange = (conditionIndex: number) => (value: any) => {
    const isDateCondition =
      fields.find((field) => field.code === conditions[conditionIndex].firstNode.value)?.type === DATETIME_FIELD
    const freshValue = update(conditions, {
      [conditionIndex]: {
        secondNode: isDateCondition ? { $set: value } : { value: { $set: value } },
      },
    })
    onChange?.(freshValue)
  }

  return (
    <>
      {conditions.map((condition, idx) => (
        <Condition
          key={condition.firstNode.value + `${idx}`}
          condition={condition}
          fields={fields}
          onFirstNodeChange={onFirstNodeChange}
          onSecondNodeChange={onSecondNodeChange}
          index={idx}
          onDelete={onDelete}
          onOperatorChange={onOperatorNodeChange}
          readOnly={readOnly}
        />
      ))}
    </>
  )
}

export default Conditions
