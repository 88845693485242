import React, { useMemo } from 'react'
import { Box, Group, LoadingOverlay, useMantineTheme } from '@mantine/core'
import router from 'next/router'

import { outsideModalBodyHeight, PAGE_SIZE } from 'config/constants'
import { isCacheHit } from 'plugins/apollo/cacheUtils'
import {
  CampaignType,
  CampaignWidgetType,
  CustomApplicationEventsDocument,
  Field,
  useCustomApplicationEventsQuery,
} from 'types/domain'
import { groupFields } from 'utils'

import { AsideType, EntryModalData } from '../type'
import Aside from './Aside'
import FeedbackMain from './Feedback'
import SurveyMain from './Survey'
import { getBorderColor } from 'config/themeOverride/getColor'

type Props = {
  campaignType: CampaignType
  widgetType: CampaignWidgetType
  canAddToSegment: boolean
  fields: Field[]
  data: EntryModalData
  targetAside?: AsideType
  onLightboxToggle?: (value: boolean) => void
  updateReplyCallBack?: () => void
}

function getIsDisplayedLikeSurveyForm(type: CampaignType, widgetType: CampaignWidgetType) {
  return (
    type === CampaignType.Survey || (type === CampaignType.Notification && widgetType !== CampaignWidgetType.Banner)
  )
}

const EntryModalContent = (props: Props) => {
  const theme = useMantineTheme()
  const { canAddToSegment, data, targetAside, fields, campaignType, widgetType, updateReplyCallBack } = props
  const appId = router.query.appId as string
  const isDisplayedSurveyForm = getIsDisplayedLikeSurveyForm(campaignType, widgetType)

  const { formFields, customerFields } = useMemo(() => groupFields(fields), [fields])

  const {
    data: eventsData,
    loading,
    variables,
  } = useCustomApplicationEventsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: appId,
      pageArguments: { first: PAGE_SIZE.UN_LIMIT },
    },
  })

  const events = useMemo(
    () => eventsData?.application?.customApplicationEvents?.nodes || [],
    [eventsData?.application?.customApplicationEvents?.nodes]
  )

  return (
    <Group spacing={0} align="self-start" w={1000} h={`calc(100vh - ${outsideModalBodyHeight}px)`}>
      {loading && !isCacheHit(variables, CustomApplicationEventsDocument) ? (
        <LoadingOverlay visible={true} />
      ) : (
        <>
          <Box w="64%" h="100%">
            {isDisplayedSurveyForm ? (
              <SurveyMain data={data} formFields={formFields} />
            ) : (
              <FeedbackMain
                entry={data.entry}
                formFields={formFields}
                customerFields={customerFields}
                customEvents={events}
                campaignType={campaignType!}
              />
            )}
          </Box>
          <Box w="36%" sx={{ borderLeft: `1px solid ${getBorderColor('gray.2', theme)}` }} h="100%">
            <Aside
              campaignType={campaignType!}
              entry={data.entry}
              targetAside={targetAside}
              isDisplayedSurveyForm={isDisplayedSurveyForm}
              customerFields={customerFields}
              customEvents={events}
              canAddToSegment={canAddToSegment}
              updateReplyCallBack={updateReplyCallBack}
            />
          </Box>
        </>
      )}
    </Group>
  )
}

export default EntryModalContent
