import React, { FC } from 'react'
import { Group, Image, Text } from '@mantine/core'

import { csatImages, splitCharCode } from 'config/constants'

const CsatFieldCell: FC<any> = ({ renderedCellValue }) => {
  if (!renderedCellValue) return null
  const [score, label] = renderedCellValue?.split(splitCharCode)

  return (
    <Group spacing="xs" align="center">
      <Image width={20} height={20} src={csatImages[score - 1].src} alt={label ?? ''} />
      <Text>{label}</Text>
    </Group>
  )
}

export default CsatFieldCell
