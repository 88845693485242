import React, { FC, useEffect, useState } from 'react'
import { Button, Popover, useMantineTheme, Paper } from '@mantine/core'
import { PopoverBaseProps } from '@mantine/core/lib/Popover/Popover'
import { IconFilter } from '@tabler/icons-react'
import useTranslation from 'next-translate/useTranslation'

import { getTextColor } from 'config/themeOverride/getColor'
import { getConditionValid } from 'utils/condition'
import { ConditionGroupInput } from 'types/domain'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import usePropCloseOnClickOutside from './hooks/usePropCloseOnClickOutside'
import Filter, { FieldFilterProps } from './Filter'

interface Props extends FieldFilterProps {
  position?: PopoverBaseProps['position']
  width?: number
  withPaper?: boolean
}

const FieldFilterPopover: FC<Props> = ({ position = 'bottom-start', width = 610, withPaper = false, ...rest }) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('common')
  const [currentConditionsTotalCount, setCurrentConditionsTotalCount] = useState(0)
  useEffect(() => {
    setCurrentConditionsTotalCount(rest?.value?.conditions?.length || 0)
  }, [rest?.value?.conditions?.length])
  const propCloseOnClickOutside = usePropCloseOnClickOutside()

  const onChange = (conditionGroup: ConditionGroupInput) => {
    rest.onChange?.(conditionGroup)
    const validConditionsCount = conditionGroup.conditions.filter((condition) => getConditionValid(condition)).length
    setCurrentConditionsTotalCount(validConditionsCount)
  }

  const paperProps = withPaper ? { withBorder: true, p: 'xs' } : { sx: { backgroundColor: 'transparent' } }

  return (
    <Popover position={position} width={width} closeOnClickOutside={propCloseOnClickOutside}>
      <Popover.Target>
        <Paper {...paperProps}>
          <Button
            variant="subtle"
            color="gray.9"
            compact
            h={30}
            leftIcon={<IconFilter stroke={1.5} size={18} color={getTextColor('gray.9', theme)} />}
            sx={(theme) =>
              !!currentConditionsTotalCount
                ? {
                    color: getTextColor('gray.9', theme),
                    backgroundColor: getBackgroundColor('blue.0', theme),
                    borderRadius: theme.radius.xs,
                    fontSize: 14,
                    '&:hover': {
                      backgroundColor: getBackgroundColor('gray.1', theme),
                    },
                  }
                : {
                    borderRadius: theme.radius.xs,
                    fontSize: 14,
                    color: getTextColor('gray.9', theme),
                    '&:hover': {
                      backgroundColor: getBackgroundColor('gray.1', theme),
                    },
                  }
            }
          >
            {t('actions.filter')}
            {!!currentConditionsTotalCount && <>({currentConditionsTotalCount})</>}
          </Button>
        </Paper>
      </Popover.Target>
      <Popover.Dropdown sx={{ maxHeight: '50vh', display: 'flex' }} p={0}>
        <Filter {...rest} onChange={onChange} p="lg" />
      </Popover.Dropdown>
    </Popover>
  )
}

export default FieldFilterPopover
