import React, { useMemo } from 'react'
import { SimpleGrid, Stack, Title } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { LabelWithValue, UrlWithTooltip } from 'components/ui'
import { ApplicationCustomerAttribute, Customer } from 'types/domain'
import { formatISOTimeOrNull, isDatetimeField, isUrl } from 'utils'

type Props = {
  customer: Omit<Customer, 'entriesCount' | 'segments' | 'submittedEntries'>
  customerAttributes: ApplicationCustomerAttribute[]
}

const reservedCustomerFields = ['uid', 'name', 'mobile', 'email']

const CustomerAttributes = (props: Props) => {
  const { t } = useTranslation('pageCustomerSegment')
  const { customer, customerAttributes } = props

  const customizedAttributes = useMemo(
    () => customerAttributes.filter((attr) => !reservedCustomerFields.includes(attr.code)),
    [customerAttributes]
  )

  const formatValue = (attr: ApplicationCustomerAttribute) => {
    const value = customer?.attrValues?.[attr.code]
    if (!value) {
      return ''
    }
    if (isDatetimeField(attr?.aliases?.[0])) {
      return formatISOTimeOrNull(customer?.attrValues[attr.code])
    }
    if (isUrl(value)) {
      return <UrlWithTooltip url={value} includeProtocolAndHost />
    }
    return value
  }

  return (
    <Stack>
      <Title order={6}>{t('customerDetail.attribute')}</Title>
      <SimpleGrid cols={2} verticalSpacing="md">
        <LabelWithValue labelClassName="min-w-[28px]" label={t('customer:customer_name')} value={customer?.name} />
        <LabelWithValue labelClassName="min-w-[28px]" label={t('customer:customer_email')} value={customer?.email} />
        <LabelWithValue labelClassName="min-w-[28px]" label={t('customer:customer_mobile')} value={customer?.mobile} />
        {customizedAttributes.map((attr, index) => {
          return (
            <LabelWithValue
              key={`${attr.id}-${index}`}
              labelClassName="min-w-[56px]"
              label={attr.displayName || attr?.aliases?.[0] || ''}
              value={formatValue(attr)}
            />
          )
        })}
      </SimpleGrid>
    </Stack>
  )
}

export default CustomerAttributes
