import { Header, Group, Box } from '@mantine/core'
import HeaderLeft from './HeaderLeft'
import HeaderRight from './HeaderRight'
import HeaderCenter from './HeaderCenter'

const CampaignBuilderHeader = () => {
  return (
    <Header height={56} py="xs" px="xl">
      <Box className="relative h-full">
        <Group position="apart" className="h-full">
          <HeaderLeft />
          <HeaderRight />
        </Group>
        <HeaderCenter />
      </Box>
    </Header>
  )
}

export default CampaignBuilderHeader
