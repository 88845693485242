import { Select } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import React, { ComponentProps, FC } from 'react'
import SelectItem from './SelectItem'
import { Field } from 'types/domain'
import { merge } from 'lodash-es'
import useTranslation from 'next-translate/useTranslation'
import getFieldGroupName from './utils/getFieldGroupName'
import { filterSelectComponentDropDownStyle } from 'components/business/Filter/FieldFilterPopover/Filter/constants'

type FieldWithAlias = Field & { alias?: string }

type Props = {
  fields?: FieldWithAlias[]
  defaultValue?: string
  onChange?(value: string): void
} & Omit<ComponentProps<typeof Select>, 'data'>

const dropdownWidthPx = 272

const FirstNode: FC<Props> = ({ fields = [], styles = {}, ...restProps }) => {
  const { t } = useTranslation('common')
  const { ref, width } = useElementSize()

  const options: ComponentProps<typeof Select>['data'] = fields.map((field) => {
    return {
      label: field.label,
      value: field.code,
      type: field.type,
      group: getFieldGroupName(field.code, t),
      alias: field.alias,
    }
  })

  return (
    <div ref={ref}>
      <Select
        {...restProps}
        placeholder={t('placeholders.pleaseSelect')}
        itemComponent={SelectItem}
        data={options}
        searchable
        withinPortal
        styles={merge(
          {
            dropdown: {
              width: `${dropdownWidthPx}px !important`,
              marginLeft: width ? (dropdownWidthPx - width) / 2 : undefined,
            },
          },
          styles
        )}
        nothingFound={t('empty.firstNodeEmpty')}
        filter={(value, item) => {
          const searchValue = value.toLowerCase().trim()
          return (
            item.label?.toLowerCase().includes(searchValue) ||
            item.value?.toLowerCase().includes(searchValue) ||
            item.alias?.toLowerCase().includes(searchValue)
          )
        }}
        {...filterSelectComponentDropDownStyle}
      />
    </div>
  )
}

export default FirstNode
