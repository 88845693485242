import { ConditionGroupInput, ConditionGroupOperator } from 'types/domain'

export const debouncedWaitTime = 400

export const defaultConditionGroup: ConditionGroupInput = {
  operator: ConditionGroupOperator.And,
  conditions: [],
}

export const filterSelectComponentDropDownStyle: {
  maxDropdownHeight: any
} = {
  maxDropdownHeight: 'calc(50vh - 60px)',
}
