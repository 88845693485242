import React, { FC } from 'react'
import { Image } from '@mantine/core'
import { useDisclosure, useIntersection } from '@mantine/hooks'
import Lightbox from 'yet-another-react-lightbox'

const ImageCell: FC<any> = ({ renderedCellValue }) => {
  const [opened, { close, open }] = useDisclosure(false)
  const { ref, entry } = useIntersection()

  const handleClick = (event: any) => {
    event.stopPropagation()
    open()
  }

  if (!renderedCellValue) return null
  return (
    <>
      <Image
        width="auto"
        height={32}
        miw={32}
        src={entry?.isIntersecting ? renderedCellValue : ''}
        alt=""
        onClick={handleClick}
        imageProps={{ className: 'preventCellClick' }}
        className="cursor-pointer"
        ref={ref}
      />
      <Lightbox open={opened} close={close} slides={[{ src: renderedCellValue }]} carousel={{ finite: true }} />
    </>
  )
}

export default ImageCell
