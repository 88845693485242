import { SELECTABLE_FIELDS } from 'config/constants/fieldType'
import { omit } from 'lodash-es'
import {
  CesField,
  CheckBox,
  CsatField,
  CtaField,
  EndingField,
  Field,
  FormFieldType,
  NpsField,
  RatingField,
  SelectableField,
  TextArea,
  TextField,
  WelcomeField,
} from 'types/domain'
import { clearPreCode } from 'utils/tempCode'
import { getImage } from './getImage'

export default function getFormFieldInputSetting(field: Field) {
  const { labelHidden } = field
  const commonSetting = {
    labelHidden,
    image: getImage(field),
  }
  if (field.type === FormFieldType.TextArea) {
    const textAreaField = field as TextArea
    return {
      ...commonSetting,
      placeholder: textAreaField?.placeholder,
      screenshotsEnabled: textAreaField?.screenshotsEnabled,
      labelHidden,
    }
  }

  if (field.type === FormFieldType.TextField) {
    const textField = field as TextField
    return {
      ...commonSetting,
      placeholder: textField?.placeholder,
    }
  }

  if (field.type === FormFieldType.NpsField) {
    const npsField = field as NpsField
    return {
      ...commonSetting,
      ratingMax: npsField?.ratingMax,
      ratingMinDescription: npsField?.ratingMinDescription,
      ratingMaxDescription: npsField?.ratingMaxDescription,
    }
  }

  if (field.type === FormFieldType.RatingField) {
    const ratingField = field as RatingField
    return {
      ...commonSetting,
      ratingType: ratingField?.ratingType,
      ratingMax: ratingField?.ratingMax,
      ratingMinDescription: ratingField?.ratingMinDescription,
      ratingMaxDescription: ratingField?.ratingMaxDescription,
      ratingDescriptions: ratingField?.ratingDescriptions,
      layout: ratingField?.layout,
    }
  }

  if (field.type === FormFieldType.CsatField) {
    const csatField = field as CsatField
    return {
      ...commonSetting,
      ratingDescriptions: csatField?.ratingDescriptions,
    }
  }

  if (field.type === FormFieldType.CesField) {
    const cesField = field as CesField
    return {
      ...commonSetting,
      ratingMax: cesField?.ratingMax,
      ratingMinDescription: cesField?.ratingMinDescription,
      ratingMaxDescription: cesField?.ratingMaxDescription,
    }
  }

  if (SELECTABLE_FIELDS.includes(field.type as FormFieldType)) {
    const result = {
      ...commonSetting,
      choices:
        (field as CheckBox)?.choices
          ?.filter((choice) => choice.label)
          .map((choice) => {
            choice.value = clearPreCode(choice.value)
            return {
              ...omit(choice, '__typename'),
              label: choice.label?.trim(),
            }
          }) || [],
      choicesRandom: (field as SelectableField).choicesRandom,
      choicesStyle: (field as SelectableField).choicesStyle,
      choicesSize: (field as SelectableField).choicesSize,
      rowChoiceCount: (field as SelectableField).rowChoiceCount,
    }

    if (field.type == FormFieldType.CheckBox) {
      return {
        ...result,
        minChoices: (field as CheckBox).minChoices,
        maxChoices: (field as CheckBox).maxChoices,
      }
    }

    if (field.type == FormFieldType.CtaField) {
      return {
        ...result,
        ctaPrimaryButtonLink: (field as CtaField).primaryButtonLink,
        ctaSecondaryButtonLink: (field as CtaField).secondaryButtonLink,
        ctaButtonAlign: (field as CtaField).buttonAlign,
        image: getImage(field),
      }
    }
    return result
  }

  if (field.type === FormFieldType.WelcomeField) {
    const welcomeField = field as WelcomeField
    return {
      ...commonSetting,
      welcomeContent: welcomeField?.content,
      welcomeButtonText: welcomeField?.buttonText,
    }
  }

  if (field.type === FormFieldType.EndingField) {
    const endingField = field as EndingField
    return {
      ...commonSetting,
      endingContent: endingField?.content?.trim(),
      endingAutoCloseEnabled: endingField?.autoCloseEnabled,
      endingAutoCloseDuration: endingField?.autoCloseDuration ?? 2800,
    }
  }

  if (field.type === FormFieldType.DescriptionField) {
    return {
      ...commonSetting,
    }
  }
  return {}
}
