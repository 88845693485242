import { ConditionInput, ConditionOperator } from 'types/domain'
import { Mine } from 'components/business/Filter/DefaultFilter/Conditions/Operator/getOperatorOptions'

export const getConditionValid = (condition: ConditionInput) => {
  if (!condition.operator) {
    return false
  }
  switch (condition.operator) {
    case ConditionOperator.IsNull:
    case ConditionOperator.IsNotNull:
    case Mine as never:
      return true

    default:
      const secondNodeValue = condition.secondNode?.value
      return !!secondNodeValue || secondNodeValue === 0
  }
}
