import React, { FC, useState } from 'react'
import { Box, Button, Group, Stack, TypographyStylesProvider } from '@mantine/core'
import { EntryComment, useUpdateEntryCommentMutation } from 'types/domain'
import useTranslation from 'next-translate/useTranslation'
import { htmlEncodeByRegExp, isBlank } from 'utils'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import { message } from 'components/ui'
import MessageInput from '../components/MessageAdder/MessageInput'

type Props = {
  comment: EntryComment
  isEditing: boolean
  onSave: () => void
  onCancel: () => void
}

const CommentContent: FC<Props> = ({ comment, isEditing, onSave, onCancel }) => {
  const { t } = useTranslation('entry')
  const [value, setValue] = useState<string>(comment.content)
  const [updateEntryCommentMutation] = useUpdateEntryCommentMutation()

  const handleSave = async () => {
    try {
      const { data } = await updateEntryCommentMutation({
        variables: {
          input: {
            id: comment.id,
            content: value?.trim()!,
          },
        },
      })
      if (data?.updateEntryComment) {
        onSave()
      }
    } catch (e) {
      message.error(t('common:message.updateError'))
    }
  }

  if (!isEditing) {
    return (
      <Box
        p="sm"
        sx={(theme) => ({
          backgroundColor: getBackgroundColor('brandBlue.0', theme),
          borderBottomLeftRadius: theme.radius.md,
          borderBottomRightRadius: theme.radius.md,
          borderTopRightRadius: theme.radius.md,
        })}
      >
        <TypographyStylesProvider
          sx={(theme) => ({
            fontSize: theme.fontSizes.sm,
            wordBreak: 'break-all',
          })}
        >
          <div dangerouslySetInnerHTML={{ __html: htmlEncodeByRegExp(comment.content) }} />
        </TypographyStylesProvider>
      </Box>
    )
  }

  return (
    <Stack spacing="sm" align="end" w="100%">
      <MessageInput
        placeholder={t('entryModal.comments.placeholder')}
        value={value}
        onSave={handleSave}
        onChange={setValue}
      />
      <Group spacing="sm">
        <Button variant="default" onClick={onCancel} size="xs">
          {t('common:actions.cancel')}
        </Button>
        <Button disabled={isBlank(value)} onClick={handleSave} size="xs">
          {t('common:actions.save')}
        </Button>
      </Group>
    </Stack>
  )
}

export default CommentContent
