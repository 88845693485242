import React from 'react'
import { IconChevronDown } from '@tabler/icons-react'
import { Accordion, useMantineTheme } from '@mantine/core'

import { getBackgroundColor, getBorderColor, getTextColor } from 'config/themeOverride/getColor'

import CampaignCreatedTask from './CampaignCreatedTask'
import SdkTask from './SdkTask'
import MemberInvitedTask from './MemberInvitedTask'
import EntryCollectedTask from './EntryCollectedTask'
import useOnboardingCache from 'components/business/hooks/useOnboardingCache'

const Tasks = () => {
  const theme = useMantineTheme()
  const { pendingTasks } = useOnboardingCache()

  return (
    <Accordion
      radius="xs"
      px="lg"
      pt="xl"
      pb="2xl"
      bg={getBackgroundColor('white', theme)}
      variant="separated"
      defaultValue={pendingTasks[0]}
      transitionDuration={100}
      chevron={<IconChevronDown color={getTextColor('gray.7', theme)} size={18} stroke={1.5} />}
      styles={{
        control: {
          padding: 0,
          '&[data-active]': {
            '.mantine-Text-root': {
              fontWeight: 600,
            },
            '.xm-onboarding-radio': {
              border: `2px solid ${getBorderColor('brandBlue.2', theme)}`,
              background: getBackgroundColor('brandBlue.0', theme),
            },
          },
        },
        label: {
          padding: 0,
        },
        item: {
          backgroundColor: getBackgroundColor('white', theme),
          border: 'none',
          '& + &': {
            marginTop: '24px',
          },
        },
        panel: {
          border: 'none',
          marginTop: '8px',
          marginLeft: '35px',
        },
        content: {
          padding: 0,
        },
        chevron: {
          width: '28px',
          height: '28px',
          borderRadius: theme.radius.xs,
          '&:hover': {
            background: getBackgroundColor('gray.1', theme),
          },
        },
      }}
    >
      <CampaignCreatedTask />
      <SdkTask />
      <EntryCollectedTask />
      <MemberInvitedTask />
    </Accordion>
  )
}

export default Tasks
