import Link from 'next/link'
import React from 'react'
import { useRouter } from 'next/router'
import { Button, Group } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { ApplicationSurvey } from 'config/constants'
import Task from './Task'
import useCreateFeedback from 'modules/Application/Feedback/Create/useCreateFeedback'
import useApplicationInfoBaseInfoCache from 'components/business/hooks/useApplicationInfoBaseInfoCache'
import { Application } from 'types/domain'
import { isMiniappDistribution } from 'utils/applicationUtils'
import useOnboardingCache from 'components/business/hooks/useOnboardingCache'

const CampaignCreatedTask = () => {
  const { t } = useTranslation('componentAppOnboarding')
  const appId = useRouter().query.appId as string
  const { onboarding } = useOnboardingCache()
  const application = useApplicationInfoBaseInfoCache().application as Application
  const isCurrentMiniappDistribution = isMiniappDistribution(application)
  const { createFeedback, loading } = useCreateFeedback()

  return (
    <Task
      value="campaignCreated"
      checked={!!onboarding?.campaignCreated}
      title={isCurrentMiniappDistribution ? t('campaign.titleWithoutFeedback') : t('campaign.title')}
      descriptions={t('campaign.descriptions', {}, { returnObjects: true })}
    >
      <Group spacing="sm" mt="md">
        <Link href={{ pathname: ApplicationSurvey, query: { appId, templateCode: 1 } }} passHref legacyBehavior>
          <Button component="a" size="xs">
            {t('campaign.createSurvey')}
          </Button>
        </Link>
        {!isCurrentMiniappDistribution && (
          <Button component="a" onClick={createFeedback} loading={loading} size="xs">
            {t('campaign.createFeedback')}
          </Button>
        )}
      </Group>
    </Task>
  )
}

export default CampaignCreatedTask
