import { useDisclosure } from '@mantine/hooks'
import { useEffect } from 'react'

const TOGGLE_FIELD_FILTER_POPOVER_CLOSE_ON_CLICK_OUTSIDE = 'toggle_fieldFilter_popover_close_on_click_outside'
export default function usePropCloseOnClickOutside() {
  const [opened, { toggle }] = useDisclosure(true)

  useEffect(() => {
    window.addEventListener(TOGGLE_FIELD_FILTER_POPOVER_CLOSE_ON_CLICK_OUTSIDE, toggle)
    return () => window.removeEventListener(TOGGLE_FIELD_FILTER_POPOVER_CLOSE_ON_CLICK_OUTSIDE, toggle)
  }, [toggle])

  return opened
}

export const toggleParentPopoverCloseState = () => {
  window.dispatchEvent(new Event(TOGGLE_FIELD_FILTER_POPOVER_CLOSE_ON_CLICK_OUTSIDE))
}
