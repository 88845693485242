import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
  item: {
    '&::before': {
      left: '4px',
    },
    '&:not(:first-of-type)': {
      marginTop: theme.spacing.md,
    },
  },
  itemBullet: {
    left: '-4px',
  },
  itemTitle: {
    fontWeight: 400,
  },
}))

export const itemCommonProps = {
  lineWidth: 1,
  pl: 24,
  bulletSize: 16,
}
