import React, { FC } from 'react'
import { Avatar } from '@mantine/core'
import AvatarImg from './avatar.png'

type Props = {
  size: number
}

const CustomerAvatar: FC<Props> = ({ size }) => {
  return <Avatar size={size} src={AvatarImg.src} radius="xl" alt="user avatar" />
}

export default CustomerAvatar
