import React from 'react'
import { openModal } from '@mantine/modals'
import { modalConfig } from 'config/constants'

import EntryModalContent from './Content'
import EntryModalHeader from './Header'
import { OpenEntryDetailProps } from './type'
import { useMantineTheme } from '@mantine/core'

export default function useEntryModal(canAddToSegment: boolean) {
  const theme = useMantineTheme()
  const open = (props: OpenEntryDetailProps) => {
    const { data, fields, onClose, campaignId, campaignType, widgetType, targetAside, updateReplyCallBack } = props

    openModal({
      ...modalConfig(theme),
      modalId: 'entryModal',
      onClose,
      title: (
        <EntryModalHeader
          campaignId={campaignId}
          campaignType={campaignType}
          enableSwitchEntry={false}
          enableRemoveEntry={false}
          entry={data.entry}
        />
      ),
      children: (
        <EntryModalContent
          canAddToSegment={canAddToSegment}
          widgetType={widgetType}
          campaignType={campaignType}
          data={data!}
          fields={fields}
          targetAside={targetAside}
          updateReplyCallBack={updateReplyCallBack}
        />
      ),
    })
  }

  return {
    open,
  }
}
