import { ReactNode, useState, useRef } from 'react'
import { Stack, Group, Text, Button, FileButton } from '@mantine/core'
import { IconTrash, IconRotateClockwise, IconCloudUpload } from '@tabler/icons-react'
import { AttachmentType } from 'types/domain'
import { message } from 'components/ui'
import useTranslation from 'next-translate/useTranslation'
import useUploadFile from 'hooks/useUploadFile'

const ACCEPT_DEFAULT = 'image/png,image/jpeg,image/gif'
const MAXSIZE_DEFAULT = 2

type Image = {
  url: string
  height: number
  width: number
  storageKey?: string
}

type Props = {
  ownerId: string
  accept?: string
  value?: Image
  tip?: ReactNode
  maxSize?: number // maxSize in MB
  onChange?: (image: Image | null) => void
}

type ImageDimensions = {
  width: number
  height: number
}

const ImageUploader = ({
  ownerId,
  maxSize = MAXSIZE_DEFAULT,
  accept = ACCEPT_DEFAULT,
  tip,
  value,
  onChange,
}: Props) => {
  const uploadFile = useUploadFile()
  const { t } = useTranslation('common')
  const [loading, setLoading] = useState<boolean>(false)
  const resetRef = useRef<() => void>(null)
  const fileSettled = !!value

  const getImageDimensions = (image?: File) => {
    return new Promise<ImageDimensions>((resolve, reject) => {
      if (!image) {
        reject(new Error(t('imageUploader.dimensionsError')))
        return
      }
      const url = URL.createObjectURL(image)
      const img = new Image()

      img.onload = function () {
        resolve({
          height: img.height,
          width: img.width,
        } as ImageDimensions)
        URL.revokeObjectURL(img.src)
      }
      img.onerror = function () {
        reject(new Error(t('imageUploader.error')))
      }

      img.src = url
    })
  }

  const getImageBase64 = (file?: File) => {
    return new Promise<string>((resolve, reject) => {
      if (!file) {
        reject(new Error(t('imageUploader.transImageError')))
        return
      }
      var reader = new FileReader()
      reader.onloadend = function () {
        resolve(reader.result as string)
      }
      reader.readAsDataURL(file)
    })
  }

  const handleFileChange = (file: File) => {
    if (!file) {
      return
    }

    const maxAllowedSize = maxSize * 1024 * 1024
    if (file.size > maxAllowedSize) {
      message.error(
        t('imageUploader.maxSizeError', {
          size: maxSize,
        })
      )
      resetRef.current?.()
      return
    }
    setLoading(true)
    uploadFile({
      ownerId,
      fileInfo: {
        file,
      },
      attachmentType: AttachmentType.Assets,
      onUploadCompleted: async (fileKey) => {
        try {
          const dimensions = await getImageDimensions(file)
          const tempUrl = await getImageBase64(file)
          onChange?.({
            url: tempUrl,
            storageKey: fileKey,
            ...dimensions,
          })
          setLoading(false)
        } catch (error: any) {
          message.error(error.message)
          setLoading(false)
        }
      },
    })
  }
  const handleFileReset = () => {
    onChange?.(null)
    resetRef.current?.()
  }
  return (
    <Stack spacing="sm">
      {!!tip && (
        <Text color="dimmed" size="xs" className="whitespace-pre">
          {tip}
        </Text>
      )}
      <Group spacing="sm">
        <FileButton onChange={handleFileChange} accept={accept} resetRef={resetRef}>
          {(props) => (
            <Button
              {...props}
              variant="default"
              loading={loading}
              sx={{
                fontWeight: 'normal',
              }}
              leftIcon={
                fileSettled ? (
                  <IconRotateClockwise size={18} stroke={1.5} className="rotate-180" />
                ) : (
                  <IconCloudUpload size={18} stroke={1.5} />
                )
              }
            >
              {t(loading ? 'imageUploader.uploading' : fileSettled ? 'imageUploader.reupload' : 'imageUploader.upload')}
            </Button>
          )}
        </FileButton>
        {fileSettled && (
          <Button
            onClick={handleFileReset}
            variant="default"
            sx={{
              fontWeight: 'normal',
            }}
            leftIcon={<IconTrash size={18} stroke={1.5} />}
          >
            {t('common:actions.delete')}
          </Button>
        )}
      </Group>
    </Stack>
  )
}

export default ImageUploader
