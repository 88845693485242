import React, { FC, ReactNode } from 'react'
import { Group, Stack, Text, Box } from '@mantine/core'

type StatusContentProps = {
  title: string
  icon: React.ReactNode
  content: string
  link?: ReactNode
}

const StatusContent: FC<StatusContentProps> = (props) => {
  const { title, icon, content, link } = props
  return (
    <Group position="left" align="start" spacing="lg">
      <Box mt="xs">{icon}</Box>
      <Stack spacing="sm" align="flex-start" sx={{ width: '270px' }}>
        <Text weight="600">{title}</Text>
        <Text> {content}</Text>
        {link}
      </Stack>
    </Group>
  )
}

export default StatusContent
