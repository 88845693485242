import { getDefaultZIndex } from '@mantine/core'
import { openModal } from '@mantine/modals'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import { productsModalId } from './constant'
import ProductsModalContent from './ProductsModalContent'

export default function openProductsModal(feature?: string) {
  openModal({
    modalId: productsModalId,
    withinPortal: true,
    size: 1300,
    zIndex: getDefaultZIndex('popover') + 1,
    transitionProps: {
      transition: {
        in: { opacity: 1, transform: 'scale(1)' },
        out: { opacity: 0, transform: 'scale(1.2)' },
        common: { transformOrigin: 'center' },
        transitionProperty: 'transform, opacity',
      },
      timingFunction: 'cubic-bezier(0.6, 0.85, 0.32, 1.28)',
      exitDuration: 250,
    },
    children: <ProductsModalContent feature={feature} />,
    styles: (theme) => ({
      inner: {
        overflow: 'hidden',
      },
      modal: {
        backgroundColor: getBackgroundColor('gray.0', theme),
      },
    }),
  })
}
