import { PropsWithChildren, useEffect, useRef } from 'react'
import { Box } from '@mantine/core'
import MobileSimulator from 'components/ui/MobileSimulator'

const MobilePreview = ({ children }: PropsWithChildren) => {
  const simulatorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      simulatorRef.current && (simulatorRef.current.style.opacity = '1')
    }, 150)
  }, [])

  return (
    <Box pos="relative" h="100%">
      <MobileSimulator
        ref={simulatorRef}
        sx={{
          height: '100%',
        }}
      >
        {children}
      </MobileSimulator>
    </Box>
  )
}

export default MobilePreview
