import React, { FC } from 'react'
import { MultiSelect } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { ValueNodeProps } from './type'
import { SelectableField } from 'types/domain'
import { isEmpty, pick } from 'lodash-es'
import keepConditionIntoView from '../utils/keepConditionIntoView'

const ChoiceSelector: FC<ValueNodeProps> = ({ fields = [], condition, onChange, index, readOnly }) => {
  const { t } = useTranslation('common')
  const firstNodeField = fields.find((field) => field.code === condition.firstNode.value) as SelectableField
  const options = firstNodeField.choices.map((c) => pick(c, ['label', 'value'])) || []

  const handleOnChange = (value: string[]) => {
    const validValue = isEmpty(value) ? undefined : value
    onChange?.(validValue)
    keepConditionIntoView(index)
  }

  const onDropdownOpen = () => keepConditionIntoView(index)

  return (
    <MultiSelect
      withinPortal
      data={options}
      placeholder={t('placeholders.pleaseSelect')}
      value={condition.secondNode?.value ?? []}
      onChange={handleOnChange}
      onDropdownOpen={onDropdownOpen}
      dropdownPosition="flip"
      styles={{
        value: {
          margin: '3px 5px',
        },
      }}
      readOnly={readOnly}
    />
  )
}

export default ChoiceSelector
