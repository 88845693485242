import { useRouter } from 'next/router'
import { CampaignTemplate } from 'types/domain'
import { ApplicationTemplate } from 'config/constants'
import TemplateCard from './TemplateCard'

type Props = {
  template: Pick<CampaignTemplate, 'code' | 'description' | 'title' | '__typename'>
  className?: string
}

const SystemTemplateCard = (props: Props) => {
  const { template, className } = props
  const appId = useRouter().query.appId as string
  const url = ApplicationTemplate.replace('[appId]', appId).replace('[code]', template.code)

  return <TemplateCard url={url} template={template} className={className} />
}

export default SystemTemplateCard
