import React, { FC } from 'react'
import { Group, NumberInput, Select, Text } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { useBuilderFormContext } from 'components/business/CampaignBuilder'
import { Align } from 'types/domain'

type Props = {}

const CustomizedWidgetPosition: FC<Props> = () => {
  const { t } = useTranslation('componentCampaignBuilder')
  const form = useBuilderFormContext()
  const options = [Align.Left, Align.Right].map((align) => ({
    value: align,
    label: t(`styles.customizedPosition.align.${align}`),
  }))

  return (
    <Group noWrap spacing="sm">
      <Select
        defaultValue={Align.Right}
        data={options}
        {...form.getInputProps('widget.customizedPosition.align')}
        styles={{
          input: {
            paddingRight: 0,
          },
          rightSection: {
            width: '24px',
          },
        }}
      />
      <Text className="flex-shrink-0">{t('styles.customizedPosition.percentageToTop')}</Text>
      <NumberInput
        min={0}
        max={100}
        step={5}
        defaultValue={60}
        {...form.getInputProps('widget.customizedPosition.percentageToTop')}
        parser={(value) => value?.replace('%', '')}
        formatter={(value) => `${value}%`}
        styles={{
          rightSection: {
            width: '24px',
          },
          control: {
            width: '22px',
          },
        }}
      />
    </Group>
  )
}

export default CustomizedWidgetPosition
