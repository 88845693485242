import { Center, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Icon } from 'components/ui'
import { getTextColor } from 'config/themeOverride/getColor'

const BlankComments: FC = () => {
  const { t } = useTranslation('entry')
  const theme = useMantineTheme()
  return (
    <Center mt="3xl">
      <Stack spacing="sm" align="center">
        <Icon name="comments" className="mb-sm" style={{ color: getTextColor('red', theme) }} />
        <Title order={6}>{t('entryModal.comments.emptyTitle')}</Title>
        <Text color="dimmed">{t('entryModal.comments.emptyDescription')}</Text>
      </Stack>
    </Center>
  )
}

export default BlankComments
