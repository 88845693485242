import { useMantineTheme } from '@mantine/core'
import React, { PropsWithChildren } from 'react'
import { getImage } from '../getImage'
import style from './index.module.scss'
import { getBorderColor } from 'config/themeOverride/getColor'

const FakeBrowser = ({ children }: PropsWithChildren) => {
  const theme = useMantineTheme()

  return (
    <div
      className={style.browser}
      style={{
        border: `1px solid ${getBorderColor('gray.3', theme, 'dark.9')}`,
        borderRadius: theme.radius.md,
        minHeight: '300px',
      }}
    >
      <header
        className="h-[50px]"
        style={{
          backgroundImage: `url(${getImage('browser-end', theme)}), url(${getImage(
            'browser-start',
            theme
          )}), url(${getImage('browser-repeat', theme)}), url(${getImage('browser-end', theme)})`,
        }}
      />

      <main
        style={{
          overflow: 'auto',
        }}
      >
        {children}
      </main>
    </div>
  )
}

export default FakeBrowser
