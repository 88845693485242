import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Group, Menu, Text } from '@mantine/core'
import { eventHowxm } from 'howxm-js'
import { openCrisp } from 'plugins/crisp'

import { blogUrl, helpCenterUrl, newFeatureUrl } from 'config'
import { Icon } from 'components/ui'
import MenuWrapper from '../MenuWrapper'

const HelpCenter = () => {
  const { t } = useTranslation('account')

  return (
    <MenuWrapper
      buttonChildren={
        <>
          <Icon name="tabHelp" />
          <Text ml={22}>{t('assistance.title')}</Text>
        </>
      }
      dropdownChildren={
        <>
          <Menu.Item
            px="lg"
            py="sm"
            target="_blank"
            icon={<Icon name="helpCenter" />}
            component="a"
            href={helpCenterUrl}
          >
            {t('assistance.helpCenter')}
          </Menu.Item>
          <Menu.Item px="lg" py="sm" target="_blank" icon={<Icon name="blog" />} component="a" href={blogUrl}>
            {t('assistance.blog')}
          </Menu.Item>
          <Menu.Item
            px="lg"
            py="sm"
            target="_blank"
            icon={<Icon name="newFunction" />}
            component="a"
            href={newFeatureUrl}
          >
            <Group>
              <Text>{t('assistance.newFunction')}</Text>
              <Icon name="newFeature" className="h-[22px] items-center" />
            </Group>
          </Menu.Item>
          <Menu.Divider my="xs" />
          <Menu.Item px="lg" py="sm" icon={<Icon name="one2oneServe" />} onClick={() => eventHowxm('mkt_consult')}>
            {t('assistance.one2oneServe')}
          </Menu.Item>
          <Menu.Item px="lg" py="sm" icon={<Icon name="onlineCustomers" />} onClick={openCrisp}>
            {t('assistance.onlineCustomers')}
          </Menu.Item>
          <Menu.Item px="lg" py="sm" icon={<Icon name="followUs" />} onClick={() => eventHowxm('mkt_subscribe')}>
            {t('assistance.followUs')}
          </Menu.Item>
        </>
      }
    />
  )
}

export default HelpCenter
