import React, { FC } from 'react'
import { ActionIcon, useMantineTheme } from '@mantine/core'
import { IconEdit } from '@tabler/icons-react'
import { getTextColor } from 'config/themeOverride/getColor'

type Props = {
  onClick: () => void
}

const EditButton: FC<Props> = ({ onClick }) => {
  const theme = useMantineTheme()

  return (
    <ActionIcon onClick={onClick}>
      <IconEdit stroke={1.5} size={18} color={getTextColor('gray.7', theme)} />
    </ActionIcon>
  )
}

export default EditButton
