import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useWindowEvent } from '@mantine/hooks'
import { AppShell } from '@mantine/core'

import { getBackgroundColor } from 'config/themeOverride/getColor'
import { useBuilderSharedDataContext } from 'components/business/CampaignBuilder/BuilderSharedDataContext'
import useHowxmSDK from 'plugins/howxm/useHowxmSDK'
import { isWebDistribution } from 'utils/applicationUtils'
import { BuilderFormProvider, useBuilderForm } from './formContext'
import CampaignBuilderHeader from './Header'
import { isFormDataChanged, setInitialFormData } from './utils/formDataUtils'
import { HowXMAppId } from 'config'
import DesktopPreview from 'components/business/PlatformPreview/DesktopPreview'

type Props = PropsWithChildren & {
  initialValues: any
}

const CampaignBuilder: FC<Props> = (props) => {
  const { initialValues, children } = props
  const application = useBuilderSharedDataContext().application!
  const isWebPlatform = isWebDistribution(application)
  useHowxmSDK(HowXMAppId)
  const form = useBuilderForm({
    validateInputOnChange: true,
    initialValues: initialValues,
  })

  // 在更新完survey或feedback的字段后，从服务端取到最新的字段，重新设置formFields的状态
  useEffect(() => {
    form.setValues({ formFields: initialValues.formFields })
    setInitialFormData(initialValues)
    // eslint-disable-next-line
  }, [initialValues.formFields])

  useWindowEvent('beforeunload', (event) => {
    if (!isFormDataChanged(form.values)) return
    event.preventDefault()
    event.returnValue = ''
  })

  useEffect(() => {
    // 为了在点击返回的时候比较form data是否有改变
    // 为什么不直接用initialValues进行比较？因为除了initialValues外，还会自动增加screenshotField（如果开启了Toggle， 同时当前没有screenshotField）
    setInitialFormData(initialValues)
    // eslint-disable-next-line
  }, [])

  return (
    <BuilderFormProvider form={form}>
      <form onSubmit={form.onSubmit(() => false)}>
        <AppShell
          padding={0}
          header={<CampaignBuilderHeader />}
          styles={(theme) => ({
            main: { backgroundColor: getBackgroundColor('gray.0', theme, 'dark.8') },
          })}
        >
          {children}
        </AppShell>
      </form>
      {isWebPlatform && <DesktopPreview.PreLoadBg />}
    </BuilderFormProvider>
  )
}

export default CampaignBuilder
