import { ComponentPropsWithoutRef, forwardRef, useContext } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Group, Select, Text } from '@mantine/core'
import { ApplicationPermission, Campaign, useApplicationCampaignsQuery, User } from 'types/domain'
import { formatISODate } from 'utils'
import { PAGE_SIZE } from 'config/constants'
import { useCheckCampaignPermission } from 'components/business'
import { useWebhookBuilderFormContext } from '../../../formContext'
import WebhookContext from 'components/business/Integration/WebhookContext'

interface CampaignSelectItemProps extends ComponentPropsWithoutRef<'div'> {
  label: string
  creatorName: string
  createdAt: string
}

const CampaignSelect = () => {
  const { t } = useTranslation('integration')
  const form = useWebhookBuilderFormContext()
  const checkPermission = useCheckCampaignPermission()
  const { appId, webhook } = useContext(WebhookContext)
  const readonly = !!webhook

  const { data } = useApplicationCampaignsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: appId,
      pageArguments: {
        first: PAGE_SIZE.UN_LIMIT,
      },
    },
    skip: readonly,
  })

  const buildCampaignOption = (
    campaign: Pick<Campaign, 'id' | 'name' | 'type' | 'createdAt'> & { creator?: Pick<User, 'name'> }
  ) => {
    return {
      value: campaign.id,
      label: campaign.name,
      group: t(`webhook.builder.stepTrigger.campaignSelect.group`, {
        campaignTypeName: t(`common:campaignTypeName.${campaign.type}`),
      }),
      createdAt: campaign.createdAt,
      creatorName: campaign.creator?.name,
    }
  }

  const getAvailableCampaigns = () => {
    return data?.application?.campaigns?.nodes?.filter((campaign) => {
      return checkPermission(campaign.creator.id, ApplicationPermission.CampaignUpdate)
    })
  }

  const options = readonly
    ? [buildCampaignOption(webhook.campaign!)]
    : getAvailableCampaigns()?.map((node) => buildCampaignOption(node))

  const CampaignSelectItem = forwardRef<HTMLDivElement, CampaignSelectItemProps>(
    ({ label, creatorName, createdAt, ...others }, ref) => {
      return (
        <div ref={ref} {...others}>
          <Group noWrap>
            <Text lineClamp={1}>{label}</Text>
            <Group noWrap>
              <Text color="dimmed" lineClamp={1}>
                {creatorName}
              </Text>
              <Text color="dimmed" className="whitespace-nowrap">
                {t('common:createdAt', {
                  dateTime: formatISODate(createdAt),
                })}
              </Text>
            </Group>
          </Group>
        </div>
      )
    }
  )

  return (
    <Select
      data={options ?? []}
      label={t('webhook.builder.stepTrigger.campaignSelect.label')}
      placeholder={t('common:placeholders.pleaseSelect')}
      {...form.getInputProps('campaignId')}
      mb="xl"
      styles={{ label: { fontWeight: 400 } }}
      readOnly={readonly}
      searchable
      nothingFound={t('webhook.builder.stepTrigger.campaignSelect.empty')}
      itemComponent={CampaignSelectItem}
    />
  )
}

export default CampaignSelect
