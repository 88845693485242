import { Group, Rating, ScrollArea, Stack, Text, useMantineTheme } from '@mantine/core'
import ImageGroup from 'components/business/Campaign/EntryModal/Content/components/ImageGroup'
import { getBackgroundColor, getBorderColor } from 'config/themeOverride/getColor'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { Entry, Field, FormFieldType, RatingField } from 'types/domain'

type Props = {
  entry: Entry
  formFields: Field[]
}

const Fields = (props: Props) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('entry')
  const { entry, formFields } = props
  const ratingField = formFields.find((field) => field.type === FormFieldType.RatingField)!
  const ratingFieldCode = ratingField.code
  const moreSuggestionFieldCode = formFields.find((field) => field.type === FormFieldType.TextArea)?.code

  return (
    <ScrollArea.Autosize
      scrollbarSize="8px"
      mah="360px"
      className="rounded-sm w-full"
      sx={{
        backgroundColor: getBackgroundColor('gray.0', theme),
        border: `1px solid ${getBorderColor('gray.2', theme)}`,
      }}
    >
      <Stack className="p-xl" spacing="sm" align="flex-start">
        <Group spacing={0}>
          <Rating size="md" count={(ratingField as RatingField).ratingMax} value={entry.fieldValues[ratingFieldCode]} />
          <Text ml="sm" size={20} weight="bold">
            {t('entryModal.feedbackScore', { score: entry.fieldValues[ratingFieldCode] })}
          </Text>
        </Group>
        {moreSuggestionFieldCode && entry.fieldValues[moreSuggestionFieldCode] && (
          <Text className="break-all" size={14}>
            {entry.fieldValues[moreSuggestionFieldCode].text}
          </Text>
        )}
        {moreSuggestionFieldCode && entry.fieldValues[moreSuggestionFieldCode] && (
          <ImageGroup images={entry.fieldValues[moreSuggestionFieldCode].screenshots} size={178} />
        )}
      </Stack>
    </ScrollArea.Autosize>
  )
}

export default Fields
