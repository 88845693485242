import { CampaignTemplate } from 'types/domain'
import { Box, Card, Image, Text, useMantineTheme } from '@mantine/core'
import cls from 'classnames'

import { getTextColor } from 'config/themeOverride/getColor'
import { AnchorLink } from 'components/ui'
import getTemplateImageUrlByCode from './getTemplateImageByCode'

type Props = {
  template: Pick<CampaignTemplate, 'code' | 'description' | 'title' | '__typename'>
  className?: string
  url: string
}

const TemplateCard = (props: Props) => {
  const { template, className, url } = props
  const theme = useMantineTheme()

  return (
    <AnchorLink href={url} passHref target="_self" className="!no-underline">
      <Card
        p="md"
        radius="md"
        className={cls(className, 'group')}
        withBorder
        sx={() => ({
          transition: 'box-shadow 0.3s',
          '&:hover': {
            boxShadow: '0px 12px 16px rgba(16, 24, 40, 0.08), 0px 4px 6px rgba(16, 24, 40, 0.03)',
          },
        })}
      >
        <Card.Section>
          <Box h={160} className="overflow-hidden">
            <Image
              src={getTemplateImageUrlByCode(template.code)}
              height={160}
              className="transition-transform group-hover:scale-105"
              alt={template.title}
            />
          </Box>
        </Card.Section>
        <Text weight={600} mt="md" mb="sm" size="md">
          {template.title}
        </Text>
        <Text size="sm" lineClamp={3} color={getTextColor('gray.7', theme)}>
          {template.description}
        </Text>
      </Card>
    </AnchorLink>
  )
}

export default TemplateCard
