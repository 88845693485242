import React, { useCallback, useContext, useMemo } from 'react'
import { Select } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { useConditionGroup } from '../../ConditionGroup'
import ConditionContext from '../ConditionContext'
import { UrlConditionOperator } from 'types/domain'
import classes from '../index.module.scss'

const Operator = () => {
  const { index, condition } = useContext(ConditionContext)
  const { t } = useTranslation('common')
  const { dispatch } = useConditionGroup()
  const { Equal, StartWith, Wildcard } = UrlConditionOperator

  const selectOptions = useMemo(() => {
    return [Equal, StartWith, Wildcard]?.map((option) => {
      return {
        value: option,
        label: t(`componentConditionGroup:conditionOperator.${option}`),
      }
    })
  }, [Equal, StartWith, Wildcard, t])

  const handleChange = useCallback(
    (value: string) => {
      dispatch({
        type: 'conditionOperatorChange',
        index,
        value,
      })
    },
    [dispatch, index]
  )

  const widthMap = useMemo(
    () => ({
      [UrlConditionOperator.Equal]: { width: 76, ml: 26 },
      [UrlConditionOperator.StartWith]: { width: 90, ml: 18 },
      [UrlConditionOperator.Wildcard]: { width: 118, ml: 4 },
    }),
    []
  )

  return useMemo(() => {
    return (
      <Select
        placeholder={t('placeholders.pleaseSelect')}
        data={selectOptions}
        value={condition?.operator}
        onChange={handleChange}
        className={classes.operator}
        styles={{
          root: {
            width: widthMap[condition?.operator as UrlConditionOperator.Equal]?.width,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          },
          dropdown: {
            width: '128px!important',
            marginLeft: widthMap[condition?.operator as UrlConditionOperator.Equal]?.ml,
            marginTop: -4,
          },
        }}
      />
    )
  }, [condition?.operator, handleChange, selectOptions, t, widthMap])
}

export default Operator
