import { useCampaignFilter, useCampaignFilterActions } from 'components/business/Campaign/Filter/CampaignFilterContext'
import React, { useMemo, useRef } from 'react'
import { MultiSelect, useMantineTheme } from '@mantine/core'
import { isEqual, range } from 'lodash-es'
import { IconStar } from '@tabler/icons-react'

import { ratingCode } from 'config/constants/fieldType'
import { getTextColor } from 'config/themeOverride/getColor'
import { ConditionInput, ConditionOperator } from 'types/domain'
import { subtleSelectStyle } from 'utils/mantineStyles'

import { getDropDownComponent } from './DropDownComponent'
import { getValueComponent } from './ValueComponent'

const defaultValue = ['1', '2', '3', '4', '5']

const options = range(1, 7).map(String)

const RatingSelector = () => {
  const selectRef = useRef<HTMLInputElement>(null)
  const theme = useMantineTheme()
  const { filter } = useCampaignFilter()
  const { onExplicitlyFieldChange } = useCampaignFilterActions()

  const value = useMemo(() => {
    return (
      (filter?.conditions as ConditionInput[])?.find(({ firstNode }) => firstNode.value === ratingCode)?.secondNode
        ?.value ?? defaultValue
    )
  }, [filter])

  const handleCloseDropDown = (freshValue: string[]) => {
    if (isEqual(freshValue, defaultValue)) {
      onExplicitlyFieldChange(ratingCode, null)
    } else {
      onExplicitlyFieldChange(ratingCode, {
        operator: ConditionOperator.Include,
        firstNode: { value: ratingCode },
        secondNode: { value: freshValue },
      })
    }
    selectRef.current?.blur()
  }

  // todo: so magic
  const dropDownMarginLeftSet = [70, 57, 45, 0, 53]

  return (
    <MultiSelect
      radius="xs"
      data={options}
      value={value}
      dropdownComponent={getDropDownComponent(handleCloseDropDown, value)}
      valueComponent={getValueComponent(value)}
      icon={<IconStar stroke={1.5} size={18} color={getTextColor('gray.9', theme)} />}
      styles={{
        ...subtleSelectStyle(theme),
        searchInput: {
          minWidth: 0,
        },
        dropdown: {
          width: '200px!important',
          marginLeft: dropDownMarginLeftSet[value.length - 1] || '32px',
        },
        values: {
          minHeight: 30,
        },
      }}
      ref={selectRef}
      maxDropdownHeight={500}
    />
  )
}

export default RatingSelector
