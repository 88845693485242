import { createStyles } from '@mantine/core'
import { pickSchemeColor } from 'config/themeOverride/getColor'

interface Params {
  checked?: boolean
}

const useSwatchStyles = createStyles((theme, { checked }: Params) => {
  const innerShadow = `0 0 0 2px ${pickSchemeColor('white', theme, theme.colors.dark[7])}`
  return {
    root: {
      borderRadius: theme.radius.sm,
      boxShadow: checked ? `${innerShadow}, 0 0 0 4px ${theme.colors.indigo[6]}` : innerShadow,
      cursor: 'pointer',
    },
  }
})

export default useSwatchStyles
