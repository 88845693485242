import React, { Ref } from 'react'
import { Box, Image } from '@mantine/core'
import classNames from 'classnames'
import { SurveyTemplatePreview } from 'components/business'
import { SurveyTemplatePreviewApi } from 'components/business/SurveyTemplatePreview'

import { DistributionChannelGroup } from 'config/constants'
import { CampaignTemplate } from 'types/domain'
import miniappNav from './images/miniappNav.png'
import MobilePreview from 'components/business/CampaignBuilder/PlatformPreview/MobilePreview'

type Props = {
  channel: DistributionChannelGroup
  template?: CampaignTemplate
  previewRef?: Ref<SurveyTemplatePreviewApi>
}

const Mobile = ({ template, channel, previewRef }: Props) => {
  return (
    <MobilePreview>
      {channel === 'miniapp' && <Image src={miniappNav.src} width="100%" h={44} alt="miniapp nav" />}
      <Box className={classNames('overflow-hidden absolute left-1/2 -translate-x-1/2 bg-white rounded-full')}></Box>
      <Box className="overflow-hidden absolute top-0 right-0 bottom-0 left-0">
        <Box className="overflow-hidden h-full rounded-[6px]">
          {template && (
            <SurveyTemplatePreview
              channel={channel}
              platform="mobile"
              token={template.code}
              fields={template?.form?.fields!}
              formSetting={template.form?.setting!}
              widget={template?.widget}
              ref={previewRef}
            />
          )}
        </Box>
      </Box>
      <Box
        className={classNames(
          'absolute left-1/2 -translate-x-1/2 bg-[#CED4DA] rounded-full',
          'bottom-[8px] w-[134px] h-[5px]'
        )}
      />
    </MobilePreview>
  )
}

export default Mobile
