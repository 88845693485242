import { CampaignType } from 'types/domain'

const xFormPrefixMapping = {
  [CampaignType.Feedback]: 'xf',
  [CampaignType.Survey]: 'xs',
  [CampaignType.Notification]: 'xn',
}

export const getXFormUrl = (token: string, type: CampaignType, isUrlVisit?: boolean): string => {
  const baseUrl = process.env.NEXT_PUBLIC_X_FORM_HOST
  return `${baseUrl}/${xFormPrefixMapping[type]}${isUrlVisit ? '/u' : ''}/${token}`
}
