import React from 'react'
import { Stack } from '@mantine/core'
import { useWebhookBuilderFormContext } from '../../../formContext'
import { isChannelSupportTargets } from '../../utils'
import ChannelSelect from './ChannelSelect'
import UrlInput from './UrlInput'
import TargetsInput from './TargetsInput'

const Settings = () => {
  const form = useWebhookBuilderFormContext()
  const { channel } = form.values

  return (
    <Stack align="flex-start">
      <ChannelSelect />
      <UrlInput />
      {isChannelSupportTargets(channel) && <TargetsInput />}
    </Stack>
  )
}

export default Settings
