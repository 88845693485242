import { useEffect, useState } from 'react'
import { identifyHowxm, initHowxmWithCustomSdkUrl } from 'howxm-js'
import { User } from 'types/domain'
import useCurrentUserCache from 'components/business/hooks/useCurrentUserCache'
import useAccountSubscriptionCache from 'components/business/hooks/useAccountSubscriptionCache'
import { useRouter } from 'next/router'

const sdkHostMapping: Record<string, string> = {
  dev: 'http://localhost:4000/umd',
  usd: 'https://static-usd.howxm.com',
  cny: 'https://static-cny.howxm.com',
  prod: 'https://static.howxm.com',
}
const useHowxmSDK = (appId: string | undefined) => {
  const [howxmSdkReady, setHowxmSdkReady] = useState(false)
  const router = useRouter()
  const { subscription } = useAccountSubscriptionCache()
  const { user } = useCurrentUserCache()

  useEffect(() => {
    if (appId && localStorage) {
      const sdkEnv = localStorage.getItem('sdkEnv') || 'prod'
      const sdkUrl = `${sdkHostMapping[sdkEnv]}/sdk.js`
      initHowxmWithCustomSdkUrl(appId, sdkUrl, () => {
        setHowxmSdkReady(true)
      })
    }
  }, [appId])

  useEffect(() => {
    identify(user)
  }, [user, router])

  const identify = (user: User) => {
    if (!howxmSdkReady || !user) {
      return
    }
    const { id: uid, email, name, mobile, accountUser, createdAt } = user
    const accountId = accountUser?.account?.id || ''
    identifyHowxm({
      uid,
      email: email || '',
      name: name || '',
      mobile: mobile || '',
      account_id: accountId,
      registered_at: new Date(createdAt).toISOString(),
      subscription: subscription?.productCode ?? '',
      banner_link: `${process.env.NEXT_PUBLIC_APP_URL}/banner/accounts/${accountId}`,
      last_seen_at: new Date().toISOString(),
    })
  }
}

export default useHowxmSDK
