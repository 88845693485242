import { Field, FieldJumpRule, FieldJumpRuleTargetType, RuleControllableField } from 'types/domain'
import { getJumpRuleByValue } from './getJumpRuleByValue'
import { PreviewField } from 'components/business/CampaignBuilder/CampaignPreview/type'
import { isEmpty, isNil, last } from 'lodash-es'

type Params = {
  currentFieldValue: PreviewField['value']
  formFields: Field[]
  currentFieldIndex: number
}

function findIndexByCode(code: string, formFields: Field[]) {
  return formFields.findIndex((field) => field.code === code)
}

function getIndexByJumpRule(params: Params, jumpRule?: FieldJumpRule): number | null {
  const { formFields, currentFieldIndex, currentFieldValue } = params
  const hasDefaultJumpRule = jumpRule?.targetType === FieldJumpRuleTargetType.SpecificField
  const hasConditionalJumRule = !isEmpty(jumpRule?.subRules)
  if (hasConditionalJumRule && !isNil(currentFieldValue)) {
    const currentJumpRule = getJumpRuleByValue(formFields[currentFieldIndex], currentFieldValue) as FieldJumpRule
    if (currentJumpRule) {
      return getIndexByJumpRule(params, currentJumpRule)
    } else if (hasDefaultJumpRule) {
      return findIndexByCode(jumpRule?.target!, formFields)
    }
  }

  if (hasDefaultJumpRule) {
    return findIndexByCode(jumpRule?.target!, formFields)
  }

  return null
}

export function getNextFieldIndex(params: { formFields: Field[]; currentPageValues: PreviewField[] }): number {
  const { formFields, currentPageValues } = params
  const nextFieldIndex = getDefaultNextFieldIndex(formFields, currentPageValues)
  let resultIndex: number | null = null
  for (let value of currentPageValues) {
    const currentFieldIndex = formFields.findIndex((f) => f.code === value.code)
    const currentField = formFields[currentFieldIndex] as RuleControllableField
    if (currentField?.jumpRule) {
      resultIndex = getIndexByJumpRule(
        { formFields, currentFieldIndex, currentFieldValue: value.value },
        currentField?.jumpRule
      )
      if (resultIndex !== null) {
        break
      }
    }
  }
  return resultIndex ?? nextFieldIndex
}

function getDefaultNextFieldIndex(formFields: Field[], currentPageValues: PreviewField[]) {
  const currentPage = formFields.find((f) => f.code === last(currentPageValues)?.code)?.page || 0
  return formFields.findIndex((field) => field.page === currentPage + 1)
}
