import React from 'react'
import { Center, Tooltip, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import cls from 'classnames'

import { Icon } from 'components/ui'
import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'

const ReplyCell = ({ renderedCellValue: hasReply }: any) => {
  const { t } = useTranslation('entry')
  const theme = useMantineTheme()

  const wrapperClasses = cls('cursor-pointer group-hover:visible', {
    visible: hasReply,
    invisible: !hasReply,
  })

  return (
    <Tooltip label={t('list.replyTooltip')} withArrow withinPortal>
      <Center
        w={18}
        h={18}
        className={wrapperClasses}
        sx={(theme) => ({
          backgroundColor: getBackgroundColor(hasReply ? 'orange.0' : 'brandBlue.0', theme),
          borderRadius: `${theme.radius.xs} 0 0 ${theme.radius.xs}`,
        })}
      >
        <Icon name="reply" size={12} style={{ color: getTextColor(hasReply ? 'orange.6' : 'brandBlue.9', theme) }} />
      </Center>
    </Tooltip>
  )
}

export default ReplyCell
