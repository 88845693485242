import { noop } from 'lodash-es'
import { ConditionGroupOperator } from 'types/domain'
import { ConditionType } from '../type'

export const defaultCondition: ConditionType = {
  firstNode: {
    value: null,
  },
  operator: null,
  secondNode: {
    value: null,
  },
}

export const defaultConditions: ConditionType[] = [defaultCondition]

export const defaultValue = {
  operator: ConditionGroupOperator.And,
  conditions: defaultConditions,
}

export const defaultFirstNode = {
  fields: [],
  getCreateLabel: noop,
  getFieldType: undefined,
  onFirstNodeChange: noop,
  rules: [],
  hidden: false,
}

export const defaultSecondNode = {
  getCreateLabel: noop,
  validator: noop,
  onFirstNodeChange: noop,
  autoFocus: false,
}
