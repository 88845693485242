import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
  step: {
    height: 26,
    minWidth: 26,
    borderRadius: '18px',
    backgroundColor: theme.colors.gray[0],
    border: `1px solid ${theme.colors.gray[2]}`,
    color: theme.colors.gray[6],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.fontSizes.xs,
    padding: `0 ${theme.spacing.sm}`,
  },
  selected: {
    backgroundColor: theme.colors.brandBlue[1],
    border: `1px solid ${theme.colors.brandBlue[2]}`,
    color: theme.colors.brandBlue[7],
  },
}))
