import React from 'react'
import { TypographyStylesProvider } from '@mantine/core'

import { getTextColor } from 'config/themeOverride/getColor'
import { htmlEncodeByRegExp } from 'utils'

type Props = {
  content: string
}

const MessageContent = ({ content }: Props) => {
  return (
    <TypographyStylesProvider
      sx={(theme) => ({
        fontSize: theme.fontSizes.sm,
        color: getTextColor('gray.7', theme),
        wordBreak: 'break-all',
      })}
    >
      <div dangerouslySetInnerHTML={{ __html: htmlEncodeByRegExp(content!) }} />
    </TypographyStylesProvider>
  )
}

export default MessageContent
