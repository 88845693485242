import {
  ApplicationCustomerAttributeValuesQueryVariables,
  ConditionInput,
  useApplicationCustomerAttributeValuesQuery,
  useApplicationSceneAttributeValuesQuery,
} from 'types/domain'
import { message } from 'components/ui'
import { useRouter } from 'next/router'
import { codePrefix } from 'config'
import { PAGE_SIZE } from 'config/constants'
import { QueryHookOptions } from '@apollo/client'

export default function useCustomerOrSceneOptions(condition: ConditionInput) {
  const router = useRouter()
  const { appId } = router.query
  const isSceneCondition = condition.firstNode.value?.startsWith?.(codePrefix.scene)
  const isCustomerCondition = condition.firstNode.value?.startsWith?.(codePrefix.customer)
  const queryOptions = {
    variables: {
      idOrAliasOrCode: condition?.firstNode?.value,
      applicationId: appId as string,
      pageArguments: {
        first: PAGE_SIZE.UN_LIMIT,
      },
    },
    onError(error) {
      message.error(error.message)
    },
  } as QueryHookOptions<any, ApplicationCustomerAttributeValuesQueryVariables>
  const { data: customerAttrData } = useApplicationCustomerAttributeValuesQuery({
    ...queryOptions,
    skip: !isCustomerCondition,
  })
  const { data: sceneAttrData } = useApplicationSceneAttributeValuesQuery({ ...queryOptions, skip: !isSceneCondition })

  return (
    customerAttrData?.applicationCustomerAttribute?.values?.nodes ||
    sceneAttrData?.applicationSceneAttribute?.values.nodes ||
    []
  )
}
