import { ConditionType, usePeriodTypes } from 'components/business'
import { userSegmentCode } from 'components/business/CampaignBuilder/Distribution/Who/constant'
import { getOperatorLabel } from 'components/business/Filter/DefaultFilter/Conditions/Operator/operatorUtils'
import { isArray, isNil } from 'lodash-es'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { ConditionNode, ConditionNodeType, ConditionOperator, useCustomerSegmentsBaseInfoQuery } from 'types/domain'
import { getRangeLabel } from 'utils'

export default function useFormatCondition() {
  const { t } = useTranslation('common')
  const { getPeriodLabel } = usePeriodTypes()
  const { data } = useCustomerSegmentsBaseInfoQuery({
    variables: {
      id: useRouter().query.appId as string,
    },
  })

  const getSecondNodeText = (node?: ConditionNode | null, operator?: ConditionType['operator']) => {
    const { type, value } = node ?? {}
    if (value && type === ConditionNodeType.Period) {
      return getPeriodLabel(value)
    }
    if (operator === ConditionOperator.NumberBetween) {
      return isArray(value) ? getRangeLabel(value, t) : ''
    }
    if (isArray(value)) {
      return value.join(', ')
    }
    return value ?? ''
  }

  const getUserSegments = (node?: ConditionNode | null) => {
    const { value } = node ?? {}
    return data?.application?.customerSegments
      ?.filter((segment) => value.includes(segment.id))
      .map((value) => value.name)
      .join(', ')
  }

  return (condition: ConditionType, firstNodeMapper?: (value: string) => string | null | undefined) => {
    const {
      firstNode: { value: firstNodeValue },
      operator,
      secondNode,
    } = condition
    if (isNil(firstNodeValue) || isNil(operator)) {
      return ''
    }
    const firstNodeText = firstNodeMapper?.(firstNodeValue) || firstNodeValue
    const operatorText = getOperatorLabel(operator as ConditionOperator, t)
    const secondNodeText =
      firstNodeValue === userSegmentCode
        ? getUserSegments(secondNode as ConditionNode)
        : getSecondNodeText(secondNode as ConditionNode, operator)
    return `${firstNodeText} ${operatorText} ${secondNodeText}`
  }
}
