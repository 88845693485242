import React, { ChangeEvent } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { PasswordInput } from '@mantine/core'
import { AuthenticationFormValues } from 'components/business/Authentication'
import { UseFormReturnType } from '@mantine/form/lib/types'
import { SignupFormValues } from 'modules/Signup/formContext'

interface Props {
  form: UseFormReturnType<AuthenticationFormValues | SignupFormValues>
}

const PasswordInputStep = ({ form }: Props) => {
  const { t } = useTranslation('user')
  const { onBlur, ...passwordProps } = form.getInputProps('password')

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    onBlur(event)
    form.isDirty('confirmPassword') && form.values.confirmPassword && form.validateField('confirmPassword')
  }

  return (
    <>
      <PasswordInput
        size="md"
        placeholder={t('authentication.form.passwordInput.password')}
        autoComplete="new-password"
        onBlur={handlePasswordChange}
        {...passwordProps}
      />
      <PasswordInput
        size="md"
        placeholder={t('authentication.form.passwordInput.confirmPassword')}
        {...form.getInputProps('confirmPassword')}
      />
    </>
  )
}

export default PasswordInputStep
