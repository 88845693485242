import { hideNotification } from '@mantine/notifications'
import { AnchorLink } from 'components/ui'
import Trans from 'next-translate/Trans'
import React, { useState } from 'react'
import { isEmpty } from 'lodash-es'
import router from 'next/router'
import { Button, Group, Stack, Text, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { useDisclosure } from '@mantine/hooks'
import { useAddToCustomerSegmentsMutation, useCustomerSegmentsCustomerCountByIdsLazyQuery } from 'types/domain'
import message from 'components/ui/message'

import CustomerSegmentSelector from '../CustomerSegmentSelector'
import { AddToSegmentsProps } from './type'
import { getTextColor } from 'config/themeOverride/getColor'

type Props = AddToSegmentsProps & {
  onClose: () => void
}

const Content = (props: Props) => {
  const { t } = useTranslation('pageCustomerSegment')
  const theme = useMantineTheme()
  const { customerIds, existedSegmentIds, onClose, onSuccess } = props
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [disabled, toggle] = useDisclosure(false)
  const [queryCustomerSegmentsCustomerCount] = useCustomerSegmentsCustomerCountByIdsLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  const [mutation] = useAddToCustomerSegmentsMutation()

  const handleSuccess = () => {
    onClose()
    onSuccess?.()
    message.successMsg(`${t('customersActions.addToSegment')}${t('common:message.success')}`)
  }

  const handleSave = async () => {
    if (isEmpty(customerIds) || isEmpty(selectedIds)) {
      handleSuccess()
      return
    }
    toggle.open()
    try {
      const { data } = await mutation({
        variables: {
          input: {
            applicationId: router.query.appId as string,
            customerIds,
            segmentIds: selectedIds,
          },
        },
      })
      await queryCustomerSegmentsCustomerCount({
        variables: {
          ids: selectedIds,
        },
      })
      if (data?.addToCustomerSegments) {
        handleSuccess()
      }
    } catch (e) {
      message.error(`${t('customersActions.addToSegment')}${t('common:message.failed')}`)
    } finally {
      toggle.close()
    }
  }

  return (
    <Stack>
      <Text color={getTextColor('gray.7', theme)}>
        <Trans
          i18nKey="pageCustomerSegment:addToSegment.description"
          components={{
            link: <AnchorLink target="__blank" href="https://howxm.com/help/articles/customer-segment" />,
          }}
        />
      </Text>
      <CustomerSegmentSelector disabledSegmentIds={existedSegmentIds} value={selectedIds} onChange={setSelectedIds} />
      <Group position="right" mt="md">
        <Button variant="default" onClick={onClose}>
          {t('common:actions.cancel')}
        </Button>
        <Button type="submit" disabled={disabled} onClick={handleSave}>
          {t('common:actions.save')}
        </Button>
      </Group>
    </Stack>
  )
}

export default Content
