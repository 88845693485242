import useTranslation from 'next-translate/useTranslation'
import { Group, Text, useMantineTheme } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { getTextColor } from 'config/themeOverride/getColor'

type VasFeatureProps = {
  titleKey: string
  value?: string
}

export const FeatureText = ({ titleKey, value }: VasFeatureProps) => {
  return (
    <Feature titleKey={titleKey}>
      <Text>{value}</Text>
    </Feature>
  )
}

export const FeatureCheck = ({ titleKey }: { titleKey: string }) => {
  const theme = useMantineTheme()
  return (
    <Feature titleKey={titleKey}>
      <IconCheck color={theme.colors.brandBlue[7]} size={24} />
    </Feature>
  )
}

const Feature = ({ titleKey, children }: { titleKey: string; children: React.ReactNode }) => {
  const { t } = useTranslation('product')
  const theme = useMantineTheme()

  return (
    <Group position="apart">
      <Text c={getTextColor('gray.7', theme)}>{t(`feature.${titleKey}.name`)}</Text>
      {children}
    </Group>
  )
}
