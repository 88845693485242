import useTranslation from 'next-translate/useTranslation'
import { Badge, Group, MantineColor, Stack, Text } from '@mantine/core'
import { floor, isNil } from 'lodash-es'
import { WebhookHistory, WebhookHistoryStatus } from 'types/domain'
import { formatISOTime } from 'utils'
import { DEMO_WEBHOOK_CAMPAIGN_ID, DEMO_WEBHOOK_ENTRY_ID } from '../constants'

export type WebhookHistoryItemType = Pick<
  WebhookHistory,
  'id' | 'eventId' | 'responseStatusCode' | 'sentAt' | 'status'
> & { target?: { id?: string | null } | null }

interface Props {
  history: WebhookHistoryItemType
  className?: string
  onClick?: () => void
}

function getBadgeColor(statusCode: number | null | undefined, status: WebhookHistoryStatus): MantineColor {
  if (isNil(statusCode) || status == WebhookHistoryStatus.Failure) {
    return 'red'
  }
  const series = floor(statusCode / 100)
  if (series === 2) {
    return 'teal'
  }
  if ([4, 5].includes(series)) {
    return 'red'
  }
  return 'yellow'
}

const HistoryItem = ({ history, className, onClick }: Props) => {
  const { t } = useTranslation('integration')
  const { responseStatusCode, eventId, sentAt, status } = history
  const isDemo = history.target?.id === DEMO_WEBHOOK_ENTRY_ID || history.eventId.startsWith(DEMO_WEBHOOK_CAMPAIGN_ID)

  return (
    <Group align="start" noWrap spacing="sm" px="sm" py={12} className={className} onClick={onClick}>
      <Badge
        color={getBadgeColor(responseStatusCode, status)}
        styles={{
          root: {
            paddingLeft: 0,
            paddingRight: 0,
            width: '38px',
            flexShrink: 0,
          },
        }}
      >
        {!responseStatusCode || (responseStatusCode == 200 && status == WebhookHistoryStatus.Failure)
          ? 'ERR'
          : responseStatusCode}
      </Badge>
      <Stack spacing="xs" w="100%">
        <Group position="apart">
          <Text>{formatISOTime(sentAt)}</Text>
          {isDemo && (
            <Text color="dimmed" size="xs">
              {t('webhook.histories.list.demo')}
            </Text>
          )}
        </Group>
        <Text fz="xs" color="dimmed" lineClamp={1}>
          {eventId}
        </Text>
      </Stack>
    </Group>
  )
}

export default HistoryItem
