import { noop } from 'lodash-es'
import { createContext } from 'react'
import { CampaignActionProps, CampaignContextProps } from './type'

export const CampaignContext = createContext<CampaignContextProps>({
  campaign: null,
  campaignTypeName: '',
})

export const CampaignActionContext = createContext<CampaignActionProps>({
  setEntriesCount: noop,
  setEnabled: noop,
})
