import React from 'react'
import { Box, Group, ScrollArea, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { Field, FormFieldType } from 'types/domain'
import { FieldIcon } from 'components/business'
import { shouldShowFieldInEntryModal } from 'utils/fieldUtils'
import getFieldRender from './getFieldRender'

type Props = {
  data: any
  formFields: Field[]
}

const SurveyMain = ({ data, formFields }: Props) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('entry')

  return (
    <ScrollArea py="lg" px="3xl" h="100%">
      <Stack spacing={32}>
        <Title order={5} mb={`calc(${theme.spacing.xl}-${theme.spacing.lg}) * -1`}>
          {t('entryModal.formEntry')}
        </Title>
        {formFields.map((field, index) => {
          return (
            <Box key={field.code}>
              <Stack spacing="sm" className="grow">
                <Group align="flex-start" noWrap>
                  <FieldIcon className="p-[2px] !h-[20px]" type={field.type as FormFieldType} />
                  <Text className="break-all">
                    {t('entryModal.fieldIndexWithLabel', {
                      fieldIndex: shouldShowFieldInEntryModal(field) ? undefined : index + 1,
                      fieldLabel: field.label,
                    })}
                  </Text>
                </Group>
                <Box ml={36}>{getFieldRender({ data, field })}</Box>
              </Stack>
            </Box>
          )
        })}
      </Stack>
    </ScrollArea>
  )
}

export default SurveyMain
