import { cloneDeep } from 'lodash-es'
import { MRT_Localization_ZH_HANS } from 'mantine-react-table/locales/zh-Hans'

const defaultTableConfig = {
  localization: MRT_Localization_ZH_HANS,
  enableColumnActions: false,
  enableColumnFilters: false,
  enablePagination: false,
  enableSorting: false,
  enableTopToolbar: false,
  enableBottomToolbar: false,
  enableHiding: false,
  mantineLoadingOverlayProps: { zIndex: 200 },
  mantineTableContainerProps: { sx: { height: '100%' } },
  initialState: {
    density: 'xs',
  },
  mantinePaperProps: {
    withBorder: false,
    radius: 0,
    sx: {
      height: '100%',
    },
  },
  mantineTableProps: {
    sx: {
      tableLayout: 'fixed',
    },
  },
  mantineTableBodyRowProps: () => ({
    className: 'group',
  }),
}

export default function getDefaultTableConfig() {
  return cloneDeep(defaultTableConfig)
}
