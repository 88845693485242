import { CampaignType } from 'types/domain'
import { Storage } from 'utils'
import { useContext } from 'react'
import ApplicationContext from 'components/business/Layouts/ApplicationLayout/ApplicationContext'

export default function useGroupCache() {
  const { me, application } = useContext(ApplicationContext)

  const getDefaultGroup = (campaignType: CampaignType) => {
    const groupDict = Storage.getItem(getKey()) ?? {}
    return groupDict[campaignType] ?? null
  }

  const setDefaultGroup = (campaignType: CampaignType, groupId: string | null) => {
    const key = getKey()
    const old = Storage.getItem(key) ?? {}
    Storage.setItem(key, { ...old, [campaignType]: groupId })
  }

  const deleteGroup = (campaignType: CampaignType, groupId: string) => {
    const defaultGroup = getDefaultGroup(campaignType)
    if (defaultGroup === groupId) {
      Storage.setItem(getKey(), { ...defaultGroup, [campaignType]: null })
    }
  }

  const getKey = () => {
    return `hx_user_${me?.id}_application_${application?.id}_groups`
  }

  return {
    getDefaultGroup,
    setDefaultGroup,
    deleteGroup,
  }
}
