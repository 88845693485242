import React, { FC, useState } from 'react'
import { Box, Button, Group, Stack, Text, useMantineTheme } from '@mantine/core'
import { formatISO } from 'date-fns'
import { DatePicker } from '@mantine/dates'
import { IconX } from '@tabler/icons-react'
import useTranslation from 'next-translate/useTranslation'

import { getBorderColor, getTextColor } from 'config/themeOverride/getColor'
import { endOfDate, formatRangeDates } from 'utils'
import { ConditionNodeInput, ConditionNodeType } from 'types/domain'
import Divider from 'components/ui/Divider'

type Props = {
  value: ConditionNodeInput
  onChange: (value: ConditionNodeInput) => void
  onClose: () => void
}

const CustomizeDatePicker: FC<Props> = ({ onChange, onClose, value }) => {
  const { t } = useTranslation('componentEntryFilter')
  const theme = useMantineTheme()
  const defaultValue = value?.type === ConditionNodeType.CustomizedPeriod ? value.value : [null, null]
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>(defaultValue)

  const onOk = (value: ConditionNodeInput) => {
    onChange?.(value)
  }

  const label = formatRangeDates(dateValue)

  return (
    <Stack spacing="sm" pt="md" pb="sm">
      <Box
        p="sm"
        mx="md"
        sx={(theme) => ({
          border: `1px solid ${getBorderColor('gray.2', theme)}`,
          borderRadius: theme.radius.sm,
        })}
      >
        <Group position="apart">
          <Text color={getTextColor(label ? 'gray.9' : 'gray.5', theme)}>{label || t('datePeriodPlaceholder')}</Text>
          {label && (
            <IconX size={14} color={getTextColor('gray.6', theme)} onClick={() => setDateValue([null, null])} />
          )}
        </Group>
      </Box>
      <DatePicker
        type="range"
        value={dateValue}
        onChange={(value) => {
          const [start, end] = value
          const endDate = end ? endOfDate(end) : null
          setDateValue([start, endDate])
        }}
        mx="md"
        monthLabelFormat="YYYY/MM"
        locale="zh-CN"
        allowSingleDateInRange
        firstDayOfWeek={0}
      />
      <Divider />
      <Group spacing="sm" mr="md" position="right">
        <Button variant="default" size="xs" onClick={onClose}>
          {t('common:actions.cancel')}
        </Button>
        <Button
          size="xs"
          disabled={!dateValue[0] || !dateValue[1]}
          onClick={() => {
            onOk({
              value: [formatISO(dateValue[0]!), formatISO(dateValue[1]!)],
              type: ConditionNodeType.CustomizedPeriod,
            })
          }}
        >
          {t('common:actions.save')}
        </Button>
      </Group>
    </Stack>
  )
}

export default CustomizeDatePicker
