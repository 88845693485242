import { useCallback, useEffect, useRef } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { createStyles, Stack, useMantineTheme } from '@mantine/core'
import { isNil } from 'lodash-es'
import InfiniteScroll from 'components/ui/InfiniteScroll'
import { getBorderColor } from 'config/themeOverride/getColor'
import HistoryItem, { WebhookHistoryItemType } from './HistoryItem'
import SubTitle, { subTitleLineHeightPx } from './SubTitle'

const useStyles = createStyles((theme) => {
  return {
    root: {
      flexShrink: 0,
    },
    item: {
      cursor: 'pointer',
      '& + &': {
        borderTop: `1px solid ${getBorderColor('gray.1', theme)}`,
      },
      '&:hover, &.active': {
        backgroundColor: getBorderColor('brandBlue.0', theme, 'dark.5'),
      },
    },
  }
})

interface Props {
  histories: WebhookHistoryItemType[]
  onLoadMore: () => void
  hasMore: boolean
  onItemClick?: (item: WebhookHistoryItemType) => void
}

const HistoryList = ({ histories, onLoadMore, hasMore, onItemClick }: Props) => {
  const { t } = useTranslation('integration')
  const theme = useMantineTheme()
  const { classes, cx } = useStyles()
  const selected = useRef<WebhookHistoryItemType>()

  const handleItemClick = useCallback(
    (history: WebhookHistoryItemType) => {
      selected.current = history
      onItemClick?.(history)
    },
    [onItemClick]
  )

  useEffect(() => {
    isNil(selected.current) && histories.length > 0 && handleItemClick(histories[0])
  }, [handleItemClick, histories])

  return (
    <Stack w={330} h="100%" spacing="lg" className={classes.root}>
      <SubTitle>{t('webhook.histories.list.title')}</SubTitle>
      <InfiniteScroll
        next={onLoadMore}
        hasMore={hasMore}
        dataLength={histories.length}
        offsetScrollbars
        h={`calc(100% - ${subTitleLineHeightPx} - ${theme.spacing.lg})`}
      >
        {histories.map((history) => (
          <HistoryItem
            key={history.id}
            history={history}
            className={cx(classes.item, { active: history.id === selected.current?.id })}
            onClick={() => handleItemClick(history)}
          />
        ))}
      </InfiniteScroll>
    </Stack>
  )
}

export default HistoryList
