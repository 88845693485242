import React, { useContext } from 'react'
import { Center, Flex, Group, Navbar } from '@mantine/core'
import { Application, CampaignDistributionChannel } from 'types/domain'
import { isLinkDistribution } from 'utils/applicationUtils'
import CurrentSubscription from '../CurrentSubscription'
import AppManager from '../AppManager'
import ClientSideOnly from 'components/ui/ClientSideOnly'
import Onboarding from '../Onboadring'
import ApplicationContext from 'components/business/Layouts/ApplicationLayout/ApplicationContext'
import ApplicationStatus from '../ApplicationStatus'
import SubscriptionAlert from 'components/business/subscription/SubscriptionAlert'
import { Customer, Feedback, Integration, Member, Notification, Reply, Setting, Survey, Template } from './navs'

const Navs = ({ channel }: { channel: CampaignDistributionChannel }) => {
  switch (channel) {
    case CampaignDistributionChannel.Link:
      return (
        <>
          <Survey /> <Integration /> <Member /> <Setting />
        </>
      )
    case CampaignDistributionChannel.WechatMiniapp:
      return (
        <>
          <Survey /> <Customer /> <Template /> <Integration /> <Member /> <Setting />
        </>
      )
    case CampaignDistributionChannel.IosSwift:
      return (
        <>
          <Survey /> <Feedback /> <Customer /> <Template /> <Integration /> <Member /> <Setting />
        </>
      )
    case CampaignDistributionChannel.Android:
      return (
        <>
          <Survey /> <Feedback /> <Customer /> <Template /> <Integration /> <Member /> <Setting />
        </>
      )
    default:
      return (
        <>
          <Survey /> <Feedback /> <Notification />
          <Reply /> <Customer /> <Template /> <Integration /> <Member />
          <Setting />
        </>
      )
  }
}

const Nav = () => {
  const { application, me } = useContext(ApplicationContext)
  const isLinkApplication = isLinkDistribution(application as Application)
  if (!application) {
    return null
  }

  return (
    <>
      <Navbar.Section px="sm">
        <Group spacing="sm" align="stretch" mb="md">
          <Flex align="stretch" className="flex-1" gap={6}>
            <Center>
              <AppManager />
            </Center>
            <Center>
              <SubscriptionAlert collapsed={true} />
            </Center>
          </Flex>
          {!isLinkApplication && <ApplicationStatus />}
        </Group>
      </Navbar.Section>
      <Navbar.Section grow px="sm">
        <Navs channel={application.distribution.channel} />
      </Navbar.Section>
      <Navbar.Section px="sm">
        <CurrentSubscription />
      </Navbar.Section>
      <ClientSideOnly>{!isLinkApplication && <Onboarding />}</ClientSideOnly>
    </>
  )
}

export default Nav
