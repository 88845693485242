import { FC, PropsWithChildren } from 'react'
import { useBuilderSharedDataContext } from 'components/business/CampaignBuilder'
import { isLinkDistribution, isWebDistribution } from 'utils/applicationUtils'
import HybridPreview from './HybridPreview'
import { Box, Group } from '@mantine/core'
import cn from 'classnames'
import ContainerWithDotBg from 'components/ui/ContainerWithDotBg'

interface Props {
  left?: number
  right?: number
  className?: string
}

const Container = ({ left, right, children, className }: PropsWithChildren<Props>) => {
  return (
    <ContainerWithDotBg className="h-full max-w-none">
      <Box
        pos="absolute"
        top="0"
        bottom="0"
        left={left || 0}
        right={right || 0}
        className={cn(className, 'overflow-hidden')}
      >
        <Box className="h-full w-full relative overflow-hidden" py="xl">
          <Group position="center" align="top" className="h-full">
            {children}
          </Group>
        </Box>
      </Box>
    </ContainerWithDotBg>
  )
}

const PlatformPreview: FC<Props> = ({ left, right }: Props) => {
  const application = useBuilderSharedDataContext().application!
  const shouldShowHybridPreview = isLinkDistribution(application) || isWebDistribution(application)

  return (
    <Container left={left} right={right}>
      <HybridPreview
        platformChangeable={shouldShowHybridPreview}
        defaultPlatform={isWebDistribution(application) ? 'web' : 'mobile'}
      />
    </Container>
  )
}

export default PlatformPreview
