import { useContext } from 'react'
import { EntriesActionContext, EntriesContext } from './EntriesContext'

export function useEntries() {
  const context = useContext(EntriesContext)
  if (context === undefined) {
    throw new Error('useEntries must be used within a EntriesProvider')
  }
  return context
}

export function useEntriesActions() {
  const context = useContext(EntriesActionContext)
  if (context === undefined) {
    throw new Error('useEntriesActions must be used within a EntriesProvider')
  }
  return context
}
