import React from 'react'
import { IconSearch } from '@tabler/icons-react'
import { Stack, Text, ThemeIcon, Title, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'

const TableEmpty = () => {
  const { t } = useTranslation('common')
  const theme = useMantineTheme()

  return (
    <Stack spacing={0} align="center" mt={80}>
      <ThemeIcon radius="xl" size={48} color={getBackgroundColor('gray.0', theme)}>
        <IconSearch size={30} color={getTextColor('gray.5', theme)} />
      </ThemeIcon>
      <Title mt="md" size="sm">
        {t('empty.tableEmpty')}
      </Title>
      <Text mt="sm" color={getTextColor('gray.7', theme)}>
        {t('empty.filterDescription')}
      </Text>
    </Stack>
  )
}

export default TableEmpty
