import React, { FC } from 'react'
import { IconChevronLeft } from '@tabler/icons-react'
import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'
import { Button, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

type Props = {}

const Back: FC<Props> = ({}) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('common')

  const onBack = () => {
    window.history.back()
  }

  return (
    <Button
      variant="subtle"
      c={getTextColor('gray.7', theme)}
      sx={{ '&:hover': { backgroundColor: getBackgroundColor('gray.2', theme, 'dark.6') } }}
      leftIcon={<IconChevronLeft size={18} stroke={1.5} color={getTextColor('gray.7', theme)} />}
      pos="absolute"
      top={theme.spacing.lg}
      left={theme.spacing.xl}
      onClick={onBack}
    >
      {t('actions.back')}
    </Button>
  )
}

export default Back
