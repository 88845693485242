import React, { useEffect } from 'react'
import { TextInput } from '@mantine/core'
import { useDebouncedValue, useInputState, usePrevious } from '@mantine/hooks'
import { IconSearch } from '@tabler/icons-react'
import useTranslation from 'next-translate/useTranslation'

import { keywordCode } from 'config/constants/fieldType'
import { useCampaignFilter, useCampaignFilterActions } from 'components/business/Campaign/Filter/CampaignFilterContext'
import { ConditionInput, ConditionOperator } from 'types/domain'

const KeywordSearch = () => {
  const { t } = useTranslation('common')
  const { filter } = useCampaignFilter()
  const { onExplicitlyFieldChange } = useCampaignFilterActions()

  const [keyword, setKeyword] = useInputState<string>('')
  const [debounced] = useDebouncedValue(keyword, 400)
  const previousValue = usePrevious(debounced)

  useEffect(() => {
    setKeyword(
      (filter?.conditions as ConditionInput[])?.find(({ firstNode }) => firstNode.value === keywordCode)?.secondNode
        ?.value ?? ''
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.conditions])

  useEffect(() => {
    if ((previousValue?.trim() ?? '') === debounced.trim()) {
      return
    }
    if (debounced.trim() == '') {
      onExplicitlyFieldChange(keywordCode, null)
    } else {
      onExplicitlyFieldChange(keywordCode, {
        operator: ConditionOperator.Contains,
        firstNode: { value: keywordCode },
        secondNode: { value: debounced.trim() },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced])

  return (
    <TextInput
      size="xs"
      w={160}
      placeholder={t('placeholders.search')}
      data-autofocus
      icon={<IconSearch size={16} />}
      value={keyword}
      onChange={setKeyword}
    />
  )
}

export default KeywordSearch
