import { createContext } from 'react'
import { ApplicationCustomerAttribute, User, Webhook } from 'types/domain'

export interface WebhookContextProps {
  appId: string
  campaign?: {
    id: string
    name: string
    creator?: User
  }
  webhook?: Webhook
  customerAttributes: Pick<ApplicationCustomerAttribute, 'code' | 'aliases'>[]
  isAppScope: boolean
}

const WebhookContext = createContext<WebhookContextProps>({
  appId: '',
  customerAttributes: [],
  isAppScope: false,
})

export default WebhookContext
