import React, { useState } from 'react'
import { Center, px, SimpleGrid, Stack, Text, useMantineTheme } from '@mantine/core'

import { PriceInterval, Product as ProductType, useProductsQuery } from 'types/domain'
import { ProductCode } from 'config/constants/products'
import { recommendBadgeOverflowSize, recommendProduct } from './constant'
import PriceIntervalToggle, { PriceIntervalType } from './PriceIntervalToggle'
import Product from './Product'
import useAccountSubscriptionCache from 'components/business/hooks/useAccountSubscriptionCache'

interface Props {
  fromSite?: boolean
  feature?: string
  products?: ProductType[]
}

const minProductItemWidth = 288
const maxProductItemWidth = 300

const ProductsModalContent = ({ feature, products: serverSideProducts, fromSite = false }: Props) => {
  const theme = useMantineTheme()
  const { subscription } = useAccountSubscriptionCache()

  const { productCode } = subscription ?? {}
  const [interval, setInterval] = useState<PriceIntervalType>(PriceInterval.Year)
  const { data } = useProductsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !!serverSideProducts?.length,
  })

  const products = data?.products ?? serverSideProducts ?? []

  const currentIndex = products.findIndex(({ code }) => code === productCode)

  const productsCount = products.length
  const minWidth = productsCount * minProductItemWidth + (productsCount - 1) * px(theme.spacing.lg)
  const maxWidth = productsCount * maxProductItemWidth + (productsCount - 1) * px(theme.spacing.lg)

  return (
    <>
      <Center mih="100%" pb="md" className={`max-xs:w-full sm:min-w-[${minWidth}px]`}>
        <Stack className="max-xs:w-full items-center gap-4xl sm:px-[0] sm:gap-2xl">
          <PriceIntervalToggle value={interval} onChange={setInterval} />
          <SimpleGrid
            cols={productsCount}
            pt={recommendBadgeOverflowSize}
            className={`max-xs:w-full sm:!max-w-[${maxWidth}px]`}
            breakpoints={[
              { maxWidth: 576, cols: 1, spacing: theme.spacing.lg },
              { minWidth: 576, cols: productsCount, spacing: theme.spacing.lg },
            ]}
          >
            {products.map((product, index) => (
              <Product
                key={product.code}
                product={product}
                priceInterval={interval}
                currentCode={productCode as ProductCode}
                recommend={product.code === recommendProduct}
                disablePurchase={index < currentIndex}
                feature={feature}
                fromSite={fromSite}
              />
            ))}
          </SimpleGrid>
        </Stack>
      </Center>
      <Text color="red" pb="3xl">
        * 浩客也支持私有化部署，具体内容，可咨询在线客服或客户顾问
      </Text>
    </>
  )
}

export default ProductsModalContent
