import { getTextColor } from 'config/themeOverride/getColor'
import Trans from 'next-translate/Trans'
import { AnchorLink } from 'components/ui'
import { ApplicationSettingsCodeTracking } from 'config/constants'
import { Text, useMantineTheme } from '@mantine/core'
import React from 'react'
import { useRouter } from 'next/router'

const SdkGuide = () => {
  const appId = useRouter().query.appId as string
  const theme = useMantineTheme()
  return (
    <Text c={getTextColor('white', theme, 'white')}>
      <Trans
        i18nKey="layoutApplication:sdkOnboarding.connectSdk"
        components={{
          install: (
            <AnchorLink
              target="_blank"
              className="text-[white] underline"
              href={ApplicationSettingsCodeTracking.replace('[appId]', appId)}
            />
          ),
        }}
      />
    </Text>
  )
}

export default SdkGuide
