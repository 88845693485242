import { useContext } from 'react'
import { isNil } from 'lodash-es'
import { Field, useCampaignFieldsQuery } from 'types/domain'
import { commentedCode } from 'config/constants/fieldType'
import FieldFilter from 'components/business/Filter/FieldFilterPopover/Filter'
import { convertToFieldFilterPopoverValue } from 'components/business/Campaign/Filter/utils/convertToValidConditionGroup'
import useEntryFilterableFields from 'components/business/Campaign/Filter/hooks/useEntryFilterableFields'
import WebhookContext from '../../../../WebhookContext'
import { useWebhookBuilderFormContext } from '../../../formContext'
import useIsBuilderEditable from '../../../hooks/useIsBuilderEditable'

const Filter = () => {
  const form = useWebhookBuilderFormContext()
  const { customerAttributes } = useContext(WebhookContext)
  const editable = useIsBuilderEditable()
  const getEntryFilterableFields = useEntryFilterableFields()
  const { value, ...rest } = form.getInputProps('conditionGroup')

  const campaignId = form.values.campaignId
  const { data, loading } = useCampaignFieldsQuery({
    variables: {
      campaignId: campaignId!,
    },
    skip: isNil(campaignId) || (!editable && isNil(form.values.conditionGroup)),
  })

  if (loading) {
    return null
  }

  const fields = (data?.campaign?.form?.fields as Field[]) ?? []

  const filterableFields = getEntryFilterableFields(fields ?? [], customerAttributes, [commentedCode])
  return (
    <FieldFilter
      showHeader={false}
      withDefault
      campaignFields={filterableFields}
      value={convertToFieldFilterPopoverValue(value)}
      readOnly={!editable}
      pl={28}
      {...rest}
    />
  )
}

export default Filter
