import { FC } from 'react'
import CustomerSegmentNode from './CustomerSegmentNode'
import DateSelector from './DateSelector'
import MultiSelector from './MultiSelector'
import NumberBetweenInput from './NumberBetweenInput'
import NumberInput from './NumberInput'
import TextInput from './TextInput'
import UrlInput from './UrlInput'

export enum SECOND_NODE_TYPE {
  TEXT = 'text',
  URL = 'urlInput',
  MULTIPLE_SELECT = 'multiSelect',
  DATE = 'date',
  NUMBER = 'number',
  NUMBER_BETWEEN = 'numberBetween',
  USER_SEGMENT = 'userSegment',
}

export const secondsNodes: { [key in typeof SECOND_NODE_TYPE[keyof typeof SECOND_NODE_TYPE]]: FC<any> } = {
  [SECOND_NODE_TYPE.URL]: UrlInput,
  [SECOND_NODE_TYPE.TEXT]: TextInput,
  [SECOND_NODE_TYPE.MULTIPLE_SELECT]: MultiSelector,
  [SECOND_NODE_TYPE.DATE]: DateSelector,
  [SECOND_NODE_TYPE.NUMBER]: NumberInput,
  [SECOND_NODE_TYPE.NUMBER_BETWEEN]: NumberBetweenInput,
  [SECOND_NODE_TYPE.USER_SEGMENT]: CustomerSegmentNode,
}
