import React, { FC, useRef } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { throttle } from 'lodash-es'

import { message } from 'components/ui'
import { useCreateEntryCommentMutation } from 'types/domain'
import MessageAdder from '../components/MessageAdder'

type Props = {
  entryId: string
  onSave: () => void
  onFocus: () => void
}

const CommentAdder: FC<Props> = ({ entryId, onSave, onFocus }) => {
  const { t } = useTranslation('entry')
  const [createEntryCommentMutation] = useCreateEntryCommentMutation()

  const handleSave = useRef(
    throttle(async (entryId: string, value: string, afterSave: () => void) => {
      try {
        const { data } = await createEntryCommentMutation({
          variables: {
            input: {
              entryId,
              content: value?.trim()!,
            },
          },
          update: (cache, { data }) => {
            if (!data?.createEntryComment?.entryComment) {
              return
            }
            cache.modify({
              id: cache.identify({ __typename: 'Entry', id: entryId }),
              fields: {
                comments: (existingCommentsRef) => {
                  return {
                    ...existingCommentsRef,
                    nodes: [...existingCommentsRef.nodes, data?.createEntryComment?.entryComment],
                    totalCount: existingCommentsRef.totalCount + 1,
                  }
                },
                commentsCount(existingCommentCountRef) {
                  return existingCommentCountRef + 1
                },
              },
            })
          },
        })
        if (data?.createEntryComment) {
          onSave()
          afterSave()
        }
      } catch (e) {
        message.error(t('common:message.createError'))
      }
    }, 1000)
  ).current

  return (
    <MessageAdder
      placeholder={t('entryModal.comments.placeholder')}
      onSave={(value, afterSave) => handleSave(entryId, value, afterSave)}
      onFocus={onFocus}
    />
  )
}

export default CommentAdder
