import { Stack } from '@mantine/core'
import { Product } from 'types/domain'
import { ProductCode } from 'config/constants/products'
import FreeFeature from './FreeFeature'
import BasicFeature from './BasicFeature'
import ProFeature from './ProFeature'
import UltimateFeature from './UltimateFeature'

interface Props {
  product: Pick<Product, 'features' | 'vas' | 'code'>
}

const ProductFeatures = ({ product }: Props) => {
  return (
    <Stack pt="lg" px={40} pb={40} spacing={12}>
      {product.code === ProductCode.Free && <FreeFeature />}
      {product.code === ProductCode.Basic && <BasicFeature />}
      {product.code === ProductCode.Pro && <ProFeature />}
      {product.code === ProductCode.Ultimate && <UltimateFeature />}
    </Stack>
  )
}

export default ProductFeatures
