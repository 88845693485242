import { merge } from 'lodash-es'
import React, { CSSProperties, FC, ReactNode } from 'react'
import { Button, Menu, Stack, useMantineTheme } from '@mantine/core'
import { getBackgroundColor, getBorderColor, getTextColor } from 'config/themeOverride/getColor'

type Props = {
  buttonChildren: ReactNode
  dropdownChildren: ReactNode
  dropdownStyle?: CSSProperties
}

const MenuWrapper: FC<Props> = (props) => {
  const { buttonChildren, dropdownChildren, dropdownStyle } = props
  const theme = useMantineTheme()

  return (
    <Menu
      withinPortal
      offset={12}
      shadow="xl"
      position="right-end"
      closeOnItemClick
      // @ts-ignore
      styles={(theme) => ({
        itemIcon: {
          marginRight: theme.spacing.md,
        },
        dropdown: merge(
          {
            width: '236px !important',
            borderColor: getBorderColor('gray.2', theme),
          },
          dropdownStyle
        ),
        item: {
          '&[data-hovered]': {
            backgroundColor: getBackgroundColor('gray.1', theme),
          },
        },
      })}
    >
      <Menu.Target>
        <Stack spacing={0}>
          <Button
            fullWidth
            h={44}
            px={10}
            py="xs"
            variant="subtle"
            styles={{
              root: {
                border: 0,
                color: getTextColor('gray.9', theme),
                '&:hover': {
                  backgroundColor: getBackgroundColor('gray.1', theme),
                },
              },
              inner: {
                justifyContent: 'flex-start',
              },
            }}
          >
            {buttonChildren}
          </Button>
        </Stack>
      </Menu.Target>
      <Menu.Dropdown>{dropdownChildren}</Menu.Dropdown>
    </Menu>
  )
}

export default MenuWrapper
