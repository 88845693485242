import Icon from 'components/ui/Icon'
import {
  ApplicationCustomers,
  ApplicationFeedback,
  ApplicationFeedbackEntries,
  ApplicationFeedbackEntry,
  ApplicationFeedbackIntegration,
  ApplicationFeedbackShow,
  ApplicationIntegration,
  ApplicationMembers,
  ApplicationNotification,
  ApplicationNotificationEntries,
  ApplicationNotificationEntry,
  ApplicationNotificationIntegration,
  ApplicationNotificationReports,
  ApplicationNotificationShow,
  ApplicationReply,
  ApplicationSegmentCustomer,
  ApplicationSettingsApi,
  ApplicationSettingsCodeBasic,
  ApplicationSettingsCodeTracking,
  ApplicationSettingsCustomerAttributes,
  ApplicationSettingsDistribution,
  ApplicationSettingsEvents,
  ApplicationSettingsWebhook,
  ApplicationSurvey,
  ApplicationSurveyEntries,
  ApplicationSurveyEntry,
  ApplicationSurveyIntegration,
  ApplicationSurveyReports,
  ApplicationSurveyShow,
  ApplicationTemplate,
  ApplicationTemplates,
} from 'config/constants/routerPath'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import MainLink from 'components/business/Layouts/MainLayout/components/MainLink'

export const Survey = () => {
  const { t } = useTranslation('layoutApplication')
  return (
    <MainLink
      icon={<Icon name="tabSurvey" />}
      iconActive={<Icon name="tabSurveyFill" />}
      label={t('nav.survey')}
      pathname={ApplicationSurvey}
      activePathnames={[
        ApplicationSurveyShow,
        ApplicationSurveyReports,
        ApplicationSurveyEntries,
        ApplicationSurveyIntegration,
        ApplicationSurveyEntry,
      ]}
    />
  )
}

export const Feedback = () => {
  const { t } = useTranslation('layoutApplication')
  return (
    <MainLink
      icon={<Icon name="tabFeedback" />}
      iconActive={<Icon name="tabFeedbackFill" />}
      label={t('nav.feedback')}
      pathname={ApplicationFeedback}
      activePathnames={[
        ApplicationFeedbackShow,
        ApplicationFeedbackEntries,
        ApplicationFeedbackIntegration,
        ApplicationFeedbackEntry,
      ]}
    />
  )
}

export const Customer = () => {
  const { t } = useTranslation('layoutApplication')
  return (
    <MainLink
      icon={<Icon name="tabCustomer" />}
      iconActive={<Icon name="tabCustomerFill" />}
      label={t('nav.customer')}
      pathname={ApplicationCustomers}
      activePathnames={[ApplicationCustomers, ApplicationSegmentCustomer]}
    />
  )
}

export const Reply = () => {
  const { t } = useTranslation('layoutApplication')
  return (
    <MainLink
      icon={<Icon name="tabReply" />}
      iconActive={<Icon name="tabReplyFill" />}
      label={t('nav.reply')}
      pathname={ApplicationReply}
      activePathnames={[ApplicationReply]}
    />
  )
}

export const Notification = () => {
  const { t } = useTranslation('layoutApplication')
  return (
    <MainLink
      icon={<Icon name="tabNotification" />}
      iconActive={<Icon name="tabNotificationFill" />}
      label={t('nav.notification')}
      pathname={ApplicationNotification}
      isNewFeature={true}
      activePathnames={[
        ApplicationNotificationShow,
        ApplicationNotificationReports,
        ApplicationNotificationEntries,
        ApplicationNotificationEntry,
        ApplicationNotificationIntegration,
      ]}
    />
  )
}

export const Template = () => {
  const { t } = useTranslation('layoutApplication')
  return (
    <MainLink
      icon={<Icon name="tabTemplates" />}
      iconActive={<Icon name="tabTemplatesFill" />}
      label={t('nav.template')}
      pathname={ApplicationTemplates}
      activePathnames={[ApplicationTemplates, ApplicationTemplate]}
    />
  )
}

export const Integration = () => {
  const { t } = useTranslation('layoutApplication')
  return (
    <MainLink
      icon={<Icon name="tabIntegration" />}
      iconActive={<Icon name="tabIntegrationFill" />}
      label={t('nav.integration')}
      pathname={ApplicationIntegration}
      activePathnames={[ApplicationIntegration]}
    />
  )
}

export const Member = () => {
  const { t } = useTranslation('layoutApplication')
  return (
    <MainLink
      icon={<Icon name="tabTeammates" />}
      iconActive={<Icon name="tabTeammatesFill" />}
      label={t('nav.member')}
      pathname={ApplicationMembers}
      activePathnames={[ApplicationMembers]}
    />
  )
}

export const Setting = () => {
  const { t } = useTranslation('layoutApplication')
  return (
    <MainLink
      icon={<Icon name="tabSetting" />}
      iconActive={<Icon name="tabSettingFill" />}
      label={t('nav.setting')}
      pathname={ApplicationSettingsCodeBasic}
      activePathnames={[
        ApplicationSettingsCodeBasic,
        ApplicationSettingsCodeTracking,
        ApplicationSettingsDistribution,
        ApplicationSettingsEvents,
        ApplicationSettingsCustomerAttributes,
        ApplicationSettingsWebhook,
        ApplicationSettingsApi,
      ]}
    />
  )
}
