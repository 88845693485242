import React, { FC } from 'react'
import { ActionIcon, Button, Group, Popover, Stack, Text, Tooltip } from '@mantine/core'
import { useClickOutside, useDisclosure, useWindowEvent } from '@mantine/hooks'

import { useCampaignPermission } from 'components/business'
import useTranslation from 'next-translate/useTranslation'
import { useDeleteEntriesMutation } from 'types/domain'
import { message } from 'components/ui'
import { IconTrash } from '@tabler/icons-react'
import { useCampaign } from '../../CampaignContext'

type Props = {
  entryId: string
  onOk?: () => void
}

const DeleteEntry: FC<Props> = ({ entryId, onOk }) => {
  const { t } = useTranslation('entry')
  const { campaign } = useCampaign()
  const id = campaign?.id!
  const [deleteEntriesMutation] = useDeleteEntriesMutation()
  const { deletable } = useCampaignPermission(campaign?.creator?.id!)
  const [opened, { close, open }] = useDisclosure(false)
  const ref = useClickOutside(() => close())
  useWindowEvent('resize', close)

  if (!deletable) {
    return null
  }

  const handleDelete = async () => {
    try {
      const { data } = await deleteEntriesMutation({
        variables: { input: { campaignId: id, entryIds: [entryId] } },
      })
      if (data?.deleteEntries) {
        onOk?.()
      }
    } catch (e) {
      message.error(t('common:message.deleteError'))
    }
  }

  return (
    <Popover
      opened={opened}
      position="bottom-end"
      width={280}
      shadow="xl"
      offset={18}
      styles={{
        dropdown: { transform: 'translateX(-6px)' },
      }}
    >
      <Popover.Target>
        <Tooltip withArrow label={t('common:actions.deleteData')}>
          <ActionIcon onClick={open}>
            <IconTrash size={18} color="red" />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack spacing="lg" ref={ref}>
          <Text>{t('delete.content')}</Text>
          <Group spacing="sm" position="right">
            <Button size="xs" variant="default" onClick={close}>
              {t('common:actions.cancel')}
            </Button>
            <Button size="xs" color="red" onClick={handleDelete}>
              {t('common:actions.delete')}
            </Button>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}

export default DeleteEntry
