import { WebhookChannel } from 'types/domain'
import { IconBell } from '@tabler/icons-react'
import { Icon } from 'components/ui'

interface Props {
  channel: WebhookChannel
  size?: number
}

const ChannelIcon = ({ channel, size }: Props) => {
  return (
    <>
      {channel === WebhookChannel.Default && <IconBell size={size ?? 16} stroke={1.5} color="orange" />}
      {channel === WebhookChannel.QiyeWx && <Icon name="brandWeCom" size={size} />}
      {channel === WebhookChannel.Dingtalk && <Icon name="brandDingtalk" size={size} />}
      {channel === WebhookChannel.Feishu && <Icon name="brandFeishu" size={size} />}
    </>
  )
}

export default ChannelIcon
