import { DATE_FORMAT_FOR_DAYJS } from 'config/constants'
import React, { FC, useEffect } from 'react'
import { Group, Select } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { formatISODATE } from 'utils'
import { parseISO } from 'date-fns'
import { ConditionNodeType } from 'types/domain'
import { DatePickerInput } from '@mantine/dates'
import { useSetState } from '@mantine/hooks'
import { ValueNodeProps } from './type'
import { toggleParentPopoverCloseState } from 'components/business/Filter/FieldFilterPopover/hooks/usePropCloseOnClickOutside'
import usePeriodTypes from '../../../../DefaultFilter/hooks/usePeriodTypes'

const CustomDate: FC<ValueNodeProps> = ({ condition, onChange, readOnly }) => {
  const [{ type, value }, setValue] = useSetState({
    type: condition.secondNode?.type || ConditionNodeType.CustomizedDate,
    value: condition.secondNode?.value,
  })
  const { t } = useTranslation('common')
  const { getPeriodOptions } = usePeriodTypes()
  const isCustomDate = type === ConditionNodeType.CustomizedDate
  const currentDateValue = isCustomDate && value ? parseISO(value) : null

  useEffect(() => {
    if (!isCustomDate) {
      setValue({ type: condition.secondNode?.value })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition.secondNode?.value, isCustomDate])

  const onSelectChange = (value: string) => {
    setValue({ type: value as never })
    if (value === ConditionNodeType.CustomizedDate) {
      setValue({ value: '' })
    } else {
      onChange?.({
        value,
        type: ConditionNodeType.Period,
      })
    }
  }
  const onDateChange = (date: any) => {
    const freshValue = {
      value: date ? formatISODATE(date) : '',
      type: ConditionNodeType.CustomizedDate,
    }
    setValue(freshValue)
    onChange?.(freshValue)
  }

  return (
    <Group noWrap spacing="sm">
      <Select
        withinPortal
        data={getPeriodOptions()}
        className="flex-1"
        placeholder={t('placeholders.pleaseSelect')}
        onChange={onSelectChange}
        value={type}
        readOnly={readOnly}
      />
      {isCustomDate && (
        <DatePickerInput
          valueFormat={DATE_FORMAT_FOR_DAYJS}
          placeholder={t('placeholders.pleaseSelect')}
          className="flex-1"
          locale="zh-cn"
          onChange={onDateChange}
          value={currentDateValue}
          clearable={false}
          readOnly={readOnly}
          popoverProps={{
            withinPortal: true,
            onOpen: toggleParentPopoverCloseState,
            onClose: toggleParentPopoverCloseState,
          }}
        />
      )}
    </Group>
  )
}

export default CustomDate
