import React, { FC, useEffect, useRef, useState } from 'react'
import { Box, LoadingOverlay, ScrollArea, Stack } from '@mantine/core'

import { EntryComment, EntryCommentsDocument, useEntryCommentsQuery } from 'types/domain'
import cache from 'plugins/apollo/cache'
import { isCacheHit } from 'plugins/apollo/cacheUtils'
import { initializeApollo } from 'plugins/apollo'
import { PAGE_SIZE } from 'config/constants'
import CommentAdder from './CommentAdder'
import BlankComments from './BlankComments'
import Comment from './Comment'

type Props = {
  entryId: string
}

const client = initializeApollo()
const Comments: FC<Props> = ({ entryId }) => {
  const [editingId, setEditingId] = useState<string | null>(null)
  const viewport = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    setEditingId(null)
  }, [entryId])

  const updateCommentsCount = (commentsCount: number | undefined) => {
    client.cache.modify({
      id: cache.identify({ __typename: 'Entry', id: entryId }),
      fields: {
        commentsCount() {
          return commentsCount
        },
      },
    })
  }

  const { data, loading, variables } = useEntryCommentsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: entryId, pageArguments: { first: PAGE_SIZE.UN_LIMIT } },
    onCompleted(data) {
      updateCommentsCount(data.entry?.comments?.totalCount)
    },
  })

  if (loading && !isCacheHit(variables, EntryCommentsDocument)) {
    return <LoadingOverlay visible={true} overlayBlur={2} />
  }

  if (!data?.entry) {
    return null
  }

  const comments = data.entry.comments

  const handleSave = () => {
    setTimeout(() => {
      viewport.current?.scrollTo({ top: viewport.current?.scrollHeight })
    }, 100)
  }

  return (
    <Stack pb="sm" pt={20} h="100%" spacing="sm" justify="space-between">
      <Box className="grow shrink contents">
        {comments.totalCount === 0 ? (
          <BlankComments />
        ) : (
          <ScrollArea px="lg" viewportRef={viewport}>
            <Stack spacing="xl">
              {comments.nodes.map((comment) => {
                return (
                  <Comment
                    entryId={entryId}
                    comment={comment as EntryComment}
                    key={comment.id}
                    isEditing={editingId === comment.id}
                    onSave={() => setEditingId(null)}
                    onCancel={() => setEditingId(null)}
                    onEdit={() => setEditingId(comment.id)}
                  />
                )
              })}
            </Stack>
          </ScrollArea>
        )}
      </Box>
      <Box px="lg">
        <CommentAdder
          entryId={entryId}
          onSave={handleSave}
          onFocus={() => {
            setEditingId(null)
          }}
        />
      </Box>
    </Stack>
  )
}

export default Comments
