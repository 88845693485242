import React from 'react'
import { Group, Pagination, Text } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { formatNumberToThousands } from 'utils'

export type TableFooterProps = {
  totalCount?: number
  page: number
  totalPages: number
  onPageChange: (page: number) => void
}

export const TableFooter = (props: TableFooterProps) => {
  const { t } = useTranslation('common')
  const { page, totalPages, totalCount, onPageChange } = props

  return (
    <Group align="center" position="apart" px="lg" pb="sm">
      {totalPages > 1 && (
        <Pagination
          siblings={1}
          boundaries={2}
          size="xs"
          spacing="xs"
          value={page}
          onChange={onPageChange}
          total={totalPages}
        />
      )}
      <Text color="dimmed" size="xs">
        {t('common:grid.totalCount', { total: totalCount ? formatNumberToThousands(totalCount) : '-' })}
      </Text>
    </Group>
  )
}
