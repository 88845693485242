import React from 'react'
import { Text } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { AT_ALL_VALUE } from 'components/business'

interface Props {
  targets?: string[]
}

const MentionedTargets = ({ targets }: Props) => {
  const { t } = useTranslation('integration')

  if (!targets || targets.length === 0) {
    return null
  }

  const formattedTargets = targets.map((target) =>
    target.toLowerCase() === AT_ALL_VALUE ? t('webhook.histories.detail.targetsAll') : target
  )

  return (
    <Text>
      {t('webhook.histories.detail.mentionedTargets', {
        targets: formattedTargets.join(t('common:punctuation.comma')),
      })}
    </Text>
  )
}

export default MentionedTargets
