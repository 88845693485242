import { Code } from '@mantine/core'
import useStyles from '../../styles'
import { WebhookRequestDetailProps } from './type'

const DefaultRequestDetail = ({ requestPayload }: WebhookRequestDetailProps) => {
  const { classes } = useStyles()

  return (
    <Code block className={classes.code}>
      {JSON.stringify(requestPayload, null, 2)}
    </Code>
  )
}

export default DefaultRequestDetail
