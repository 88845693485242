import { ConditionType, FieldType } from 'components/business'
import { userSegmentCode } from 'components/business/CampaignBuilder/Distribution/Who/constant'
import { find } from 'lodash-es'
import { useContext, useMemo } from 'react'

import { ConditionOperator, FieldType as ExternalAttributeType, UrlConditionOperator } from 'types/domain'

import DataContext from '../../DataContext'
import ConditionContext from '../ConditionContext'

const {
  Include,
  Exclude,
  Contains,
  NotContains,
  DateEq,
  DateNe,
  Before,
  After,
  Gt,
  Lt,
  Gte,
  Lte,
  NumberBetween,
  IsNull,
  IsNotNull,
} = ConditionOperator

export const Mine = 'MINE'

const { Equal, StartWith, Wildcard } = UrlConditionOperator

const urlOptions: string[] = [Equal, StartWith, Wildcard]

const OPERATOR_CONFIG: { [key: string]: string[] } = {
  Url: urlOptions,
}

export function getOperatorOptions(type: string) {
  return OPERATOR_CONFIG[type]
}

function getFirstNodeType(fields: FieldType[], value: string) {
  return find(fields, { code: value })?.type!
}

function getOperatorsByFirstNodeType(fields: FieldType[], condition: ConditionType) {
  const type = condition?.firstNode?.type
    ? condition?.firstNode?.type
    : getFirstNodeType(fields, condition.firstNode?.value!)
  return getOperatorOptions(type!)
}

function getDynamicOperator(condition: ConditionType) {
  const {
    firstNode: { type: firstNodeType, value: firstNodeValue },
  } = condition
  if (firstNodeType === ExternalAttributeType.DateTime) {
    return [DateEq, DateNe, Before, After, IsNull, IsNotNull]
  }
  if (firstNodeValue === userSegmentCode) {
    return [Include, Exclude]
  }
  return [Include, Exclude, Contains, NotContains, Gt, Lt, Gte, Lte, NumberBetween, IsNull, IsNotNull]
}

export default function useOptions() {
  const { fields, dynamic } = useContext(DataContext)
  const { condition } = useContext(ConditionContext)

  return useMemo(() => {
    return dynamic ? getDynamicOperator(condition) : getOperatorsByFirstNodeType(fields, condition)
  }, [condition, dynamic, fields])
}
