import React, { useEffect, useState } from 'react'
import { AspectRatio, ColorPicker, Image, Popover, TextInput } from '@mantine/core'
import { getDefaultBorderColor } from 'config/themeOverride/getColor'
import useSwatchStyles from '../swatch.styles'

import img from './img.png'

interface Props {
  color?: string
  checked?: boolean
  onChange?: (color: string) => void
  onClick?: () => void
  error?: string
}

const CustomSwatch = ({ color, checked, onChange, onClick, error }: Props) => {
  const [opened, setOpened] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const { classes } = useSwatchStyles({ checked })

  useEffect(() => {
    color && setInputValue(color)
  }, [color])

  const handleClick = () => {
    onClick?.()
    setOpened(true)
  }

  const handleChange = (color: string) => {
    onChange?.(color)
  }

  return (
    <Popover opened={opened} onChange={setOpened} position="bottom-end" shadow="sm">
      <Popover.Target>
        <AspectRatio ratio={1} className={classes.root} onClick={handleClick}>
          <Image src={img.src} alt="Custom Color" />
        </AspectRatio>
      </Popover.Target>

      <Popover.Dropdown p="sm">
        <ColorPicker
          format="hex"
          onChange={setInputValue}
          onChangeEnd={handleChange}
          defaultValue={color}
          styles={(theme) => ({
            body: {
              paddingTop: theme.spacing.sm,
              gap: 12,
              alignItems: 'center',
              '&::after': {
                width: 32,
                height: 32,
                content: '""',
                borderRadius: theme.radius.sm,
                backgroundColor: error ? undefined : inputValue,
                border: error ? `1px solid ${getDefaultBorderColor(theme)}` : undefined,
              },
            },
          })}
        />
        <TextInput mt="sm" value={inputValue} onChange={(e) => handleChange(e.currentTarget.value)} error={error} />
      </Popover.Dropdown>
    </Popover>
  )
}

export default CustomSwatch
