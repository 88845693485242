import React, { useCallback, useContext, useMemo } from 'react'

import { useConditionGroup } from '../../ConditionGroup'
import ConditionContext from '../ConditionContext'
import DataContext from 'components/business/Filter/DefaultFilter/DataContext'
import { isArray, isEmpty } from 'lodash-es'
import classes from '../index.module.scss'
import { nodes } from './constant'
import { useNodeType } from './getNodeType'
import { NodeFieldType } from 'components/business/UrlFilter/type'

const Node = () => {
  const { index } = useContext(ConditionContext)
  const { dispatch } = useConditionGroup()
  const {
    secondNode: { rules },
  } = useContext(DataContext)
  const nodeType = useNodeType()
  const NodeComponent = nodes[nodeType]

  const getError = (value: string | null | undefined) => {
    const firstError = rules?.find(({ regex, required }) => {
      if (required) {
        return isEmpty(value)
      }
      return isArray(value) ? value.some((item) => !regex?.test(item)) : !regex?.test(value!)
    })
    return firstError ? firstError?.message : undefined
  }

  const handleChange = useCallback(
    ({ value, error }: NodeFieldType) => {
      const errorMessage = error ? error : getError(value)
      dispatch({
        type: 'nodeChange',
        index,
        node: {
          value,
          error: errorMessage,
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, index]
  )

  return useMemo(() => {
    return <NodeComponent onChange={handleChange} className={classes.node} />
  }, [NodeComponent, handleChange])
}

export default Node
