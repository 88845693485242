import { ActionIcon, CopyButton, Group, HoverCard, Input, MantineNumberSize, Paper, Tooltip } from '@mantine/core'
import { IconCheck, IconCopy, IconExternalLink, IconQrcode } from '@tabler/icons-react'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { QRCodeSVG } from 'qrcode.react'

type Props = {
  url: string
  iconContainerSize?: MantineNumberSize
  iconSize?: MantineNumberSize
}

export const Copy = ({ url, iconSize = 16, iconContainerSize = 'xs' }: Props) => {
  const { t } = useTranslation('common')
  return (
    <CopyButton value={url}>
      {({ copied, copy }) => (
        <Tooltip
          label={copied ? t('message.duplicateSuccess') : t('componentCampaignList:deliveryUrl.copy')}
          withArrow
          position="bottom"
          withinPortal
        >
          <ActionIcon
            size={iconContainerSize}
            color={copied ? 'green' : 'gray'}
            onClick={(e: any) => {
              e.stopPropagation()
              copy()
            }}
          >
            {copied ? <IconCheck size={iconSize} stroke={1.5} /> : <IconCopy size={iconSize} stroke={1.5} />}
          </ActionIcon>
        </Tooltip>
      )}
    </CopyButton>
  )
}

export const QrCode = ({ url, iconSize = 16, iconContainerSize = 'xs' }: Props) => {
  return (
    <HoverCard withArrow withinPortal position="bottom">
      <HoverCard.Target>
        <ActionIcon
          size={iconContainerSize}
          color="gray"
          onClick={(e: any) => {
            e.stopPropagation()
          }}
        >
          <IconQrcode size={iconSize} stroke={1.5} color="gray" />
        </ActionIcon>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Paper p="xs">
          <QRCodeSVG value={url} size={100} display="block" />
        </Paper>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}

export const OpenInNewTab = ({ url, iconSize = 16, iconContainerSize = 'xs' }: Props) => {
  const { t } = useTranslation('common')
  return (
    <Tooltip label={t('componentCampaignList:deliveryUrl.open')} withArrow position="bottom" withinPortal>
      <ActionIcon
        color="gray"
        size={iconContainerSize}
        onClick={(e: any) => {
          e.stopPropagation()
          window.open(url, '_blank')
        }}
      >
        <IconExternalLink size={iconSize} stroke={1.5} />
      </ActionIcon>
    </Tooltip>
  )
}

const LinkActions = ({ url }: Props) => {
  return (
    <Group spacing="xs" noWrap>
      <Copy url={url} />
      <QrCode url={url} />
      <OpenInNewTab url={url} />
    </Group>
  )
}
const LinkWithAction = ({ url }: Props) => {
  return (
    <Input
      styles={{
        wrapper: {
          minWidth: '340px',
        },
        rightSection: {
          right: 20,
        },
      }}
      value={url}
      readOnly
      size="xs"
      rightSection={<LinkActions url={url} />}
    />
  )
}

export default LinkWithAction
