import React from 'react'
import { Center, Text } from '@mantine/core'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import useOnboardingCache from 'components/business/hooks/useOnboardingCache'

const OnboardingBadge = () => {
  const { pendingTasks } = useOnboardingCache()

  return (
    <Center
      sx={(theme) => ({
        background: getBackgroundColor('brandBlue.7', theme),
        width: '18px',
        height: '18px',
        borderRadius: '50%',
      })}
    >
      <Text size="xs" weight={600} color="white">
        {pendingTasks.length}
      </Text>
    </Center>
  )
}

export default OnboardingBadge
