import React, { FC } from 'react'
import { useRouter } from 'next/router'
import { Button, Text, Group } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { isEmpty, isNil, uniq } from 'lodash-es'

import { useEntries } from 'components/business/Campaign/Entries/EntriesContext'
import useAddToSegments from 'components/business/Customer/AddToSegments'

import { useDeleteEntriesMutation } from 'types/domain'
import { useDeleteModal } from 'hooks'
import { message } from 'components/ui'

interface Props {
  tableRef: any
}

const BatchActions: FC<Props> = ({ tableRef }) => {
  const { t } = useTranslation('entry')
  const router = useRouter()
  const campaignId = router.query.campaignId as string
  const [deleteEntriesMutation] = useDeleteEntriesMutation()
  const { selectedRowsCount: selectedCount } = useEntries()
  const { open } = useAddToSegments()

  const handleDelete = async () => {
    const selectedRows = tableRef?.current?.tableInstance?.getSelectedRowModel().rows
    if (isEmpty(selectedRows)) {
      return
    }
    const selectedEntryIds = selectedRows.map((row: any) => row.id)
    try {
      const { data } = await deleteEntriesMutation({
        variables: { input: { campaignId, entryIds: selectedEntryIds } },
      })
      if (data?.deleteEntries) {
        message.successMsg(t('common:message.deleteSuccess'))
        tableRef?.current?.refetch()
      }
    } catch (e) {
      message.error(t('common:message.deleteError'))
    }
  }

  const { openModal } = useDeleteModal(
    t('batchDelete.title'),
    t('batchDelete.content', { count: selectedCount }),
    handleDelete
  )

  const clearRowSelection = () => {
    tableRef?.current?.tableInstance?.resetRowSelection()
  }

  const handleAddToCustomerSegmentClick = () => {
    const selectedRows = tableRef?.current?.tableInstance?.getSelectedRowModel().rows
    const selectedCustomerIds = uniq(
      selectedRows
        ?.map((row: any) => row?.original?.entry.customerSnapshot?.customerId)
        .filter((cid?: string | null) => !isNil(cid))
    )
    open({
      customerIds: selectedCustomerIds as string[],
      existedSegmentIds: [],
      onSuccess: clearRowSelection,
    })
  }

  return (
    <Group spacing="md" pos="absolute" className="z-10">
      <Text>{t('batchDelete.selected', { count: selectedCount })}</Text>
      <Button
        px="sm"
        h={30}
        onClick={clearRowSelection}
        variant="default"
        styles={() => ({
          root: { fontWeight: 'normal' },
        })}
      >
        {t('batchDelete.cancelSelect')}
      </Button>
      <Button px="sm" h={30} onClick={handleAddToCustomerSegmentClick}>
        {t('pageCustomerSegment:customersActions.addToSegment')}
      </Button>
      <Button px="sm" h={30} color="red" onClick={openModal}>
        {t('batchDelete.delete')}
      </Button>
    </Group>
  )
}
export default BatchActions
