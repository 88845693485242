import { useCampaignPermission } from 'components/business'
import { useCampaign } from 'components/business/Campaign/CampaignContext'
import React, { ChangeEvent, FC, useCallback } from 'react'
import { Center, Checkbox, Text } from '@mantine/core'
import cls from 'classnames'

const SerialNumberBodyCell: FC<any> = (props) => {
  const { row, renderedCellValue } = props
  const { campaign } = useCampaign()
  const { deletable } = useCampaignPermission(campaign?.creator?.id!)

  const toggleChecked = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation()
      row.toggleSelected()
    },
    [row]
  )

  const isRowSelected = row.getIsSelected()

  const textClasses = cls('', {
    'group-hover:invisible': deletable,
    visible: !isRowSelected,
    invisible: isRowSelected,
  })

  const checkboxClasses = cls('absolute left-0 group-hover:visible', {
    invisible: !isRowSelected,
    visible: isRowSelected,
  })

  return (
    <Center className="relative">
      <Text className={textClasses}>{renderedCellValue}</Text>
      {deletable && <Checkbox className={checkboxClasses} checked={isRowSelected} onChange={toggleChecked} size="xs" />}
    </Center>
  )
}

export default SerialNumberBodyCell
