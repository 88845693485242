import { ComponentProps } from 'react'
import { Select } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { ConditionGroupOperator as ConditionGroupOperatorDomain } from 'types/domain'

type Props = Omit<ComponentProps<typeof Select>, 'data'>

const ConditionGroupOperatorSelector = (props: Props) => {
  const { t } = useTranslation('componentConditionGroup')
  const data = [
    {
      value: ConditionGroupOperatorDomain.And,
      label: t('conditionGroupOperator.andOptionLabel'),
    },
    {
      value: ConditionGroupOperatorDomain.Or,
      label: t('conditionGroupOperator.orOptionLabel'),
    },
  ]
  return (
    <Select
      {...props}
      data={data}
      styles={() => ({
        root: {
          display: 'inline-block',
        },
        input: {
          display: 'inline-block',
          width: '60px',
          height: '24px',
          minHeight: '24px',
          padding: '0px 8px',
          borderRadius: '4px',
          lineHeight: '24px',
        },
        rightSection: {
          width: '24px',
        },
        dropdown: {
          borderRadius: '4px',
        },
      })}
    />
  )
}

export default ConditionGroupOperatorSelector
