import React from 'react'
import { Text } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { closeModal, openModal } from '@mantine/modals'
import { message } from 'components/ui'
import { CampaignGroup, useUpdateCampaignGroupMutation } from 'types/domain'
import graphqlErrorCatch from 'utils/graphqlErrorCatch'
import GroupForm from 'components/business/Campaign/List/Group/GroupForm'

export const modalId = 'editCampaignGroupModal'

export default function useEditCampaignGroupModal() {
  const { t } = useTranslation('componentCampaignList')

  const [updateCampaignGroupMutation] = useUpdateCampaignGroupMutation()

  const handleSave = async (values: { id: string; name: string }, callback: Function) => {
    try {
      const { data } = await updateCampaignGroupMutation({
        variables: {
          input: {
            ...values,
          },
        },
      })
      if (data?.updateCampaignGroup?.id) {
        close()
        message.successMsg(`${t('common:message.updateSuccess')}`)
      }
    } catch (error: any) {
      const mapping = new Map()
      mapping.set('error.duplicate_key', t('campaignGroup.nameDuplicate'))
      const { graphQLErrors } = error
      graphqlErrorCatch({ graphQLErrors, mapping, defaultMessage: t('common:message.updateError') })
      callback()
    }
  }

  const open = (group?: Pick<CampaignGroup, 'id' | 'name'>) => {
    openModal({
      modalId,
      title: (
        <Text weight="bold" size="md">
          {t('common:actions.edit')}
        </Text>
      ),
      size: 440,
      children: (
        <GroupForm
          group={group}
          onSave={(name, callback) => handleSave({ id: group?.id!, name }, callback)}
          onCancel={close}
        />
      ),
    })
  }

  const close = () => {
    closeModal(modalId)
  }
  return {
    open,
    close,
  }
}
