import React from 'react'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { Button, Stack, Text, Title, Tooltip, useMantineTheme } from '@mantine/core'

import { getTextColor } from 'config/themeOverride/getColor'

import emptyImg from 'assets/images/empty.png'
import { useCheckFeature } from 'components/business'
import { Feature } from 'types/domain'
import useSendWebhookDemo, { SendWebhookDemoProps } from '../hooks/useSendWebhookDemo'
import useWebhookEditable from '../hooks/useWebhookEditable'

interface Props extends Omit<SendWebhookDemoProps, 'webhookUrl'> {
  webhookUrl?: string
  onDemoSent?: () => void
  campaignCreatorId?: string
}

const Empty = ({ onDemoSent, campaignCreatorId, ...sendDemoProps }: Props) => {
  const { t } = useTranslation('integration')
  const theme = useMantineTheme()
  const { sendWebhookDemo, loading } = useSendWebhookDemo()
  const canUseWebhook = useCheckFeature()(Feature.Webhook)
  const editable = useWebhookEditable(campaignCreatorId)
  const webhookUrl = sendDemoProps.webhookUrl

  const handleSendDemo = () => {
    webhookUrl && sendWebhookDemo({ webhookUrl, ...sendDemoProps }).then(onDemoSent)
  }

  return (
    <Stack align="center" spacing="sm">
      <Image src={emptyImg} width={48} height={48} alt="No Webhook Histories" />
      <Title order={5} mt="sm">
        {t('webhook.histories.empty.title')}
      </Title>
      {editable && (
        <>
          <Text color={getTextColor('gray.7', theme)}>{t('webhook.histories.empty.description')}</Text>
          <Tooltip withArrow disabled={!!webhookUrl} label={t('webhook.histories.empty.sendDemoTip')}>
            <div>
              <Button
                variant="outline"
                onClick={handleSendDemo}
                disabled={!webhookUrl || !canUseWebhook}
                loading={loading}
              >
                {t('webhook.histories.sendDemo')}
              </Button>
            </div>
          </Tooltip>
        </>
      )}
    </Stack>
  )
}

export default Empty
