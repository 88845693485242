import { InlineInput } from 'components/ui'
import useTranslation from 'next-translate/useTranslation'
import { useBuilderFormContext } from 'components/business/CampaignBuilder'
import { useRouter } from 'next/router'

const NameInput = () => {
  const { t } = useTranslation('componentCampaignBuilder')
  const form = useBuilderFormContext()
  const { value, onChange } = form?.getInputProps('name')
  const router = useRouter()
  const { isDuplicated } = router.query

  return (
    <InlineInput
      value={value}
      onChange={onChange}
      width={300}
      maxLength={20}
      placeholder={t('titlePlaceholder')}
      radius="sm"
      autoFocus={isDuplicated === 'true'}
      autoRestore
      showEmoji
      rightSectionStyle={{ top: 0 }}
      styles={(theme) => ({
        input: {
          fontWeight: 600,
          fontSize: 14,
        },
      })}
    />
  )
}

export default NameInput
