import { ScrollArea, Stack } from '@mantine/core'
import Fields from 'components/business/Campaign/EntryModal/Content/Feedback/Fields'
import React from 'react'
import { CampaignType, CustomApplicationEvent, Entry, Field } from 'types/domain'
import DeviceInfo from '../components/DeviceInfo'
import UserInfo from '../components/UserInfo'

type Props = {
  campaignType: CampaignType
  entry: Entry
  formFields: Field[]
  customerFields: Field[]
  customEvents?: CustomApplicationEvent[]
}

const FeedbackMain = (props: Props) => {
  const { entry, customerFields, formFields, customEvents, campaignType } = props

  return (
    <ScrollArea p="3xl" pb="lg" h="100%">
      <Stack spacing="40px">
        {campaignType === CampaignType.Feedback && <Fields entry={entry} formFields={formFields} />}
        <UserInfo customer={entry.customerSnapshot} customerFields={customerFields} gridCols={2} />
        <DeviceInfo entry={entry} gridCols={2} campaignType={campaignType} customEvents={customEvents} />
      </Stack>
    </ScrollArea>
  )
}

export default FeedbackMain
