import { useCallback, useMemo } from 'react'
import { UrlFilterConditionGroupType } from './type'
import { ConditionGroupProvider } from './ConditionGroup'
import Conditions from './Conditions'
import { defaultValue } from './constants'
import ConditionAdder from './Adder'
import { UrlConditionGroup } from 'types/domain'

interface Props {
  value?: UrlConditionGroup | null
  onChange?: (data: UrlFilterConditionGroupType | null) => void
  defaultValue?: UrlConditionGroup
  minLength?: number
  checkUrl?: boolean
}

const UrlFilter = (props: Props) => {
  const { value, onChange, checkUrl } = props

  const initialValue = useMemo(() => {
    if (value?.conditions?.length) {
      const conditions = value?.conditions?.map((c) => {
        return {
          operator: c.operator,
          node: { value: c.value },
        }
      })
      return {
        ...value,
        conditions,
      }
    }
    return props.defaultValue ?? defaultValue
  }, [props.defaultValue, value])

  const handleChange = useCallback(
    (data: UrlFilterConditionGroupType) => {
      onChange?.(data)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return useMemo(() => {
    return (
      <ConditionGroupProvider defaultValue={initialValue} onChange={handleChange}>
        <Conditions minLength={props.minLength} checkUrl={checkUrl} />
        <ConditionAdder />
      </ConditionGroupProvider>
    )
  }, [initialValue, handleChange, props.minLength, checkUrl])
}

export default UrlFilter
