import React, { FC, forwardRef } from 'react'
import { Button, ButtonProps } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'
import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'

type Props = ButtonProps & {
  onClick?: () => void
}

const BaseButton: FC<Props> = forwardRef<HTMLButtonElement, Props>(({ children, ...others }, ref) => {
  return (
    <Button
      ref={ref}
      {...others}
      variant="subtle"
      compact
      h={30}
      leftIcon={<IconDownload size={18} stroke={1.5} />}
      sx={(theme) => ({
        borderRadius: theme.radius.xs,
        color: getTextColor('gray.9', theme),
        '&:hover': {
          backgroundColor: getBackgroundColor('gray.1', theme),
        },
      })}
    >
      {children}
    </Button>
  )
})

export default BaseButton
