import { RuleControllableField, ConditionNode, ConditionOperator, Field, FieldJumpRule } from 'types/domain'
import { PreviewField, SelectableFieldValue } from 'components/business/CampaignBuilder/CampaignPreview/type'

function match(operator: ConditionOperator, secondNodeValue: ConditionNode['value'], value: PreviewField['value']) {
  const matchValue = (v: string | number) => secondNodeValue.includes(v) || secondNodeValue.includes(`${v}`)
  switch (operator) {
    case ConditionOperator.Lt:
      return value && value < secondNodeValue
    case ConditionOperator.Lte:
      return value && secondNodeValue >= value
    case ConditionOperator.Eq:
      return value === secondNodeValue
    case ConditionOperator.Ne:
      return value !== secondNodeValue
    case ConditionOperator.Gt:
      return value && value > secondNodeValue
    case ConditionOperator.Gte:
      return value && value >= secondNodeValue
    case ConditionOperator.Promoter:
      return value && value > 8
    case ConditionOperator.Passive:
      return value === 7 || value === 8
    case ConditionOperator.Detractor:
      return value && value < 7
    case ConditionOperator.Include:
      if (Array.isArray((value as SelectableFieldValue)?.value)) {
        return (value as SelectableFieldValue)?.value?.some(matchValue)
      }
      return matchValue(value as number)
    default:
      return false
  }
}

export function getJumpRuleByValue(currentField: Field, currentFieldValue: PreviewField['value']) {
  const field = currentField as RuleControllableField
  return field?.jumpRule?.subRules?.find((jumpRule: FieldJumpRule) => {
    const condition = jumpRule?.conditionGroup?.conditions[0]!
    const { operator, secondNode } = condition
    if (match(operator, secondNode?.value, currentFieldValue)) {
      return jumpRule
    }
  })
}
