import React, { FC, useCallback } from 'react'
import cls from 'classnames'
import { Center, Checkbox } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { useCampaignPermission } from 'components/business'
import { useCampaign } from 'components/business/Campaign/CampaignContext'
import TableHeaderCell from 'components/ui/TableHeaderCell'

const SerialNumberHeaderCell: FC<any> = (props) => {
  const { t } = useTranslation('entry')
  const { table } = props
  const { campaign } = useCampaign()
  const { deletable } = useCampaignPermission(campaign?.creator?.id!)

  const toggleChecked = useCallback(() => {
    table.toggleAllPageRowsSelected()
  }, [table])

  const isAllRowsSelected = table.getIsAllRowsSelected()
  const isSomeRowsSelected = table.getIsSomePageRowsSelected()

  const textClasses = cls('', {
    'group-hover:invisible': deletable,
    visible: !isAllRowsSelected && !isSomeRowsSelected,
    invisible: isAllRowsSelected || isSomeRowsSelected,
  })

  const checkboxClasses = cls('absolute left-0 group-hover:visible', {
    invisible: !isAllRowsSelected && !isSomeRowsSelected,
    visible: isAllRowsSelected || isSomeRowsSelected,
  })

  return (
    <Center className="group relative">
      <TableHeaderCell className={textClasses}>{t('entry:list.serialNumber')}</TableHeaderCell>
      {deletable && (
        <Checkbox
          className={checkboxClasses}
          checked={isAllRowsSelected}
          onChange={toggleChecked}
          indeterminate={isSomeRowsSelected}
          size="xs"
        />
      )}
    </Center>
  )
}

export default SerialNumberHeaderCell
