import { first, isEmpty } from 'lodash-es'
import { Field } from 'types/domain'

export default function getStartIndex(allFields: Field[], pageFields?: Field[]) {
  if (isEmpty(pageFields)) {
    return null
  }
  const startIndex = allFields.findIndex(({ code }) => code === first(pageFields)?.code)
  return startIndex === -1 ? null : startIndex
}
