import { createContext } from 'react'
import { Account, Application, User } from 'types/domain'

export type AccountLayoutProps = Pick<
  Account,
  'id' | 'setting' | 'features' | 'subscription' | 'vasBalance' | 'subscriptionLastEndDate'
>

export interface ApplicationContextProps {
  application: Application | null
  account: AccountLayoutProps | null
  me: User | null
}

const ApplicationContext = createContext<ApplicationContextProps>({
  application: null,
  account: null,
  me: null,
})

export default ApplicationContext
