import { createContext } from 'react'
import { UrlFilterConditionType } from '../type'

export interface ConditionContextProps {
  index: number
  condition: UrlFilterConditionType
  checkUrl: boolean
}

const ConditionContext = createContext<ConditionContextProps>({
  index: 0,
  condition: {
    operator: null,
    node: {
      value: undefined,
    },
  },
  checkUrl: true,
})

export default ConditionContext
