import React, { FC } from 'react'
import { Select } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { Field } from 'types/domain'
import { getOperatorsByFirstNodeCode } from './getOperatorOptions'
import { filterSelectComponentDropDownStyle } from 'components/business/Filter/FieldFilterPopover/Filter/constants'

type Props = {
  value: string
  fieldCode: string
  onChange?(value: string): void
  fields?: Field[]
  readOnly?: boolean
}

const Operator: FC<Props> = ({ value, fieldCode, onChange, fields = [], readOnly }) => {
  const { t } = useTranslation()
  const options =
    getOperatorsByFirstNodeCode(fields, fieldCode)?.map((option) => {
      return {
        value: option,
        label: t(`componentConditionGroup:conditionOperator.${option}`),
      }
    }) || []

  return (
    <Select
      withinPortal
      data={options}
      value={value}
      onChange={onChange}
      placeholder={t('common:placeholders.pleaseSelect')}
      readOnly={readOnly}
      {...filterSelectComponentDropDownStyle}
    />
  )
}

export default Operator
