import { IconAlertCircle } from '@tabler/icons-react'
import React from 'react'
import { Alert, Anchor, HoverCard, Text, useMantineTheme } from '@mantine/core'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { openProductsModal } from 'components/business/ProductsModal'
import { openCrisp } from 'plugins/crisp'
import { getTextColor } from 'config/themeOverride/getColor'
import router from 'next/router'
import useCheckFeatureAlert from 'components/business/subscription/useCheckFeatureAlert'
import { SystemAccount } from 'config/constants'

type Props = {
  collapsed?: boolean
}

const SubscriptionAlert = ({ collapsed = false }: Props) => {
  const { t } = useTranslation('account')
  const theme = useMantineTheme()
  const unavailableFeature = useCheckFeatureAlert()

  if (!unavailableFeature) {
    return null
  }

  const content = (
    <Trans
      i18nKey={`account:subscription.free.tooltip.unavailable.${unavailableFeature}`}
      components={{
        upgrade: <Anchor onClick={() => openProductsModal(unavailableFeature)} />,
        crisp: <Anchor onClick={openCrisp} />,
        account: <Anchor onClick={() => router.push(SystemAccount)} />,
      }}
    />
  )

  return (
    <>
      {collapsed ? (
        <HoverCard withinPortal position="bottom-start">
          <HoverCard.Target>
            <IconAlertCircle size={18} color="red" stroke={1.5} className="cursor-pointer" />
          </HoverCard.Target>
          <HoverCard.Dropdown
            sx={{
              borderRadius: theme.radius.sm,
              maxWidth: 350,
            }}
          >
            <Text>{content}</Text>
          </HoverCard.Dropdown>
        </HoverCard>
      ) : (
        <Alert
          icon={<IconAlertCircle size={18} color="red" stroke={1.5} className="cursor-pointer" />}
          px="lg"
          py="md"
          variant="light"
          color="red"
          radius={2}
        >
          <Text color={getTextColor('red', theme)}>{content}</Text>
        </Alert>
      )}
    </>
  )
}

export default SubscriptionAlert
