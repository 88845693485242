import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useDisclosure, useWindowEvent } from '@mantine/hooks'
import { Button, Popover, Text, Tooltip } from '@mantine/core'
import { IconExternalLink } from '@tabler/icons-react'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import dynamic from 'next/dynamic'

const Content = dynamic(() => import('./Content'))

interface Props {
  id: string
  size?: number
  showText?: boolean
}

const DeliveryUrl = ({ id, size = 18, showText = false }: Props) => {
  const { t } = useTranslation('componentCampaignList')
  const [opened, { close, open }] = useDisclosure(false)
  const [tooltipOpened, { close: tooltipClose, open: tooltipOpen }] = useDisclosure(false)

  useWindowEvent('resize', close)

  const handleClick = (event: any) => {
    event.stopPropagation()
    open()
    tooltipClose()
  }

  return (
    <Popover width={400} position="bottom-end" shadow="md" withinPortal opened={opened}>
      <Popover.Target>
        <Tooltip label={t('deliveryUrl.title')} opened={tooltipOpened} withArrow withinPortal>
          <Button
            c="dimmed"
            onClick={handleClick}
            onMouseEnter={tooltipOpen}
            onMouseLeave={tooltipClose}
            variant="subtle"
            sx={(theme) => ({
              '&:hover': {
                backgroundColor: getBackgroundColor('gray.1', theme),
              },
            })}
            leftIcon={<IconExternalLink size={size} stroke={1.5} />}
            px="sm"
          >
            {showText && <Text>{t('deliveryUrl.shortname')}</Text>}
          </Button>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown p="lg" onClick={(e) => e.stopPropagation()}>
        <Content id={id} close={close} />
      </Popover.Dropdown>
    </Popover>
  )
}

export default DeliveryUrl
