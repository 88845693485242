import { SelectItem } from '@mantine/core'
import { ConditionGroupOperator, ConditionNode, ConditionOperator, Feature, UrlConditionOperator } from 'types/domain'

export interface Item {
  label: string | number
  value: string | number
}

interface Statement {
  label: string
  code: string
}

export type DynamicField = {
  code: string
  label: string
  type?: string
  id?: string
  group?: string
}

export type FieldType = {
  code: string
  label: string
  id?: string
  type?: string
  choices?: Item[]
  statements?: Statement[]
  group?: string
}

export type ConditionGroupType = {
  operator: ConditionGroupOperator
  conditions: ConditionType[]
}

export type FirstNodeFieldType = {
  label?: string | null
  value?: string | null
  type?: string | null
  error?: string
}

export type SecondNodeFieldType = {
  label?: string | string[] | null
  value?: string | string[] | null | number | number[]
  type?: string | null
  error?: string
}

export type ConditionType = {
  firstNode: FirstNodeFieldType
  operator?: ConditionOperator | UrlConditionOperator | null
  secondNode?: SecondNodeFieldType | null
}

export const TimePeriod = 'TimePeriod'

export enum DateOperator {
  WEEK = 'WEEK',
  PREVIOUS_WEEK = 'PREVIOUS_WEEK',
  MONTH = 'MONTH',
  PREVIOUS_MONTH = 'PREVIOUS_MONTH',
  QUARTERLY = 'QUARTERLY',
  PREVIOUS_QUARTERLY = 'PREVIOUS_QUARTERLY',
  YEAR = 'YEAR',
  PREVIOUS_YEAR = 'PREVIOUS_YEAR',
  BETWEEN = 'BETWEEN',
}

export type FilterValueType = {
  operator: ConditionGroupOperator
  conditions: {
    firstNode: ConditionNode
    operator: ConditionOperator | UrlConditionOperator
    secondNode?: ConditionNode | null
  }[]
}

type Rule = {
  required?: boolean
  regex?: RegExp
  message: string
}

export type FirstNodeType = {
  fields?: DynamicField[]
  hidden?: boolean
  labelIsExists?: (input: string, items: SelectItem[]) => boolean
  getCreateLabel?: (query: string) => void
  getFieldType?: (name: string) => string | undefined
  onFirstNodeChange?: (value: DynamicField) => void
  rules?: Rule[]
}

export type SecondNodeType = {
  getFields?: (id: string, searchValue?: string) => Promise<string[]>
  getCreateLabel?: (query: string) => void
  rules?: Rule[]
  autoFocus?: boolean
}

export type AdderType = {
  feature?: Feature
  disabled?: boolean
}
