import { COMMENTS_COUNT_CODE, REPLY_CODE } from 'components/business/Campaign/Entries/constants'
import { difference, isEmpty, map } from 'lodash-es'
import { MRT_ColumnDef } from 'mantine-react-table'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useUpsertCampaignSettingMutation } from 'types/domain'

function getInitialOrderFields(columns: MRT_ColumnDef<any>[], orderedFields?: string[] | null) {
  const allColumnIds = map(columns, 'id') as string[]
  let finalOrderedFields = isEmpty(orderedFields)
    ? allColumnIds
    : orderedFields?.filter((field) => field !== 'mrt-row-select') ?? []

  const notExistedInOrderedFields = difference(allColumnIds, orderedFields ?? [])

  const otherNewFields = notExistedInOrderedFields?.filter((id) => ![REPLY_CODE, COMMENTS_COUNT_CODE]?.includes(id))

  if (notExistedInOrderedFields.includes(REPLY_CODE)) {
    finalOrderedFields?.splice(1, 0, REPLY_CODE)
  }
  if (notExistedInOrderedFields.includes(COMMENTS_COUNT_CODE)) {
    finalOrderedFields?.splice(2, 0, COMMENTS_COUNT_CODE)
  }
  return finalOrderedFields.concat(otherNewFields)
}

export default function useOrderedColumns(columns: MRT_ColumnDef<any>[], orderedFields?: string[] | null) {
  const campaignId = useRouter().query.campaignId as string
  const [updateCampaignSettingMutation] = useUpsertCampaignSettingMutation()

  const [columnOrder, setColumnOrder] = useState<string[]>(() => {
    return getInitialOrderFields(columns, orderedFields)
  })

  const handleColumnOrderChange = async (value: string[]) => {
    await updateCampaignSettingMutation({
      variables: { input: { campaignId, orderedFields: value } },
    })
    setColumnOrder(value)
  }

  return {
    columnOrder,
    onColumnOrderChange: handleColumnOrderChange,
  }
}
