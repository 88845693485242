import React, { KeyboardEvent } from 'react'
import { Modal, useMantineTheme } from '@mantine/core'
import { useAbac } from 'react-abac'

import { modalConfig } from 'config/constants'
import { ApplicationPermission } from 'types/domain'

import Header from './Header'
import DetailContent from './DetailContent'
import { CustomerDetailProps } from './type'

const CustomerDetailModal = (props: Omit<CustomerDetailProps, 'manageable'>) => {
  const { id, customerAttributes, opened, close, previousDisabled, nextDisabled, onPrevious, onNext } = props
  const { userHasPermissions } = useAbac()
  const manageable = userHasPermissions(ApplicationPermission.CustomerSegmentManage)
  const theme = useMantineTheme()

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
      e.preventDefault()
      if (!previousDisabled) {
        onPrevious()
      }
    }
    if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
      e.preventDefault()
      if (!nextDisabled) {
        onNext()
      }
    }
  }

  return (
    <Modal
      {...modalConfig(theme)}
      opened={opened}
      title={
        <Header
          previousDisabled={previousDisabled}
          nextDisabled={nextDisabled}
          onPrevious={onPrevious}
          onNext={onNext}
        />
      }
      onClose={close}
      onKeyDown={handleKeyDown}
    >
      <DetailContent id={id} customerAttributes={customerAttributes} manageable={manageable} />
    </Modal>
  )
}

export default CustomerDetailModal
