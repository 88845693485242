import { Anchor, Text, useMantineTheme } from '@mantine/core'
import { ProductCode } from 'config/constants/products'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { openProductsModal } from 'components/business/ProductsModal'
import { openCrisp } from 'plugins/crisp'
import Trans from 'next-translate/Trans'
import { formatISODate } from 'utils'
import { getTextColor } from 'config/themeOverride/getColor'
import { Feature } from 'types/domain'
import router from 'next/router'
import { SystemAccount } from 'config/constants'

interface Props {
  productCode: string | undefined
  unavailableFeature?: Feature
  endDate: any
}

const SubscriptionHoverDropDown = ({ productCode, unavailableFeature, endDate }: Props) => {
  const { t } = useTranslation('account')
  const theme = useMantineTheme()

  const upgrade = (feature?: string) => (
    <Anchor
      color={getTextColor('blue', theme)}
      onClick={() => openProductsModal(feature ?? t(`account:subscription.${productCode}.upgradeFeature`))}
    />
  )
  const crisp = <Anchor color={getTextColor('blue', theme)} onClick={openCrisp} />
  const account = <Anchor onClick={() => router.push(SystemAccount)} />

  if (!productCode) {
    return null
  }

  if (unavailableFeature) {
    return (
      <Text>
        <Trans
          i18nKey={`account:subscription.free.tooltip.unavailable.${unavailableFeature}`}
          components={{ upgrade: upgrade(unavailableFeature), crisp, account }}
        />
      </Text>
    )
  }

  if (productCode === ProductCode.Free) {
    return (
      <Text>
        <Trans
          i18nKey="account:subscription.free.tooltip.notOverLimitLabel"
          components={{ upgrade: upgrade(), crisp }}
        />
      </Text>
    )
  }

  return (
    <Text>
      <Trans
        i18nKey="account:subscription.paid.tooltip.label"
        values={{
          date: formatISODate(endDate),
          product: t(`account:subscription.${productCode}.name`),
        }}
        components={{ upgrade: upgrade() }}
      />
    </Text>
  )
}

export default SubscriptionHoverDropDown
