import React, { FC, useMemo } from 'react'
import { Group } from '@mantine/core'
import { CampaignType, ConditionGroupInput, ConditionGroupOperator, ConditionInput, Field } from 'types/domain'

import { useGetApplicationCustomerAttributes } from 'components/business'
import { useCampaignFilter, useCampaignFilterActions } from 'components/business/Campaign/Filter/CampaignFilterContext'
import { createdAtCode, keywordCode, ratingCode, textareaCode } from 'config/constants/fieldType'
import { isFormField } from 'utils/fieldUtils'

import { useCampaign } from 'components/business/Campaign/CampaignContext'
import FieldFilterPopover from 'components/business/Filter/FieldFilterPopover'
import CreatedAtSelector from '../components/CreatedAtSelector'
import convertToValidConditionGroup, { convertToFieldFilterPopoverValue } from '../utils/convertToValidConditionGroup'
import useEntryFilterableFields from '../hooks/useEntryFilterableFields'
import InputContentSelector from './InputContentSelector'
import RatingSelector from './RatingSelector'

type Props = {
  fields: Field[]
}

const EntriesFilter: FC<Props> = (props) => {
  const { campaign } = useCampaign()
  const { fields } = props
  const customerAttributes = useGetApplicationCustomerAttributes()
  const getFilterableFields = useEntryFilterableFields()
  const { filter } = useCampaignFilter()
  const { onFieldsChange } = useCampaignFilterActions()
  const isFeedback = campaign?.type === CampaignType.Feedback

  const explicitlyFieldCodes = useMemo(() => {
    return isFeedback ? [createdAtCode, textareaCode, ratingCode, keywordCode] : [createdAtCode, keywordCode]
  }, [isFeedback])

  const fieldsFilterValue = useMemo(() => {
    const conditions = (filter?.conditions as ConditionInput[])?.filter(
      ({ firstNode }) => !explicitlyFieldCodes.includes(firstNode.value)
    )
    return convertToFieldFilterPopoverValue({
      operator: ConditionGroupOperator.And,
      conditions,
    })
  }, [explicitlyFieldCodes, filter?.conditions])

  const onFieldFilterPopoverChange = (conditionGroup: ConditionGroupInput) => {
    onFieldsChange(explicitlyFieldCodes, convertToValidConditionGroup(conditionGroup).conditions)
  }

  // feedback: hidden all form field;  survey: show all (form field、customer、scene)
  const filterableFields = getFilterableFields(
    fields.filter((f) => (isFeedback ? !isFormField(f.code) : true)),
    customerAttributes
  )

  return (
    <Group spacing="xs" align="center">
      <CreatedAtSelector />
      {isFeedback && (
        <>
          <RatingSelector />
          <InputContentSelector />
        </>
      )}
      <FieldFilterPopover
        value={fieldsFilterValue}
        campaignFields={filterableFields}
        onChange={onFieldFilterPopoverChange}
      />
    </Group>
  )
}

export default EntriesFilter
