import { DefaultProps, MantineTheme } from '@mantine/core'
import { getBackgroundColor } from 'config/themeOverride/getColor'

export const subtleSelectStyle = (theme: MantineTheme): DefaultProps['styles'] => {
  return {
    wrapper: {
      '&:active': {
        transform: 'translateY(1px)',
      },
    },
    icon: {
      paddingLeft: theme.spacing.sm,
      width: '26px',
    },
    input: {
      border: 0,
      borderRadius: theme.radius.xs,
      paddingLeft: `30px !important`,
      paddingRight: theme.spacing.sm,
      height: 30,
      minHeight: 30,
      '&:hover': {
        backgroundColor: getBackgroundColor('gray.1', theme),
      },
    },
    rightSection: {
      display: 'none',
    },
    item: {
      borderRadius: theme.radius.xs,
    },
  }
}
