import React, { FC, KeyboardEvent, useRef } from 'react'
import { Styles } from '@mantine/core'

import { isBlank } from 'utils'
import Textarea, { TextareaRef } from 'components/ui/Textarea'

type Props = {
  placeholder: string
  value: string | undefined
  onChange: (value: string) => void
  onSave: () => void
  styles?: Styles<any, any>
  minRows?: number
}

const MessageInput: FC<Props> = (props) => {
  const { placeholder, value, onSave, onChange, styles, minRows } = props
  const textareaRef = useRef<TextareaRef>(null)

  const handleSave = () => {
    if (isBlank(value)) return
    onSave()
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && !textareaRef.current?.inCompositionRef.current) {
      if (event.metaKey || event.ctrlKey) {
        textareaRef.current?.handleOnChange(value + '\n')
      } else {
        event.preventDefault()
        handleSave()
      }
    }
    if (['ArrowUp', 'ArrowLeft', 'ArrowDown', 'ArrowRight'].includes(event.key)) {
      event.stopPropagation()
    }
  }

  return (
    <Textarea
      placeholder={placeholder}
      onChange={onChange}
      maxLength={1000}
      styles={styles}
      autosize
      maxRows={8}
      minRows={minRows ?? 1}
      autoFocus
      w="100%"
      onKeyDown={handleKeyDown}
      value={value}
      ref={textareaRef}
    />
  )
}

export default MessageInput
