import { containerMaxSize } from 'config/constants'
import React, { FC, PropsWithChildren, useState } from 'react'
import { Box, Container, Stack } from '@mantine/core'
import Head from 'next/head'
import { Campaign } from 'types/domain'

import CampaignTabs from './Tabs'
import Header from './Header'
import { CampaignLayoutProps, Tab } from './type'
import Onboarding from './Onboarding'
import { CampaignProvider } from '../CampaignContext'
import Blank from './Blank'

type Props = CampaignLayoutProps & {
  activeTab: Tab
}

const CampaignLayout: FC<PropsWithChildren<Props>> = (props) => {
  const { children, campaignTypeName, activeTab, showOnboarding, showEmpty = false, campaign } = props
  const [enabled, setEnabled] = useState(campaign?.enabled!)
  const [entriesCount, setEntriesCount] = useState(campaign?.entriesCount!)

  return (
    <>
      <Head>
        <style>{`
          body {
            overflow-y: hidden;
          }
        `}</style>
      </Head>
      <Container size={containerMaxSize} p={0} w="100%" h="100%">
        <Stack h="100%" spacing={0}>
          <CampaignProvider
            defaultValue={{ campaign: { ...campaign, enabled, entriesCount } as Campaign, campaignTypeName }}
            actions={{ setEntriesCount, setEnabled }}
          >
            <Stack spacing="sm">
              <Header />
              <CampaignTabs activeTab={activeTab} />
            </Stack>
            <Stack className="flex-1" spacing={0}>
              {showOnboarding ? (
                <Onboarding activeTab={activeTab} />
              ) : (
                <>
                  {showEmpty && entriesCount === 0 ? (
                    <Blank />
                  ) : (
                    <Box h="100%" mt="lg">
                      {children}
                    </Box>
                  )}
                </>
              )}
            </Stack>
          </CampaignProvider>
        </Stack>
      </Container>
    </>
  )
}

export default CampaignLayout
