import React from 'react'
import { Center, Stack, Text, ThemeIcon, useMantineTheme } from '@mantine/core'
import { IconNote } from '@tabler/icons-react'
import useTranslation from 'next-translate/useTranslation'
import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'

const Blank = () => {
  const theme = useMantineTheme()
  const { t } = useTranslation('pageCustomerSegment')
  return (
    <Center mt="3xl">
      <Stack spacing="sm" align="center">
        <ThemeIcon radius="xl" color={getBackgroundColor('gray.0', theme)} size="xl">
          <IconNote color={getTextColor('gray.5', theme)} />
        </ThemeIcon>
        <Text color="dimmed">{t('customerDetail.submittedRecords.blank')}</Text>
      </Stack>
    </Center>
  )
}

export default Blank
