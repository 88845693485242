import React, { FC, useContext } from 'react'
import { Group, NumberInput, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import ConditionContext from '../ConditionContext'
import { onChangeCallback } from './type'
import { IconMinus } from '@tabler/icons-react'
import { getTextColor } from 'config/themeOverride/getColor'

interface Props {
  onChange: onChangeCallback
}

const NumberBetweenInput: FC<Props> = (props: Props) => {
  const {
    condition: { secondNode },
  } = useContext(ConditionContext)
  const { onChange } = props
  const { t } = useTranslation('common')
  const theme = useMantineTheme()
  const [min, max] = (secondNode?.value as number[]) || []

  const handleMinChange = (value: number) => {
    if (value !== min) {
      onChange({ value: [value, max] })
    }
  }

  const handleMaxChange = (value: number) => {
    if (value !== max) {
      onChange({ value: [min, value] })
    }
  }

  const getNumberInputComponent = (
    value: number | undefined,
    onChange: (value: number) => void,
    min?: number,
    max?: number
  ) => {
    return (
      <NumberInput
        value={value}
        placeholder={t('placeholders.pleaseInput')}
        onChange={onChange}
        removeTrailingZeros
        precision={2}
        min={min}
        max={max}
      />
    )
  }

  return (
    <Group noWrap spacing="sm">
      {getNumberInputComponent(min, handleMinChange, undefined, max)}
      <IconMinus size={16} stroke={1.5} color={getTextColor('gray.6', theme)} />
      {getNumberInputComponent(max, handleMaxChange, min, undefined)}
    </Group>
  )
}

export default NumberBetweenInput
