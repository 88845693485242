import { useContext } from 'react'
import { CampaignFilterActionContext, CampaignFilterContext } from './CampaignFilterContext'

export function useCampaignFilter() {
  const context = useContext(CampaignFilterContext)
  if (context === undefined) {
    throw new Error('useCampaignFilter must be used within a CampaignFilterProvider')
  }
  return context
}

export function useCampaignFilterActions() {
  const context = useContext(CampaignFilterActionContext)
  if (context === undefined) {
    throw new Error('useCampaignFilterActions must be used within a CampaignFilterActionsProvider')
  }
  return context
}
