import React, { FC, Ref } from 'react'
import { SurveyTemplatePreviewApi } from 'components/business/SurveyTemplatePreview'
import { DistributionChannelGroup } from 'config/constants'
import { CampaignTemplate } from 'types/domain'
import Web from './Web'
import Mobile from './Mobile'

const PreviewMap: { [key in DistributionChannelGroup]: FC<any> } = {
  web: Web,
  mobileApp: Mobile,
  miniapp: Mobile,
  link: Web,
}

type Props = {
  channel: DistributionChannelGroup
  template?: CampaignTemplate
  previewRef?: Ref<SurveyTemplatePreviewApi>
}

const ChannelPreview: FC<Props> = ({ channel, ...rest }) => {
  const Component = PreviewMap[channel]
  return <Component channel={channel} {...rest} />
}

export default ChannelPreview
