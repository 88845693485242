import {
  defaultColorScheme,
  defaultFormSetting,
  defaultLocaleSetting,
  defaultOpenStrategy,
  defaultTargeting,
  defaultWidgetWidth,
} from 'components/business/CampaignBuilder'
import { defaultTriggerOperator } from 'components/business/CampaignBuilder/Distribution/When/constant'
import { BuilderFormDataState } from 'components/business/CampaignBuilder/type'
import getWidgetPosition from 'components/business/CampaignBuilder/utils/getWidgetPosition'
import { Translate } from 'next-translate'

import {
  Align,
  CampaignDistributionChannel,
  CampaignTriggerEvent,
  CampaignType,
  CampaignWidgetButtonType,
  CampaignWidgetIcon,
  CampaignWidgetType,
  EndingField,
  FieldValidatorType,
  FormFieldType,
  NpsField,
} from 'types/domain'
import { getTempCode } from 'utils/tempCode'

const getDefaultTriggerConditions = (channel: CampaignDistributionChannel) => {
  switch (channel) {
    case CampaignDistributionChannel.Android:
    case CampaignDistributionChannel.IosSwift:
      return []
    case CampaignDistributionChannel.WechatMiniapp:
    case CampaignDistributionChannel.Web:
      return [{ event: CampaignTriggerEvent.PageVisit }]
    default:
      return [{ event: CampaignTriggerEvent.PageVisit }]
  }
}

export function getDefaultWidgetSetting(
  channel: CampaignDistributionChannel = CampaignDistributionChannel.Web,
  campaignType: CampaignType = CampaignType.Survey
) {
  return {
    position: getWidgetPosition(channel),
    type: CampaignWidgetType.Popup,
    colorScheme: defaultColorScheme,
    customizedPosition: {
      align: Align.Right,
      percentageToTop: 60,
    },
    width: defaultWidgetWidth[campaignType],
  }
}

export const defaultFormData = (
  channel: CampaignDistributionChannel = CampaignDistributionChannel.Web
): BuilderFormDataState => ({
  name: '未命名问卷',
  type: CampaignType.Survey,
  token: '',
  enabled: false,
  formFields: [
    {
      code: getTempCode(),
      page: 0,
      type: FormFieldType.NpsField,
      label: '您是否愿意把我们推荐给身边的人？',
      ratingMax: 10,
      ratingMinDescription: '肯定不推荐',
      ratingMaxDescription: '肯定会推荐',
      validators: [{ type: FieldValidatorType.Presence }],
      hidden: false,
      labelHidden: false,
    } as NpsField,
    {
      code: getTempCode(),
      label: '感谢您的反馈️',
      description: '每一条反馈，都在帮助我们进步！',
      page: 1,
      type: FormFieldType.EndingField,
      content: '感谢您的反馈️',
      setting: { endingContent: '感谢您的反馈️' },
      hidden: false,
      labelHidden: false,
    } as EndingField,
  ],
  formSetting: defaultFormSetting,
  widget: getDefaultWidgetSetting(channel, CampaignType.Survey),
  targeting: defaultTargeting,
  trigger: {
    conditions: getDefaultTriggerConditions(channel),
    operator: defaultTriggerOperator,
  },
  openStrategy: defaultOpenStrategy,
  localeSetting: defaultLocaleSetting,
})

export const getDefaultSurveyButtonWidgetSetting = (t: Translate) => {
  return {
    autoOpen: false,
    buttonType: CampaignWidgetButtonType.Aside,
    icon: CampaignWidgetIcon.Comment,
    label: t('componentCampaignBuilder:styles.button.surveyDefaultLabel'),
  }
}

export const otherChoiceCode = 'choiceOther'
export const otherChoiceType = 'OtherChoice'
