import React from 'react'
import { Button, Group, HoverCard } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { Feature } from 'types/domain'
import { FeatureTip, useCheckFeature, VipIcon } from 'components/business'

interface Props {
  onClick: () => void
}

const WebhookAddButton = ({ onClick }: Props) => {
  const { t } = useTranslation('integration')
  const canUseWebhook = useCheckFeature()(Feature.Webhook)

  return (
    <HoverCard disabled={canUseWebhook} withinPortal withArrow>
      <HoverCard.Target>
        <div>
          <Button onClick={onClick} disabled={!canUseWebhook}>
            <Group>
              {t('webhook.actions.create')}
              {!canUseWebhook && <VipIcon />}
            </Group>
          </Button>
        </div>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <FeatureTip feature={Feature.Webhook} />
      </HoverCard.Dropdown>
    </HoverCard>
  )
}

export default WebhookAddButton
