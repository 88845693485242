import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Group, Popover, Text } from '@mantine/core'
import { DeliveryUrlContent } from 'components/business'

type Props = {
  id: string
}

const DeliveryGuide = ({ id }: Props) => {
  const { t } = useTranslation('layoutApplication')
  return (
    <Group spacing={0}>
      <Text c="white">{t('sdkOnboarding.deliveryGuide')}</Text>
      <Popover width={400} position="bottom-end" shadow="md" offset={35}>
        <Popover.Target>
          <Text c="white" underline className="cursor-pointer">
            {t('sdkOnboarding.deliveryUrl')}
          </Text>
        </Popover.Target>
        <Popover.Dropdown p="lg" onClick={(e) => e.stopPropagation()}>
          <DeliveryUrlContent id={id} />
        </Popover.Dropdown>
      </Popover>
    </Group>
  )
}

export default DeliveryGuide
