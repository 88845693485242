import React from 'react'
import { Group, Title } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { ItemNavigator, ItemNavigatorProps } from 'components/ui/ItemNavigator'

type Props = ItemNavigatorProps & {
  showNavigator?: boolean
}

const Header = (props: Props) => {
  const { t } = useTranslation('pageCustomerSegment')
  const { showNavigator = true, ...restProps } = props

  return (
    <Group position="apart">
      <Title order={5}>{t('customerDetail.title')}</Title>
      {showNavigator && <ItemNavigator {...restProps} />}
    </Group>
  )
}

export default Header
