import { CampaignDistributionChannel, useApplicationBasicInfoQuery } from 'types/domain'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

const useApplicationInfoBaseInfoCache = () => {
  const id = useRouter().query.appId as string

  const { data } = useApplicationBasicInfoQuery({
    skip: !id,
    variables: {
      id,
    },
  })

  const application = data?.application
  const distribution = application?.distribution
  const channel = distribution?.channel
  const channelGroup = useMemo(() => {
    switch (channel) {
      case CampaignDistributionChannel.Web:
        return 'web'
      case CampaignDistributionChannel.IosSwift:
      case CampaignDistributionChannel.Android:
        return 'mobileApp'
      case CampaignDistributionChannel.WechatMiniapp:
        return 'miniapp'
    }
  }, [channel])

  return { application, channel, channelGroup, distribution }
}

export default useApplicationInfoBaseInfoCache
