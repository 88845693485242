import React, { FC, PropsWithChildren } from 'react'
import { Card, Group, Stack, Text, Tooltip } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import cls from 'classnames'

import CustomerAvatar from 'components/business/Customer/Avatar'
import { Maybe } from 'types/domain'
import useDarkMode from 'components/ui/Chart/useDarkMode'

type Props = PropsWithChildren & {
  UID?: Maybe<string>
  size?: 'sm' | 'md'
  className?: string
  onClick?: () => void
}

const sizeMap = {
  sm: {
    padding: 16,
    avatar: 40,
    gap: 12,
    maw: 200,
  },
  md: {
    padding: 24,
    avatar: 80,
    gap: 24,
    maw: 480,
  },
}

const UserCard: FC<Props> = (props) => {
  const { t } = useTranslation('customer')
  const { UID, size = 'sm', className = '', onClick, children } = props

  const darkMode = useDarkMode()
  const shownUID = UID ?? t('anonymous')

  return (
    <Card
      padding={sizeMap[size].padding}
      bg={darkMode ? 'dark.5' : 'linear-gradient(95deg, #FEFAFA 0%, #FFECEF 51.56%, #FFE6CB 100%)'}
      className={cls('flex-shrink-0', className)}
      styles={{
        '&:hover': {
          backgroundColor: 'red',
        },
      }}
      onClick={onClick}
    >
      <Group spacing={sizeMap[size].gap} align="flex-start" noWrap>
        <CustomerAvatar size={sizeMap[size].avatar} />
        <Stack spacing={0} w="100%">
          <Tooltip label={shownUID} withinPortal withArrow position="top-start" color="dark">
            <Group spacing={0} noWrap>
              <Text weight="bold">{t('customer_uid')}：</Text>
              <Text weight="bold" className="truncate" style={{ maxWidth: `${sizeMap[size].maw}px` }}>
                {shownUID}
              </Text>
            </Group>
          </Tooltip>
          {children}
        </Stack>
      </Group>
    </Card>
  )
}

export default UserCard
