import React, { FC, useContext } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { UrlInput as InputUrl } from 'components/ui'
import ConditionContext from '../ConditionContext'
import { onChangeCallback } from './type'

interface Props {
  onChange: onChangeCallback
}

const UrlInput: FC<Props> = ({ onChange }) => {
  const { condition } = useContext(ConditionContext)
  const { t } = useTranslation('common')
  let inputRef: any = null

  const handleChange = (value: string, error?: string) => {
    const { value: originValue, error: originError } = condition.secondNode ?? {}
    if (value !== originValue || error !== originError) {
      onChange({ value, label: value, error })
    }
  }

  return (
    <InputUrl
      ref={(input) => {
        inputRef = input
      }}
      value={condition.secondNode?.value as string}
      placeholder={t('common:placeholders.pleaseInput')}
      onChange={handleChange}
      allowBlank
      validateFirst
    />
  )
}

export default UrlInput
