import { CheckBox, Choice, DropDown, RadioButton } from 'types/domain'
import { isArray, isEmpty, isObject } from 'lodash-es'
import React from 'react'
import { splitCharCode } from 'config/constants'

type ValueType =
  | { value: string[]; other: string; extended_text?: { [key: string]: any } }
  | string
  | number
  | undefined

type FieldType = CheckBox | RadioButton | DropDown

export const format = (value: ValueType, field: FieldType) => {
  const values = convertValue(value, field)
  if (isArray(values)) {
    return values.join(splitCharCode)
  }
  return values
}

export const formatDetail = (value: ValueType, field: FieldType) => {
  const values = convertValue(value, field)
  if (isArray(values)) {
    return values.map((v) => (
      <p key={v} className="mb-0">
        {v}
      </p>
    ))
  }
  return values
}

const formatAsText = (value: ValueType, field: FieldType) => {
  return convertValue(value, field)
}

const convertValue = (value: ValueType, field: CheckBox | RadioButton | DropDown) => {
  if (isEmpty(value)) {
    return ''
  }
  if (!isObject(value)) {
    // @ts-ignore
    return formatValue(value, field.choices)
  }
  return field.choices
    .filter((choice) => value?.value?.some((v) => v === choice.value))
    .map((choice) => {
      if (choice.type === 'OtherChoice') {
        return value?.other ? `${choice?.label}: ${value?.other}` : `${choice?.label}`
      }
      const extendedText = value?.extended_text?.[choice.value]
      return extendedText ? `${choice.label}: ${extendedText}` : choice.label
    })
}

function formatValue(value: any, choices?: Array<Choice>): string {
  for (const choice of choices || []) {
    if (choice.value === value) {
      return choice.label
    }
  }
  return ''
}

const output = {
  format,
  formatDetail,
  formatAsText,
}

export default output
