import { ChangeEvent } from 'react'
import { Box, Group, Switch, Text } from '@mantine/core'
import { PriceInterval } from 'types/domain'
import useTranslation from 'next-translate/useTranslation'

export type PriceIntervalType = PriceInterval.Year | PriceInterval.Month

interface Props {
  value: PriceIntervalType
  onChange: (value: PriceIntervalType) => void
}

const Label = (props: { text: string; active: boolean; onClick: () => void }) => {
  return (
    <Text
      color={props.active ? undefined : 'dimmed'}
      fw={props.active ? 600 : undefined}
      onClick={props.onClick}
      className="cursor-pointer"
    >
      {props.text}
    </Text>
  )
}

const RightTip = ({ label }: { label: string }) => {
  return (
    <Box
      ml="md"
      px="md"
      py={5}
      pos="relative"
      bg="orange.0"
      sx={(theme) => ({
        border: `1px solid ${theme.colors.orange[6]}`,
        borderRadius: theme.radius.xs,
      })}
    >
      <Text color="orange.6" lh="20px">
        {label}
      </Text>
      <Box
        w={7}
        h={7}
        top={11.5}
        left={-4.5}
        pos="absolute"
        bg="inherit"
        sx={(theme) => ({
          transform: 'rotate(45deg)',
          border: `1px solid ${theme.colors.orange[6]}`,
          borderRight: 0,
          borderTop: 0,
          borderBottomLeftRadius: 1,
        })}
      ></Box>
    </Box>
  )
}

const PriceIntervalToggle = ({ value, onChange }: Props) => {
  const { t } = useTranslation('product')

  const checked = value === PriceInterval.Year
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.currentTarget.checked ? onChange(PriceInterval.Year) : onChange(PriceInterval.Month)
  }

  return (
    <Group spacing="xs">
      <Label text={t('price.interval.MONTH.label')} active={!checked} onClick={() => onChange(PriceInterval.Month)} />
      <Switch
        className="flex"
        checked={checked}
        onChange={handleChange}
        styles={(theme) => ({
          track: {
            backgroundColor: theme.colors.brandBlue[7],
            borderColor: theme.colors.brandBlue[7],
          },
        })}
      />
      <Label text={t('price.interval.YEAR.label')} active={checked} onClick={() => onChange(PriceInterval.Year)} />
      <RightTip label={t('price.interval.YEAR.tip')} />
    </Group>
  )
}

export default PriceIntervalToggle
