import { useContext } from 'react'
import { CampaignActionContext, CampaignContext } from './CampaignContext'

export function useCampaign() {
  const context = useContext(CampaignContext)
  if (context === undefined) {
    throw new Error('useCampaign must be used within a CampaignProvider')
  }
  return context
}

export function useCampaignActions() {
  const context = useContext(CampaignActionContext)
  if (context === undefined) {
    throw new Error('useCampaignActions must be used within a CampaignProvider')
  }
  return context
}
