import React, { PropsWithChildren, ReactNode } from 'react'
import { Box, createStyles } from '@mantine/core'
import { Panel } from 'components/ui'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import PlatformPreview from '../PlatformPreview'

interface Props {
  previewPosition?: 'left' | 'right'
  panelTitle?: ReactNode
  panelWidth?: number
}

const defaultPanelWidth = 520

const useStyles = createStyles((theme) => ({
  panel: {
    width: defaultPanelWidth,
    backgroundColor: getBackgroundColor('white', theme),
  },
}))

const PanelWithPreview = (props: PropsWithChildren<Props>) => {
  const { panelTitle, children } = props
  const { classes, cx } = useStyles()

  return (
    <Box w="100%" h="100%" pos="relative">
      <Panel className={cx('absolute h-full overflow-y-auto top-0 left-0 bottom-0', classes.panel)} title={panelTitle}>
        {children}
      </Panel>
      <PlatformPreview left={defaultPanelWidth} right={0} />
    </Box>
  )
}

export default PanelWithPreview
