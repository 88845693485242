import { ApplicationPermission, useApplicationOnboardingQuery } from 'types/domain'
import { useRouter } from 'next/router'
import { useAbac } from 'react-abac'
import { useMemo } from 'react'
import { TaskItemType, TaskType } from 'components/business/Layouts/ApplicationLayout/Onboadring/type'
import { initializeApollo } from 'plugins/apollo'

export default function useOnboardingCache() {
  const appId = useRouter().query.appId as string
  const { userHasPermissions } = useAbac()
  const hasOnboarding = userHasPermissions(ApplicationPermission.ApplicationDelete)
  const client = initializeApollo()

  const { data } = useApplicationOnboardingQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      appId: useRouter().query.appId as string,
    },
    skip: !hasOnboarding,
  })

  const onboarding = data?.application?.onboarding
  const pendingTasks: string[] = useMemo(() => {
    if (!onboarding) {
      return []
    }
    const { campaignCreated, entryCollected, memberInvited, sdkSettingCompleted, sdkConnected } = onboarding
    const mergedObj: { [key in TaskType]: boolean } = {
      campaignCreated,
      sdkCompleted: sdkSettingCompleted && sdkConnected,
      entryCollected,
      memberInvited,
    }
    return Object.keys(mergedObj).filter((key) => {
      return !mergedObj[key as TaskType]
    })
  }, [data])

  const markTaskItemCompleted = (item: TaskItemType) => {
    if (!onboarding) {
      return
    }
    client.cache.modify({
      id: client.cache.identify({ __typename: 'Application', id: appId }),
      fields: {
        onboarding(prev) {
          return {
            ...prev,
            [item]: true,
          }
        },
      },
    })
  }

  return { onboarding, pendingTasks, markTaskItemCompleted }
}
