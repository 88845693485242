import React from 'react'
import { Text } from '@mantine/core'
import { useRouter } from 'next/router'
import { closeModal, openModal } from '@mantine/modals'
import { message } from 'components/ui'
import useTranslation from 'next-translate/useTranslation'
import { CampaignType, useCreateCampaignGroupMutation } from 'types/domain'
import GroupForm from './GroupForm'
import { graphqlErrorCatch } from 'utils'

export const modalId = 'createCampaignGroupModal'

export default function useCreateCampaignGroupModal(type: CampaignType) {
  const { t } = useTranslation('componentCampaignList')
  const router = useRouter()
  const appId = router.query.appId as string
  const [createCampaignGroupMutation] = useCreateCampaignGroupMutation()

  const handleSave = async (name: string, callback: Function, onSuccess?: (groupId: string) => void) => {
    try {
      const { data } = await createCampaignGroupMutation({
        variables: {
          input: {
            name: name,
            type,
            applicationId: appId,
          },
        },
        update(cache, { data: newData }) {
          if (newData?.createCampaignGroup?.id) {
            cache.modify({
              id: cache.identify({ __typename: 'Application', id: appId, type: type }),
              fields: {
                campaignGroups(existingCampaignGroups = []) {
                  return [newData?.createCampaignGroup, ...existingCampaignGroups]
                },
              },
            })
            onSuccess?.(newData?.createCampaignGroup?.id)
          }
        },
      })
      if (data?.createCampaignGroup?.id) {
        message.successMsg(`${t('common:message.createSuccess')}`)
        close()
      }
    } catch (error: any) {
      const mapping = new Map()
      mapping.set('error.duplicate_key', t('campaignGroup.nameDuplicate'))
      const { graphQLErrors } = error
      graphqlErrorCatch({ graphQLErrors, mapping, defaultMessage: t('common:message.createError') })
      callback()
    }
  }

  const open = (onSuccess?: (groupId: string) => void) => {
    openModal({
      modalId,
      title: (
        <Text weight="bold" size="md">
          {t('campaignGroup.create')}
        </Text>
      ),
      size: 440,
      children: <GroupForm onSave={(name, callback) => handleSave(name, callback, onSuccess)} onCancel={close} />,
    })
  }

  const close = () => {
    closeModal(modalId)
  }
  return {
    open,
    close,
  }
}
