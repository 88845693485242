import React from 'react'
import cls from 'classnames'
import { isNil } from 'lodash-es'
import { Center, Text, Tooltip, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { Icon } from 'components/ui'
import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'

const CommentsCountCell = ({ renderedCellValue }: any) => {
  const { t } = useTranslation('entry')
  const theme = useMantineTheme()

  const noComment = isNil(renderedCellValue) || renderedCellValue === 0

  const wrapperClasses = cls('group-hover:visible', {
    visible: !noComment,
    invisible: noComment,
  })

  return (
    <Tooltip
      label={noComment ? t('list.commentsCount.noComment') : t('list.commentsCount.hasComment')}
      withArrow
      withinPortal
    >
      <Center
        className={wrapperClasses}
        sx={(theme) => ({
          backgroundColor: getBackgroundColor('brandBlue.0', theme),
          borderRadius: `0 ${theme.radius.xs} ${theme.radius.xs} 0`,
          textAlign: 'center',
          minWidth: '20px',
          height: '18px',
          cursor: 'pointer',
        })}
      >
        {noComment ? (
          <Icon name="wechat" size={12} style={{ color: getTextColor('brandBlue.9', theme) }} />
        ) : (
          <Text size="xs" color={getTextColor('brandBlue.9', theme)}>
            {renderedCellValue > 99 ? '99+' : renderedCellValue}
          </Text>
        )}
      </Center>
    </Tooltip>
  )
}

export default CommentsCountCell
