import useTranslation from 'next-translate/useTranslation'
import { Code, Paper, ScrollArea, Stack, Tabs, Text, useMantineTheme } from '@mantine/core'
import { isNil } from 'lodash-es'
import { useWebhookHistoryQuery, WebhookChannel } from 'types/domain'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import { WebhookHistoryItemType } from '../HistoryItem'
import SubTitle, { subTitleLineHeightPx } from '../SubTitle'
import DefaultRequestDetail from './DefaultRequestDetail'
import QiyeWXRequestDetail from './QiyeWXRequestDetail'
import DingTalkRequestDetail from './DingTalkRequestDetail'
import FeishuRequestDetail from './FeishuRequestDetail'
import useStyles from '../../styles'

interface Props {
  history?: WebhookHistoryItemType
}

const requestDetailComponentMap = {
  [WebhookChannel.Default]: DefaultRequestDetail,
  [WebhookChannel.QiyeWx]: QiyeWXRequestDetail,
  [WebhookChannel.Dingtalk]: DingTalkRequestDetail,
  [WebhookChannel.Feishu]: FeishuRequestDetail,
}

const HistoryDetail = ({ history }: Props) => {
  const { t } = useTranslation('integration')
  const theme = useMantineTheme()
  const { classes } = useStyles()

  const historyId = history?.id
  const { data, loading } = useWebhookHistoryQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: historyId ?? '' },
    skip: isNil(historyId),
  })

  const responseStatusCode = history?.responseStatusCode
  const { channel, requestUrl, requestPayload, responseBody, errMsg } = data?.webhookHistory ?? {}

  const RequestDetail = requestDetailComponentMap[channel ?? WebhookChannel.Default]

  return (
    <Stack h="100%" className="grow">
      <SubTitle>{t('webhook.histories.detail.title')}</SubTitle>
      <Paper withBorder w="100%" h={`calc(100% - ${subTitleLineHeightPx}px - ${theme.spacing.lg})`}>
        <Tabs
          defaultValue="request"
          h="100%"
          styles={{
            root: {
              display: 'flex',
              flexDirection: 'column',
            },
            panel: {
              flexGrow: 1,
            },
          }}
        >
          <Tabs.List>
            <Tabs.Tab value="request">{t('webhook.histories.detail.request')}</Tabs.Tab>
            <Tabs.Tab value="response">{t('webhook.histories.detail.response')}</Tabs.Tab>
          </Tabs.List>
          <ScrollArea h="100%" bg={getBackgroundColor('gray.0', theme)}>
            <Tabs.Panel value="request" p="sm">
              {!loading && (
                <Stack spacing="md">
                  <Text className="break-all">{t('webhook.histories.detail.requestUrl', { url: requestUrl })}</Text>
                  {requestPayload && <RequestDetail requestPayload={requestPayload} />}
                </Stack>
              )}
            </Tabs.Panel>
            <Tabs.Panel value="response" p="sm">
              {!loading && (
                <>
                  {responseStatusCode && (
                    <Text mb="md">{t('webhook.histories.detail.responseStatus', { code: responseStatusCode })}</Text>
                  )}
                  <Code block className={classes.code}>
                    {responseBody ?? errMsg}
                  </Code>
                </>
              )}
            </Tabs.Panel>
          </ScrollArea>
        </Tabs>
      </Paper>
    </Stack>
  )
}

export default HistoryDetail
