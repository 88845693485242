import { FC } from 'react'
import { Group } from '@mantine/core'

import PageBack from './PageBack'
import NameInput from './NameInput'

const HeaderLeft: FC = () => {
  return (
    <Group noWrap spacing="sm">
      <PageBack />
      <NameInput />
    </Group>
  )
}

export default HeaderLeft
