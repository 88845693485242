import { createContext } from 'react'

import { ConditionType, FieldType, FirstNodeType, SecondNodeType } from '../type'
import { defaultCondition, defaultFirstNode, defaultSecondNode } from './constants'

export interface DataContextProps {
  fields: FieldType[]
  dynamic: boolean
  firstNode: FirstNodeType
  secondNode: SecondNodeType
  maxLength: number
  defaultCondition?: ConditionType
}

const DataContext = createContext<DataContextProps>({
  fields: [],
  dynamic: false,
  firstNode: defaultFirstNode,
  secondNode: defaultSecondNode,
  maxLength: 1,
  defaultCondition,
})

export default DataContext
