import React, { FC, memo, ReactNode, useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { Box, Group, SegmentedControl, SegmentedControlItem, Text, useMantineTheme } from '@mantine/core'

import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'
import { CampaignBuilderStep } from 'components/business/CampaignBuilder/type'
import { useBuilderShareActions, useBuilderSharedDataContext } from 'components/business/CampaignBuilder'
import { Icon } from 'components/ui'
import { CampaignType } from 'types/domain'
import { isLinkDistribution } from 'utils/applicationUtils'

type StepProps = {
  icon: ReactNode
  text: ReactNode
}

const Step: FC<StepProps> = ({ icon, text }) => {
  return (
    <Group spacing="xs">
      {icon}
      <Text>{text}</Text>
    </Group>
  )
}

const HeaderCenter = () => {
  const theme = useMantineTheme()
  const router = useRouter()
  const { appId, campaignId } = router.query
  const { t } = useTranslation('componentCampaignBuilder')
  const { application, campaignType } = useBuilderSharedDataContext()
  const isCurrentLinkDistribution = isLinkDistribution(application)
  const { setEditingFieldIndex, setAutoOpen } = useBuilderShareActions()
  const currentStepName = (router?.query?.step ?? 'fields') as CampaignBuilderStep

  const steps: SegmentedControlItem[] = useMemo(() => {
    const commonSteps = [
      {
        value: 'fields',
        label: (
          <Step
            icon={'fields' === currentStepName ? <Icon name="paperFill" /> : <Icon name="paper" />}
            text={t('steps.fields')}
          />
        ),
      },
      {
        value: 'styles',
        label: (
          <Step
            icon={'styles' === currentStepName ? <Icon name="paletteFill" /> : <Icon name="palette" />}
            text={t('steps.styles')}
          />
        ),
      },
    ]
    const distributionStep = {
      value: 'distribution',
      label: (
        <Step
          icon={
            'distribution' === currentStepName ? (
              <Icon
                size={20}
                name="sendFill"
                style={{
                  color: getTextColor('brandBlue.7', theme),
                  // @ts-ignore
                  '--filled-color': '#D1E5FF',
                }}
              />
            ) : (
              <Icon name="send" />
            )
          }
          text={t('steps.distribution')}
        />
      ),
    }
    return isCurrentLinkDistribution ? commonSteps : [...commonSteps, distributionStep]
  }, [currentStepName, t, theme, isCurrentLinkDistribution])

  const handleOnChange = (value: string) => {
    router.push(
      {
        pathname: router.pathname,
        query: {
          appId,
          campaignId,
          step: value,
        },
      },
      undefined,
      { shallow: true }
    )
    setEditingFieldIndex(0)
    setAutoOpen?.(value === 'fields' && campaignType === CampaignType.Feedback)
  }

  return (
    <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <SegmentedControl
        value={currentStepName}
        data={steps}
        onChange={handleOnChange}
        styles={(theme) => ({
          root: {
            backgroundColor: 'transparent',
          },
          controlActive: {
            backgroundColor: getBackgroundColor('brandBlue.1', theme),
          },
          indicator: {
            boxShadow: 'none',
          },
          label: {
            fontWeight: 'normal',
            padding: '8px 16px',
            '&[data-active]': { color: `${getTextColor('brandBlue.7', theme)} !important` },
          },
          control: {
            borderWidth: '0 !important',
            marginRight: theme.spacing.xs,
          },
        })}
      />
    </Box>
  )
}

export default memo(HeaderCenter)
