import { CampaignDistributionChannel, CampaignWidgetPosition } from 'types/domain'

const getWidgetPosition = (channel: CampaignDistributionChannel) => {
  switch (channel) {
    case CampaignDistributionChannel.Android:
    case CampaignDistributionChannel.IosSwift:
    case CampaignDistributionChannel.WechatMiniapp:
      return CampaignWidgetPosition.BottomCenter
    case CampaignDistributionChannel.Web:
      return CampaignWidgetPosition.MiddleRight
    default:
      return CampaignWidgetPosition.MiddleRight
  }
}

export default getWidgetPosition
