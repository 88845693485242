import React from 'react'
import { Group, Navbar, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconBellRinging } from '@tabler/icons-react'
import useTranslation from 'next-translate/useTranslation'
import MenuWrapper from 'components/business/Layouts/MainLayout/components/MenuWrapper'

import OnboardingBadge from './Badge'
import OnboardingContent from './Content'
import useOnboardingCache from 'components/business/hooks/useOnboardingCache'

const Onboarding = () => {
  const { t } = useTranslation('componentAppOnboarding')
  const [isSkip, { close }] = useDisclosure(true)
  const { onboarding } = useOnboardingCache()

  if (!isSkip || !onboarding) {
    return null
  }

  return (
    <Navbar.Section px="sm">
      <MenuWrapper
        buttonChildren={
          <Group spacing={80}>
            <Group spacing="xl">
              <IconBellRinging size={22} stroke={1.5} color="#5B636F" />
              <Text>{t('title')}</Text>
            </Group>
            <OnboardingBadge />
          </Group>
        }
        dropdownChildren={<OnboardingContent onSkip={close} />}
        dropdownStyle={{ width: '360px !important', bottom: '80px !important', top: 'unset !important' }}
      />
    </Navbar.Section>
  )
}

export default Onboarding
