import { useCampaignBasicInfoQuery } from 'types/domain'

const useCampaignBaseInfoCache = (id: string) => {
  const { data } = useCampaignBasicInfoQuery({
    variables: {
      id,
    },
  })

  const campaign = data?.campaign
  return { campaign }
}

export default useCampaignBaseInfoCache
