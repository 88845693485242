import {
  ApplicationFeedbackEdit,
  ApplicationFeedbackEntries,
  ApplicationNotificationEdit,
  ApplicationNotificationEntries,
  ApplicationSurveyEdit,
  ApplicationSurveyEntries,
} from 'config/constants/routerPath'
import { CampaignType } from 'types/domain'

export const editPathMapping: { [x: string]: string } = {
  [CampaignType.Survey]: ApplicationSurveyEdit,
  [CampaignType.Feedback]: ApplicationFeedbackEdit,
  [CampaignType.Notification]: ApplicationNotificationEdit,
}

export const entriesPathMapping: { [x: string]: string } = {
  [CampaignType.Survey]: ApplicationSurveyEntries,
  [CampaignType.Feedback]: ApplicationFeedbackEntries,
  [CampaignType.Notification]: ApplicationNotificationEntries,
}
