import { Feature, useAccountBrandingConfigQuery } from 'types/domain'

const useAccountSettingCache = () => {
  const { data, loading } = useAccountBrandingConfigQuery()

  return {
    hideBranding: data?.account?.setting.hideBranding,
    canHideBranding: data?.account?.features?.includes(Feature.HideBranding),
    loading,
  }
}

export default useAccountSettingCache
