import { isNil, reduce } from 'lodash-es'
import { Field, FormFieldType } from 'types/domain'
import { PreviewField } from '../type'
import { getNextFieldIndex } from './getNextField'

export default function isLastPage(currentPageFields: Field[] | undefined, formFields: Field[]) {
  if (isNil(currentPageFields) || currentPageFields.some(({ type }) => type === FormFieldType.EndingField)) {
    return true
  }
  const currentPageValues = reduce(
    currentPageFields,
    (values, { code }) => {
      values.push({ code, value: null })
      return values
    },
    [] as PreviewField[]
  )
  const nextFieldIndex = getNextFieldIndex({ formFields, currentPageValues })
  return (
    isNil(nextFieldIndex) ||
    isNil(formFields[nextFieldIndex]) ||
    formFields[nextFieldIndex].type === FormFieldType.EndingField
  )
}
