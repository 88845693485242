import { ConditionType, FieldType } from 'components/business'
import { userSegmentCode } from 'components/business/CampaignBuilder/Distribution/Who/constant'
import { find } from 'lodash-es'
import { useContext, useMemo } from 'react'

import { ConditionOperator, FieldType as ExternalAttributeType, UrlConditionOperator } from 'types/domain'

import DataContext from '../../DataContext'
import ConditionContext from '../ConditionContext'
import { isPresentOperator } from '../Operator/operatorUtils'
import { SECOND_NODE_TYPE } from './constant'

function isUrl(type: string, operator?: UrlConditionOperator | string) {
  return type === 'Url' && (operator === UrlConditionOperator.Equal || operator === UrlConditionOperator.StartWith)
}

function isText(type: string, operator?: UrlConditionOperator | string) {
  return type === 'Url' && operator === UrlConditionOperator.Wildcard
}

function isNumber(operator: ConditionOperator) {
  return [ConditionOperator.Lt, ConditionOperator.Lte, ConditionOperator.Gt, ConditionOperator.Gte].includes(operator)
}

function getFirstNodeType(fields: FieldType[], value: string) {
  return find(fields, { code: value })?.type!
}

function getSecondNodeType(fields: FieldType[], condition: ConditionType) {
  const type = condition?.firstNode?.type
    ? condition?.firstNode?.type
    : getFirstNodeType(fields, condition.firstNode?.value!)

  const operator = condition?.operator as string | undefined

  if (isUrl(type, operator)) {
    return SECOND_NODE_TYPE.URL
  }

  if (isText(type, operator)) {
    return SECOND_NODE_TYPE.TEXT
  }

  return SECOND_NODE_TYPE.TEXT
}

export function getDynamicSecondType(condition: ConditionType) {
  const {
    firstNode: { type: firstNodeType, value: firstNodeValue },
    operator,
  } = condition
  if (isPresentOperator(operator as ConditionOperator)) {
    return
  }
  if (firstNodeType === ExternalAttributeType.DateTime) {
    return SECOND_NODE_TYPE.DATE
  }
  if (firstNodeValue === userSegmentCode) {
    return SECOND_NODE_TYPE.USER_SEGMENT
  }
  if (isNumber(operator as ConditionOperator)) {
    return SECOND_NODE_TYPE.NUMBER
  }
  if (operator === ConditionOperator.NumberBetween) {
    return SECOND_NODE_TYPE.NUMBER_BETWEEN
  }
  const notIsEnOrNe = ![ConditionOperator.Eq, ConditionOperator.Ne].includes(operator as ConditionOperator)
  if (notIsEnOrNe) {
    return SECOND_NODE_TYPE.MULTIPLE_SELECT
  }
  return SECOND_NODE_TYPE.TEXT
}

export function useSecondNodeType() {
  const { fields, dynamic } = useContext(DataContext)
  const { condition } = useContext(ConditionContext)

  return useMemo(() => {
    return dynamic ? getDynamicSecondType(condition) : getSecondNodeType(fields, condition)
  }, [condition, dynamic, fields])
}
