import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Box, Stack } from '@mantine/core'
import { isEmpty, isEqual, keyBy } from 'lodash-es'
import { MantineReactTable, MRT_TableInstance, useMantineReactTable } from 'mantine-react-table'
import { usePrevious } from '@mantine/hooks'

import TableEmpty from 'components/ui/TableEmpty'
import { useCampaignFilter } from 'components/business/Campaign/Filter/CampaignFilterContext'
import { useEntries, useEntriesActions } from 'components/business/Campaign/Entries/EntriesContext'
import useApplicationInfoBaseInfoCache from 'components/business/hooks/useApplicationInfoBaseInfoCache'
import { useCampaign } from 'components/business/Campaign/CampaignContext'
import { TableFooter } from 'components/ui/TableFooter'
import { AsideType } from 'components/business/Campaign/EntryModal'
import { PAGE_SIZE } from 'config/constants'
import { Application, ConditionGroupInput, Entry, FormFieldType, useCampaignEntriesQuery } from 'types/domain'
import { isUrl } from 'utils'
import { getAfter } from 'utils/graphqlCursor'

import { COMMENTS_COUNT_CODE, REPLY_CODE, SERIAL_NUMBER_CODE } from '../../constants'
import { formFieldResolver } from './utils/formFieldResolver'
import getEntriesTableConfig from './utils/tableConfig'
import EntryDetail from './components/EntryDetail'
import { EntryDetailApi } from './components/EntryDetail/type'
import { isMobileDistribution } from 'utils/applicationUtils'

export type EntriesTableApi = {
  tableInstance: MRT_TableInstance<any>
  refetch: any
}

type Props = {}

const pageSize = PAGE_SIZE.SM

const targetAsideTab: { [key: string]: AsideType } = {
  [COMMENTS_COUNT_CODE]: AsideType.Comments,
  [REPLY_CODE]: AsideType.Reply,
}

const EntriesTable = forwardRef<EntriesTableApi, Props>((_, ref) => {
  const application = useApplicationInfoBaseInfoCache().application as Application
  const isCurrentMobileDistribution = isMobileDistribution(application)
  const { campaign } = useCampaign()
  const campaignId = campaign?.id!
  const { columnVisibility, columnOrder, columns, formFields, rowSelection } = useEntries()
  const fieldCodeMap = useMemo(() => keyBy(formFields, (field) => field.code), [formFields])
  const { filter: filterInput } = useCampaignFilter()
  const previousFilterValue = usePrevious(filterInput)
  const [page, setPage] = useState(1)
  const modalRef = useRef<EntryDetailApi | null>(null)
  const { onColumnOrderChange, onRowSelectionChange } = useEntriesActions()

  useEffect(() => {
    if (!isEqual(previousFilterValue, filterInput)) {
      setPage(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInput])

  const { variables, loading, refetch, data } = useCampaignEntriesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      campaignId,
      first: pageSize,
      after: getAfter(page, pageSize).after,
      filter: filterInput as ConditionGroupInput,
      skipBrowserInfo: isCurrentMobileDistribution,
    },
    onCompleted(data) {
      const totalCount = data?.campaign?.form?.entries?.totalCount ?? 0

      if (totalCount > 0 && isEmpty(data?.campaign?.form?.entries?.nodes)) {
        handlePageChange(page - 1)
      }
    },
  })

  const entries = data?.campaign?.form?.entries

  const totalCount = data?.campaign?.form?.entries?.totalCount
  const totalPages = Math.ceil((totalCount ?? 0) / pageSize)

  const handlePageChange = (newPage: number) => {
    onRowSelectionChange({})
    setPage(newPage)
    const updateVariables = { ...variables, after: getAfter(newPage, pageSize).after }
    refetch(updateVariables).then()
  }

  // @ts-ignore
  const table = useMantineReactTable({
    ...getEntriesTableConfig(),
    columns,
    getRowId: (row) => row.id,
    state: {
      isLoading: !entries,
      columnVisibility,
      rowSelection,
      columnOrder,
      pagination: {
        pageSize: Math.min(totalCount ?? 0, pageSize),
        pageIndex: page,
      },
    },
    data: formFieldResolver((entries?.nodes ?? []) as Entry[], fieldCodeMap),
    // @ts-ignore
    onColumnOrderChange,
    onRowSelectionChange,
    mantineTableBodyCellProps: ({ cell }) => ({
      onClick: (event) => {
        if (
          loading ||
          SERIAL_NUMBER_CODE === cell.column.id ||
          // @ts-ignore
          cell.column.columnDef.type === FormFieldType.ScreenshotField ||
          isUrl(cell.renderValue() as string)
        ) {
          event.stopPropagation()
          return
        }
        onRowSelectionChange({})
        const targetAside = targetAsideTab[cell.column.id] ?? undefined
        modalRef?.current?.openModal(cell.row.index, targetAside)
      },
    }),
  })

  useImperativeHandle(ref, () => ({
    tableInstance: table,
    refetch: () => {
      refetch().then(() => onRowSelectionChange({}))
    },
  }))

  return (
    <Box pos="relative" h="100%">
      <Box pos="absolute" inset={0}>
        {totalCount === 0 ? (
          <TableEmpty />
        ) : (
          <>
            <Stack w="100%" h="100%" spacing="sm">
              <MantineReactTable table={table} />
              <TableFooter
                totalCount={totalCount}
                page={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </Stack>
            <EntryDetail
              ref={modalRef}
              page={page}
              pageSize={pageSize}
              tableData={formFieldResolver((entries?.nodes ?? []) as Entry[], fieldCodeMap)}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              onRemove={refetch}
            />
          </>
        )}
      </Box>
    </Box>
  )
})

export default EntriesTable
