import { Feature } from 'types/domain'
import useAccountSubscriptionCache from 'components/business/hooks/useAccountSubscriptionCache'
import { isNil } from 'lodash-es'

export default function useCheckQuota(defaultForLoading?: boolean) {
  const { vasBalance, loading } = useAccountSubscriptionCache()
  return (feature: Feature, allowZero: boolean) => {
    if (!isNil(defaultForLoading) && loading) {
      return defaultForLoading
    }
    if (allowZero) {
      return vasBalance?.[feature].balance >= 0
    }
    return vasBalance?.[feature].balance > 0
  }
}
