import React, { FC, useState, useEffect } from 'react'
import { TextInput } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { ValueNodeProps } from './type'
import { ConditionOperator } from 'types/domain'
import { useDebouncedValue } from '@mantine/hooks'
import { debouncedWaitTime } from '../../constants'

type RefererOperators = ConditionOperator.Eq | ConditionOperator.Contains | ConditionOperator.NotContains

const RefererSelector: FC<ValueNodeProps> = ({ condition, onChange, readOnly }) => {
  const { t } = useTranslation('componentEntryFilter')
  const [error, setError] = useState<string>()
  const [value, setValue] = useState<string>(condition.secondNode?.value ?? '')
  const [debouncedValue] = useDebouncedValue(value, debouncedWaitTime)

  useEffect(() => {
    onChange?.(debouncedValue.trim())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  const operatorInputMap = {
    [ConditionOperator.Eq]: {
      placeholder: t('referer.eqPlaceholder'),
      getError: (value: string) => {
        return !value ? t('common:message.valueRequired') : null
      },
    },
    [ConditionOperator.Contains]: {
      placeholder: t('referer.containsOrNotContainsPlaceholder'),
      getError: (value: string) => {
        return !value ? t('common:message.valueRequired') : null
      },
    },
    [ConditionOperator.NotContains]: {
      placeholder: t('referer.containsOrNotContainsPlaceholder'),
      getError: (value: string) => {
        return !value ? t('common:message.valueRequired') : null
      },
    },
  }

  const { placeholder, getError } = operatorInputMap[condition.operator as RefererOperators]

  const handleInputValue = (value: string) => {
    const inputError = getError(value)
    if (inputError) {
      setValue('')
      setError(inputError)
    } else {
      setValue(value)
      setError('')
    }
  }

  const handleOnChange = (event: any) => {
    handleInputValue(event.target?.value)
  }

  return (
    <TextInput
      error={error}
      placeholder={placeholder}
      value={value || ''}
      onChange={handleOnChange}
      readOnly={readOnly}
    />
  )
}

export default RefererSelector
