import { editPathMapping } from 'config/constants/campaignPathMapping'
import React from 'react'
import { useRouter } from 'next/router'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { Anchor, Box, List, Text } from '@mantine/core'
import { CampaignType } from 'types/domain'
import { AnchorLink } from 'components/ui'
import { openProductsModal } from 'components/business/ProductsModal'
import { CampaignBuilderStep } from 'components/business/CampaignBuilder/type'

interface Props {
  campaignId: string
  campaignType: CampaignType
  reasons: string[]
  terminated?: boolean
  affectedSteps: CampaignBuilderStep[]
}

const DistributionAffectionTooltipLabel = ({ campaignId, campaignType, reasons, terminated, affectedSteps }: Props) => {
  const router = useRouter()
  const appId = router.query.appId as string
  const { t } = useTranslation('componentCampaignList')

  const handleClickEventPropagation = (event: any) => {
    event.stopPropagation()
  }

  const onUpgrade = () => openProductsModal('CampaignDistributionAffection')

  return (
    <Box onClick={handleClickEventPropagation}>
      <Text pb="xs" className="leading-[20px]">
        {t(`list.distributionAffectionTooltip.title.${terminated ? 'terminated' : 'affected'}`, {
          type: t(campaignType),
        })}
      </Text>
      <List
        size="sm"
        spacing="xs"
        icon={<div className="w-[4px] h-[4px] rounded-full bg-gray-5"></div>}
        styles={{
          itemWrapper: {
            alignItems: 'center !important',
          },
        }}
      >
        {reasons.map((reason, index) => (
          <List.Item key={`${campaignId} - ${index}`} className="leading-[20px]" h={20}>
            {reason}
          </List.Item>
        ))}
      </List>
      <Text pt="md" className="leading-[20px]">
        {terminated ? (
          <Trans
            i18nKey="componentCampaignList:list.distributionAffectionTooltip.tips.terminated"
            components={{
              upgrade: <Anchor onClick={onUpgrade} />,
            }}
          />
        ) : (
          <Trans
            i18nKey="componentCampaignList:list.distributionAffectionTooltip.tips.affected"
            components={{
              steps: (
                <>
                  {affectedSteps.map((step, index) => (
                    <>
                      {index > 0 && '、'}
                      <AnchorLink
                        key={step}
                        href={{
                          pathname: editPathMapping[campaignType],
                          query: { appId: appId, campaignId: campaignId, step },
                        }}
                      >
                        {t(`list.distributionAffectionTooltip.steps.${step}`)}
                      </AnchorLink>
                    </>
                  ))}
                </>
              ),
              upgrade: <Anchor onClick={onUpgrade} />,
            }}
          />
        )}
      </Text>
    </Box>
  )
}

export default DistributionAffectionTooltipLabel
