import { ConditionGroupOperator, UrlConditionGroup, UrlConditionOperator } from 'types/domain'
import { UrlFilterConditionType } from './type'

export const defaultCondition: UrlFilterConditionType = {
  operator: UrlConditionOperator.Equal,
  node: {
    value: null,
  },
}

export const defaultConditions: UrlFilterConditionType[] = [defaultCondition]

export const defaultValue = {
  operator: ConditionGroupOperator.And,
  conditions: defaultConditions,
} as UrlConditionGroup

export const maxLength = 10
