import { CampaignTemplate } from 'types/domain'
import { Template } from 'config/constants'
import TemplateCard from './TemplateCard'

type Props = {
  template: Pick<CampaignTemplate, 'code' | 'description' | 'title' | '__typename'>
  className?: string
}

const SiteTemplateCard = (props: Props) => {
  const { template, className } = props
  const url = Template.replace('[templateId]', template.code)

  return <TemplateCard url={url} template={template} className={className} />
}

export default SiteTemplateCard
