import { FC, useMemo } from 'react'
import { Center, useMantineTheme } from '@mantine/core'
import cls from 'classnames'
import Icon from 'components/ui/Icon'
import { FieldIconCodeType, formFieldIconMapping, reservedFieldIconMapping, typeIconMapping } from 'utils/fieldIcon'
import { isFormField } from 'utils/fieldUtils'

type Props = {
  type: FieldIconCodeType
  fieldCode: string
  className?: string
  useDefault?: boolean
}

const EntryIcon: FC<Props> = ({ type, fieldCode, className, useDefault }) => {
  const theme = useMantineTheme()
  const mapping = useMemo(() => {
    const formFieldIcon = formFieldIconMapping(theme)
    const reservedFieldIcon = reservedFieldIconMapping(theme)
    const typeMapping = typeIconMapping(theme)
    if (type && isFormField(fieldCode)) {
      return formFieldIcon[type]
    }
    return (
      typeMapping[type] ??
      reservedFieldIcon[type] ??
      reservedFieldIcon[fieldCode] ??
      (useDefault
        ? {
            iconName: 'info',
            bg: theme.colors.cyan[3],
            iconColor: theme.colors.cyan[9],
          }
        : {})
    )
  }, [theme, type, fieldCode, useDefault])

  if (!mapping) {
    return null
  }
  const { iconName, bg, iconColor } = mapping

  return (
    <>
      {iconName && (
        <Center w={20} h={20} bg={bg} className={cls('rounded-sm flex-shrink-0', className)}>
          <Icon name={iconName} style={{ color: iconColor }} />
        </Center>
      )}
    </>
  )
}

export default EntryIcon
