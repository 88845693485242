import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Application, CampaignType, CampaignWidget, Field, FormSetting, Locale } from 'types/domain'
import { brandingUrl, DistributionChannelGroup } from 'config/constants'
import { defaultColorScheme } from 'components/business/CampaignBuilder'
import getDefaultLocaleWords from 'components/business/CampaignBuilder/StyleSetting/Locale/utils/getDefaultLocaleWords'
import { PreviewField } from 'components/business/CampaignBuilder/CampaignPreview/type'
import { getNextFieldIndex } from 'components/business/CampaignBuilder/CampaignPreview/utils/getNextField'
import parseFieldsToFieldsPages from 'components/business/CampaignBuilder/CampaignPreview/utils/parseFieldsToFieldsPages'
import { isLinkDistribution } from 'utils/applicationUtils'
import useApplicationInfoBaseInfoCache from 'components/business/hooks/useApplicationInfoBaseInfoCache'

type Props = {
  platform?: 'mobile' | 'desktop'
  fields: Field[]
  formSetting: FormSetting
  widget: CampaignWidget
  channel?: DistributionChannelGroup
  hideBranding?: boolean
  token?: string
  type?: CampaignType
}

export type SurveyTemplatePreviewApi = {
  refresh: () => void
}

const SurveyTemplatePreview: ForwardRefRenderFunction<SurveyTemplatePreviewApi, Props> = (props, ref) => {
  const { fields, widget, platform, channel, formSetting, hideBranding, token, type = CampaignType.Survey } = props
  const fieldsPages = parseFieldsToFieldsPages(fields)
  const currentWidget = useRef<any>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const outsideContainerRef = useRef<HTMLDivElement>(null)
  const [activePageIndex, setActivePageIndex] = useState<number>(0)
  const { application } = useApplicationInfoBaseInfoCache()
  const isLinkApplication = isLinkDistribution(application as Application)

  let pageIndex = 0

  const handleFieldChange = (data: PreviewField[]) => {
    const nextFieldIndex = getNextFieldIndex({ formFields: fields, currentPageValues: data })
    pageIndex = nextFieldIndex && nextFieldIndex > -1 ? fields[nextFieldIndex].page : -1
    setActivePageIndex(pageIndex)
    updateWidget()
  }

  const previewProps = {
    interactive: true,
    activePageIndex,
    formSetting: {
      ...formSetting,
      colorScheme: widget.colorScheme ?? defaultColorScheme,
      brandingUrl: hideBranding ? null : brandingUrl,
      localeSetting: formSetting.localeSetting ?? {
        locale: 'zh_CN',
        wordings: getDefaultLocaleWords(Locale.ZhCn),
      },
    },
    widget,
    maxHeight: '520px',
    onFieldChange: handleFieldChange,
    startIndex: 0,
    type,
    forcePlatformAs: platform,
    token,
    onClose: () => {
      destroyWidget()
    },
  }

  const getFinalPreviewPropsByCurrentPageField = () => {
    const common = {
      ...previewProps,
      pages: fieldsPages,
      activePageIndex: pageIndex,
    }
    return isLinkApplication ? { ...common, forcePlatformAs: 'pc', isUrlVisit: true } : common
  }

  const createWidget = () => {
    pageIndex = 0

    import('assets/libs/sdk.js').then((module) => {
      if (isLinkApplication) {
        currentWidget.current = new module.FormPreview({
          ...getFinalPreviewPropsByCurrentPageField(),
          container: outsideContainerRef.current,
        })
        currentWidget.current.render()
      } else {
        const XSdk = module.default
        currentWidget.current = new XSdk({
          ...getFinalPreviewPropsByCurrentPageField(),
          container: containerRef.current,
        })
      }
    })
  }

  const updateWidget = () => {
    currentWidget.current?.update(getFinalPreviewPropsByCurrentPageField())
  }

  const destroyWidget = () => {
    currentWidget.current?.destroyWidget?.()
  }

  const refresh = () => {
    destroyWidget()
    createWidget()
  }

  useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, channel])

  useEffect(() => destroyWidget(), [])

  useImperativeHandle(ref, () => ({ refresh }))

  return (
    <div
      ref={outsideContainerRef}
      style={{ height: '100%', position: 'relative', transform: 'translate(0, 0)', overflow: 'hidden' }}
    >
      <div ref={containerRef}></div>
    </div>
  )
}

export default forwardRef(SurveyTemplatePreview)
