import { MantineTheme } from '@mantine/core'
import { Icon } from 'components/ui'
import { NEXT_FIELD } from 'config/constants'
import { AllFieldType, AttributeFieldType } from 'config/constants/fieldType'
import { ComponentProps } from 'react'
import { FieldType, FormFieldType } from 'types/domain'

export type FieldStyle = {
  iconName: ComponentProps<typeof Icon>['name']
  bg: string
  iconColor?: string
}

export const formFieldIconMapping = (
  theme: MantineTheme
): {
  [x: string]: FieldStyle
} => {
  return {
    [FormFieldType.NpsField]: { iconName: 'nps', bg: theme.colors.teal[3], iconColor: '#004537' },
    [FormFieldType.CsatField]: { iconName: 'csat', bg: theme.colors.red[3], iconColor: '#87121D' },
    [FormFieldType.CesField]: { iconName: 'ces', bg: theme.colors.green[3], iconColor: '#0E4D20' },
    [FormFieldType.RadioButton]: { iconName: 'radio', bg: theme.colors.cyan[3], iconColor: '#003C4D' },
    [FormFieldType.CheckBox]: { iconName: 'checkbox', bg: theme.colors.indigo[3], iconColor: '#111C82' },
    [FormFieldType.TextArea]: { iconName: 'textArea', bg: theme.colors.blue[3], iconColor: '#003273' },
    [FormFieldType.TextField]: { iconName: 'textField', bg: theme.colors.yellow[3], iconColor: '#483C09' },
    [FormFieldType.EndingField]: { iconName: 'ending', bg: theme.colors.gray[1], iconColor: theme.colors.gray[7] },
    [FormFieldType.RatingField]: { iconName: 'rating', bg: theme.colors.orange[3], iconColor: '#8A3000' },
    [FormFieldType.WelcomeField]: { iconName: 'welcome', bg: theme.colors.gray[1], iconColor: theme.colors.gray[7] },
    [FormFieldType.DescriptionField]: { iconName: 'description', bg: theme.colors.pink[3], iconColor: '#A11344' },
    [FormFieldType.CtaField]: { iconName: 'cta', bg: theme.colors.cyan[3], iconColor: '#003C4D' },
    [NEXT_FIELD]: {
      iconName: 'arrowAutofitDown',
      bg: theme.colors.gray[1],
      iconColor: theme.colors.gray[7],
    },
  }
}

export const reservedFieldIconMapping = (theme: MantineTheme): Record<AllFieldType | string, FieldStyle> => {
  return {
    'entry.createdAt': { iconName: 'calendar', bg: theme.colors.brandBlue[3], iconColor: theme.colors.brandBlue[9] },
    'entry.fillingDuration': { iconName: 'time', bg: theme.colors.teal[3], iconColor: theme.colors.teal[9] },
    'entry.submittedOn': { iconName: 'upCloud', bg: theme.colors.pink[3], iconColor: theme.colors.pink[9] },
    'entry.submitted_on': { iconName: 'upCloud', bg: theme.colors.pink[3], iconColor: theme.colors.pink[9] },
    'entry.region': { iconName: 'map', bg: theme.colors.cyan[3], iconColor: theme.colors.cyan[9] },
    'entry.browser': { iconName: 'browser', bg: theme.colors.green[3], iconColor: theme.colors.green[9] },
    'entry.screenResolution': { iconName: 'resolution', bg: theme.colors.violet[3], iconColor: theme.colors.violet[9] },
    'entry.ip': { iconName: 'earth', bg: theme.colors.yellow[3], iconColor: theme.colors.yellow[9] },
    'entry.referer': { iconName: 'signPaper', bg: theme.colors.indigo[3], iconColor: theme.colors.indigo[9] },
    'entry.os': { iconName: 'os', bg: theme.colors.orange[3], iconColor: theme.colors.orange[9] },
    'entry.deliveryMethod': { iconName: 'affiliate', bg: theme.colors.lime[3], iconColor: theme.colors.lime[9] },
    'entry.delivery_method': { iconName: 'affiliate', bg: theme.colors.lime[3], iconColor: theme.colors.lime[9] },
    'entry.$commented': { iconName: 'commented', bg: theme.colors.orange[3], iconColor: theme.colors.orange[9] },
    customer_mobile: { iconName: 'mobile', bg: theme.colors.blue[3], iconColor: theme.colors.blue[9] },
    mobile: { iconName: 'mobile', bg: theme.colors.blue[3], iconColor: theme.colors.blue[9] },
    customer_email: { iconName: 'email', bg: theme.colors.lime[3], iconColor: theme.colors.lime[9] },
    email: { iconName: 'email', bg: theme.colors.lime[3], iconColor: theme.colors.lime[9] },
    customer_name: { iconName: 'user', bg: theme.colors.brandBlue[3], iconColor: theme.colors.brandBlue[9] },
    name: { iconName: 'user', bg: theme.colors.brandBlue[3], iconColor: theme.colors.brandBlue[9] },
    customer_uid: { iconName: 'uid', bg: theme.colors.red[3], iconColor: theme.colors.red[9] },
    uid: { iconName: 'uid', bg: theme.colors.red[3], iconColor: theme.colors.red[9] },
  }
}

export const typeIconMapping = (theme: MantineTheme): Record<AllFieldType | string, FieldStyle> => {
  return {
    [FieldType.DateTime]: {
      iconName: 'dateTime',
      bg: theme.colors.teal[3],
      iconColor: theme.colors.teal[9],
    },
    [AttributeFieldType.DateTimeField]: {
      iconName: 'dateTime',
      bg: theme.colors.teal[3],
      iconColor: theme.colors.teal[9],
    },
    [AttributeFieldType.TextField]: {
      iconName: 'info',
      bg: theme.colors.cyan[3],
      iconColor: theme.colors.cyan[9],
    },
  }
}

type AvailableFieldCode =
  | 'entry.createdAt'
  | 'entry.fillingDuration'
  | 'entry.submittedOn'
  | 'entry.deliveryMethod'
  | 'entry.region'
  | 'entry.browser'
  | 'entry.os'
  | 'entry.ip'
  | 'entry.referer'
  | 'customer_mobile'
  | 'mobile'
  | 'customer_email'
  | 'email'
  | 'customer_name'
  | 'name'
  | 'customer_uid'
  | 'uid'

export type FieldIconCodeType = AvailableFieldCode | AllFieldType
