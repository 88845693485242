import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { DeliveryMethod, SelectableField } from 'types/domain'
import { ValueNodeProps } from './type'
import SingleChoiceSelector from './SingleChoiceSelector'

const DeliveryMethodSelector: FC<ValueNodeProps> = (props) => {
  const { t } = useTranslation('componentEntryFilter')
  const { fields, condition } = props
  const firstNodeField = fields.find((field) => field.code === condition.firstNode.value) as SelectableField
  firstNodeField.choices = Object.values(DeliveryMethod).map((value) => ({
    label: t(`deliveryMethodOption.${value}`),
    value,
    type: 'TextChoice',
  }))
  return <SingleChoiceSelector {...props} />
}

export default DeliveryMethodSelector
