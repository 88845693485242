import React, { FC, PropsWithChildren } from 'react'
import { AppShell, Box, useMantineTheme } from '@mantine/core'
import Head from 'next/head'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import useLogRocket from 'plugins/logrocket/useLogRocket'
import useHowxmSDK from 'plugins/howxm/useHowxmSDK'
import { HowXMAppId } from 'config'
import Nav from 'components/business/Layouts/MainLayout/Nav'
import MainContext, { MainContextProps } from './MainContext'

const MainLayout: FC<PropsWithChildren<MainContextProps>> = ({ children, nav, me, account }) => {
  const theme = useMantineTheme()
  useLogRocket()
  useHowxmSDK(HowXMAppId)

  return (
    <Box className="h-full" pos="relative">
      <Head>
        <style>
          {`
              html body {
                background-color: ${getBackgroundColor('gray.0', theme)};
                overflow: overlay;
              }
            `}
        </style>
      </Head>
      <MainContext.Provider value={{ me, account }}>
        <AppShell
          padding={32}
          navbarOffsetBreakpoint="sm"
          asideOffsetBreakpoint="sm"
          navbar={<Nav>{nav}</Nav>}
          styles={(theme) => ({
            main: {
              maxHeight: '100vh',
              overflow: 'auto',
              paddingTop: theme.spacing.lg,
              backgroundColor: getBackgroundColor('gray.0', theme),
            },
          })}
        >
          <Box pos="relative" h="100%">
            {children}
          </Box>
        </AppShell>
      </MainContext.Provider>
    </Box>
  )
}

export default MainLayout
