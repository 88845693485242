import React, { PropsWithChildren } from 'react'
import { Alert, DefaultProps } from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react'
import { Feature } from 'types/domain'
import Message from './Message'

interface Props extends DefaultProps {
  feature: Feature
}

const FeatureUnavailableAlert = ({ feature, ...rest }: PropsWithChildren<Props>) => (
  <Alert icon={<IconAlertCircle size={16} />} color="red" {...rest}>
    <Message feature={feature} />
  </Alert>
)

export default FeatureUnavailableAlert
