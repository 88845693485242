import { FC } from 'react'
import { Rating } from '@mantine/core'

interface StarRatingCellType {
  max: number
}

const RatingCell: FC<StarRatingCellType | any> = (props) => {
  const { max, renderedCellValue } = props
  return <Rating count={max ?? 5} value={renderedCellValue} />
}

export default RatingCell
