import React, { FC } from 'react'
import { Text } from '@mantine/core'
import { formatISOTimeOrNull } from 'utils'

const DateCell: FC<any> = ({ renderedCellValue }) => {
  if (!renderedCellValue) {
    return null
  }

  return <Text>{formatISOTimeOrNull(renderedCellValue)}</Text>
}

export default DateCell
