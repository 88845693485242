import React, { FC, PropsWithChildren } from 'react'
import { Box, useMantineTheme } from '@mantine/core'
import FakeBrowser from './FakeBrowser'
import { getImage } from './getImage'
import { BoxProps } from '@mantine/core/lib/Box/Box'
import cn from 'classnames'

const DesktopPreview: FC<PropsWithChildren<BoxProps>> & {
  PreLoadBg: FC
} = ({ children, className, pos, ...rest }) => {
  return (
    <Box className={cn('w-full h-full', className)} pos={pos ?? 'relative'} maw={1200}>
      <FakeBrowser {...rest}>{children}</FakeBrowser>
    </Box>
  )
}

/* eslint-disable */
DesktopPreview.PreLoadBg = () => {
  const theme = useMantineTheme()
  return (
    <div className={'w-[0px] h-[0px] overflow-hidden'}>
      <img src={getImage('browser-start', theme)} alt={'preload'} />
      <img src={getImage('browser-end', theme)} alt={'preload'} />
      <img src={getImage('browser-repeat', theme)} alt={'preload'} />
    </div>
  )
}
/* eslint-enable */

export default DesktopPreview
