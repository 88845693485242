import React from 'react'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { Anchor, AnchorProps } from '@mantine/core'
import { Feature } from 'types/domain'
import { openProductsModal } from 'components/business/ProductsModal'

interface Props {
  feature: Feature
  anchor?: Partial<AnchorProps>
}

const Message = ({ feature, anchor }: Props) => {
  const { t } = useTranslation()

  return (
    <Trans
      i18nKey="componentCampaignList:featureUnavailable"
      components={{
        upgrade: <Anchor {...anchor} onClick={() => openProductsModal(t(`product:feature.${feature}.eventAttr`))} />,
      }}
    />
  )
}

export default Message
