import React, { forwardRef, ForwardRefRenderFunction, PropsWithChildren, ReactNode } from 'react'
import { Button, ActionIcon, MantineColor, HoverCard } from '@mantine/core'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import { isNil } from 'lodash-es'

type StatusPopoverProps = {
  targetIcon: ReactNode
  color: MantineColor
  targetText?: string
}
const StatusPopover: ForwardRefRenderFunction<HTMLButtonElement, PropsWithChildren<StatusPopoverProps>> = (
  props,
  ref
) => {
  const { targetIcon, color, targetText, children } = props
  const isTargetTextSettled = isNil(targetText)

  return (
    <HoverCard width={360} position="bottom-start" shadow="md" offset={4} withinPortal>
      <HoverCard.Target>
        {isTargetTextSettled ? (
          <ActionIcon
            size={32}
            variant="transparent"
            sx={(theme) => ({
              '&:hover': {
                backgroundColor: getBackgroundColor('gray.1', theme),
              },
            })}
          >
            {targetIcon}
          </ActionIcon>
        ) : (
          <Button
            ref={ref}
            styles={(theme) => ({
              root: {
                fontSize: 12,
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: theme.colors.red[0],
                },
              },
            })}
            variant="subtle"
            color={color}
            size="sm"
            leftIcon={targetIcon}
          >
            {targetText}
          </Button>
        )}
      </HoverCard.Target>
      <HoverCard.Dropdown p="md">{children}</HoverCard.Dropdown>
    </HoverCard>
  )
}

export default forwardRef(StatusPopover)
