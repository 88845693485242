import { forwardRef, ForwardRefRenderFunction, PropsWithChildren } from 'react'
import { Box, CSSObject } from '@mantine/core'
import bgImage from './images/bg.png'
import classNames from 'classnames'

type Props = {
  sx?: CSSObject
}

const MAX_SIMULATOR_WIDTH = 375
const MAX_SIMULATOR_HEIGHT = 826

const MobileSimulator: ForwardRefRenderFunction<HTMLDivElement, PropsWithChildren<Props>> = (props, ref) => {
  const { sx, children } = props
  const outerRadius = 34

  return (
    <Box
      ref={ref}
      sx={{
        overflow: 'hidden',
        position: 'relative',
        width: MAX_SIMULATOR_WIDTH,
        maxHeight: MAX_SIMULATOR_HEIGHT,
        backgroundImage: `url(${bgImage.src})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% -2px',
        border: '12px solid #000000',
        borderRadius: outerRadius,
        ...(sx || {}),
      }}
    >
      <Box className={classNames('absolute left-1/2 -translate-x-1/2 bg-black rounded-full')}></Box>
      <Box className="absolute top-0 right-0 bottom-0 left-0">
        <Box
          sx={{
            overflow: 'hidden',
            height: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
      <Box
        className={classNames(
          'absolute left-1/2 -translate-x-1/2 bg-black rounded-full bottom-[8px] w-[134px] h-[5px]'
        )}
      ></Box>
    </Box>
  )
}

export default forwardRef(MobileSimulator)
