import React, { FC } from 'react'
import { ActionIcon, useMantineTheme } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import { useConditionGroup } from '../ConditionGroup'

type Props = {
  index: number
}

const RemoveCondition: FC<Props> = ({ index }) => {
  const { dispatch } = useConditionGroup()
  const theme = useMantineTheme()

  const handleRemoveClick = () => {
    dispatch({
      type: 'removeCondition',
      index: index,
    })
  }

  return (
    <ActionIcon
      onClick={handleRemoveClick}
      sx={(theme) => ({
        '&:hover': {
          background: theme.colors.gray[1],
        },
      })}
    >
      <IconTrash size={16} stroke={1} color={theme.colors.gray[7]} />
    </ActionIcon>
  )
}

export default RemoveCondition
