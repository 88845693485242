import React, { FC, useContext } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Group, Select } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { parseISO } from 'date-fns'
import { isNil } from 'lodash-es'
import 'dayjs/locale/zh-cn'

import { ConditionNodeType } from 'types/domain'
import { DATE_FORMAT_FOR_DAYJS } from 'config/constants'
import { formatISODATE } from 'utils'
import ConditionContext from '../ConditionContext'
import usePeriodTypes from '../../hooks/usePeriodTypes'
import { onChangeCallback } from './type'

type Props = {
  onChange: onChangeCallback
}

const DateSelector: FC<Props> = (props: Props) => {
  const { t } = useTranslation('common')
  const { onChange } = props
  const {
    condition: { secondNode },
  } = useContext(ConditionContext)
  const { getPeriodOptions } = usePeriodTypes()

  const isCustomizedDate =
    isNil(secondNode?.type) || isNil(secondNode?.value) || secondNode?.type === ConditionNodeType.CustomizedDate

  const currentType = isCustomizedDate
    ? ConditionNodeType.CustomizedDate
    : (secondNode?.value as string | null | undefined)

  const currentValue = isCustomizedDate && secondNode?.value ? parseISO(secondNode.value as string) : null

  const handleTypeChange = (type: string) => {
    if (type === ConditionNodeType.CustomizedDate) {
      onChange({ value: '', type })
    } else {
      onChange({ value: type, type: ConditionNodeType.Period })
    }
  }

  const handleDateChange = (date: Date) => {
    onChange({
      value: date ? formatISODATE(date) : '',
      type: currentType ?? ConditionNodeType.CustomizedDate,
    })
  }

  return (
    <Group grow spacing={8} align="top">
      <Select
        placeholder={t('placeholders.pleaseSelect')}
        data={getPeriodOptions()}
        value={currentType}
        onChange={handleTypeChange}
      />
      {isCustomizedDate && (
        <DatePickerInput
          clearable
          valueFormat={DATE_FORMAT_FOR_DAYJS}
          placeholder={t('placeholders.pleaseSelect')}
          onChange={handleDateChange}
          locale="zh-cn"
          value={currentValue}
          error={secondNode?.error}
        />
      )}
    </Group>
  )
}

export default DateSelector
