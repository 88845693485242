import React, { useMemo } from 'react'
import { Stack } from '@mantine/core'
import { groupBy } from 'lodash-es'
import { CampaignTemplate, CampaignTemplateCategory } from 'types/domain'
import CategoryTemplates from '../CategoryTemplates'

type Props = {
  source?: 'site' | 'system'
  templates?: CampaignTemplate[]
  containerRef?: any
  activeCategory?: CampaignTemplateCategory
}

const Categories = (props: Props) => {
  const { source = 'system', templates, activeCategory, containerRef } = props

  const categoryTemplates = useMemo(() => {
    return groupBy(templates, 'category')
  }, [templates])

  return (
    <Stack spacing={32}>
      {Object.keys(categoryTemplates ?? {}).map((category, index) => {
        return (
          <CategoryTemplates
            containerRef={containerRef}
            active={activeCategory === category}
            key={index}
            templates={categoryTemplates[category] as CampaignTemplate[]}
            source={source}
          />
        )
      })}
    </Stack>
  )
}

export default Categories
