import { groupBy, values } from 'lodash-es'
import { Field, FormFieldType } from 'types/domain'
import isLastPage from './isLastPage'
import updateWithRealJumpRuleTargetType from './updateWithRealJumpRuleTargetType'
import getStartIndex from 'components/business/CampaignBuilder/CampaignPreview/utils/getStartIndex'

const parseFieldsToFieldsPages = (fields: Field[]) => {
  const groups = groupBy(fields, (field) => {
    return field.page ?? 0
  })
  const fieldsWithoutEnding = fields.filter(({ type }) => type !== FormFieldType.EndingField)
  return values(groups).map((pageFields) => ({
    nodes: updateWithRealJumpRuleTargetType(pageFields, fields),
    totalCount: fieldsWithoutEnding.length,
    startIndex: getStartIndex(fieldsWithoutEnding, pageFields),
    isLastPage: isLastPage(pageFields, fields),
  }))
}

export default parseFieldsToFieldsPages
