import { FeatureCheck, FeatureText } from './components/Feature'
import { CustomizedFeature } from './constant'

const FreeFeature = () => {
  return (
    <>
      <FeatureText titleKey={CustomizedFeature.EntriesCount} value="3000条/年" />
      <FeatureText titleKey={CustomizedFeature.ApplicationsCount} value="2个" />
      <FeatureText titleKey={CustomizedFeature.CampaignsCountPerApplication} value="3份/应用" />
      <FeatureCheck titleKey={CustomizedFeature.WebSupport} />
    </>
  )
}

export default FreeFeature
