import { IconX } from '@tabler/icons-react'
import React, { MouseEvent, useState } from 'react'
import { Image } from '@mantine/core'
import Lightbox from 'yet-another-react-lightbox'
import { useDisclosure } from '@mantine/hooks'
import { getBorderColor } from 'config/themeOverride/getColor'

type Props = {
  images?: string[]
  size: number
}
const ImageGroup = ({ images, size }: Props) => {
  const [opened, { close, open }] = useDisclosure(false)
  const [currentImage, setCurrentImage] = useState<string | null>(null)
  const handleClick = (image: string, e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setCurrentImage(image)
    open()
  }

  const handleCloseClick = (event: MouseEvent<SVGElement>) => {
    event.stopPropagation()
    close()
  }

  if (!images) {
    return null
  }

  return (
    <>
      {images.map((image) => (
        <Image
          styles={(theme) => ({
            image: {
              cursor: 'pointer',
              border: `1px solid ${getBorderColor('gray.2', theme)}`,
              borderRadius: theme.radius.xs,
            },
          })}
          key={image}
          width={size}
          height={size}
          src={image}
          alt=""
          onClick={(e) => handleClick(image, e)}
        />
      ))}
      {currentImage && (
        <Lightbox
          open={opened}
          close={close}
          slides={[{ src: currentImage }]}
          carousel={{ finite: true }}
          render={{
            iconClose: () => <IconX onClick={handleCloseClick} />,
          }}
        />
      )}
    </>
  )
}

export default ImageGroup
