import { noop } from 'lodash-es'
import { createContext } from 'react'
import { EntriesActionProps, EntriesContextProps } from './type'

export const EntriesContext = createContext<EntriesContextProps>({
  hiddenColumns: [],
  columnVisibility: {},
  columnOrder: [],
  formFields: [],
  columns: [],
  rowSelection: {},
  selectedRowsCount: 0,
})

export const EntriesActionContext = createContext<EntriesActionProps>({
  onHiddenColumnChange: noop,
  onColumnOrderChange: noop,
  onRowSelectionChange: noop,
})
