import React, { useState } from 'react'
import { isEnter } from 'utils/keyboardUtils'
import { isBlank } from 'utils'

const useEnterBlurMultiSelect = (onCallback?: (value: string) => void) => {
  const [searchValue, onSearchChange] = useState<string | undefined>(undefined)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isEnter(event)) {
      return
    }
    updateCreateValue()
  }

  const updateCreateValue = () => {
    if (isBlank(searchValue)) {
      return
    }
    onCallback && onCallback(searchValue!.trim())
    onSearchChange('')
  }

  return {
    searchValue,
    onSearchChange,
    handleKeyDown,
    handleBlur: updateCreateValue,
  }
}

export default useEnterBlurMultiSelect
