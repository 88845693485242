import { useContext } from 'react'
import { useCheckCampaignPermission } from 'components/business'
import WebhookContext from 'components/business/Integration/WebhookContext'
import { ApplicationPermission } from 'types/domain'

export default function useWebhookEditable(campaignCreatorId?: string) {
  const { campaign, webhook } = useContext(WebhookContext)
  const checkPermission = useCheckCampaignPermission()
  const finalCampaignCreatorId = campaignCreatorId ?? webhook?.campaign?.creator?.id ?? campaign?.creator?.id
  return checkPermission(finalCampaignCreatorId ?? '', ApplicationPermission.CampaignUpdate)
}
