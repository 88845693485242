import React from 'react'
import { openModal } from '@mantine/modals'

import { modalConfig } from 'config/constants'

import DetailContent from './DetailContent'
import Header from './Header'
import { modalId } from './constant'
import { CustomerDetailBasicProps } from './type'
import { useMantineTheme } from '@mantine/core'

export default function useCustomerDetailModal(canAddToSegment: boolean) {
  const theme = useMantineTheme()

  return (props: CustomerDetailBasicProps) => {
    openModal({
      ...modalConfig(theme),
      modalId,
      title: <Header showNavigator={false} />,
      children: <DetailContent {...props} manageable={canAddToSegment} />,
    })
  }
}
