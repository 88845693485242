import React, { forwardRef } from 'react'
import { FormFieldType } from 'types/domain'
import { Flex, Text, Tooltip } from '@mantine/core'
import EntryIcon from '../../../../../EntryIcon'

export interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  type: FormFieldType
  label: string
  value: string
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, type, value, ...others }: ItemProps, ref) => {
  return (
    <Flex ref={ref} {...others} align="center" w={262}>
      <EntryIcon type={type} fieldCode={value} useDefault />
      <Tooltip label={label} position="top" withinPortal withArrow width={260}>
        <Text ml="sm" className="flex-1 min-w-0" lineClamp={1}>
          {label}
        </Text>
      </Tooltip>
    </Flex>
  )
})

export default SelectItem
