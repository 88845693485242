import React, { FC, PropsWithChildren } from 'react'
import { Grid, Text } from '@mantine/core'
import useSecondaryTextColor from '../utils/useSecondaryTextColor'

type Props = PropsWithChildren & {
  label?: string
  align?: string
}

const StyleItem: FC<Props> = (props) => {
  const { label, align = 'center', children } = props
  const secondaryTextColor = useSecondaryTextColor()

  return (
    <Grid align={align}>
      {!!label && (
        <Grid.Col span={3}>
          <Text color={secondaryTextColor}>{label}</Text>
        </Grid.Col>
      )}
      <Grid.Col span={label ? 8 : 11} offset={1}>
        {children}
      </Grid.Col>
    </Grid>
  )
}

export default StyleItem
