import { find } from 'lodash-es'

import { ConditionOperator, FormFieldType, UrlConditionOperator } from 'types/domain'
import { FieldType } from 'components/business'

import { DATETIME_FIELD, SystemFilterableField, TEXT_FIELD } from 'config/constants/fieldType'
import { CUSTOMER_EMAIL, CUSTOMER_MOBILE, CUSTOMER_NAME, CUSTOMER_UID } from 'config/constants'
import { isFormField } from 'utils/fieldUtils'

const {
  Include,
  Exclude,
  Contains,
  NotContains,
  DateEq,
  DateNe,
  Before,
  After,
  Gt,
  Lt,
  Gte,
  Lte,
  NumberBetween,
  Eq,
  Ne,
  IsNull,
  IsNotNull,
} = ConditionOperator

export const Mine = 'MINE'

const { Equal, StartWith, Wildcard } = UrlConditionOperator

const urlOptions: string[] = [Equal, StartWith, Wildcard]

const numberFieldOperator = [Eq, Ne, Gt, Gte, Lt, Lte, NumberBetween, IsNull, IsNotNull]
const choiceFieldOperator = [Eq, Ne, Include, Exclude, IsNull, IsNotNull]
const stringFieldOperator = [Eq, Ne, Contains, NotContains, IsNull, IsNotNull]
const customerStringFieldOperator = [Eq, Ne, Contains, NotContains, Gt, Gte, Lt, Lte, NumberBetween, IsNull, IsNotNull]
const customerDateFieldOperator = [DateEq, DateNe, Before, After]
const submittedOnFieldOperator = [Eq]
const refererFieldOperator = [Eq, Contains, NotContains]
const commentedFieldOperator = [IsNull, IsNotNull, Mine]

const FORM_FIELD_CONFIG: { [key: string]: string[] } = {
  [FormFieldType.NpsField]: numberFieldOperator,
  [FormFieldType.CsatField]: numberFieldOperator,
  [FormFieldType.CesField]: numberFieldOperator,
  [FormFieldType.RatingField]: numberFieldOperator,
  [FormFieldType.CheckBox]: choiceFieldOperator,
  [FormFieldType.RadioButton]: choiceFieldOperator,
  [FormFieldType.TextArea]: stringFieldOperator,
  [FormFieldType.TextField]: stringFieldOperator,
  [FormFieldType.CtaField]: choiceFieldOperator,
}

const RESERVED_FIELD_CONFIG: { [key: string]: string[] } = {
  Url: urlOptions,

  [TEXT_FIELD as string]: customerStringFieldOperator,
  [DATETIME_FIELD as string]: customerDateFieldOperator,

  [SystemFilterableField.SubmittedOn]: submittedOnFieldOperator,
  [SystemFilterableField.Referer]: refererFieldOperator,
  [SystemFilterableField.Commented]: commentedFieldOperator,
  [SystemFilterableField.DeliveryMethod]: choiceFieldOperator,

  [CUSTOMER_EMAIL]: stringFieldOperator,
  [CUSTOMER_MOBILE]: stringFieldOperator,
  [CUSTOMER_NAME]: stringFieldOperator,
  [CUSTOMER_UID]: stringFieldOperator,
}

function getFirstNodeType(fields: FieldType[], value: string) {
  return find(fields, { code: value })?.type!
}

export function getOperatorsByFirstNodeCode(fields: FieldType[], code: string) {
  const type = getFirstNodeType(fields, code)
  return code && isFormField(code) ? FORM_FIELD_CONFIG[type] : RESERVED_FIELD_CONFIG[type!]
}
