import React, { FC, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useElementSize } from '@mantine/hooks'
import { Box } from '@mantine/core'
import { isEmpty, isNil, merge } from 'lodash-es'
import { CampaignType, CampaignWidgetPosition, CampaignWidgetType, Field, FormFieldType } from 'types/domain'
import {
  useBuilderFormContext,
  useBuilderShareActions,
  useBuilderSharedDataContext,
} from 'components/business/CampaignBuilder'
import { getNextFieldIndex } from './utils/getNextField'
import parseFieldsToFieldsPages from './utils/parseFieldsToFieldsPages'
import isLastPage from './utils/isLastPage'
import updateWithRealJumpRuleTargetType from './utils/updateWithRealJumpRuleTargetType'
import getStartIndex from './utils/getStartIndex'
import { PreviewField } from './type'
import useEditingPageState from './hooks/useEditingPageState'
import usePublishedFields from './hooks/usePublishedFields'
import getDefaultLocaleWords from 'components/business/CampaignBuilder/StyleSetting/Locale/utils/getDefaultLocaleWords'
import useAccountSettingCache from 'components/business/hooks/useAccountSettingCache'
import { isLinkDistribution } from 'utils/applicationUtils'

type Props = {
  widgetType?: CampaignWidgetType
  position?: CampaignWidgetPosition
  forcePlatformAs?: 'mobile' | 'desktop'
  interactive?: boolean
  showProgressBar?: boolean
  campaignPreviewRef?: MutableRefObject<any>
}

const defaultBrandingUrl = 'https://howxm.com'

const CampaignPreview: FC<Props> = (props) => {
  const { interactive = true, widgetType, position, showProgressBar, forcePlatformAs, campaignPreviewRef } = props
  const previewContainerRef = useRef<HTMLDivElement>(null)
  const form = useBuilderFormContext()
  const { token, formFields: rawFormFields, formSetting, widget, type, targeting, localeSetting } = form.values
  const formFields = usePublishedFields(rawFormFields)
  const [activePageIndex, setActivePageIndex] = useState<number>(0)
  const [field, setField] = useState<Field | null>(formFields[0])
  const currentWidget = useRef<any>(null)
  const { autoOpen, editingFieldIndex } = useBuilderSharedDataContext()
  const { setEditingFieldIndex, setAutoOpen } = useBuilderShareActions()
  const { currentPageFormFields } = useEditingPageState(formFields)
  const { ref, height } = useElementSize()
  const { application } = useBuilderSharedDataContext()
  const isLinkApplication = isLinkDistribution(application)

  const fieldsPages = useMemo(() => {
    if (!interactive) {
      const fieldsWithoutEnding = formFields.filter(({ type }) => type !== FormFieldType.EndingField)
      return [
        {
          nodes: updateWithRealJumpRuleTargetType(currentPageFormFields, formFields),
          totalCount: fieldsWithoutEnding.length,
          startIndex: getStartIndex(fieldsWithoutEnding, currentPageFormFields),
          isLastPage: isLastPage(currentPageFormFields, formFields),
        },
      ]
    }
    return parseFieldsToFieldsPages(formFields)
  }, [interactive, formFields, currentPageFormFields])

  const { hideBranding, canHideBranding } = useAccountSettingCache()

  const handleFieldChange = useCallback(
    (data: PreviewField[] | number) => {
      if (type === CampaignType.Feedback) {
        setEditingFieldIndex(data as number)
      } else {
        const nextActivePageIndex =
          formFields[getNextFieldIndex({ currentPageValues: data as PreviewField[], formFields })]?.page ?? -1
        setEditingFieldIndex(getNextFieldIndex({ currentPageValues: data as PreviewField[], formFields }))
        setActivePageIndex(nextActivePageIndex)
      }
    },
    [formFields, setEditingFieldIndex, type]
  )

  const brandingUrl = hideBranding && canHideBranding ? null : defaultBrandingUrl

  const previewProps = useMemo<any>(() => {
    return {
      activePageIndex,
      editingFieldIndex,
      token,
      formSetting: {
        ...formSetting,
        showProgressBar: isNil(showProgressBar) ? formSetting.showProgressBar : showProgressBar,
        colorScheme: widget.colorScheme,
        brandingUrl,
        localeSetting: {
          ...localeSetting,
          wordings: merge(getDefaultLocaleWords(localeSetting?.locale), localeSetting?.wordings),
        },
      },
      widget: {
        ...widget,
        ...(isNil(position) ? {} : { position }),
        ...(isNil(widgetType) ? {} : { type: widgetType }),
      },
      editingCode: interactive ? undefined : field?.code,
      ...(type === CampaignType.Feedback
        ? {
            field,
            fields: formFields,
            startIndex: formFields.findIndex((field) => field.code === currentPageFormFields?.[0]?.code),
            totalCount: formFields.length,
          }
        : {
            pages: fieldsPages,
          }),
      onFieldChange: handleFieldChange,
      type,
      forcePlatformAs,
      interactive,
      isUrlVisit: isLinkApplication,
      autoOpen,
      onClose: () => {
        if (widget.type === CampaignWidgetType.Button) {
          setAutoOpen?.(false)
          setEditingFieldIndex(0)
        }
        setActivePageIndex(0)
      },
      // 在survey的题目tab，一页多题field点击切换的时候，左侧对应选中的field需要更新
      onFieldClick: (code: string) => {
        const clickedFieldIndex = formFields.findIndex((field) => field.code === code)
        const clickedField = formFields[clickedFieldIndex]
        if (clickedField) {
          setField(clickedField)
          setEditingFieldIndex(clickedFieldIndex)
        }
      },
      maxHeight: `${Math.ceil(height * 0.8)}px`,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formSetting,
    widget,
    position,
    field,
    formFields,
    type,
    forcePlatformAs,
    interactive,
    autoOpen,
    brandingUrl,
    localeSetting,
    height,
  ])

  useEffect(() => {
    const currentField = formFields[editingFieldIndex]!
    setField(currentField)
  }, [editingFieldIndex, formFields])

  useEffect(() => {
    resetToFirstField()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget.type, widget.position, widget.width])

  useEffect(() => {
    if (editingFieldIndex === -1) {
      resetToFirstField()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget.colorScheme])

  useEffect(() => {
    if (isNil(currentWidget.current) && widget.type === CampaignWidgetType.Banner) {
      const createWidgetProps = {
        ...previewProps,
        container: ref.current,
        maxHeight: `${Math.ceil(ref.current.clientHeight * 0.8)}px`,
      }
      import('assets/libs/sdk.js').then((module) => {
        const XSdk = module.default
        currentWidget.current = new XSdk(createWidgetProps)
      })
      return
    }

    if (isNil(currentWidget.current) && !isEmpty(formFields) && widget.type !== CampaignWidgetType.Banner) {
      const createWidgetProps = {
        ...previewProps,
        container: ref.current,
        maxHeight: `${Math.ceil(ref.current.clientHeight * 0.8)}px`,
      }
      import('assets/libs/sdk.js').then((module) => {
        if (isLinkApplication) {
          currentWidget.current = new module.FormPreview({ ...previewProps, container: previewContainerRef.current })
          currentWidget.current.render()
        } else {
          const XSdk = module.default
          currentWidget.current = new XSdk(createWidgetProps)
        }
      })
    }
    return () => {
      currentWidget.current?.destroyWidget?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    currentWidget.current?.update({ ...previewProps, container: previewContainerRef.current })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(previewProps), targeting])

  const resetToFirstField = () => {
    setEditingFieldIndex(0)
    setActivePageIndex(0)
    currentWidget.current?.refresh()
  }

  if (campaignPreviewRef) {
    campaignPreviewRef.current = {
      refresh: resetToFirstField,
    }
  }

  return (
    <Box
      className="campaign-preview h-full relative overflow-hidden translate-x-0 translate-y-0"
      ref={previewContainerRef}
    >
      <Box ref={ref} h="100%" />
    </Box>
  )
}

export default CampaignPreview
