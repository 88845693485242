import { useApplicationRoleQuery } from 'types/domain'
import { useRouter } from 'next/router'

export default function useApplicationRoleCache() {
  const id = useRouter().query.appId as string

  const { data } = useApplicationRoleQuery({
    variables: {
      id,
    },
    skip: !id,
  })

  return { applicationRole: data?.application?.role }
}
