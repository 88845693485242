import useTranslation from 'next-translate/useTranslation'
import { SendWebhookInput, useSendWebhookMutation, WebhookChannel, WebhookEventType } from 'types/domain'
import { graphqlErrorCatch } from 'utils'
import { message } from 'components/ui'
import { DEMO_WEBHOOK_ENTRY_ID } from 'components/business'
import { useRouter } from 'next/router'

export interface SendWebhookDemoProps
  extends Omit<SendWebhookInput, 'applicationId' | 'channel' | 'content' | 'eventType'> {
  channel?: WebhookChannel
}

export default function useSendWebhookDemo() {
  const { t } = useTranslation('integration')
  const applicationId = useRouter().query.appId as string
  const [send, { loading }] = useSendWebhookMutation()

  const sendWebhookDemo = async ({ channel = WebhookChannel.Default, ...rest }: SendWebhookDemoProps) => {
    try {
      const { data } = await send({
        variables: {
          input: {
            applicationId,
            channel,
            content: DEMO_WEBHOOK_ENTRY_ID,
            eventType: WebhookEventType.EntrySubmitted,
            ...rest,
          },
        },
      })
      if (data?.sendWebhook) {
        message.successMsg(t('webhook.sendDemo.successMessage'), t('webhook.sendDemo.successTitle'))
      } else {
        message.error(t('webhook.sendDemo.failed'))
      }
    } catch (err: any) {
      const { graphQLErrors } = err
      graphqlErrorCatch({ graphQLErrors, defaultMessage: t('common:message.graphqlError', { message: err.message }) })
    }
  }

  return {
    sendWebhookDemo,
    loading,
  }
}
