import { Stack, Timeline, useMantineTheme } from '@mantine/core'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import React, { FC } from 'react'
import { Reply } from 'types/domain'
import ReplyItem from './ReplyItem'

type Props = {
  replies: Reply[]
  entryId: string
  updateReplyCallBack?: () => void
}

const Content: FC<Props> = ({ replies, entryId, updateReplyCallBack }) => {
  const theme = useMantineTheme()

  return (
    <Stack spacing="xl">
      {replies?.map((reply: Reply, index: number) => {
        return (
          <Timeline key={index} bg={getBackgroundColor('gray.0', theme)} p="sm" radius="sm" className="rounded">
            <ReplyItem key={reply.id} reply={reply} entryId={entryId} updateReplyCallBack={updateReplyCallBack} />
          </Timeline>
        )
      })}
    </Stack>
  )
}

export default Content
