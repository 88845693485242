import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Button, Group, Menu, Text } from '@mantine/core'
import { IconChevronDown } from '@tabler/icons-react'
import { orderedWebhookChannels, WebhookChannelIcon } from 'components/business'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import { useWebhookBuilderFormContext } from '../../../formContext'
import useIsBuilderEditable from '../../../hooks/useIsBuilderEditable'

const ChannelSelect = () => {
  const { t } = useTranslation('integration')
  const form = useWebhookBuilderFormContext()
  const { channel } = form.values
  const editable = useIsBuilderEditable()

  if (!editable) {
    return (
      <Group spacing="sm">
        <WebhookChannelIcon channel={channel!} size={18} />
        <Text>{t(`webhook.builder.stepAction.${channel}.label`)}</Text>
      </Group>
    )
  }

  return (
    <Menu position="bottom-start">
      <Menu.Target>
        <Button
          variant="subtle"
          color="dark"
          leftIcon={<WebhookChannelIcon channel={channel!} size={18} />}
          rightIcon={<IconChevronDown size={20} stroke={1.2} />}
          styles={(theme) => ({
            leftIcon: {
              marginRight: theme.spacing.sm,
            },
            root: {
              ':hover': {
                backgroundColor: getBackgroundColor('gray.1', theme),
              },
            },
          })}
        >
          {t(`webhook.builder.stepAction.${channel}.label`)}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {orderedWebhookChannels.map((c) => (
          <Menu.Item
            key={c}
            icon={<WebhookChannelIcon channel={c} size={18} />}
            onClick={() => form.setFieldValue('channel', c)}
          >
            {t(`webhook.builder.stepAction.${c}.label`)}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}

export default ChannelSelect
