import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { useDisclosure } from '@mantine/hooks'
import { Center, Group, Loader, SystemProp } from '@mantine/core'

import { PageInfo, useWebhookHistoriesLazyQuery, Webhook } from 'types/domain'
import { PAGE_SIZE } from 'config/constants'
import HistoryList from './HistoryList'
import HistoryDetail from './HistoryDetail'
import { WebhookHistoryItemType } from './HistoryItem'
import Empty from './Empty'

interface Props {
  applicationId: string
  webhookUrl?: string
  webhook?: Pick<Webhook, 'id' | 'channel' | 'campaign'>
  h?: SystemProp<CSSProperties['height']>
}

const ModalContent = ({ applicationId, webhookUrl, webhook, h }: Props) => {
  const [history, setHistory] = useState<WebhookHistoryItemType>()
  const [isDemoSent, { open: demoSent }] = useDisclosure(false)
  const histories = useRef<WebhookHistoryItemType[]>([])
  const pageInfo = useRef<Pick<PageInfo, 'endCursor' | 'hasNextPage'>>()
  const [fetch, { loading }] = useWebhookHistoriesLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  const webhookId = webhook?.id

  useEffect(() => {
    fetch({
      variables: {
        applicationId,
        webhookId,
        pageArguments: { first: PAGE_SIZE.LG },
      },
      onCompleted(data) {
        histories.current = data.webhookHistories.nodes ?? []
        pageInfo.current = data.webhookHistories.pageInfo
      },
    }).then(() => {
      const history = histories.current[0]
      history && setHistory(history)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDemoSent])

  const loadMore = async () => {
    await fetch({
      variables: {
        applicationId,
        webhookId,
        pageArguments: {
          after: pageInfo.current?.endCursor,
          first: PAGE_SIZE.LG,
        },
        maxSentAt: histories.current[0]!.sentAt,
      },
      onCompleted(nextData) {
        histories.current.push(...(nextData.webhookHistories.nodes ?? []))
        pageInfo.current = nextData.webhookHistories.pageInfo
      },
    })
  }

  if (histories.current.length === 0) {
    return (
      <Center h={h}>
        {loading ? (
          <Loader />
        ) : (
          <Empty
            webhookUrl={webhookUrl}
            webhookId={webhook?.id}
            channel={webhook?.channel}
            campaignCreatorId={webhook?.campaign?.creator.id}
            onDemoSent={demoSent}
          />
        )}
      </Center>
    )
  }

  return (
    <Group align="self-start" h={h} noWrap>
      <HistoryList
        onItemClick={setHistory}
        histories={histories.current}
        onLoadMore={loadMore}
        hasMore={pageInfo.current?.hasNextPage ?? false}
      />
      <HistoryDetail history={history} />
    </Group>
  )
}

export default ModalContent
