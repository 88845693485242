import { useCampaignFilter, useCampaignFilterActions } from 'components/business/Campaign/Filter/CampaignFilterContext'
import { createdAtCode } from 'config/constants/fieldType'
import { parseISO } from 'date-fns'
import { isEqual, isNil } from 'lodash-es'
import React, { FC, useMemo } from 'react'
import { Group, Popover, Text, Paper, useMantineTheme } from '@mantine/core'
import { getTextColor } from 'config/themeOverride/getColor'
import { ConditionInput, ConditionNodeInput, ConditionNodeType, ConditionOperator } from 'types/domain'
import { useClickOutside, useDisclosure } from '@mantine/hooks'
import { IconCalendar } from '@tabler/icons-react'
import useTranslation from 'next-translate/useTranslation'
import { formatRangeDates } from 'utils'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import SelectItems from './SelectItems'

type Props = {
  withPaper?: boolean
}

const defaultValue = { type: ConditionNodeType.Period, value: 'ALL' }

const CreatedAtSelector: FC<Props> = (props) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('componentEntryFilter')
  const [opened, { close, open }] = useDisclosure(false)
  const { withPaper = false } = props
  const { filter } = useCampaignFilter()
  const { onExplicitlyFieldChange } = useCampaignFilterActions()

  const value = useMemo(() => {
    const secondNode = (filter?.conditions as ConditionInput[])?.find(
      ({ firstNode }) => firstNode.value === createdAtCode
    )?.secondNode
    if (isNil(secondNode)) {
      return defaultValue
    }
    if (secondNode?.type == ConditionNodeType.CustomizedPeriod) {
      return {
        ...secondNode,
        value: [parseISO(secondNode?.value[0]), parseISO(secondNode?.value[1])],
      }
    }
    return secondNode
  }, [filter])

  const ref = useClickOutside(() => {
    close()
  })

  const handleClose = () => {
    close()
  }

  const handleChange = (value: ConditionNodeInput) => {
    if (isEqual(value, defaultValue)) {
      onExplicitlyFieldChange(createdAtCode, null)
    } else {
      onExplicitlyFieldChange(createdAtCode, {
        operator: ConditionOperator.DateBetween,
        firstNode: { value: createdAtCode },
        secondNode: value,
      })
    }
    close()
  }

  const label =
    value.type === ConditionNodeType.Period ? t(`createdAtOption.${value.value}`) : formatRangeDates(value.value)

  const paperProps = withPaper ? { withBorder: true, p: 'xs' } : { sx: { backgroundColor: 'transparent' } }

  return (
    <Popover offset={4} width={178} opened={opened} withinPortal position="bottom-start" shadow="md" radius="xs">
      <Popover.Target>
        <Paper {...paperProps} radius="sm">
          <Group
            h={30}
            spacing="xs"
            px="sm"
            onClick={open}
            noWrap
            sx={(theme) => ({
              borderRadius: theme.radius.xs,
              cursor: 'pointer',
              '&:hover': {
                background: getBackgroundColor('gray.1', theme),
              },
              '&:active': {
                transform: 'translateY(1px)',
              },
            })}
          >
            <IconCalendar stroke={1.5} size={18} color={getTextColor('gray.9', theme)} />
            <Text className="select-none">{label}</Text>
          </Group>
        </Paper>
      </Popover.Target>
      <Popover.Dropdown p="xs">
        <SelectItems value={value} onChange={handleChange} onClose={handleClose} ref={ref} />
      </Popover.Dropdown>
    </Popover>
  )
}

export default CreatedAtSelector
