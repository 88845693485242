import { BuilderFormDataState } from 'components/business/CampaignBuilder/type'
import {
  getJumpRule,
  getShowRules,
} from 'components/business/SurveyBuilder/Field/FieldEditor/RulesSetting/utils/getRule'
import { cloneDeep, isArray, isEmpty, isNil, omit, trim } from 'lodash-es'
import {
  CampaignTriggerCondition,
  CampaignTriggerEvent,
  CampaignWidgetType,
  ConditionGroup,
  ConditionNodeType,
  EventTriggerCondition,
  Field,
  FieldJumpRule,
  FieldShowRule,
  FormFieldInput,
  Maybe,
  UrlConditionGroup,
} from 'types/domain'

import { clearPreCode } from 'utils/tempCode'
import getFormFieldInputSetting from './getFormFieldInputSetting'

function getFormFields(formFields: Field[]) {
  return formFields.map((field) => {
    const { label, type, page, validators, code, apiCode, hidden, description } = field
    const jumpRule = getJumpRule(field)
    const showRules = getShowRules(field)
    return {
      label: trim(label),
      type,
      page,
      code: clearPreCode(code),
      apiCode,
      description: trim(description!),
      validators: validators,
      setting: getFormFieldInputSetting(field),
      hidden,
      jumpRule: getFormFieldJumpRule(jumpRule),
      showRules: getFormFieldShowRules(showRules),
    } as FormFieldInput
  }) as FormFieldInput[]
}

function getFormFieldJumpRule(jumpRule?: FieldJumpRule | null) {
  if (!jumpRule?.target && !jumpRule?.subRules) {
    return jumpRule
  }
  return {
    ...jumpRule,
    target: jumpRule?.target ? clearPreCode(jumpRule?.target) : null,
    subRules: jumpRule?.subRules?.map((subRule) => {
      return (
        {
          ...subRule,
          key: clearPreCode(subRule?.key!),
          target: clearPreCode(subRule?.target!),
          conditionGroup: formatConditionGroup(subRule.conditionGroup),
        } ?? null
      )
    }),
  }
}

function getFormFieldShowRules(showRules?: FieldShowRule[]) {
  if (showRules) {
    return showRules.map((rule) => ({
      ...rule,
      targets: rule?.targets?.map(clearPreCode),
      conditionGroup: formatConditionGroup(rule.conditionGroup),
    }))
  }
  return []
}

function clearConditionNodeType(nodeType?: string | null): ConditionNodeType | undefined {
  return nodeType && Object.values(ConditionNodeType).includes(nodeType as ConditionNodeType)
    ? (nodeType as ConditionNodeType)
    : undefined
}

function formatConditionGroup(conditionGroup?: ConditionGroup | null) {
  if (conditionGroup) {
    return {
      ...conditionGroup,
      conditions: conditionGroup?.conditions.map((condition) => {
        return {
          ...condition,
          firstNode: {
            type: clearConditionNodeType(condition.firstNode.type),
            value: clearPreCode(condition.firstNode.value),
          },
          secondNode: condition.secondNode
            ? {
                type: clearConditionNodeType(condition.secondNode.type),
                value: isArray(condition.secondNode.value)
                  ? condition.secondNode.value.map(clearPreCode)
                  : clearPreCode(condition.secondNode.value),
              }
            : null,
        }
      }),
    }
  }
  return null
}

function cleanAndFormatConditionGroup(conditionGroup?: ConditionGroup | null) {
  if (conditionGroup && conditionGroup.conditions.length > 0) {
    return formatConditionGroup(conditionGroup)
  }
  return null
}

function formatEventTriggerCondition({ eventConditions, ...rest }: EventTriggerCondition) {
  return {
    ...rest,
    eventConditions: eventConditions.map(({ code, attributeFilter }) => ({
      code,
      attributeFilter: cleanAndFormatConditionGroup(attributeFilter),
    })),
  }
}

function filterTriggerConditions(conditions: CampaignTriggerCondition[], widgetType: CampaignWidgetType) {
  if (widgetType === CampaignWidgetType.Popup) {
    return conditions.map((condition) => {
      return condition.event === CampaignTriggerEvent.EventReceived
        ? formatEventTriggerCondition(condition as EventTriggerCondition)
        : condition
    })
  }
  return conditions.filter(({ event }) => event !== CampaignTriggerEvent.EventReceived)
}

function formatUrlFilter(filter: Maybe<UrlConditionGroup> | undefined) {
  return isNil(filter) || isEmpty(filter.conditions) ? null : filter
}

export default function getCampaignInput(state: BuilderFormDataState) {
  const { name, enabled, type, widget, formFields, formSetting, targeting, openStrategy, trigger, localeSetting } =
    cloneDeep(state)

  return {
    name,
    enabled,
    type,
    form: {
      fields: getFormFields(formFields),
      setting: {
        ...omit(formSetting || {}, 'colorScheme'),
      },
    },
    widget: {
      ...widget,
      colorScheme: {
        ...widget.colorScheme,
        background: widget.colorScheme?.background ? widget.colorScheme?.background : '#1D55C4',
      },
    },
    targeting: {
      ...targeting,
      urlFilter: formatUrlFilter(targeting.urlFilter),
      excludeUrlFilter: formatUrlFilter(targeting.excludeUrlFilter),
      customerFilter: cleanAndFormatConditionGroup(targeting.customerFilter),
    },
    openStrategy,
    trigger: {
      operator: trigger.operator,
      conditions: filterTriggerConditions(trigger.conditions, widget.type),
    },
    localeSetting: {
      ...localeSetting,
      wordings: omit(localeSetting?.wordings, ['branding', 'ratingDescription']),
    },
  }
}
