import { useDebouncedValue } from '@mantine/hooks'
import { isNumber } from 'lodash-es'
import { useEffect, useRef, useState } from 'react'
import { ConditionOperator } from 'types/domain'
import { debouncedWaitTime } from '../../../constants'
import { ValueNodeProps } from '../type'

export default function useBetweenState<T = string | number>(
  condition: ValueNodeProps['condition'],
  onChange: ValueNodeProps['onChange']
) {
  const isBetween = [ConditionOperator.NumberBetween, ConditionOperator.DateBetween].includes(condition.operator)
  const [betweenValue, setBetweenValue] = useState<T[]>(isBetween ? condition.secondNode?.value || [] : [])
  const [debouncedValue] = useDebouncedValue(betweenValue, debouncedWaitTime)
  const mountedRef = useRef(false)
  const [minValue, maxValue] = betweenValue

  useEffect(() => {
    if (mountedRef.current && isBetween) {
      const completed = betweenValue.length === 2 && betweenValue.every(isNumber(minValue) ? isNumber : Boolean)
      if (completed) {
        onChange?.(betweenValue as never)
      } else {
        onChange?.('')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betweenValue, debouncedValue, isBetween, minValue])

  useEffect(() => {
    mountedRef.current = true
  }, [])

  return {
    isBetween,
    minValue,
    maxValue,
    setBetweenValue,
  }
}
