import { useRouter } from 'next/router'
import { useApplicationSceneAttributesQuery } from 'types/domain'
import { PAGE_SIZE } from 'config/constants'

export default function useApplicationSceneAttributes() {
  const router = useRouter()
  const appId = router.query.appId as string

  const { data } = useApplicationSceneAttributesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: appId,
      pageArguments: {
        first: PAGE_SIZE.UN_LIMIT,
      },
    },
  })

  return data?.application?.applicationSceneAttributes.nodes ?? []
}
