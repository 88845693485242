import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { Anchor, Group, Popover, Text, useMantineTheme } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import useTranslation from 'next-translate/useTranslation'
import { getTextColor } from 'config/themeOverride/getColor'
import { ExportTargetType, useCancelExportMutation } from 'types/domain'
import BaseButton from './BaseButton'
import Divider from 'components/ui/Divider'

type Props = {
  downloadUrl: string
  onCancel: () => void
  onDownload: (url: string) => void
}

const ButtonWithDownload: FC<Props> = ({ downloadUrl, onDownload, onCancel }) => {
  const { t } = useTranslation('entry')
  const theme = useMantineTheme()
  const router = useRouter()
  const campaignId = router.query.campaignId as string
  const target = { targetId: campaignId, targetType: ExportTargetType.CampaignEntries }
  const [cancelExportMutation] = useCancelExportMutation()

  const handleExportCancel = () => {
    cancelExportMutation({ variables: target }).then()
    onCancel()
  }

  return (
    <Popover withinPortal opened={true} position="bottom-start" zIndex={100}>
      <Popover.Target>
        <BaseButton disabled>{t('export.label')}</BaseButton>
      </Popover.Target>
      <Popover.Dropdown
        style={{
          boxShadow: theme.shadows.md,
        }}
      >
        <Group position="apart">
          <Group spacing="xs">
            <IconCheck stroke={2} size={20} color={getTextColor('green.6', theme)} />
            <Text>{t('export.downloadTips')}</Text>
          </Group>
          <Group spacing="sm">
            <Anchor onClick={() => onDownload(downloadUrl)}>{t('common:actions.download')}</Anchor>
            <Divider orientation="vertical" color="brandBlue.1" my={2} />
            <Text onClick={handleExportCancel} className="cursor-pointer">
              {t('common:actions.cancel')}
            </Text>
          </Group>
        </Group>
      </Popover.Dropdown>
    </Popover>
  )
}

export default ButtonWithDownload
