import React, { FC, PropsWithChildren } from 'react'
import { OnboardingLayoutProvider, useOnboardingLayoutForm } from './context'
import { ResponsiveHead } from 'components/ui'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import Content from './Content'
import { isEmptyUrl, validateName } from './validate'
import { showBackBtnQueryKey } from 'components/business/Layouts/OnboardingLayout/constant'

type Props = {
  openDropdown?: boolean
}

const OnboardingLayout: FC<PropsWithChildren<Props>> = ({ openDropdown, children }) => {
  const { t } = useTranslation('pageAppNew')
  const router = useRouter()

  const form = useOnboardingLayoutForm({
    clearInputErrorOnChange: true,
    initialValues: {
      openDropdown,
    },
    validate: {
      accountName: (val?: string | null) => validateName(t, val),
    },
    transformValues: (values) => ({
      ...values,
      appId: isEmptyUrl(values.appId) ? '' : values.appId,
    }),
  })

  return (
    <>
      <div>
        <ResponsiveHead />
        <OnboardingLayoutProvider form={form}>
          <Content back={!!router.query?.[showBackBtnQueryKey]}>{children}</Content>
        </OnboardingLayoutProvider>
      </div>
    </>
  )
}

export default OnboardingLayout
