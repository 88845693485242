import { CampaignWidgetPosition, CampaignWidgetType } from 'types/domain'

const positionConfig: { [key: string]: CampaignWidgetPosition[] } = {
  [CampaignWidgetType.Popup]: [
    CampaignWidgetPosition.BottomRight,
    CampaignWidgetPosition.MiddleRight,
    CampaignWidgetPosition.Center,
    CampaignWidgetPosition.BottomLeft,
    CampaignWidgetPosition.MiddleLeft,
  ],
  [CampaignWidgetType.Button]: [
    CampaignWidgetPosition.BottomRight,
    CampaignWidgetPosition.MiddleRight,
    CampaignWidgetPosition.BottomLeft,
    CampaignWidgetPosition.MiddleLeft,
    CampaignWidgetPosition.Customized,
  ],
  [CampaignWidgetType.Banner]: [CampaignWidgetPosition.Top, CampaignWidgetPosition.Bottom],
}

export default positionConfig
