import { Group, Text } from '@mantine/core'
import { Price } from 'types/domain'
import { formatNumberToThousands } from 'utils'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  price: Price
}

const Price = ({ price }: Props) => {
  const { t } = useTranslation('product')

  return (
    <Group spacing={0} position="center" align="flex-end">
      <Text fz={24} fw={600} lh="30px">
        {formatNumberToThousands(price.unitAmount / 100, {
          style: 'currency',
          currency: 'CNY',
          point: 0,
          currencyDisplay: 'narrowSymbol',
        })}
      </Text>
      <Text lh="26px">{t(`price.interval.${price.interval}.unit`)}</Text>
    </Group>
  )
}

export default Price
