import React, { FC } from 'react'
import { AuthenticationFormValues, CaptchaSender } from 'components/business'
import { UseFormReturnType } from '@mantine/form/lib/types'
import { SignupFormValues } from 'modules/Signup/formContext'
import { CaptchaType } from 'types/domain'
import { Flex, TextInput } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

type Props = {
  form: UseFormReturnType<AuthenticationFormValues | SignupFormValues>
  captchaType: CaptchaType
  identifierExistedErrorHandler?: () => void
  identifierNotExistedErrorHandler?: () => void
}

const CaptchaCodeSendAndInput: FC<Props> = ({
  identifierExistedErrorHandler,
  captchaType,
  identifierNotExistedErrorHandler,
  form,
}) => {
  const { t } = useTranslation('user')
  const onCaptchaSend = () => {
    form.setFieldValue('captcha', '')
  }
  return (
    <Flex>
      <TextInput
        placeholder={t('authentication.form.errors.captchaRequired')}
        className="flex-1"
        size="md"
        {...form.getInputProps('captcha')}
      />
      <CaptchaSender
        size="md"
        identifierExistedErrorHandler={identifierExistedErrorHandler}
        identifierNotExistedErrorHandler={identifierNotExistedErrorHandler}
        onSend={onCaptchaSend}
        disabled={!form.values.identifier || !form.isValid('identifier')}
        form={form as UseFormReturnType<AuthenticationFormValues | SignupFormValues>}
        captchaType={captchaType}
        ml="sm"
        fullWidth={false}
      />
    </Flex>
  )
}

export default CaptchaCodeSendAndInput
