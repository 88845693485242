import React from 'react'
import { Stack } from '@mantine/core'

import Tasks from '../Tasks'
import OnboardingTitle from './Title'

type Props = {
  onSkip: () => void
}

const OnboardingContent = (props: Props) => {
  const { onSkip } = props

  return (
    <Stack w={360} m="-xs" spacing={0}>
      <OnboardingTitle onSkip={onSkip} />
      <Tasks />
    </Stack>
  )
}

export default OnboardingContent
