import React, { FC } from 'react'
import { ActionIcon } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import { useConditionGroup } from '../ConditionGroup'

type Props = {
  index: number
}

const RemoveCondition: FC<Props> = ({ index }) => {
  const { dispatch } = useConditionGroup()

  const handleRemoveClick = () => {
    dispatch({
      type: 'removeCondition',
      index: index,
    })
  }

  return (
    <ActionIcon onClick={handleRemoveClick}>
      <IconTrash size={16} />
    </ActionIcon>
  )
}

export default RemoveCondition
