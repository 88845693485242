import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Box } from '@mantine/core'
import { AddButton } from 'components/ui/Button'

type Props = {
  onAdd?: () => void
  className?: string
}

const ConditionAdder: FC<Props> = ({ className, onAdd }) => {
  const { t } = useTranslation('componentEntryFilter')

  return (
    <Box className={className}>
      <AddButton onClick={onAdd} text={t('add')} />
    </Box>
  )
}

export default ConditionAdder
