import React, { FC, PropsWithChildren } from 'react'
import Image from 'next/image'
import { Box, Flex, Group, Stack } from '@mantine/core'
import { useCrisp } from 'plugins/crisp'
import avatar from './avatar.png'
import useDarkMode from 'components/ui/Chart/useDarkMode'
import { getBackgroundColor, getBorderColor } from 'config/themeOverride/getColor'

const SdkOnboarding: FC<PropsWithChildren> = ({ children }) => {
  useCrisp()
  const darkMode = useDarkMode()

  return (
    <Flex
      justify="center"
      align="flex-end"
      sx={() => ({
        position: 'fixed',
        bottom: 0,
        left: '252px',
        width: '100%',
        height: '405px',
        background: darkMode
          ? 'linear-gradient(0deg, #25262B 0%, rgba(255, 255, 255, 0) 104.44%)'
          : 'linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 104.44%)',
      })}
    >
      <Box
        px="2xl"
        mb="md"
        sx={(theme) => ({
          background: getBackgroundColor('black', theme, 'dark.8'),
          width: 490,
          height: 68,
          border: '1px solid',
          borderColor: getBorderColor('white', theme, 'dark.4'),
          boxShadow: theme.shadows.xs,
          borderRadius: '56px',
          transform: 'translateX(-25%)',
        })}
      >
        <Group spacing="lg">
          <Image src={avatar.src} alt="avatar" width={66} height={66} />
          <Stack spacing="xs">{children}</Stack>
        </Group>
      </Box>
    </Flex>
  )
}

export default SdkOnboarding
