export { default as IdentifierInput } from './components/IdentifierInput'
export { default as CaptchaSender } from './components/CaptchaSender'
export { default as PasswordInputStep } from './components/PasswordInputStep'
export { default as useFormValidations } from './hooks/useFormValidations'
export { default as CaptchaCodeSendAndInput } from './components/CaptchaCodeSendAndInput'

export interface AuthenticationFormValues {
  identifier: string
  password: string
  confirmPassword: string
  captcha: string
}
