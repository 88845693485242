import { CreateCampaignWebhookInput, UpdateCampaignWebhookInput, WebhookChannel } from 'types/domain'
import { WebhookBuilderFormDataState } from '../type'
import convertToValidConditionGroup from 'components/business/Campaign/Filter/utils/convertToValidConditionGroup'

const targetsSupportChannels = [WebhookChannel.QiyeWx, WebhookChannel.Dingtalk, WebhookChannel.Feishu]

export function isChannelSupportTargets(channel?: WebhookChannel) {
  return channel && targetsSupportChannels.includes(channel)
}

export function getCreateWebhookInput(data: WebhookBuilderFormDataState): CreateCampaignWebhookInput {
  const { campaignId, targets, conditionGroup, ...rest } = data
  return {
    campaignId: campaignId!,
    targets: isChannelSupportTargets(data.channel) ? targets : undefined,
    conditionGroup: conditionGroup ? convertToValidConditionGroup(conditionGroup) : undefined,
    ...rest,
  }
}

export function getUpdateWebhookInput(data: WebhookBuilderFormDataState): UpdateCampaignWebhookInput {
  const { id, targets, campaignId, conditionGroup, ...rest } = data
  return {
    id: id!,
    targets: isChannelSupportTargets(data.channel) ? targets : null,
    conditionGroup: conditionGroup ? convertToValidConditionGroup(conditionGroup) : null,
    ...rest,
  }
}
