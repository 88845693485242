import { Group, Title, useMantineTheme } from '@mantine/core'
import { getTextColor } from 'config/themeOverride/getColor'
import useTranslation from 'next-translate/useTranslation'
import cls from 'classnames'

type Props = {
  className?: string
}

const Header = ({ className }: Props) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('componentEntryFilter')
  return (
    <Group position="apart" className={cls('w-full', className)} mb="md">
      <Title order={5} color={getTextColor('gray.9', theme)}>
        {t('title')}
      </Title>
    </Group>
  )
}

export default Header
