import React, { ReactNode } from 'react'
import { CampaignActionProps, CampaignContextProps } from './type'
import { CampaignActionContext, CampaignContext } from './CampaignContext'

type CampaignProviderProps = {
  children: ReactNode
  defaultValue: CampaignContextProps
  actions: CampaignActionProps
}

export default function CampaignProvider(props: CampaignProviderProps) {
  const { defaultValue, actions, children } = props

  return (
    <CampaignActionContext.Provider value={actions}>
      <CampaignContext.Provider value={defaultValue}>{children}</CampaignContext.Provider>
    </CampaignActionContext.Provider>
  )
}
