import { ConditionGroupInput, ConditionInput, ConditionOperator } from 'types/domain'
import update from 'immutability-helper'
import { Mine } from 'components/business/Filter/DefaultFilter/Conditions/Operator/getOperatorOptions'
import { codePrefix } from 'config'
import { commentedCode } from 'config/constants/fieldType'
import { CUSTOMER_EMAIL, CUSTOMER_MOBILE, CUSTOMER_NAME, CUSTOMER_UID } from 'config/constants'

const customerCodes = [CUSTOMER_UID, CUSTOMER_MOBILE, CUSTOMER_EMAIL, CUSTOMER_NAME]
const dateOperator = [
  ConditionOperator.DateEq,
  ConditionOperator.DateNe,
  ConditionOperator.Before,
  ConditionOperator.After,
  ConditionOperator.DateBetween,
  ConditionOperator.DateNotBetween,
]
const isNonDatetimeCustomerOrSceneCondition = (condition: ConditionInput) =>
  !customerCodes.includes(condition.firstNode.value) &&
  (condition.firstNode.value.startsWith(codePrefix.scene) ||
    condition.firstNode.value.startsWith(codePrefix.customer)) &&
  !dateOperator.includes(condition.operator)

// 这个函数是将 report、entries 中的FilterPopover组件的值转换成请求后端需要的conditionGroup
export default function convertToValidConditionGroup(conditionGroup: ConditionGroupInput): ConditionGroupInput {
  return update(conditionGroup, {
    conditions: {
      $set: conditionGroup.conditions.map((condition) => {
        if (condition.operator === (Mine as never)) {
          return {
            ...condition,
            operator: ConditionOperator.Eq,
            secondNode: {
              value: Mine,
            },
          }
        }

        if (
          isNonDatetimeCustomerOrSceneCondition(condition) &&
          condition.operator !== ConditionOperator.NumberBetween
        ) {
          return {
            ...condition,
            secondNode: {
              ...condition.secondNode,
              value: Array.isArray(condition.secondNode?.value)
                ? condition.secondNode?.value[0]
                : condition.secondNode?.value,
            },
          }
        }

        return condition
      }),
    },
  })
}

// 这个函数是将后端保存conditionGroup转换成FilterPopover需要的值，与上面的函数作用刚好相反
export function convertToFieldFilterPopoverValue(value: ConditionGroupInput | undefined | null) {
  if (!value) {
    return undefined
  }
  return update(value, {
    conditions: {
      $set: value.conditions.map((condition) => {
        if (condition.firstNode.value === commentedCode && condition.secondNode?.value === (Mine as never)) {
          return {
            firstNode: { value: commentedCode },
            operator: Mine as ConditionOperator,
            secondNode: { value: '' },
          }
        }

        if (
          isNonDatetimeCustomerOrSceneCondition(condition) &&
          condition.operator !== ConditionOperator.NumberBetween
        ) {
          return {
            ...condition,
            secondNode: {
              ...condition.secondNode,
              value: Array.isArray(condition.secondNode?.value)
                ? condition.secondNode?.value
                : [condition.secondNode?.value],
            },
          }
        }

        return condition
      }),
    },
  })
}
