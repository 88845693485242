import { useContext } from 'react'
import { ConditionGroupContext } from './ConditionGroupContext'

export default function useConditionGroup() {
  const context = useContext(ConditionGroupContext)
  if (context === undefined) {
    throw new Error('useConditionGroup must be used within a ConditionGroupProvider')
  }
  return context
}
