import CustomerSegments from 'components/business/Customer/CustomerSegments'
import React from 'react'
import { Stack } from '@mantine/core'
import { CampaignType, CustomApplicationEvent, Entry, Field } from 'types/domain'
import UserInfo from 'components/business/Campaign/EntryModal/Content/components/UserInfo'
import DeviceInfo from 'components/business/Campaign/EntryModal/Content/components/DeviceInfo'
import Divider from 'components/ui/Divider'

type Props = {
  campaignType: CampaignType
  entry: Entry
  customerFields: Field[]
  customEvents?: CustomApplicationEvent[]
  manageable: boolean
}

const SurveyBaseInfo = (props: Props) => {
  const { campaignType, entry, customerFields, customEvents, manageable } = props

  return (
    <Stack spacing={0}>
      {entry.customerSnapshot?.customerId && (
        <>
          <UserInfo customer={entry.customerSnapshot} customerFields={customerFields} />
          <Divider mt="xl" pb="lg" />
          <CustomerSegments id={entry.customerSnapshot?.customerId} manageable={manageable} />
          <Divider mt="xl" pb="lg" />
        </>
      )}
      <DeviceInfo entry={entry} campaignType={campaignType} customEvents={customEvents} />
    </Stack>
  )
}

export default SurveyBaseInfo
