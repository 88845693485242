import React, { FC, useMemo } from 'react'
import cls from 'classnames'
import { Box, Text, useMantineTheme } from '@mantine/core'
import { isNil } from 'lodash-es'

import { getTextColor } from 'config/themeOverride/getColor'
import { Icon } from 'components/ui'
import { FormFieldType } from 'types/domain'
import { NEXT_FIELD } from 'config/constants'
import { formFieldIconMapping } from 'utils/fieldIcon'

type Props = {
  type: FormFieldType | typeof NEXT_FIELD
  index?: number
  className?: string
  compact?: boolean
}

const FieldIcon: FC<Props> = ({ type, index, compact = false, className }) => {
  const theme = useMantineTheme()
  const { iconName, bg, iconColor } = useMemo(() => {
    return formFieldIconMapping(theme)[type]
  }, [type])

  return (
    <Box
      className={cls(
        'p-xs flex items-center h-xl rounded-sm flex-shrink-0 ',
        {
          'min-w-2xl': !compact,
        },
        className
      )}
      data-testid={type}
      style={{ background: bg }}
    >
      <Icon name={iconName} style={{ color: iconColor }} />
      {!isNil(index) && (
        <Text className="ml-[6px]" size="xs" color={getTextColor('gray.9', theme, 'dark.5')}>
          {index}
        </Text>
      )}
    </Box>
  )
}

export default FieldIcon
