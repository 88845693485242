import React, { Ref } from 'react'
import { Box } from '@mantine/core'
import { SurveyTemplatePreview } from 'components/business'
import { SurveyTemplatePreviewApi } from 'components/business/SurveyTemplatePreview'
import { DistributionChannelGroup } from 'config/constants'

import { CampaignTemplate } from 'types/domain'
import DesktopPreview from '../PlatformPreview/DesktopPreview'

type Props = {
  template?: CampaignTemplate
  channel: DistributionChannelGroup
  previewRef?: Ref<SurveyTemplatePreviewApi>
}

const Web = ({ template, channel, previewRef }: Props) => {
  return (
    <Box className="rounded-[12px] w-full h-full">
      <DesktopPreview>
        {template && (
          <SurveyTemplatePreview
            channel={channel}
            platform="desktop"
            token={template.code}
            fields={template?.form?.fields!}
            formSetting={template.form?.setting!}
            widget={template?.widget}
            ref={previewRef}
          />
        )}
      </DesktopPreview>
    </Box>
  )
}

export default Web
