import { closeModal, openModal } from '@mantine/modals'
import { Anchor, Button, Group, Stack, Text, TextInput, useMantineTheme } from '@mantine/core'
import React, { useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { isNotEmpty, useForm } from '@mantine/form'
import getDefaultLocaleWords from 'components/business/CampaignBuilder/StyleSetting/Locale/utils/getDefaultLocaleWords'
import { Application, CampaignLocaleSettingInput, CampaignType, WordingsInput } from 'types/domain'
import { TextInputProps } from '@mantine/core/lib/TextInput/TextInput'
import { getTextColor } from 'config/themeOverride/getColor'
import { hideNextButton, hideOtherChoicePlaceholder, hideScreenshot } from './utils/wordingInputAvailable'
import { calculateInitialValues } from './utils/calculateInitialValues'

const wordingModalId = 'wordingModalId'
type Props = {
  localeSetting: CampaignLocaleSettingInput
  onConfirm: (wordings: WordingsInput) => void
  application: Application
  campaignType: CampaignType
}

const FormTextInput = (props: TextInputProps) => {
  const theme = useMantineTheme()
  const { label, ...others } = props
  return (
    <Group noWrap>
      <Text color={getTextColor('gray.9', theme)} sx={{ whiteSpace: 'nowrap' }} w={120} weight={600}>
        {label}
      </Text>
      <TextInput {...others} size="sm" className="w-full" />
    </Group>
  )
}

const ModalContent = ({ localeSetting, application, campaignType, onConfirm }: Props) => {
  const { t } = useTranslation('componentCampaignBuilder')
  const defaultLocaleWords = useMemo(() => getDefaultLocaleWords(localeSetting?.locale), [localeSetting?.locale])
  const initialValues = useMemo(() => {
    return calculateInitialValues(defaultLocaleWords, localeSetting)
  }, [defaultLocaleWords, localeSetting])

  const form = useForm({
    initialValues,
    validateInputOnBlur: true,
    validate: {
      nextButton: isNotEmpty(t('common:message.valueRequired')),
      submitButton: isNotEmpty(t('common:message.valueRequired')),
      otherChoicePlaceholder: isNotEmpty(t('common:message.valueRequired')),
      screenshotDescription: {
        screenshot: isNotEmpty(t('common:message.valueRequired')),
        captured: isNotEmpty(t('common:message.valueRequired')),
      },
    },
  })

  const handleSubmit = async () => {
    onConfirm(form.getTransformedValues())
    closeModal(wordingModalId)
  }

  const resetToDefault = () => {
    form.setValues(defaultLocaleWords)
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack spacing="xl">
        <Text size="sm" color="dimmed">
          {t('styles.locale.form.desc')}
        </Text>
        <Stack spacing="sm">
          {!hideNextButton(campaignType) && (
            <FormTextInput label={t('styles.locale.form.nextButton')} {...form.getInputProps('nextButton')} />
          )}
          <FormTextInput label={t('styles.locale.form.submitButton')} {...form.getInputProps('submitButton')} />
          {!hideOtherChoicePlaceholder(campaignType) && (
            <FormTextInput
              label={t('styles.locale.form.otherChoicePlaceholder')}
              {...form.getInputProps('otherChoicePlaceholder')}
            />
          )}
          {!hideScreenshot(application) && (
            <>
              <FormTextInput
                label={t('styles.locale.form.screenshotDescription.screenshot')}
                {...form.getInputProps('screenshotDescription.screenshot')}
              />
              <FormTextInput
                label={t('styles.locale.form.screenshotDescription.captured')}
                {...form.getInputProps('screenshotDescription.captured')}
              />
            </>
          )}
        </Stack>
        <Group position="apart">
          <Group spacing="xs">
            <Anchor onClick={() => resetToDefault()}>恢复默认</Anchor>|
            <Anchor onClick={() => form.reset()}>重置修改</Anchor>
          </Group>
          <Group position="right">
            <Button variant="default" onClick={() => closeModal(wordingModalId)}>
              {t('common:actions.cancel')}
            </Button>
            <Button type="submit">{t('common:actions.confirm')}</Button>
          </Group>
        </Group>
      </Stack>
    </form>
  )
}

export default function useWordingsModal({ localeSetting, application, campaignType, onConfirm }: Props) {
  const { t } = useTranslation('componentCampaignBuilder')

  return () => {
    openModal({
      modalId: wordingModalId,
      closeOnClickOutside: false,
      closeOnEscape: false,
      title: (
        <Text size="md" weight={600}>
          {t('styles.locale.form.title')}
        </Text>
      ),
      children: (
        <ModalContent
          localeSetting={localeSetting}
          application={application}
          campaignType={campaignType}
          onConfirm={onConfirm}
        />
      ),
      zIndex: 1000,
      centered: true,
      styles: {
        header: {
          marginBottom: 0,
        },
      },
    })
  }
}
