import { eventHowxm } from 'howxm-js'

type HowxmEvent = {
  code: string
  attrs?: Record<string, string | number | Date | boolean>
}

export default function useHowxmEvent() {
  const clickToPay = (
    feature?: string,
    otherAttrs?: Record<string, string | number | Date | boolean>,
    callback?: () => void
  ) => {
    eventHowxm('click_to_pay', feature ? { feature, ...otherAttrs } : otherAttrs, callback)
  }

  const actionEvent = ({ code, attrs }: HowxmEvent) => {
    eventHowxm(code, attrs)
  }

  return {
    clickToPay,
    actionEvent,
  }
}
