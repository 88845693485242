import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Text } from '@mantine/core'
import { closeModal, openModal } from '@mantine/modals'

import Content from './Content'
import { AddToSegmentsProps } from './type'

const modalId = 'addToSegment'

export const closeAddToSegmentModal = () => {
  closeModal(modalId)
}

export default function useAddToSegments() {
  const { t } = useTranslation('pageCustomerSegment')

  const open = (props: AddToSegmentsProps) => {
    openModal({
      modalId,
      closeOnEscape: false,
      closeOnClickOutside: false,
      size: 500,
      title: (
        <Text weight={600} size="md">
          {t('addToSegment.title')}
        </Text>
      ),
      children: <Content {...props} onClose={closeAddToSegmentModal} />,
    })
  }

  return {
    open,
  }
}
