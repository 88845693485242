import { Progress, Stack, Text, useMantineTheme } from '@mantine/core'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import React from 'react'
import { formatNumberToThousands } from 'utils'

interface Props {
  vasBalance: any
}

const FreeSubscription = ({ vasBalance }: Props) => {
  const theme = useMantineTheme()
  const entriesTotalCount = vasBalance['ENTRIES_COUNT'].total
  const entriesUsageCount = entriesTotalCount - vasBalance['ENTRIES_COUNT'].balance
  const usagePercentage = (entriesUsageCount / entriesTotalCount) * 100

  return (
    <>
      {usagePercentage >= 90 && (
        <Stack spacing="xs">
          <Progress size="sm" value={usagePercentage} color={getBackgroundColor('red.6', theme)} />
          <Text size="xs" color="dimmed">{`${formatNumberToThousands(entriesUsageCount)}/${entriesTotalCount}`}</Text>
        </Stack>
      )}
    </>
  )
}

export default FreeSubscription
