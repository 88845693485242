import React, { FC, useEffect, useState, ChangeEvent } from 'react'
import { Group, NumberInput, TextInput, MultiSelect, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { ValueNodeProps } from './type'
import { IconMinus } from '@tabler/icons-react'
import { getTextColor } from 'config/themeOverride/getColor'
import { useDebouncedValue } from '@mantine/hooks'
import { debouncedWaitTime, filterSelectComponentDropDownStyle } from '../../constants'
import useBetweenState from './hooks/useBetweenState'
import { ConditionOperator } from 'types/domain'
import { isNumber, isString, isEmpty, castArray } from 'lodash-es'
import useCustomerOrSceneOptions from './hooks/useCustomerOrSceneOptions'

const CustomerString: FC<ValueNodeProps> = ({ condition, onChange, readOnly }) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('common')
  const { operator } = condition
  const isNumberCompareOperator = [
    ConditionOperator.Gt,
    ConditionOperator.Gte,
    ConditionOperator.Lt,
    ConditionOperator.Lte,
  ].includes(operator)
  const isEquivalentOperator = [ConditionOperator.Ne, ConditionOperator.Eq].includes(operator)
  const isContainOperator = [ConditionOperator.Contains, ConditionOperator.NotContains].includes(operator)
  const defaultValue = isNumberCompareOperator ? undefined : ''
  const [value, setValue] = useState<any>(condition.secondNode?.value || defaultValue)
  const [debouncedValue] = useDebouncedValue(value, debouncedWaitTime)
  const { isBetween, maxValue, minValue, setBetweenValue } = useBetweenState<number | '' | undefined>(
    condition,
    onChange
  )

  const optionValues = useCustomerOrSceneOptions(condition)

  useEffect(() => {
    onChange?.(debouncedValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  const handleOnChange = (values: string[]) => {
    setValue(isEmpty(values) ? undefined : values)
  }
  const handleTextOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }
  const handleNumberOnChange = (value?: number | string) => {
    setValue(isNumber(value) ? `${value}` : undefined)
  }

  if (isBetween) {
    return (
      <Group noWrap spacing="sm">
        <NumberInput
          value={minValue}
          placeholder={t('placeholders.pleaseInput')}
          onChange={(value) => setBetweenValue([value!, maxValue])}
          readOnly={readOnly}
          precision={2}
          removeTrailingZeros
        />
        <IconMinus size={16} stroke={1.5} color={getTextColor('gray.6', theme)} />
        <NumberInput
          value={maxValue}
          placeholder={t('placeholders.pleaseInput')}
          onChange={(value) => setBetweenValue([minValue, value!])}
          readOnly={readOnly}
          precision={2}
          removeTrailingZeros
        />
      </Group>
    )
  }

  if (isNumberCompareOperator) {
    const firstValue = castArray(value)[0]
    const numberValue = isNumber(firstValue) ? firstValue : isString(firstValue) ? Number(firstValue) : undefined
    return (
      <NumberInput
        placeholder={t('placeholders.pleaseInput')}
        value={numberValue}
        onChange={handleNumberOnChange}
        readOnly={readOnly}
        precision={2}
        removeTrailingZeros
      />
    )
  }

  if (!!optionValues?.length) {
    return (
      <MultiSelect
        withinPortal
        searchable
        placeholder={t('placeholders.pleaseSelect')}
        data={optionValues}
        // value={value ? (isArray(value) ? value : [value]) : []}
        value={value ?? []}
        onChange={handleOnChange}
        maxSelectedValues={isEquivalentOperator || isContainOperator ? 1 : undefined}
        {...filterSelectComponentDropDownStyle}
        readOnly={readOnly}
      />
    )
  }
  return (
    <TextInput
      placeholder={t('placeholders.pleaseInput')}
      value={value || ''}
      onChange={handleTextOnChange}
      readOnly={readOnly}
    />
  )
}

export default CustomerString
