import update from 'immutability-helper'
import { defaultConditions } from '../constants'
import { UrlFilterConditionGroupType, UrlFilterConditionType } from '../type'
import { Action } from './type'

export default function conditionGroupReducer(state: UrlFilterConditionGroupType, action: Action) {
  switch (action.type) {
    case 'addCondition': {
      return {
        ...state,
        conditions: update(state.conditions, { $push: action.condition ? [action.condition] : defaultConditions }),
      }
    }
    case 'removeCondition': {
      return { ...state, conditions: update(state.conditions, { $splice: [[action.index, 1]] }) }
    }
    case 'conditionOperatorChange':
      return {
        ...state,
        conditions: update(state.conditions, {
          [action.index]: {
            $set: {
              ...state.conditions[action.index],
              operator: action.value,
              node: {
                value: undefined,
              },
            } as UrlFilterConditionType,
          },
        }),
      }
    case 'nodeChange':
      return {
        ...state,
        conditions: update(state.conditions, {
          [action.index]: {
            $set: {
              ...state.conditions[action.index],
              node: action.node,
            } as UrlFilterConditionType,
          },
        }),
      }
    default:
      return state
  }
}
