export enum Mode {
  ALL = 'all',
  SPECIALITY = 'speciality',
}

export const customerFilterConditionsPath = 'targeting.customerFilter.conditions'
export const defaultCustomerAttributesCodes = ['mobile', 'name', 'uid', 'email']

export const userSegmentCode = '$customerSegments'

export const allAttributeCodes = defaultCustomerAttributesCodes.concat(userSegmentCode)
