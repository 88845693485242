import React from 'react'
import { ActionIcon, Group, useMantineTheme } from '@mantine/core'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import { getBorderColor, getTextColor } from 'config/themeOverride/getColor'

export type ItemNavigatorProps = {
  previousDisabled?: boolean
  nextDisabled?: boolean
  onPrevious?: () => void
  onNext?: () => void
}

export const ItemNavigator = (props: ItemNavigatorProps) => {
  const theme = useMantineTheme()
  const { previousDisabled, nextDisabled, onPrevious, onNext } = props

  return (
    <Group spacing={0}>
      <ActionIcon
        disabled={previousDisabled}
        onClick={onPrevious}
        variant="outline"
        sx={{
          color: getTextColor('gray.7', theme),
          borderColor: getBorderColor('gray.2', theme),
          borderRightWidth: 0,
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          '&:focus': {
            outlineWidth: 0,
          },
        }}
      >
        <IconChevronUp size={16} />
      </ActionIcon>
      <ActionIcon
        disabled={nextDisabled}
        onClick={onNext}
        variant="outline"
        sx={{
          color: getTextColor('gray.7', theme),
          borderColor: getBorderColor('gray.2', theme),
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          '&:focus': {
            outlineWidth: 0,
          },
        }}
      >
        <IconChevronDown size={16} />
      </ActionIcon>
    </Group>
  )
}
