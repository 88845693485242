import React, { ReactNode } from 'react'
import { EntriesActionContext, EntriesContext } from './EntriesContext'
import { EntriesActionProps, EntriesContextProps } from './type'

type EntriesProviderProps = {
  children: ReactNode
  defaultValue: EntriesContextProps
  actions: EntriesActionProps
}

export default function EntriesProvider(props: EntriesProviderProps) {
  const { defaultValue, actions, children } = props

  return (
    <EntriesActionContext.Provider value={actions}>
      <EntriesContext.Provider value={defaultValue}>{children}</EntriesContext.Provider>
    </EntriesActionContext.Provider>
  )
}
