import { useRouter } from 'next/router'
import React, { FC, useRef, useState } from 'react'
import { Button, Group, Popover, Radio, Space, Stack, Text, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { getTextColor } from 'config/themeOverride/getColor'
import {
  CampaignType,
  ExportTargetType,
  SelectableFieldSplitter,
  useExportJobInfoSubscription,
  useExportMutation,
} from 'types/domain'
import { useClickOutside, useDisclosure } from '@mantine/hooks'
import { differenceInMilliseconds } from 'date-fns'
import { delay } from 'utils'
import BaseButton from './BaseButton'
import { useCampaignFilter } from 'components/business/Campaign/Filter/CampaignFilterContext'
import { useCampaign } from 'components/business/Campaign/CampaignContext'

type Props = {
  onDownload: (downloadUrl: string) => void
}

const shortestTime = 2000

const ExportButton: FC<Props> = ({ onDownload }) => {
  const { t } = useTranslation('entry')
  const theme = useMantineTheme()
  const router = useRouter()
  const [exportMutation] = useExportMutation()
  const { campaign } = useCampaign()
  const campaignId = router.query.campaignId as string
  const { filter: filterInput } = useCampaignFilter()
  const target = { targetId: campaignId, targetType: ExportTargetType.CampaignEntries }
  const [opened, { close, toggle }] = useDisclosure(false)

  const progressRef = useRef<number | undefined>(0)
  const [status, setStatus] = useState<'idle' | 'exporting'>('idle')
  const [fieldSplitter, setFieldSplitter] = useState<SelectableFieldSplitter>(SelectableFieldSplitter.SplitByColumn)
  const ref = useClickOutside(() => close())

  useExportJobInfoSubscription({
    skip: status !== 'exporting',
    variables: target,
    onData(data) {
      const downloadUrl = data.data.data?.exportJobInfo.resUrl
      progressRef.current = downloadUrl ? 100 : data.data.data?.exportJobInfo.progress
      if (downloadUrl) {
        downloadImmediatelyOrDelay(downloadUrl)
      }
    },
  })

  let startTime: Date = new Date()
  const downloadImmediatelyOrDelay = (downloadUrl: string) => {
    const differenceTime = differenceInMilliseconds(new Date(), startTime) - shortestTime
    if (differenceTime < 0) {
      // avoid download quickly make user experience is bad, so download process at least 2 seconds
      delay(Math.abs(differenceTime)).then(() => {
        onDownload(downloadUrl)
      })
    } else {
      onDownload(downloadUrl)
    }
    setStatus('idle')
    progressRef.current = 0
  }

  const handleStartClick = () => {
    close()
    setStatus('exporting')
    exportMutation({
      variables: { filter: filterInput, ...target, fieldSplitter: fieldSplitter },
    }).then()
  }

  const handleButtonClick = () => {
    if (campaign?.type === CampaignType.Feedback) {
      handleStartClick()
    } else {
      toggle()
    }
  }
  return (
    <Popover withinPortal opened={opened} position="bottom-start" zIndex={100}>
      <Popover.Target>
        <BaseButton disabled={status === 'exporting'} loading={status === 'exporting'} onClick={handleButtonClick}>
          {status === 'exporting' ? (
            <Text color={getTextColor('gray.9', theme)}>
              {t('export.downloading', { progress: progressRef.current })}
            </Text>
          ) : (
            t('export.label')
          )}
        </BaseButton>
      </Popover.Target>
      <Popover.Dropdown
        style={{
          boxShadow: theme.shadows.md,
        }}
      >
        <Stack p={10} align="flex-start" spacing="sm" ref={ref}>
          <Stack spacing="xs">
            <Text fw={600}>{t('export.setting.title')}</Text>
            <Text color={getTextColor('gray.6', theme)}>{t('export.setting.desc')}</Text>
          </Stack>
          <Stack>
            <Radio.Group
              label={<Text>{t('export.setting.radioGroupLabel')}</Text>}
              value={fieldSplitter}
              onChange={(v) => setFieldSplitter(v as SelectableFieldSplitter)}
              sx={{
                label: {
                  fontWeight: 'normal',
                },
              }}
            >
              <Group mt="xs">
                <Radio value={SelectableFieldSplitter.SplitByColumn} label={t('export.setting.splitByColumn')} />
                <Radio value={SelectableFieldSplitter.SplitByComma} label={t('export.setting.splitByComma')} />
              </Group>
            </Radio.Group>
          </Stack>
          <Space h="sm" />
          <Button onClick={handleStartClick}>{t('export.setting.startExport')}</Button>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}

export default ExportButton
