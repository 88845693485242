import React, { useRef } from 'react'
import { isEnter } from 'utils/keyboardUtils'
import { isBlank } from 'utils'

const useEnterBlurSelect = (onCallback?: (value: string) => void) => {
  const ref = useRef<HTMLInputElement>(null)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isEnter(event)) {
      return
    }
    updateCreateValue()
  }

  const updateCreateValue = () => {
    const value = ref.current?.value
    if (isBlank(value)) {
      return
    }
    onCallback && onCallback(value!.trim())
  }

  return {
    ref,
    handleKeyDown,
    handleBlur: updateCreateValue,
  }
}

export default useEnterBlurSelect
