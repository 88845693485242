import useCampaignBaseInfoCache from 'components/business/hooks/useCampaignBaseInfoCache'
import { message, openAlertModal } from 'components/ui'
import useTranslation from 'next-translate/useTranslation'
import { useUpdateCampaignMutation } from 'types/domain'

const useCampaignFormStatusSwitch = (id: string) => {
  const { t } = useTranslation('common')
  const { campaign } = useCampaignBaseInfoCache(id)
  const [updateMutation] = useUpdateCampaignMutation()

  const urlEnabled = campaign?.urlEnabled

  const sendRequest = async (callback?: () => void) => {
    const input = {
      id,
      urlEnabled: !urlEnabled,
    }
    updateMutation({ variables: { input } }).then(() => {
      message.successMsg(t('message.updateSuccess'))
      callback?.()
    })
  }
  const toggle = async (callback?: () => void) => {
    if (urlEnabled) {
      openAlertModal({
        title: t('common:tip.title'),
        content: t('componentCampaignList:statusSwitcher.confirmContent.link'),
        onConfirm: () => sendRequest(callback),
      })
    } else {
      await sendRequest(callback)
    }
  }
  return {
    toggle,
  }
}

export default useCampaignFormStatusSwitch
