import React, { ReactNode } from 'react'
import { Field, FormFieldType, RatingField } from 'types/domain'
import { Card, Text } from '@mantine/core'
import { htmlEncodeByRegExp } from 'utils'
import NumericCell from 'components/business/Campaign/Entries/components/EntriesTable/components/Cells/NumericCell'
import SelectableFieldCell from 'components/business/Campaign/Entries/components/EntriesTable/components/Cells/SelectableFieldCell'
import RatingCell from 'components/business/Campaign/Entries/components/EntriesTable/components/Cells/RatingCell'
import CsatFieldCell from 'components/business/Campaign/Entries/components/EntriesTable/components/Cells/CsatFieldCell'
import TextareaFieldRenderer from './TextAreaFieldRenderer'

type Props = {
  field: Field
  data: any
}

const getFieldComponent = (field: Field, data: any) => {
  const mapping: Record<
    Exclude<
      FormFieldType,
      | FormFieldType.EndingField
      | FormFieldType.WelcomeField
      | FormFieldType.ScreenshotField
      | FormFieldType.DescriptionField
    >,
    ReactNode
  > = {
    [FormFieldType.NpsField]: <NumericCell renderedCellValue={data[field.code]} />,
    [FormFieldType.CheckBox]: (
      <SelectableFieldCell renderedCellValue={data[field.code]} allowWrap choiceHeight="auto" />
    ),
    [FormFieldType.RadioButton]: <SelectableFieldCell renderedCellValue={data[field.code]} choiceHeight="auto" />,
    [FormFieldType.CtaField]: <SelectableFieldCell renderedCellValue={data[field.code]} choiceHeight="auto" />,
    [FormFieldType.RatingField]: (
      <RatingCell renderedCellValue={data[field.code]} max={(field as RatingField).ratingMax} />
    ),
    [FormFieldType.CsatField]: <CsatFieldCell renderedCellValue={data[field.code]} />,
    [FormFieldType.CesField]: <NumericCell renderedCellValue={data[field.code]} />,
    [FormFieldType.TextArea]: <TextareaFieldRenderer value={data[field.code]} />,
    [FormFieldType.TextField]: (
      <Text className="break-all">
        <div dangerouslySetInnerHTML={{ __html: htmlEncodeByRegExp(data[field.code]) }} />
      </Text>
    ),
  }
  return mapping[field.type as FormFieldType.RatingField]
}

const getFieldRender = ({ data, field }: Props) => {
  return (
    <Card withBorder p="sm" mih="40px" mah="320px" className="overflow-y-auto">
      {getFieldComponent(field, data)}
    </Card>
  )
}

export default getFieldRender
