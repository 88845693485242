import useTranslation from 'next-translate/useTranslation'
import { Button, Stack, useMantineTheme } from '@mantine/core'
import { getDefaultBorderColor } from 'config/themeOverride/getColor'
import { orderedWebhookChannels, WebhookChannelIcon } from 'components/business'
import { useWebhookBuilderFormContext } from '../../../formContext'

const Channels = () => {
  const { t } = useTranslation('integration')
  const form = useWebhookBuilderFormContext()
  const theme = useMantineTheme()

  const borderColor = getDefaultBorderColor(theme)

  return (
    <Stack align="flex-start" spacing="sm">
      {orderedWebhookChannels.map((channel) => (
        <Button
          key={channel}
          variant="default"
          radius="xl"
          leftIcon={<WebhookChannelIcon channel={channel} size={18} />}
          styles={{
            root: { borderColor, fontWeight: 400, height: 32 },
            leftIcon: {
              marginRight: theme.spacing.sm,
            },
          }}
          onClick={() => form.setFieldValue('channel', channel)}
        >
          {t(`webhook.builder.stepAction.${channel}.label`)}
        </Button>
      ))}
    </Stack>
  )
}

export default Channels
