import React from 'react'
import { useMantineTheme } from '@mantine/core'
import { getTextColor } from 'config/themeOverride/getColor'
import { Icon } from 'components/ui'

const VipIcon = () => {
  const theme = useMantineTheme()

  return <Icon name="vip" style={{ color: getTextColor('gray.5', theme) }} />
}

export default VipIcon
