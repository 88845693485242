import React from 'react'
import { HiddenColumnsProps } from 'components/business/HiddenColumns/type'
import { Group, Popover, Text, useMantineTheme } from '@mantine/core'
import { useClickOutside, useDisclosure, useWindowEvent } from '@mantine/hooks'
import { IconContainer } from '@tabler/icons-react'
import useTranslation from 'next-translate/useTranslation'

import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'
import HiddenColumnContent from './HiddenColumnContent'

const HiddenColumns = (props: HiddenColumnsProps) => {
  const { t } = useTranslation('common')
  const theme = useMantineTheme()
  const [opened, { close, open }] = useDisclosure(false)

  const ref = useClickOutside(() => close())

  useWindowEvent('resize', close)

  return (
    <Popover width={280} withinPortal position="bottom-start" opened={opened}>
      <Popover.Target>
        <Group
          h={30}
          spacing="xs"
          px="sm"
          onClick={open}
          noWrap
          sx={(theme) => ({
            borderRadius: theme.radius.xs,
            cursor: 'pointer',
            '&:hover': {
              background: getBackgroundColor('gray.1', theme),
            },
            '&:active': {
              transform: 'translateY(1px)',
            },
          })}
        >
          <IconContainer size={18} stroke={1.5} color={getTextColor('gray.9', theme)} />
          <Text className="select-none">{t('actions.hideField')}</Text>
        </Group>
      </Popover.Target>
      <Popover.Dropdown
        p={0}
        sx={(theme) => ({
          boxShadow: theme.shadows.md,
        })}
      >
        <HiddenColumnContent ref={ref} {...props} />
      </Popover.Dropdown>
    </Popover>
  )
}

export default HiddenColumns
