import { createContext } from 'react'
import { ConditionType } from 'components/business'

export interface ConditionContextProps {
  index: number
  condition: ConditionType
}

const ConditionContext = createContext<ConditionContextProps>({
  index: 0,
  condition: {
    firstNode: {
      value: undefined,
      type: undefined,
    },
    operator: undefined,
    secondNode: undefined,
  },
})

export default ConditionContext
