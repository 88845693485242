import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import Task from './Task'
import useOnboardingCache from 'components/business/hooks/useOnboardingCache'

const EntryCollectedTask = () => {
  const { t } = useTranslation('componentAppOnboarding')
  const { onboarding } = useOnboardingCache()

  return (
    <Task
      value="entryCollected"
      checked={!!onboarding?.entryCollected}
      title={t('entries.title')}
      descriptions={t('entries.descriptions', {}, { returnObjects: true })}
    />
  )
}

export default EntryCollectedTask
