import React, { FC } from 'react'
import Link from 'next/link'
import { UrlObject } from 'url'
import { ActionIcon, ActionIconProps, useMantineTheme } from '@mantine/core'
import { IconChevronLeft, TablerIconsProps } from '@tabler/icons-react'

import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'

type Props = {
  href?: string | UrlObject
  onClick?: () => void
  actionIconProps?: ActionIconProps
  iconProps?: TablerIconsProps
}

const BackButton: FC<Props> = (props) => {
  const { href, actionIconProps, iconProps, onClick } = props
  const theme = useMantineTheme()

  return (
    <>
      {href ? (
        <Link href={href} passHref legacyBehavior>
          <ActionIcon
            component="a"
            {...actionIconProps}
            sx={{
              ':hover': {
                backgroundColor: getBackgroundColor('gray.1', theme),
              },
            }}
          >
            <IconChevronLeft
              data-test-id="page-back"
              size={18}
              stroke={2}
              color={getTextColor('gray.9', theme)}
              {...iconProps}
            />
          </ActionIcon>
        </Link>
      ) : (
        <ActionIcon
          component="a"
          {...actionIconProps}
          onClick={onClick}
          sx={{
            ':hover': {
              backgroundColor: getBackgroundColor('gray.1', theme),
            },
          }}
        >
          <IconChevronLeft
            data-test-id="page-back"
            size={18}
            stroke={2}
            color={getTextColor('gray.9', theme)}
            {...iconProps}
          />
        </ActionIcon>
      )}
    </>
  )
}

export default BackButton
