import React, { useRef } from 'react'
import { Paper, Stack } from '@mantine/core'

import { CampaignFilterProvider } from 'components/business/Campaign/Filter/CampaignFilterContext'
import { CampaignEntriesProps } from 'components/business/Campaign/Layout/type'
import { ConditionGroupInput } from 'types/domain'

import { EntriesProvider } from './EntriesContext'
import useColumns from './hooks/useColumns'
import useHiddenColumns from './hooks/useHiddenColumns'
import useOrderedColumns from './hooks/useOrderedColumns'
import useRowSelection from './hooks/useRowSelection'

import HandlerBar from './components/HandleBar'
import EntriesTable from './components/EntriesTable'
import Divider from 'components/ui/Divider'

const CampaignEntries = (props: CampaignEntriesProps) => {
  const tableRef = useRef(null)
  const { userCampaignSetting, formFields } = props
  const columns = useColumns(formFields)
  const { rowSelection, selectedRowsCount, onRowSelectionChange } = useRowSelection()
  const { columnOrder, onColumnOrderChange } = useOrderedColumns(columns, userCampaignSetting?.orderedFields)
  const { hiddenColumns, columnVisibility, onHiddenColumnChange } = useHiddenColumns(
    userCampaignSetting?.hiddenFields,
    columns.map((column) => column.id as string)
  )

  return (
    <Paper radius={0} h="100%" className="rounded-t">
      <Stack spacing={0} h="100%">
        <CampaignFilterProvider
          defaultValue={{
            filter: userCampaignSetting?.entriesFilter as ConditionGroupInput,
            key: 'entriesFilter',
          }}
        >
          <EntriesProvider
            defaultValue={{
              hiddenColumns,
              columnVisibility,
              columnOrder,
              formFields,
              columns,
              rowSelection,
              selectedRowsCount,
            }}
            actions={{
              onHiddenColumnChange,
              onColumnOrderChange,
              onRowSelectionChange,
            }}
          >
            <HandlerBar tableRef={tableRef} />
            <Divider />
            <EntriesTable ref={tableRef} />
          </EntriesProvider>
        </CampaignFilterProvider>
      </Stack>
    </Paper>
  )
}

export default CampaignEntries
