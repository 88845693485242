import { useRouter } from 'next/router'
import React from 'react'
import { Image, List, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'

import { AnchorLink } from 'components/ui'
import { editPathMapping } from 'config/constants/campaignPathMapping'
import { getTextColor } from 'config/themeOverride/getColor'
import { CampaignWidgetType } from 'types/domain'
import { getXFormUrl } from 'utils/getXFormUrl'
import { useCampaign } from '../../CampaignContext'
import entriesEmptyImg from './entriesEmpty.png'

const Blank = () => {
  const theme = useMantineTheme()
  const { t } = useTranslation('layoutCampaign')
  const { campaignTypeName, campaign } = useCampaign()
  const url = getXFormUrl(campaign?.token!, campaign?.type!, true)
  const router = useRouter()
  const { appId, campaignId } = router.query

  return (
    <Stack spacing={0} align="center" mt={80}>
      <Image src={entriesEmptyImg.src} width={48} height={48} alt="no entries" />
      <Title mt="md" order={5}>
        {t('blank.title')}
      </Title>
      <List mt="sm">
        <List.Item>
          <Text ta="left" color={getTextColor('gray.7', theme)}>
            <Trans
              i18nKey="layoutCampaign:blank.description1"
              values={{ campaignTypeName }}
              components={{
                distribution: (
                  <AnchorLink
                    href={{
                      pathname: editPathMapping[campaign?.type!],
                      query: { appId: appId, campaignId: campaignId, step: 'distribution' },
                    }}
                  />
                ),
              }}
            />
          </Text>
        </List.Item>
        {campaign?.widget.type !== CampaignWidgetType.Banner && (
          <List.Item>
            <Text ta="left" color={getTextColor('gray.7', theme)}>
              <Trans
                i18nKey="layoutCampaign:blank.description2"
                values={{ campaignTypeName }}
                components={{
                  link: <AnchorLink target="blank" href={url} />,
                }}
              />
            </Text>
          </List.Item>
        )}
        <List.Item>
          <Text ta="left" color={getTextColor('gray.7', theme)}>
            {t('blank.description3')}
          </Text>
        </List.Item>
      </List>
    </Stack>
  )
}

export default Blank
