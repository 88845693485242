import { useRouter } from 'next/router'
import { ApplicationSceneAttributeValuesDocument } from 'types/domain'
import { PAGE_SIZE } from 'config/constants'
import { initializeApollo } from 'plugins/apollo'

export default function useGetApplicationSceneAttributeValues() {
  const router = useRouter()
  const applicationId = router.query.appId as string

  return async (idOrAliasOrCode: string, keyword?: string) => {
    const { data } = await initializeApollo().query({
      query: ApplicationSceneAttributeValuesDocument,
      fetchPolicy: 'network-only',
      variables: {
        idOrAliasOrCode,
        applicationId,
        keyword,
        pageArguments: {
          first: PAGE_SIZE.UN_LIMIT,
        },
      },
    })
    return data?.applicationSceneAttribute.values.nodes ?? []
  }
}
