import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react'
import { indexOf, minBy } from 'lodash-es'
import { Tabs } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { orderedCategories } from '../constant'
import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'
import { CampaignTemplateCategory } from 'types/domain'

type Props = {
  autoSelected?: boolean
  onChange: (category: CampaignTemplateCategory, scrollOptions: ScrollToOptions) => void
}

type CategoriesApi = {
  onScroll: (top?: number) => void
}

const Categories = forwardRef<CategoriesApi, Props>((props, ref) => {
  const { t } = useTranslation('pageTemplate')
  const { autoSelected = false, onChange } = props
  const [activeTab, setActiveTab] = useState<CampaignTemplateCategory>(orderedCategories[0])

  const handleScroll = (top?: number) => {
    const tabElements = document.querySelectorAll('.categories')
    const tabPositions = Array.from(tabElements).map((el) => el.getBoundingClientRect().top)

    const closestTop: number = top ?? 100
    let closestTab = orderedCategories.indexOf(activeTab)

    if (tabPositions.some((item) => item > 0)) {
      for (let i = 0; i < tabPositions.length; i++) {
        if (Math.abs(tabPositions[i] - closestTop) < closestTop) {
          closestTab = i
        }
      }
    } else {
      closestTab = tabPositions.length - 1
    }
    setActiveTab(orderedCategories[closestTab])
  }

  useEffect(() => {
    if (autoSelected) {
      const tabs = Array.from(document.querySelectorAll('.categories')) as HTMLElement[]
      const tabOffsets = tabs.map((el) => el.offsetTop)
      const scrollPosition = document.documentElement.scrollTop
      const index = indexOf(
        tabOffsets,
        minBy(tabOffsets, (item) => Math.abs(item - scrollPosition))
      )
      setActiveTab(index === -1 ? orderedCategories[0] : orderedCategories[index])
    }
  }, [autoSelected])

  useImperativeHandle(ref, () => ({
    onScroll: handleScroll,
  }))

  const handleTabChange = (category: CampaignTemplateCategory) => {
    setActiveTab(category)
    const offsetTop = document.getElementById(category)?.offsetTop!
    const scrollOptions: ScrollToOptions = { top: offsetTop, behavior: 'smooth' }
    onChange(category, scrollOptions)
  }

  return (
    <Tabs
      variant="pills"
      value={activeTab}
      onTabChange={handleTabChange}
      classNames={{ tabsList: 'justify-center sm:justify-start' }}
      styles={(theme) => {
        const backgroundColor = getBackgroundColor('brandBlue.0', theme)
        return {
          tabsList: {
            gap: 24,
          },
          tab: {
            padding: '6px 12px',
            fontWeight: 400,
            fontSize: 14,
            '&:hover': {
              backgroundColor,
            },
            '&[data-active="true"]': {
              fontWeight: 400,
              '&:hover': {
                backgroundColor,
              },
              backgroundColor,
              color: getTextColor('brandBlue.7', theme, 'brandBlue.4'),
            },
          },
        }
      }}
    >
      <Tabs.List>
        {orderedCategories.map((category) => (
          <Tabs.Tab key={category} value={category}>
            {t(`${category}.title`)}
          </Tabs.Tab>
        ))}
      </Tabs.List>
    </Tabs>
  )
})

export default memo(Categories)
