import React, { useState } from 'react'
import { Box, Center, Collapse, Group, SimpleGrid, Text, Title, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { Customer, EntryCustomer, Field } from 'types/domain'
import { formatISOTimeOrNull, isUrl } from 'utils'
import { IconChevronsDown } from '@tabler/icons-react'
import { LabelWithValue, UrlWithTooltip } from 'components/ui'

type Props = {
  customer?: EntryCustomer | null
  customerFields: Field[]
  gridCols?: number
}

const reservedCustomerFields = ['uid', 'name', 'mobile', 'email']

const UserInfo = ({ customer, customerFields, gridCols = 1 }: Props) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const [customerFieldsOpened, setCustomerFieldsOpened] = useState<boolean>(false)

  const formatLabelValue = (field: Field) => {
    let fieldValue = customer?.attrValues?.[field.code]
    if (field.type === 'DateTimeField') {
      return formatISOTimeOrNull(fieldValue)
    }
    if (isUrl(fieldValue)) {
      return <UrlWithTooltip url={fieldValue} includeProtocolAndHost />
    }
    return fieldValue
  }

  return (
    <Box>
      <Title order={6} mb="md">
        {t('entry:entryModal.baseInfo.customerInfoTitle')}
      </Title>
      <SimpleGrid cols={gridCols} verticalSpacing={12}>
        {reservedCustomerFields.map((field) => (
          <LabelWithValue
            key={field}
            labelClassName="min-w-[28px]"
            label={t(`customer:customer_${field}`)}
            value={customer && customer?.[field as keyof Customer]}
          />
        ))}
      </SimpleGrid>
      <SimpleGrid cols={gridCols} verticalSpacing={12} mt={12}>
        {customerFields.map((field, index) => {
          if (index >= 2) {
            return null
          }
          return (
            <LabelWithValue
              key={field.code}
              label={field.label}
              labelClassName="min-w-[56px]"
              value={formatLabelValue(field)}
            />
          )
        })}
      </SimpleGrid>
      {!customerFieldsOpened && customerFields.length > 2 && (
        <Center pt="sm">
          <Group
            spacing={0}
            onClick={() => setCustomerFieldsOpened(true)}
            className="px-sm py-xs rounded cursor-pointer hover:bg-brandBlue-1 select-none"
          >
            <Text color="brandBlue.7" size={12}>
              {t('entry:entryModal.baseInfo.collapse')}
            </Text>
            <IconChevronsDown size={16} stroke={1.5} color={theme.colors.brandBlue[7]} />
          </Group>
        </Center>
      )}
      <Collapse in={customerFieldsOpened}>
        <SimpleGrid cols={gridCols} verticalSpacing={12} mt={12}>
          {customerFields.map((field, index) => {
            if (index < 2) {
              return null
            }
            return (
              <LabelWithValue
                key={field.code}
                label={field.label}
                labelClassName="min-w-[56px]"
                value={formatLabelValue(field)}
              />
            )
          })}
        </SimpleGrid>
      </Collapse>
      {customerFieldsOpened && (
        <Center pt="sm">
          <Group
            spacing={0}
            onClick={() => setCustomerFieldsOpened(false)}
            className="px-sm py-xs rounded cursor-pointer hover:bg-brandBlue-1 select-none"
          >
            <Text color="brandBlue.7" size={12}>
              {t('entry:entryModal.baseInfo.putAway')}
            </Text>
            <IconChevronsDown
              size={16}
              stroke={1.5}
              color={theme.colors.brandBlue[7]}
              className="transition-transform rotate-180"
            />
          </Group>
        </Center>
      )}
    </Box>
  )
}

export default UserInfo
