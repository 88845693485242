import React, { forwardRef, MutableRefObject, useEffect, useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { SimpleGrid, Stack, Text, Title } from '@mantine/core'
import { shuffle } from 'lodash-es'

import { CampaignTemplate, CampaignTemplateCategory } from 'types/domain'
import SiteTemplateCard from './SiteTemplateCard'
import SystemTemplateCard from './SystemTemplateCard'
import { useScrollIntoView } from '@mantine/hooks'

type Props = {
  source: 'site' | 'system'
  templates?: CampaignTemplate[]
  containerRef?: MutableRefObject<HTMLDivElement>
  active: boolean
}

const TemplateCategory = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { t } = useTranslation('pageTemplate')
  const { templates, source, containerRef, active } = props
  const category: CampaignTemplateCategory = useMemo(() => templates?.[0].category!, [templates])
  const isSite = source === 'site'
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<HTMLDivElement, HTMLDivElement>({
    duration: 500,
  })

  useEffect(() => {
    if (active && containerRef && category !== CampaignTemplateCategory.UserExperience) {
      scrollableRef.current = containerRef.current
      scrollIntoView()
    }
  }, [containerRef, active, scrollableRef, scrollIntoView, category])

  const breakpoints = useMemo(() => {
    return isSite
      ? [
          { maxWidth: 1200, cols: 3 },
          { maxWidth: 900, cols: 2 },
          { maxWidth: 600, cols: 1 },
        ]
      : [
          { maxWidth: 1600, cols: 4 },
          { maxWidth: 1200, cols: 3 },
          { maxWidth: 900, cols: 2 },
        ]
  }, [isSite])

  const shownTemplates = isSite ? shuffle(templates) : templates

  return (
    <Stack spacing={0} id={category} ref={targetRef} className="px-[26px] text-center sm:px-xl sm:text-left categories">
      <Title order={4}>{t(`${category}.name`)}</Title>
      <Text mt="sm" mb="xl" c="dimmed">
        {t(`${category}.description`)}
      </Text>
      <SimpleGrid cols={source === 'site' ? 4 : 5} spacing={32} breakpoints={breakpoints}>
        {shownTemplates?.map((template) => {
          return isSite ? (
            <SiteTemplateCard key={template.code} template={template} className="h-full" />
          ) : (
            <SystemTemplateCard key={template.code} template={template} className="h-full" />
          )
        })}
      </SimpleGrid>
    </Stack>
  )
})

export default TemplateCategory
