import React, { FC, useState, useEffect, ChangeEvent } from 'react'
import { TextInput } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { toString } from 'lodash-es'
import { ValueNodeProps } from './type'
import { useDebouncedValue } from '@mantine/hooks'
import { debouncedWaitTime } from '../../constants'

const FormStringInput: FC<ValueNodeProps> = ({ condition, onChange, readOnly }) => {
  const { t } = useTranslation('common')
  const [value, setValue] = useState<string>(toString(condition.secondNode?.value))
  const [debouncedValue] = useDebouncedValue(value, debouncedWaitTime)

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target?.value ?? '')
  }

  useEffect(() => {
    onChange?.(debouncedValue.trim())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  return (
    <TextInput
      placeholder={t('placeholders.pleaseInput')}
      value={value || ''}
      onChange={handleOnChange}
      readOnly={readOnly}
    />
  )
}

export default FormStringInput
