import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Box, Center, Group, ScrollArea, Stack, Text, Tooltip, useMantineTheme } from '@mantine/core'
import { IconCheck, IconPlus } from '@tabler/icons-react'
import { AccountComplete, ApplicationDefault, SystemPrefix } from 'config/constants'
import { getBackgroundColor, getBorderColor, getTextColor } from 'config/themeOverride/getColor'
import { Icon } from 'components/ui'
import { useRouter } from 'next/router'
import { useAccountSwitcher } from 'hooks'
import useCurrentUserCache from 'components/business/hooks/useCurrentUserCache'
import { getCreatedAccount } from 'utils/accountUtils'

const AccountSwitcher = () => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const theme = useMantineTheme()
  const { user, account } = useCurrentUserCache()

  const currentAccountUserId = user?.accountUser?.id
  const createdAccount = getCreatedAccount(user)
  const switchAccount = useAccountSwitcher()

  const handleClick = (accountId: string) => {
    if (accountId === account?.id) {
      return
    }
    const path = router.asPath
    const redirection = path.startsWith(SystemPrefix) ? path : ApplicationDefault
    switchAccount(accountId, redirection).then()
  }

  const handleNewAccount = () => {
    if (createdAccount) {
      return
    }
    router.push({
      pathname: AccountComplete,
      query: {
        back: true,
        onboarding: true,
      },
    })
  }

  const itemBaseStyle = {
    borderRadius: theme.radius.sm,
    border: `1px solid ${getBorderColor('gray.2', theme)}`,
    '&:hover': {
      backgroundColor: getBackgroundColor('gray.0', theme),
    },
  }

  return (
    <ScrollArea.Autosize
      scrollbarSize="8px"
      mah="512px"
      offsetScrollbars
      mr={`calc(${theme.spacing.lg} * -1)`}
      styles={(theme) => ({
        viewport: {
          paddingRight: theme.spacing.lg,
        },
      })}
    >
      <Stack>
        {user?.accountUsers.map(({ id, account }) => {
          const selected = id === currentAccountUserId
          return (
            <Box
              key={id}
              p="md"
              className="cursor-pointer"
              sx={(theme) => ({
                ...itemBaseStyle,
                ...(selected
                  ? {
                      backgroundColor: getBackgroundColor('brandBlue.0', theme),
                      borderColor: getBorderColor('brandBlue.7', theme),
                    }
                  : {}),
              })}
              onClick={() => handleClick(account!.id)}
            >
              <Group spacing={0}>
                <Center className="w-[28px] mr-[4px]">
                  <Icon name={selected ? 'buildingFill' : 'building'} />
                </Center>
                <Text
                  lineClamp={1}
                  weight="bold"
                  w="240px"
                  className="flex-1"
                  color={getTextColor(selected ? 'gray.9' : 'gray.7', theme)}
                >
                  {account!.name}
                </Text>
                <Center miw="20px">
                  {selected && (
                    <IconCheck size={20} stroke={2} color={getTextColor('brandBlue.7', theme, 'brandBlue.2')} />
                  )}
                </Center>
              </Group>
            </Box>
          )
        })}
        <Tooltip
          label={t('notAllowedAccountCountTip', {
            accountName: getCreatedAccount(user)?.name,
          })}
          withArrow
          withinPortal
          position="top"
          disabled={!createdAccount}
        >
          <Box p="md" className="cursor-pointer" sx={itemBaseStyle} onClick={handleNewAccount}>
            <Group spacing={0}>
              <Center style={{ width: '28px' }} className="mr-4">
                <Center
                  sx={(theme) => ({
                    width: '28px',
                    height: '28px',
                    borderRadius: theme.radius.md,
                    border: `1px solid ${getBorderColor('gray.2', theme)}`,
                  })}
                >
                  <IconPlus
                    size={18}
                    {...(createdAccount
                      ? {
                          style: {
                            color: getTextColor('gray.5', theme),
                          },
                        }
                      : {})}
                  />
                </Center>
              </Center>
              <Text
                lineClamp={1}
                className="flex-1"
                {...(createdAccount ? { color: getTextColor('gray.5', theme) } : {})}
              >
                {t('actions.createEnterprise')}
              </Text>
            </Group>
          </Box>
        </Tooltip>
      </Stack>
    </ScrollArea.Autosize>
  )
}

export default AccountSwitcher
