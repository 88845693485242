import { Box, Image, Stack, Text, Title, useMantineTheme } from '@mantine/core'

import ApplicationContext from 'components/business/Layouts/ApplicationLayout/ApplicationContext'

import { getTextColor } from 'config/themeOverride/getColor'
import React, { FC, PropsWithChildren, useContext } from 'react'
import { CampaignType } from 'types/domain'
import { isLinkDistribution } from 'utils/applicationUtils'

type Props = PropsWithChildren & {
  type: CampaignType
  title: string
  description: string[]
}

const VideoLink = {
  [CampaignType.Survey]: 'https://static.howxm.com/survey.mp4',
  [CampaignType.Feedback]: 'https://static.howxm.com/feedback.mp4',
}

const BlankDesktopCampaigns: FC<Props> = (props) => {
  const { type, title, description } = props
  const theme = useMantineTheme()
  const { application } = useContext(ApplicationContext)
  const isLinkApplication = isLinkDistribution(application)

  return (
    <Stack pt={136} spacing={0} align="center" className="flex-1" justify="center">
      <Title order={2}>{title}</Title>
      <Stack spacing="xs" mt="md">
        {description.map((item, index) => (
          <Text key={index} color={getTextColor('gray.7', theme)} ta="left" className="w-[520px] text-center">
            {item}
          </Text>
        ))}
      </Stack>
      {isLinkApplication && (
        <Box mt="xl" mb="xl">
          <Image src={require(`./images/blank-link.png`).default?.src} width={520} height={320} />
        </Box>
      )}
      {!isLinkApplication && type !== CampaignType.Notification && (
        <Box mt="xl" mb="xl">
          <video width={520} height={320} autoPlay loop muted src={VideoLink[type]} className="rounded-md" />
        </Box>
      )}
      {props.children}
    </Stack>
  )
}

export default BlankDesktopCampaigns
