import React, { FC } from 'react'
import { Group, Select, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { ValueNodeProps } from './type'
import { RatingField, CsatField, NpsField } from 'types/domain'
import { range } from 'lodash-es'
import { IconMinus } from '@tabler/icons-react'
import { getTextColor } from 'config/themeOverride/getColor'
import useBetweenState from './hooks/useBetweenState'
import { filterSelectComponentDropDownStyle } from 'components/business/Filter/FieldFilterPopover/Filter/constants'

const getOptions = (field: NpsField | RatingField | CsatField) => {
  switch (field.__typename) {
    case 'NpsField':
      return range(0, field.ratingMax + 1).map(String)
    case 'CsatField':
      return range(1, field.ratingDescriptions.length + 1).map(String)
    default:
      return range(1, (field as RatingField).ratingMax + 1).map(String)
  }
}

const NumberSelector: FC<ValueNodeProps> = ({ fields = [], condition, onChange, readOnly }) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('common')
  const firstNodeField = fields.find((field) => field.code === condition.firstNode.value) as RatingField
  const options = getOptions(firstNodeField)

  const { isBetween, maxValue, minValue, setBetweenValue } = useBetweenState<Number>(condition, onChange)

  if (isBetween) {
    return (
      <Group noWrap spacing="sm">
        <Select
          withinPortal
          value={`${minValue}`}
          data={options}
          placeholder={t('placeholders.pleaseSelect')}
          onChange={(value) => setBetweenValue([Number(value!), maxValue])}
          readOnly={readOnly}
          {...filterSelectComponentDropDownStyle}
        />
        <IconMinus size={16} stroke={1.5} color={getTextColor('gray.6', theme)} />
        <Select
          withinPortal
          value={`${maxValue}`}
          data={options}
          placeholder={t('placeholders.pleaseSelect')}
          onChange={(value) => setBetweenValue([minValue, Number(value!)])}
          readOnly={readOnly}
          {...filterSelectComponentDropDownStyle}
        />
      </Group>
    )
  }

  return (
    <Select
      withinPortal
      searchable
      data={options}
      placeholder={t('placeholders.pleaseSelect')}
      onChange={(value) => onChange?.(Number(value!))}
      value={String(condition.secondNode?.value)}
      readOnly={readOnly}
      {...filterSelectComponentDropDownStyle}
    />
  )
}

export default NumberSelector
