import { fromPairs, map } from 'lodash-es'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { useUpsertCampaignSettingMutation } from 'types/domain'
import { CUSTOMER_EMAIL, CUSTOMER_MOBILE, CUSTOMER_NAME } from 'config/constants'

const defaultHiddenColumns = [CUSTOMER_NAME, CUSTOMER_MOBILE, CUSTOMER_EMAIL]

export default function useHiddenColumns(initialHiddenColumn?: string[] | null, allColumns?: string[]) {
  const [updateCampaignSettingMutation] = useUpsertCampaignSettingMutation()
  const campaignId = useRouter().query.campaignId as string

  const [hiddenColumns, setHiddenColumns] = useState<string[]>(initialHiddenColumn ?? defaultHiddenColumns)

  const columnVisibility = useMemo(() => fromPairs(map(hiddenColumns, (field) => [field, false])), [hiddenColumns])

  const handleHiddenColumnChange = async (value: string[]) => {
    await updateCampaignSettingMutation({ variables: { input: { campaignId, hiddenFields: value } } })
    setHiddenColumns(value)
  }

  useEffect(() => {
    if (!initialHiddenColumn) {
      handleHiddenColumnChange(defaultHiddenColumns).then()
    }
    // eslint-disable-next-line
  }, [])

  return {
    hiddenColumns,
    columnVisibility,
    onHiddenColumnChange: handleHiddenColumnChange,
  }
}
