import { Box, Button, Checkbox, Rating, Stack, Text, useMantineTheme } from '@mantine/core'
import React, { forwardRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { noop, range } from 'lodash-es'

type Props = {
  value: string[]
  closeDropDown: (value: string[]) => void
}

const starData = range(1, 6).reverse().map(String)
const DropDownComponent = ({ closeDropDown, value: propsValue }: Props) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const [value, setValue] = useState(propsValue)

  const selectedAll = value.length === 5
  const noSelected = value.length === 0

  const onClick = (num: string) => () => {
    if (value.includes(num)) {
      setValue(value.filter((n) => n !== num))
    } else {
      setValue(value.concat(num))
    }
  }

  const onSelectAllClick = () => {
    setValue(selectedAll ? [] : starData)
  }

  const onConfirm = () => {
    if (noSelected) {
      return
    }
    closeDropDown([...value].sort())
  }

  return (
    <>
      <Stack justify="flex-start" spacing="lg">
        <div className="flex items-center cursor-pointer" onClick={onSelectAllClick}>
          <Checkbox checked={selectedAll} onChange={noop} classNames={{ root: 'flex' }} />
          <Text color={theme.colorScheme === 'dark' ? '#C1C2C5' : '#333'} mb={-2} className="cursor-pointer" ml={10}>
            {t('common:actions.checkAll')}
          </Text>
        </div>
        {starData.map((num) => (
          <div className="flex items-center" key={num} onClick={onClick(num)}>
            <Checkbox key={num} checked={value.includes(num)} onChange={noop} classNames={{ root: 'flex' }} />
            <div className="flex items-center cursor-pointer ml-[10px]">
              <Rating value={parseInt(num, 10)} size="xs" />
              <Text color={theme.colorScheme === 'dark' ? '#C1C2C5' : '#333'} ml="sm">
                {t('componentEntryFilter:star', { num: parseInt(num, 10) })}
              </Text>
            </div>
          </div>
        ))}
      </Stack>
      {noSelected && (
        <Text color="red" mt="xs" size={12}>
          {t('componentEntryFilter:chooseAtLeastOneItem')}
        </Text>
      )}
      <Button onClick={onConfirm} mt={16}>
        {t('common:actions.confirm')}
      </Button>
    </>
  )
}

export const getDropDownComponent = (closeDropDown: (value: string[]) => void, value: string[]) =>
  forwardRef<HTMLDivElement, any>((props, ref) => (
    <Box ref={ref} p={12} className="w-full" component="div">
      <DropDownComponent closeDropDown={closeDropDown} value={value} />
    </Box>
  ))
