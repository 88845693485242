import { Modal, Text, useMantineTheme } from '@mantine/core'
import { getTextColor } from 'config/themeOverride/getColor'
import useTranslation from 'next-translate/useTranslation'
import React, { useState } from 'react'
import { useCheckToggleQuery } from 'types/domain'
import { FeatureToggle } from 'config/constants'
import StyleItem from 'components/business/CampaignBuilder/StyleItem'
import { AIButton } from 'components/ui'
import { useCheckCreateSurveyByAi } from 'components/business'
import dynamic from 'next/dynamic'
import { useBuilderSharedDataContext } from 'components/business/CampaignBuilder'

const ModalContent = dynamic(() => import('./ModalContent'))
const Emoji = () => {
  const theme = useMantineTheme()
  const { t } = useTranslation('componentCampaignBuilder')
  const [opened, setOpened] = useState(false)
  const { currentUser } = useBuilderSharedDataContext()

  const { data: toggleData } = useCheckToggleQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        identifier: currentUser?.mobile,
        name: FeatureToggle.AIEmoji,
      },
    },
  })
  const { disabled, disabledTip } = useCheckCreateSurveyByAi()

  if (!toggleData?.checkToggle) {
    return null
  }

  return (
    <>
      <StyleItem label={t('styles.emoji.title')}>
        <AIButton
          onClick={() => {
            setOpened(true)
          }}
          label={t('styles.emoji.buttonLabel')}
          disabled={disabled}
          disabledTip={disabledTip}
          w={203}
        />
      </StyleItem>
      <Modal
        title={
          <Text weight={600} size="md">
            {t('styles.emoji.buttonLabel')}
          </Text>
        }
        closeOnClickOutside={false}
        closeOnEscape={false}
        opened={opened}
        onClose={() => {
          setOpened(false)
        }}
        size={1000}
        transitionProps={{ transition: 'fade', duration: 200, timingFunction: 'linear' }}
        overlayProps={{
          color: getTextColor('gray.2', theme),
          opacity: 0.55,
          blur: 3,
        }}
      >
        <ModalContent
          onClose={() => {
            setOpened(false)
          }}
          opened={opened}
        />
      </Modal>
    </>
  )
}

export default Emoji
