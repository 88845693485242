import { CampaignLocaleSettingInput, Locale, WordingsInput } from 'types/domain'
import omitDeep from 'omit-deep-lodash'
import { cloneDeep, isEqual, merge, pick } from 'lodash-es'
import { localeMap } from 'components/business/CampaignBuilder/StyleSetting/Locale/utils/getDefaultLocaleWords'

export const calculateInitialValues = (
  defaultLocaleWords: WordingsInput,
  localeSetting?: CampaignLocaleSettingInput
) => {
  const wordingsValue = omitDeep(localeSetting?.wordings ?? {}, ['__typename'])
  const wordingsNotChanged =
    isEqual(wordingsValue, pick(localeMap[Locale.ZhCn], Object.keys(wordingsValue))) ||
    isEqual(wordingsValue, pick(localeMap[Locale.En], Object.keys(wordingsValue)))
  if (wordingsNotChanged) {
    return defaultLocaleWords
  }
  return merge(cloneDeep(defaultLocaleWords), localeSetting?.wordings)
}
