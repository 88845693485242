import React, { ComponentPropsWithoutRef, forwardRef, ReactNode, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { useForm } from '@mantine/form'
import { closeModal, openModal } from '@mantine/modals'
import { hideNotification } from '@mantine/notifications'
import { Button, Group, HoverCard, Select, Stack, Text, TextInput } from '@mantine/core'
import { head, isNil, keyBy } from 'lodash-es'
import { editPathMapping } from 'config/constants/campaignPathMapping'

import {
  Campaign,
  CampaignDistributionChannel,
  CampaignType,
  Feature,
  useApplicationsCampaignsCountQuery,
  useApplicationsQuery,
  useDuplicateCampaignMutation,
} from 'types/domain'
import { graphqlErrorCatch, length } from 'utils'
import { AnchorLink, message } from 'components/ui'
import { ApplicationType } from 'states/applications'
import AppInfo from 'components/business/Layouts/ApplicationLayout/AppManager/AppInfo'
import { FeatureTip } from 'components/business'
import useCampaignsQuota from './useCampaignsQuota'
import useCampaignsCountCache from 'components/business/hooks/useCampaignsCountCache'

const duplicateCampaignModalId = 'duplicateCampaignModal'
const nameMaxLength = 40

interface ModalContentProps {
  campaign: Pick<Campaign, 'id' | 'type' | 'name'>
  onSuccess?: (targetApplicationId: string) => void
}

interface ApplicationItemProps extends ComponentPropsWithoutRef<'div'> {
  application: ApplicationType
  disabled?: boolean
  disabledTip?: ReactNode
}

const ModalContent = ({ campaign: { id, type, name }, onSuccess }: ModalContentProps) => {
  const { t } = useTranslation('componentCampaignList')
  const [duplicateCampaignMutation, { loading: duplicating }] = useDuplicateCampaignMutation()
  const router = useRouter()
  const appId = router.query.appId as string
  const campaignsQuota = useCampaignsQuota()
  const { increase } = useCampaignsCountCache(type)
  const [applications, setApplications] = useState<ApplicationType[]>([])
  const campaignTypeName = t(`common:campaignTypeName.${type}`)

  useApplicationsQuery({
    onCompleted: (resp) => {
      const apps = resp.applications ?? []
      if (type === CampaignType.Notification) {
        setApplications(apps.filter(({ distribution }) => distribution.channel === CampaignDistributionChannel.Web))
      } else {
        setApplications(apps)
      }
    },
  })

  const { data, loading } = useApplicationsCampaignsCountQuery({
    fetchPolicy: 'cache-and-network',
    variables: { type },
  })

  const campaignsCountMap = keyBy(data?.applications, 'id')
  const availableApplicationIds = applications
    ?.filter(({ id }) => id in campaignsCountMap && campaignsCountMap[id].campaignsCount < campaignsQuota)
    .map(({ id }) => id)

  const defaultApplicationId = availableApplicationIds?.includes(appId) ? appId : head(availableApplicationIds)
  const form = useForm({
    initialValues: {
      name: t('list.action.duplicate.newName', { name }),
      applicationId: defaultApplicationId,
    },
    validateInputOnBlur: true,
    validate: {
      name: (val: string) => {
        const value = val.trim()
        if (value.length === 0) {
          return t('common:message.required', { value: t(`list.action.duplicate.name`, { campaignTypeName }) })
        }
        if (length(value) > nameMaxLength) {
          return t('common:message.charTooLong', { size: nameMaxLength / 2 })
        }
        return null
      },
    },
  })

  useEffect(() => {
    form.setFieldValue('applicationId', defaultApplicationId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultApplicationId])

  const handleSubmit = async () => {
    const targetAppId = form.values.applicationId
    if (duplicating || isNil(targetAppId)) {
      return
    }
    try {
      const { data } = await duplicateCampaignMutation({
        variables: {
          input: {
            id,
            ...form.values,
          },
        },
        onCompleted: () => {
          increase()
        },
      })
      if (data?.duplicateCampaign) {
        closeModal(duplicateCampaignModalId)
        const newCampaignId = data.duplicateCampaign.id
        const messageId = `duplicateCampaignSuccessMessage:${newCampaignId}`
        message.success({
          id: messageId,
          title: `${campaignTypeName}${t(`common:message.duplicateSuccess`)}`,
          message: (
            <Trans
              i18nKey="componentCampaignList:list.action.duplicate.description"
              components={{
                edit: (
                  <AnchorLink
                    href={{
                      pathname: editPathMapping[type],
                      query: { appId: targetAppId, campaignId: newCampaignId, isDuplicated: true },
                    }}
                    onClick={() => hideNotification(messageId)}
                  />
                ),
              }}
            />
          ),
          autoClose: 5000,
        })
        onSuccess?.(targetAppId)
      }
    } catch (e: any) {
      const { graphQLErrors } = e
      graphqlErrorCatch({
        graphQLErrors,
        defaultMessage: `${campaignTypeName}${t(`common:actions.duplicate`)}${t(`common:message.failed`)}`,
      })
    }
  }

  const disabledTip = (
    <FeatureTip
      feature={Feature.CampaignsCountPerApplication}
      transQuery={{ count: campaignsQuota, campaignType: t(type) }}
    />
  )

  const applicationOptions = applications
    .filter(
      ({ id, distribution }) =>
        id in campaignsCountMap &&
        (type != CampaignType.Feedback ||
          ![CampaignDistributionChannel.WechatMiniapp, CampaignDistributionChannel.Link].includes(distribution.channel))
    )
    .map((application) => {
      const disabled = !availableApplicationIds.includes(application.id)
      return {
        value: application.id,
        label: application.name,
        application,
        disabled,
        disabledTip: disabled ? disabledTip : undefined,
      }
    })

  const ApplicationSelectItem = forwardRef<HTMLDivElement, ApplicationItemProps>(
    ({ application, disabled, disabledTip, ...rest }, ref) => {
      return (
        <div ref={ref} {...rest}>
          <HoverCard disabled={!disabled} withArrow withinPortal offset={0} closeDelay={500}>
            <HoverCard.Target>
              <div>
                <AppInfo application={application} tooltipDisabled={disabled} />
              </div>
            </HoverCard.Target>
            <HoverCard.Dropdown>{disabledTip}</HoverCard.Dropdown>
          </HoverCard>
        </div>
      )
    }
  )

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack spacing="xl">
        <TextInput label={t(`list.action.duplicate.name`, { campaignTypeName })} {...form.getInputProps('name')} />
        <Select
          withinPortal
          data={applicationOptions}
          itemComponent={ApplicationSelectItem}
          label={t('list.action.duplicate.targetApplication')}
          placeholder={t('common:placeholders.pleaseSelect')}
          {...form.getInputProps('applicationId')}
        />
        <Group position="right">
          <Button variant="default" onClick={() => closeModal(duplicateCampaignModalId)}>
            {t('common:actions.cancel')}
          </Button>
          <Button type="submit" loading={loading || duplicating} disabled={isNil(form.values.applicationId)}>
            {t('common:actions.confirm')}
          </Button>
        </Group>
      </Stack>
    </form>
  )
}

export default function useDuplicateCampaignModal(campaign: Pick<Campaign, 'id' | 'type' | 'name'>) {
  const { t } = useTranslation('common')

  return (onSuccess?: (targetApplicationId: string) => void) => {
    openModal({
      modalId: duplicateCampaignModalId,
      withinPortal: true,

      title: (
        <Text weight={600} size="md">
          {t('common:actions.duplicate')}
          {t(`common:campaignTypeName.${campaign.type}`)}
        </Text>
      ),
      children: <ModalContent onSuccess={onSuccess} campaign={campaign} />,
    })
  }
}
