import { Application, CampaignType } from 'types/domain'
import { isMiniappDistribution, isMobileDistribution } from 'utils/applicationUtils'

export const hideNextButton = (campaignType: CampaignType) => {
  return campaignType === CampaignType.Feedback
}

export const hideScreenshot = (application: Application) => {
  return isMiniappDistribution(application) || isMobileDistribution(application)
}

export const hideOtherChoicePlaceholder = (campaignType: CampaignType) => {
  return campaignType === CampaignType.Feedback
}
