import { COMMENTS_COUNT_CODE, REPLY_CODE, SERIAL_NUMBER_CODE } from 'components/business/Campaign/Entries/constants'
import React, { FC, useMemo } from 'react'
import { Group } from '@mantine/core'
import EntriesFilter from 'components/business/Campaign/Filter/EntriesFilter'
import { useCampaign } from 'components/business/Campaign/CampaignContext'
import { useEntries, useEntriesActions } from 'components/business/Campaign/Entries/EntriesContext'
import { useCampaignPermission } from 'components/business'

import HiddenColumns from 'components/business/HiddenColumns'
import KeywordSearch from './KeywordSearch'
import Export from './Export'
import BatchActions from './BatchActions'

type Props = {
  tableRef: any
}

const notSupportHiddenColumns = [SERIAL_NUMBER_CODE, COMMENTS_COUNT_CODE, REPLY_CODE]

const HandlerBar: FC<Props> = (props) => {
  const { tableRef } = props
  const { campaign } = useCampaign()
  const { selectedRowsCount, formFields, hiddenColumns, columns } = useEntries()
  const { onHiddenColumnChange } = useEntriesActions()
  const { deletable } = useCampaignPermission(campaign?.creator?.id!)
  const batchActivity = deletable && selectedRowsCount > 0

  const canHiddenColumns = useMemo(() => {
    return columns?.filter((column) => !notSupportHiddenColumns.includes(column.id as string))
  }, [columns])

  return (
    <Group pos="relative" px="md" py="sm" align="center" grow>
      {batchActivity && <BatchActions tableRef={tableRef} />}
      <Group position="apart" opacity={batchActivity ? 0 : 1}>
        <Group spacing="sm" align="center">
          <EntriesFilter fields={formFields} />
          <HiddenColumns columns={canHiddenColumns} value={hiddenColumns} onChange={onHiddenColumnChange} />
          <Export />
        </Group>
        <KeywordSearch />
      </Group>
    </Group>
  )
}

export default HandlerBar
