import { Flex, MantineStyleSystemProps, ScrollArea, useMantineTheme } from '@mantine/core'
import { defaultCondition } from 'components/business/Filter/DefaultFilter/constants'
import ConditionAdder from 'components/business/Filter/FieldFilterPopover/Filter/ConditionAdder'
import Header from 'components/business/Filter/FieldFilterPopover/Filter/Header'
import update from 'immutability-helper'
import { isEqual } from 'lodash-es'
import { FC, useRef, useState } from 'react'
import { ConditionGroupInput, ConditionInput, Field } from 'types/domain'
import { getConditionValid } from 'utils/condition'
import Conditions from './Conditions'
import { defaultConditionGroup } from './constants'

export interface FieldFilterProps extends MantineStyleSystemProps {
  campaignFields: Field[]
  onChange?: (condition: ConditionGroupInput) => void
  value?: ConditionGroupInput
  showHeader?: boolean
  withDefault?: boolean
  readOnly?: boolean
}

const Filter: FC<FieldFilterProps> = ({
  value = defaultConditionGroup,
  onChange,
  campaignFields,
  showHeader = true,
  withDefault,
  readOnly,
  ...styleProps
}) => {
  const theme = useMantineTheme()
  const conditionsScrollViewRef = useRef<HTMLDivElement>(null)
  const [values, setValues] = useState(() => {
    if (withDefault && value.conditions.length === 0) {
      return update(value, {
        conditions: { $push: [defaultCondition as ConditionInput] },
      })
    }
    return value
  })
  const showConditions = values?.conditions?.length > 0

  const handleOnChange = (newValues: ConditionGroupInput) => {
    const validValues = update(newValues, {
      conditions: {
        $set: newValues.conditions.filter((condition) => getConditionValid(condition)),
      },
    })
    const isDifference = !isEqual(value, validValues)
    isDifference && onChange?.(validValues)
  }

  const conditionsScrollViewScrollToBottom = () => {
    setTimeout(() => {
      conditionsScrollViewRef.current?.scrollTo({
        top: conditionsScrollViewRef.current.scrollHeight,
        behavior: 'smooth',
      })
    })
  }

  const onAdd = () => {
    const newValues = update(values, {
      conditions: { $push: [defaultCondition as ConditionInput] },
    })
    setValues(newValues)
    conditionsScrollViewScrollToBottom()
  }

  const onDelete = (index: number) => {
    const newValues = update(values, {
      conditions: { $set: values.conditions.filter((_, idx) => idx !== index) },
    })
    setValues(newValues)
    handleOnChange(newValues)
  }

  const onConditionsChange = (freshConditions: ConditionGroupInput['conditions']) => {
    const newValues = update(values, {
      conditions: { $set: freshConditions },
    })
    setValues(newValues)
    handleOnChange(newValues)
  }

  return (
    <Flex direction="column" w="100%" align="flex-start" {...styleProps}>
      {showHeader && <Header />}
      {showConditions && (
        <ScrollArea className="flex-1 w-full" mb={theme.spacing.sm} viewportRef={conditionsScrollViewRef}>
          <Conditions
            conditions={values.conditions}
            onDelete={onDelete}
            fields={campaignFields}
            onChange={onConditionsChange}
            readOnly={readOnly}
          />
        </ScrollArea>
      )}
      {!readOnly && <ConditionAdder onAdd={onAdd} />}
    </Flex>
  )
}

export default Filter
