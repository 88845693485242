import { FeatureCheck, FeatureText } from './components/Feature'
import { CustomizedFeature } from './constant'

const ProFeature = () => {
  return (
    <>
      <FeatureText titleKey={CustomizedFeature.EntriesCount} value="50万条/年" />
      <FeatureText titleKey={CustomizedFeature.ApplicationsCount} value="10个" />
      <FeatureText titleKey={CustomizedFeature.CampaignsCountPerApplication} value="不限量" />
      <FeatureCheck titleKey={CustomizedFeature.WebSupport} />
      <FeatureCheck titleKey={CustomizedFeature.AppApplication} />
      <FeatureText titleKey={CustomizedFeature.Teamwork} value="不限量" />
      <FeatureCheck titleKey={CustomizedFeature.CampaignTargetingCustomer} />
      <FeatureCheck titleKey={CustomizedFeature.CampaignTriggerByEvent} />
      <FeatureCheck titleKey={CustomizedFeature.Customer} />
      <FeatureCheck titleKey={CustomizedFeature.ImPush} />
      <FeatureCheck titleKey={CustomizedFeature.Webhook} />
      <FeatureCheck titleKey={CustomizedFeature.HideBranding} />
    </>
  )
}

export default ProFeature
