import React, { FC } from 'react'
import { Flex, Skeleton, Text } from '@mantine/core'
import { channelIconMap } from 'config/constants'
import { CampaignDistributionChannel } from 'types/domain'
import { Icon } from 'components/ui'

type Props = {
  channel?: CampaignDistributionChannel
  appId?: string
  appName?: string
}

const AppSkeletonItem: FC<Props> = ({ channel, appName, appId }) => {
  return (
    <Flex mih={52} align="center">
      {channel ? (
        <Icon name={channelIconMap[channel] as any} className="shrink-0" size={28} />
      ) : (
        <Skeleton animate={false} w={28} height={28} radius="md" />
      )}
      {appName || appId ? (
        <Flex ml="md" direction="column">
          {appName && (
            <Text size="md" lh={1.5} className="break-all">
              {appName}
            </Text>
          )}
          {appId && (
            <Text c="gray.6" size="xs" lh={1.5} className="break-all">
              {appId}
            </Text>
          )}
        </Flex>
      ) : (
        <Skeleton animate={false} w={81} height={12} radius={10} ml="md" />
      )}
    </Flex>
  )
}

export default AppSkeletonItem
