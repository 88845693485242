import React from 'react'
import { GraphQLError } from 'graphql'
import { ButtonProps } from '@mantine/core'
import { CaptchaType } from 'types/domain'
import Sender, { SenderDisplayType } from 'modules/Captcha/Sender'
import { captchaCountDown, captchaDateId } from '../constants'
import { UseFormReturnType } from '@mantine/form/lib/types'
import { AuthenticationFormValues } from 'components/business/Authentication'
import { SignupFormValues } from 'modules/Signup/formContext'

interface Props extends ButtonProps {
  captchaType: CaptchaType
  form: UseFormReturnType<AuthenticationFormValues | SignupFormValues>
  identifierNotExistedErrorHandler?: () => void
  identifierExistedErrorHandler?: () => void
  guard?: () => boolean
  onSend?: () => void
  getCountDownText?: (countDown: number) => string
  defaultText?: string
  displayType?: SenderDisplayType
}

const CaptchaSender = ({
  captchaType,
  form,
  identifierNotExistedErrorHandler,
  identifierExistedErrorHandler,
  guard,
  onSend,
  getCountDownText,
  defaultText,
  displayType,
  ...buttonProps
}: Props) => {
  const getValidIdentifier = () => {
    return new Promise<string>((resolve, reject) => {
      const { hasError } = form.validateField('identifier')
      hasError || (guard && !guard()) ? reject() : resolve(form.values.identifier.trim())
    })
  }

  const onCaptchaError = (graphQLErrors: GraphQLError[], defaultHandler: () => void) => {
    const errorCode = graphQLErrors?.[0]?.extensions?.code
    if (errorCode == 'error.identifier_existed' && identifierExistedErrorHandler) {
      identifierExistedErrorHandler()
    } else if (errorCode == 'error.not_registered' && identifierNotExistedErrorHandler) {
      identifierNotExistedErrorHandler()
    } else {
      defaultHandler()
    }
  }

  return (
    <Sender
      dateId={captchaDateId}
      countDown={captchaCountDown}
      getValidIdentifier={getValidIdentifier}
      captchaType={captchaType}
      onError={onCaptchaError}
      onSend={onSend}
      getCountDownText={getCountDownText}
      defaultText={defaultText}
      displayType={displayType}
      {...buttonProps}
    />
  )
}

export default CaptchaSender
