import { WebhookChannel } from 'types/domain'

export const DEMO_WEBHOOK_ENTRY_ID = 'DEMO_ENTRY_ID'
export const DEMO_WEBHOOK_CAMPAIGN_ID = 'DEMO_CAMPAIGN_ID'

export const webhookNameMaxLength = 50

export const orderedWebhookChannels = [
  WebhookChannel.QiyeWx,
  WebhookChannel.Dingtalk,
  WebhookChannel.Feishu,
  WebhookChannel.Default,
]

export const AT_ALL_VALUE = '@all'
