import React, { FC } from 'react'
import { Text } from '@mantine/core'
import UrlWithTooltip from '../UrlWithTooltip'
import { formatISOTimeOrNull, isUrl } from 'utils'

export const DefaultCell = ({ renderedCellValue }: any) => {
  if (isUrl(renderedCellValue)) return <UrlWithTooltip url={renderedCellValue} includeProtocolAndHost />
  return <Text lineClamp={1}>{renderedCellValue}</Text>
}

export const DateCell: FC<any> = ({ renderedCellValue }) => {
  if (!renderedCellValue) {
    return null
  }

  return <Text>{formatISOTimeOrNull(renderedCellValue)}</Text>
}
