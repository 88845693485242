import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useAbac } from 'react-abac'
import { ApplicationPermission, ApplicationRoleType } from 'types/domain'
import useCurrentUserCache from 'components/business/hooks/useCurrentUserCache'
import useApplicationRoleCache from 'components/business/hooks/useApplicationRoleCache'
import { Switch } from '@mantine/core'
import StatusBadge from 'components/ui/StatusBadge'
import useCampaignBaseInfoCache from 'components/business/hooks/useCampaignBaseInfoCache'
import useCampaignFormStatusSwitch from 'components/business/Campaign/hooks/useCampaignFormStatusSwitch'

interface Props {
  id: string
}

const CampaignFormStatusSwitch = ({ id }: Props) => {
  const { t } = useTranslation('componentCampaignList')
  const { user } = useCurrentUserCache()
  const { userHasPermissions } = useAbac()
  const { applicationRole } = useApplicationRoleCache()
  const { toggle } = useCampaignFormStatusSwitch(id)
  const { campaign } = useCampaignBaseInfoCache(id)
  if (!campaign) {
    return null
  }
  const editable =
    (campaign.creator.id === user?.id || userHasPermissions(ApplicationPermission.CampaignUpdate)) &&
    applicationRole?.type !== ApplicationRoleType.ApplicationReader

  const handleChange = () => {
    toggle().then()
  }
  return (
    <>
      {editable ? (
        <Switch
          id={campaign.id}
          className="form-status-switch"
          checked={campaign.urlEnabled}
          onLabel={t('deliveryUrl.on')}
          offLabel={t('deliveryUrl.off')}
          size="lg"
          display="flex"
          color="teal.6"
          onChange={handleChange}
        />
      ) : (
        <StatusBadge value={campaign.urlEnabled} onText={t('deliveryUrl.on')} offText={t('deliveryUrl.off')} />
      )}
    </>
  )
}
export default CampaignFormStatusSwitch
