import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Center, HoverCard, useMantineTheme } from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react'
import { isNil, uniq } from 'lodash-es'
import { Campaign, CampaignTriggerEvent, Feature, useCampaignDistributionAffectionQuery } from 'types/domain'
import { useCheckFeature } from 'components/business'
import DistributionAffectionTooltipLabel from './DistributionAffectionTooltipLabel'
import { CampaignBuilderStep } from 'components/business/CampaignBuilder/type'
import useAccountSubscriptionCache from 'components/business/hooks/useAccountSubscriptionCache'

interface Props {
  id: string
  campaign?: Pick<Campaign, 'id' | 'type' | 'trigger' | 'targeting'> & {
    form?: { hasVisibleScreenshotsEnabledField: boolean } | null
  }
}

const DistributionAffectionIndicator = ({ id, campaign }: Props) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('componentCampaignList')
  const { account, subscription } = useAccountSubscriptionCache()
  const checkFeature = useCheckFeature()
  const isFreeSubscription = subscription?.productCode === 'free'

  const { data } = useCampaignDistributionAffectionQuery({
    variables: {
      id,
    },
  })

  const currentCampaign = !isNil(campaign) ? campaign : data?.campaign
  if (!isFreeSubscription || !account || isNil(currentCampaign)) {
    return null
  }

  const { type, trigger, targeting } = currentCampaign

  const handleClickEventPropagation = (event: any) => {
    event.stopPropagation()
  }

  const distributionAffections = []
  const affectedSteps: CampaignBuilderStep[] = []
  const entriesUsageCountOverLimit = account?.vasBalance[Feature.EntriesCount].balance <= 0
  if (entriesUsageCountOverLimit) {
    distributionAffections.push(t('list.distributionAffectionTooltip.entriesUsageCountOverLimit'))
  }
  if (
    !checkFeature(Feature.CampaignTriggerByEvent) &&
    trigger?.conditions.some(({ event }) => event === CampaignTriggerEvent.EventReceived)
  ) {
    distributionAffections.push(t('list.distributionAffectionTooltip.triggerByEventUnavailable', { type: t(type) }))
    affectedSteps.push('distribution')
  }
  if (!checkFeature(Feature.CampaignTargetingCustomer) && targeting?.customerFilter) {
    distributionAffections.push(t('list.distributionAffectionTooltip.targetingCustomerUnavailable'))
    affectedSteps.push('distribution')
  }
  if (!checkFeature(Feature.ScreenshotField) && currentCampaign.form?.hasVisibleScreenshotsEnabledField) {
    distributionAffections.push(t('list.distributionAffectionTooltip.screenshotUnavailable'))
    affectedSteps.unshift('fields')
  }

  if (distributionAffections.length === 0) {
    return null
  }

  return (
    <HoverCard
      width={400}
      offset={10}
      withinPortal
      openDelay={0}
      closeDelay={300}
      position="bottom-end"
      shadow={theme.shadows.md}
    >
      <HoverCard.Target>
        <Center onClick={handleClickEventPropagation}>
          <IconAlertCircle size={18} color={theme.colors.red[6]} stroke={1.5} />
        </Center>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <DistributionAffectionTooltipLabel
          campaignId={id}
          campaignType={type}
          reasons={distributionAffections}
          terminated={entriesUsageCountOverLimit}
          affectedSteps={uniq(affectedSteps)}
        />
      </HoverCard.Dropdown>
    </HoverCard>
  )
}

export default DistributionAffectionIndicator
