import React, { FC, useContext } from 'react'
import { TextInput as InputText } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import ConditionContext from '../ConditionContext'
import { NodeFieldType } from '../../type'

interface Props {
  onChange: (params: NodeFieldType) => void
  className?: string
}

const TextInput: FC<Props> = (props: Props) => {
  const { condition } = useContext(ConditionContext)
  const { onChange, className } = props
  const { t } = useTranslation('common')

  const handleChange = (value: string) => {
    const newValue = value.trim()
    if (newValue !== condition.node?.value) {
      onChange({ value: newValue })
    }
  }

  return (
    <InputText
      value={condition.node?.value || ''}
      placeholder={t('common:placeholders.pleaseInput')}
      onChange={(e) => handleChange(e.currentTarget.value)}
      className={className}
    />
  )
}

export default TextInput
