import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Text } from '@mantine/core'
import { openModal } from '@mantine/modals'
import { WebhookHistory, WebhookHistoryStatus, WebhookSuspendedBy } from 'types/domain'
import { getTextColor } from 'config/themeOverride/getColor'
import { formatISOTime } from 'utils'

const webhookSuspendedByModalId = 'webhookSuspendedByModal'

interface Props {
  showCampaignName?: boolean
}

export default function useWebhookSuspendedDetailModal(props: Props = { showCampaignName: false }) {
  const { t } = useTranslation('integration')

  function getErrMsg(webhookHistory: WebhookHistory) {
    if (webhookHistory.status == WebhookHistoryStatus.Timeout) {
      return t('error.timeout')
    }
    const { responseStatusCode, responseBody, errMsg } = webhookHistory
    if (responseStatusCode) {
      return `${responseStatusCode} ${responseBody}`
    }
    return errMsg
  }

  return (suspendedBy: WebhookSuspendedBy) => {
    const errMsg = getErrMsg(suspendedBy)

    const { campaign, entry } = suspendedBy.requestPayload ?? {}
    const entrySerialNumber = suspendedBy.target?.serialNumber ?? entry?.serial_number

    openModal({
      modalId: webhookSuspendedByModalId,
      closeOnClickOutside: false,
      trapFocus: false,
      withinPortal: true,
      title: t('webhook.suspended.modal.title'),
      styles: (theme) => ({
        title: {
          fontWeight: 600,
          fontSize: theme.fontSizes.md,
        },
        body: {
          dl: {
            fontSize: theme.fontSizes.sm,
            margin: 0,
          },
          dt: {
            color: getTextColor('gray.7', theme),
            marginBottom: theme.spacing.sm,
          },
          dd: {
            marginLeft: 0,
            wordBreak: 'break-all',
          },
          'dd + dt': {
            marginTop: theme.spacing.xl,
          },
        },
      }),
      children: (
        <dl>
          {props.showCampaignName && (
            <>
              <dt>{t('webhook.suspended.modal.campaignName')}</dt>
              <dd>{campaign?.name}</dd>
            </>
          )}
          <dt>{t('webhook.suspended.modal.serialNumber')}</dt>
          <dd>{entrySerialNumber}</dd>
          <dt>{t('webhook.suspended.modal.errMsg')}</dt>
          <dd>{errMsg}</dd>
          <dt>{t('webhook.suspended.modal.requestUrl')}</dt>
          <dd>{suspendedBy.requestUrl}</dd>
          <dt>{t('webhook.suspended.modal.requestedAt')}</dt>
          <dd>{formatISOTime(suspendedBy.sentAt)}</dd>
        </dl>
      ),
    })
  }
}
