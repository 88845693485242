const format = (value: any) => {
  const countryCode = value?.country_code ? `+${value?.country_code}` : ''
  return `${countryCode}${value?.value || ''}`
}

const mobileField = {
  format,
}

export default mobileField
