import React, { FC } from 'react'
import { ActionIcon, CopyButton, Tooltip, useMantineTheme } from '@mantine/core'
import { useRouter } from 'next/router'
import { IconLink } from '@tabler/icons-react'
import useTranslation from 'next-translate/useTranslation'

import { getTextColor } from 'config/themeOverride/getColor'
import { CampaignType } from 'types/domain'
import message from 'components/ui/message'
import { ApplicationFeedbackEntry, ApplicationNotificationEntry, ApplicationSurveyEntry } from 'config/constants'

type Props = {
  serialNumber: number
  campaignId: string
  campaignType: CampaignType
}

const entriesPathMap = {
  [CampaignType.Survey]: ApplicationSurveyEntry,
  [CampaignType.Feedback]: ApplicationFeedbackEntry,
  [CampaignType.Notification]: ApplicationNotificationEntry,
}

const CopyShareUrl: FC<Props> = ({ serialNumber, campaignId, campaignType }) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('entry')
  const router = useRouter()

  const handleCopy = () => {
    message.successMsg(t('entryModal.shareDescription'), t('common:message.linkCopySuccess'))
  }

  const getShareLink = () => {
    const path = entriesPathMap[campaignType]
      .replace('[appId]', router.query.appId as string)
      .replace('[campaignId]', campaignId)
      .replace('[serialNumber]', serialNumber.toString())
    return process.env.NEXT_PUBLIC_APP_URL! + path
  }

  return (
    <CopyButton value={getShareLink()}>
      {({ copy }) => (
        <Tooltip withArrow label={t('entryModal.shareDescription')}>
          <ActionIcon
            onClick={() => {
              copy()
              handleCopy()
            }}
          >
            <IconLink size={18} color={getTextColor('gray.7', theme)} />
          </ActionIcon>
        </Tooltip>
      )}
    </CopyButton>
  )
}

export default CopyShareUrl
