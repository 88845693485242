import React, { FC, useContext } from 'react'
import { NumberInput as MantineNumberInput } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import ConditionContext from '../ConditionContext'
import { onChangeCallback } from './type'

interface Props {
  onChange: onChangeCallback
}

const NumberInput: FC<Props> = (props: Props) => {
  const {
    condition: { secondNode },
  } = useContext(ConditionContext)
  const { onChange } = props
  const { t } = useTranslation('common')
  const secondNodeValue = secondNode?.value as number

  const handleChange = (value: number) => {
    if (value !== secondNodeValue) {
      onChange({ value })
    }
  }

  return (
    <MantineNumberInput
      value={secondNodeValue}
      placeholder={t('placeholders.pleaseInput')}
      onChange={handleChange}
      removeTrailingZeros
      precision={2}
    />
  )
}

export default NumberInput
