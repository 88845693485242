import { outsideModalBodyHeight } from 'config/constants'

export const modalId = 'customerDetail'

// marginY(24*2) + title height(25) + spacing(24)
const filledRecordsSpacing = 48 + 25 + 24
export const submittedRecordsHeight = outsideModalBodyHeight + filledRecordsSpacing
// body paddingBottom(24)
const basicInfoSpacing = 40
export const basicInfoHeight = outsideModalBodyHeight + basicInfoSpacing
