import React from 'react'
import { Text } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { ConditionGroupOperator as ConditionGroupOperatorDomain } from 'types/domain'
import { useConditionGroup } from 'components/business/Filter/DefaultFilter/ConditionGroup'
import ConditionGroupOperatorSelector from './ConditionGroupOperatorSelector'

const ConditionGroupOperator = () => {
  const { t } = useTranslation('componentConditionGroup')
  const {
    state: { operator },
    dispatch,
  } = useConditionGroup()

  const handleChange = (value: ConditionGroupOperatorDomain) => {
    dispatch({
      type: 'conditionGroupOperatorChange',
      value: value,
    })
  }
  return (
    <div className="mb-[18px]">
      <Text mt="md" color="dimmed" span className="mr-xs">
        {t('conditionGroupOperator.prefix')}
      </Text>
      <ConditionGroupOperatorSelector value={operator} onChange={handleChange} />
      <Text mt="md" color="dimmed" span className="ml-xs">
        {t('conditionGroupOperator.suffix')}
      </Text>
    </div>
  )
}

export default ConditionGroupOperator
