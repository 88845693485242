import React from 'react'
import { Text } from '@mantine/core'
import { openModal } from '@mantine/modals'
import useTranslation from 'next-translate/useTranslation'
import ModalContent from '../HistoriesModalContent'
import { Webhook } from 'types/domain'

const webhookHistoriesModalId = 'webhookHistoriesModal'
const modalInnerPadding = 90
const modalBodyPadding = 24
const modalTitleHeight = 44
const outsideModalBodyHeight = modalInnerPadding * 2 + modalBodyPadding * 2 + modalTitleHeight

interface Props {
  applicationId: string
  webhookUrl?: string
  webhook?: Pick<Webhook, 'id' | 'channel'>
}

export default function useWebhookHistoriesModal() {
  const { t } = useTranslation('integration')

  return (props: Props) => {
    openModal({
      modalId: webhookHistoriesModalId,
      withinPortal: true,
      closeOnClickOutside: false,
      trapFocus: false,
      size: 1000,
      title: (
        <Text weight={600} size="md">
          {t('webhook.histories.title')}
        </Text>
      ),
      styles: {
        content: {
          overflow: 'hidden !important',
        },
        inner: {
          paddingTop: `${modalInnerPadding}px`,
          paddingBottom: `${modalInnerPadding}px`,
        },
      },
      children: <ModalContent {...props} h={`calc(100vh - ${outsideModalBodyHeight}px)`} />,
    })
  }
}
