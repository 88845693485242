import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { useBuilderFormContext } from 'components/business/CampaignBuilder'
import { InlineInput } from 'components/ui'

import StyleItem from '../../StyleItem'

const WidgetButtonLabel: FC = () => {
  const { t } = useTranslation('componentCampaignBuilder')
  const form = useBuilderFormContext()

  return (
    <StyleItem label={t('styles.button.label')}>
      <InlineInput
        autoRestore
        autoComplete="off"
        radius={4}
        maxLength={20}
        {...form.getInputProps('widget.setting.label')}
      />
    </StyleItem>
  )
}

export default WidgetButtonLabel
