import React, { useMemo } from 'react'
import { Tooltip } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { AddButton } from 'components/ui/Button'
import { useConditionGroup } from '../ConditionGroup'
import { defaultCondition, maxLength } from '../constants'

const ConditionAdder = () => {
  const { t } = useTranslation('componentConditionGroup')
  const {
    state: { conditions },
    dispatch,
  } = useConditionGroup()

  const disabled = useMemo(() => {
    return maxLength <= conditions.length
  }, [conditions.length])

  const handleAddClick = () => {
    dispatch({
      type: 'addCondition',
      condition: defaultCondition,
    })
  }

  return (
    <Tooltip withArrow disabled={!disabled} position="top-start" label={t('maxConditionCount', { count: maxLength })}>
      {/* Add extra div element fix tooltip not render when button is disabled */}
      <div>
        <AddButton disabled={disabled} mt="sm" onClick={handleAddClick} text={t('addCondition')} />
      </div>
    </Tooltip>
  )
}

export default ConditionAdder
