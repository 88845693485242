import React, { useContext } from 'react'
import { Group, Tooltip } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import DataContext from '../DataContext'
import { useConditionGroup } from '../ConditionGroup'
import { AdderType } from 'components/business'
import { AddButton } from 'components/ui/Button'
import VipIconWithPopover from 'components/business/VipIconWithPopover'

const ConditionAdder = ({ feature, disabled }: AdderType) => {
  const { t } = useTranslation('componentConditionGroup')
  const { maxLength, defaultCondition } = useContext(DataContext)
  const {
    state: { conditions },
    dispatch,
  } = useConditionGroup()

  const handleAddClick = () => {
    dispatch({
      type: 'addCondition',
      condition: defaultCondition,
    })
  }

  const exceedMaxLength = maxLength <= conditions.length

  return (
    <Group mt="md" spacing="xs">
      <Tooltip
        withArrow
        disabled={!exceedMaxLength}
        position="top-start"
        label={t('maxConditionCount', { count: maxLength })}
        offset={-8}
      >
        <div>
          <AddButton disabled={disabled || exceedMaxLength} onClick={handleAddClick} text={t('common:actions.add')} />
        </div>
      </Tooltip>
      {feature && <VipIconWithPopover feature={feature} />}
    </Group>
  )
}

export default ConditionAdder
