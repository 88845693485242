import React from 'react'
import { fill } from 'lodash-es'
import { Box, Group, Stack, useMantineTheme } from '@mantine/core'
import { getBackgroundColor } from 'config/themeOverride/getColor'

export type Step = {
  current: number
  total: number
}

const StepProgress = ({ current, total }: Step) => {
  const items = fill(Array(total), 'a')
  const theme = useMantineTheme()

  return (
    <Stack align="center" mb="xl" mt="-md">
      <Group spacing="sm">
        {items.map((item, index) => (
          <Box
            key={index}
            w="40px"
            h="4px"
            className="rounded-[1px]"
            bg={current > index ? getBackgroundColor('brandBlue.7', theme) : getBackgroundColor('gray.2', theme)}
          />
        ))}
      </Group>
    </Stack>
  )
}

export default StepProgress
