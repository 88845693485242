import React, { FC } from 'react'
import { Select } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { ValueNodeProps } from './type'
import { EntrySubmittedOn } from 'types/domain'

const SubmittedOnSelector: FC<ValueNodeProps> = ({ condition, onChange, readOnly }) => {
  const { t } = useTranslation('componentEntryFilter')
  const options = [EntrySubmittedOn.Completed, EntrySubmittedOn.Expired].map((code) => ({
    label: t(`submitOnType.${code}`),
    value: code,
  }))

  return (
    <Select
      withinPortal
      data={options}
      placeholder={t('common:placeholders.pleaseSelect')}
      onChange={(value) => onChange?.(value!)}
      value={condition.secondNode?.value}
      readOnly={readOnly}
    />
  )
}

export default SubmittedOnSelector
