import { Signup } from 'config/constants'
import { useState } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { Badge, Box, Button, Group, List, Stack, Text, Title } from '@mantine/core'
import { isNil } from 'lodash-es'
import { PriceInterval, Product, useCreateProductPurchaseUrlMutation } from 'types/domain'
import { Icon } from 'components/ui'
import { useVipColor } from 'components/business'
import { paidProductCodes, ProductCode } from 'config/constants/products'
import Price from './Price'

export interface ProductIntroProps {
  product: Product
  priceInterval: PriceInterval
  currentCode?: ProductCode
  disablePurchase?: boolean
  feature?: string
  fromSite: boolean
}

const ProductIntro = (props: ProductIntroProps) => {
  const { t } = useTranslation('product')
  const router = useRouter()
  const getProductColor = useVipColor()
  const { product, priceInterval, currentCode, disablePurchase, feature, fromSite } = props
  const { code, name, prices } = product
  const price = prices.find((price) => price.interval === priceInterval)
  const [loading, setLoading] = useState(false)
  const [createPurchaseUrl] = useCreateProductPurchaseUrlMutation()

  const descriptions = t(`product:${code}.descriptions`, {}, { returnObjects: true }) as string[]
  const isPaid = code !== ProductCode.Free
  const isActiveTrialing = currentCode === ProductCode.Trial && code === ProductCode.Basic
  const isActive = currentCode === code || isActiveTrialing
  const disablePurchaseBtn =
    disablePurchase ||
    isNil(price) ||
    loading ||
    (currentCode && paidProductCodes.includes(currentCode) && currentCode !== code)

  const handleClick = () => {
    setLoading(true)
    if (fromSite) {
      jumpToSignUp()
    } else {
      createPurchaseUrl({
        variables: {
          input: {
            priceCode: price!.code,
            feature,
          },
        },
        onCompleted({ createProductPurchaseUrl }) {
          createProductPurchaseUrl && router.push(createProductPurchaseUrl)
        },
      })
    }
  }

  const jumpToSignUp = () => {
    const query = isPaid ? { productCode: code } : {}
    router.push({
      pathname: Signup,
      query,
    })
  }

  return (
    <Box
      m="sm"
      sx={(theme) => ({
        background: 'linear-gradient(309.58deg, rgba(235, 239, 250, 0.5) 20.01%, rgba(237, 242, 252, 0) 81.4%)',
        borderRadius: theme.radius.md,
      })}
    >
      <Stack px="2xl" py="xl" mih={310} spacing="xl">
        <Group position="center" spacing="sm">
          {isPaid && <Icon name="vip" style={{ color: getProductColor(code as ProductCode) }} />}
          <Title order={2} lh="36px">
            {name}
          </Title>
          {isActive && (
            <Badge variant="filled" color="brandBlue.4" radius="xs" px="sm" fw={400} h={24}>
              {isActiveTrialing ? t('product:trialing') : t('product:current')}
            </Badge>
          )}
        </Group>
        <List
          center
          spacing="sm"
          size="sm"
          icon={<Box bg="brandBlue.7" w={5} h={5} sx={{ transform: 'rotate(135deg)', borderRadius: 1 }} />}
        >
          {descriptions.map((desc, index) => (
            <List.Item key={index}>{desc}</List.Item>
          ))}
        </List>
        {isPaid ? (
          <Stack mt="auto" spacing="lg">
            {price ? (
              <Price price={price} />
            ) : (
              <Text fz={16} fw={600} ta="center">
                {t(`product:price.intervalUnsupported.${priceInterval}`)}
              </Text>
            )}
            <Button fullWidth size="md" disabled={disablePurchaseBtn} onClick={handleClick}>
              {isActive ? t('product:renewNow') : t('product:purchaseNow')}
            </Button>
          </Stack>
        ) : (
          <>
            {fromSite && (
              <Button mt="auto" fullWidth variant="default" size="md" onClick={jumpToSignUp}>
                {t('product:tryFree')}
              </Button>
            )}
          </>
        )}
      </Stack>
    </Box>
  )
}

export default ProductIntro
