import React from 'react'
import { Feature } from 'types/domain'
import { FeatureTip } from 'components/business'
import useAiFormsCountQuota from './useAiFormsCountQuota'
import useAccountSubscriptionCache from 'components/business/hooks/useAccountSubscriptionCache'

export default function useCheckCreateSurveyByAi() {
  const { vasBalance } = useAccountSubscriptionCache()
  const quota = useAiFormsCountQuota()
  const balance = vasBalance?.[Feature.AiFormsCount]?.balance
  if (balance === undefined) {
    return {
      disabled: false,
    }
  }

  const disabled = balance <= 0
  const disabledTip = disabled ? <FeatureTip feature={Feature.AiFormsCount} transQuery={{ count: quota }} /> : undefined

  return {
    disabled,
    disabledTip,
  }
}
