import React, { FC } from 'react'
import { Anchor, Center, Group, Image, Stack, Text, ThemeIcon, Title, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'
import { Icon } from 'components/ui'
import Trans from 'next-translate/Trans'
import replyImg from './reply.gif'
import HelpDoc from '../../../../../HelpDoc'
import { replyHelpLink } from 'config'

const BlankReply: FC = () => {
  const { t } = useTranslation('entry')
  const theme = useMantineTheme()

  return (
    <Center mt="3xl">
      <Stack spacing="md" align="center" mx="xl">
        <ThemeIcon radius="xl" color={getBackgroundColor('gray.0', theme)} size={48}>
          <Icon
            name="reply"
            size={32}
            style={{
              color: getTextColor('gray.5', theme),
              // @ts-ignore
              '--filled-color': 'var(--mantine-color-gray-2)',
            }}
          />
        </ThemeIcon>
        <Group spacing="xs">
          <Title order={6}>{t('entryModal.reply.title')}</Title>
          <HelpDoc content={t('entryModal.reply.help')} width={280} moreLink={replyHelpLink} />
        </Group>

        <Text ta="center" color="dimmed">
          <Trans
            i18nKey="entry:entryModal.reply.description"
            components={{
              component: <Anchor href={replyHelpLink} target="_blank" />,
            }}
          />
        </Text>
        <Image src={replyImg.src} />
      </Stack>
    </Center>
  )
}

export default BlankReply
