import { ApplicationCustomerAttributeType } from 'components/business/Attribute'
import { PAGE_SIZE } from 'config/constants'
import { concat } from 'lodash-es'
import { Translate } from 'next-translate'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useApplicationCustomerAttributesQuery } from 'types/domain'

export default function useGetApplicationCustomerAttributes() {
  const { t } = useTranslation('customer')
  const router = useRouter()
  const { appId } = router.query

  const { data, loading } = useApplicationCustomerAttributesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: appId as string,
      pageArguments: {
        first: PAGE_SIZE.UN_LIMIT,
      },
    },
  })

  const attributes = data?.application?.applicationCustomerAttributes?.nodes ?? []
  const defaultCustomerAttributes = getDefaultCustomerAttributes(t)
  return loading ? [] : concat(defaultCustomerAttributes, attributes)
}

const getDefaultCustomerAttributes = (t: Translate) => {
  return [
    {
      displayName: t('customer:customer_uid'),
      aliases: ['uid'],
      code: 'uid',
      readonly: true,
    },
    {
      displayName: t('customer:customer_name'),
      aliases: ['name'],
      code: 'name',
      readonly: true,
    },
    {
      displayName: t('customer:customer_mobile'),
      aliases: ['mobile'],
      code: 'mobile',
      readonly: true,
    },
    {
      displayName: t('customer:customer_email'),
      aliases: ['email'],
      code: 'email',
      readonly: true,
    },
  ] as ApplicationCustomerAttributeType[]
}
