import React, { FC, useContext, useEffect } from 'react'
import { TextInput as InputText } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import ConditionContext from '../ConditionContext'
import DataContext from '../../DataContext'
import { onChangeCallback } from './type'

interface Props {
  onChange: onChangeCallback
}

const TextInput: FC<Props> = (props: Props) => {
  const { condition } = useContext(ConditionContext)
  const {
    secondNode: { autoFocus },
  } = useContext(DataContext)
  const { onChange } = props
  const { t } = useTranslation('common')
  let inputRef: any = null

  const handleChange = (value: string) => {
    const newValue = value.trim()
    if (newValue !== condition.secondNode?.value) {
      onChange({ value: newValue })
    }
  }

  useEffect(() => {
    if (autoFocus) {
      inputRef?.focus()
    }
  }, [autoFocus, inputRef])

  return (
    <InputText
      ref={(input) => {
        inputRef = input
      }}
      value={(condition.secondNode?.value as string) || ''}
      placeholder={t('common:placeholders.pleaseInput')}
      onChange={(e) => handleChange(e.currentTarget.value)}
    />
  )
}

export default TextInput
