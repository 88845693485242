import React from 'react'
import { isEmpty, keyBy } from 'lodash-es'
import { Anchor, Group, ScrollArea, Stack, Text, Timeline, Tooltip, useMantineTheme } from '@mantine/core'
import Trans from 'next-translate/Trans'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { entryResolver } from 'components/business/Campaign/Entries/components/EntriesTable/utils/formFieldResolver'
import { EntryModalData, useEntryModal } from 'components/business/Campaign/EntryModal'
import { isCacheHit } from 'plugins/apollo/cacheUtils'
import { getTextColor } from 'config/themeOverride/getColor'
import Loading from 'components/ui/Loading'
import {
  CampaignType,
  CustomerSubmittedEntriesDocument,
  Entry,
  Field,
  useCampaignEntryComponentLazyQuery,
  useCustomerSubmittedEntriesQuery,
} from 'types/domain'
import {
  ApplicationFeedbackShow,
  ApplicationNotificationShow,
  ApplicationSurveyShow,
  PAGE_SIZE,
} from 'config/constants'
import { formatISOTime } from 'utils'
import { submittedRecordsHeight } from '../constant'
import Blank from './Blank'

const overviewPath = {
  [CampaignType.Feedback]: ApplicationFeedbackShow,
  [CampaignType.Survey]: ApplicationSurveyShow,
  [CampaignType.Notification]: ApplicationNotificationShow,
}

type Props = {
  id: string
  manageable: boolean
}

const SubmittedRecords = (props: Props) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('pageCustomerSegment')
  const appId = useRouter().query.appId as string
  const [queryCampaignEntry] = useCampaignEntryComponentLazyQuery()
  const { id, manageable } = props
  const { open } = useEntryModal(manageable)

  const { data, loading, variables } = useCustomerSubmittedEntriesQuery({
    fetchPolicy: 'cache-and-network',
    skip: !id,
    variables: {
      id,
      pageArguments: {
        first: PAGE_SIZE.UN_LIMIT,
      },
    },
  })

  const submittedEntries = (data?.customer?.submittedEntries?.nodes ?? []) as Entry[]

  const handleEntryDetailClick = async (campaignId: string, serialNumber: number) => {
    const { data } = await queryCampaignEntry({
      variables: {
        campaignId,
        serialNumber,
        skipBrowserInfo: false,
      },
    })
    const formFields = (data?.campaign?.form?.fields ?? []) as Field[]
    const fieldCodeMap = keyBy(formFields, (field) => field.code)

    open({
      data: entryResolver(data?.campaignEntry! as Entry, fieldCodeMap) as EntryModalData,
      fields: formFields,
      campaignId: data?.campaign?.id!,
      campaignType: data?.campaign?.type!,
      widgetType: data?.campaign?.widget.type!,
    })
  }

  return (
    <Stack my="xl" className="flex-1">
      <Text size="md" px="lg" weight={600} color={getTextColor('gray.7', theme)}>
        {t('customerDetail.submittedRecords.title')}
      </Text>
      {loading && !isCacheHit(variables, CustomerSubmittedEntriesDocument) ? (
        <Loading />
      ) : (
        <>
          {isEmpty(submittedEntries) ? (
            <Blank />
          ) : (
            <ScrollArea.Autosize mt="lg" pl="lg" mah={`calc(100vh - ${submittedRecordsHeight}px)`} scrollbarSize={5}>
              <Timeline
                color="gray.4"
                active={-1}
                lineWidth={1}
                bulletSize={6}
                className="mr-lg"
                styles={(theme) => ({
                  item: {
                    ':not(:first-of-type)': {
                      marginTop: `${theme.spacing.lg}`,
                    },
                    paddingLeft: `${theme.spacing.lg}`,
                    ':before': {
                      top: '8px',
                    },
                  },
                  itemBullet: {
                    top: '8px',
                  },
                })}
              >
                {submittedEntries.map((entry: Entry) => (
                  <Timeline.Item key={entry.id} bullet={<i className="rounded-full" />}>
                    <Group spacing="xs" noWrap>
                      <Tooltip label={entry.campaign.name} withArrow>
                        <Text lineClamp={1}>
                          <Trans
                            i18nKey="pageCustomerSegment:customerDetail.submittedRecords.campaign"
                            values={{ type: t(`common:campaignTypeName.${entry.campaign.type}`) }}
                            components={{
                              campaign: (
                                <Anchor
                                  target="_blank"
                                  href={`${overviewPath[entry.campaign.type]
                                    .replace('[appId]', appId)
                                    .replace('[campaignId]', entry.campaign.id)}`}
                                >
                                  {entry.campaign.name}
                                </Anchor>
                              ),
                            }}
                          />
                        </Text>
                      </Tooltip>
                    </Group>
                    <Group spacing={0} my="xs">
                      <Text lineClamp={1} onClick={() => handleEntryDetailClick(entry.campaign.id, entry.serialNumber)}>
                        <Trans
                          i18nKey="pageCustomerSegment:customerDetail.submittedRecords.viewClick"
                          components={{
                            detail: (
                              <Anchor
                                component="button"
                                type="button"
                                onClick={() => handleEntryDetailClick(entry.campaign.id, entry.serialNumber)}
                              />
                            ),
                          }}
                        />
                      </Text>
                    </Group>
                    <Text size="xs" c="dimmed">
                      {formatISOTime(entry.createdAt)}
                    </Text>
                  </Timeline.Item>
                ))}
              </Timeline>
            </ScrollArea.Autosize>
          )}
        </>
      )}
    </Stack>
  )
}

export default SubmittedRecords
