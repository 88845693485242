import { useMemo } from 'react'
import { useCheckFeature } from 'components/business'
import { Feature, Field, FormFieldType, SelectableField, TextArea } from 'types/domain'
import { SELECTABLE_FIELDS } from 'config/constants/fieldType'

export default function usePublishedFields(fields: Field[]) {
  const checkFeature = useCheckFeature()
  const canUseScreenshot = checkFeature(Feature.ScreenshotField)
  return useMemo(() => {
    return fields.map((field) => {
      if (SELECTABLE_FIELDS.includes(field.type as FormFieldType)) {
        return {
          ...field,
          choices: (field as SelectableField)?.choices?.filter(({ hidden }) => !hidden),
        }
      }
      if (field.type === FormFieldType.TextArea) {
        const textAreaField = field as TextArea
        return {
          ...field,
          screenshotsEnabled: !!canUseScreenshot && textAreaField.screenshotsEnabled,
        }
      } else {
        return field
      }
    })
  }, [canUseScreenshot, fields])
}
