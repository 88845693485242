import React, { FC } from 'react'
import { Button, Popover, Text, Tooltip } from '@mantine/core'
import { useDisclosure, useWindowEvent } from '@mantine/hooks'
import useTranslation from 'next-translate/useTranslation'
import { Icon } from 'components/ui'

import { getBackgroundColor } from 'config/themeOverride/getColor'
import dynamic from 'next/dynamic'

const ReviewContent = dynamic(() => import('./ReviewContent'))

interface Props {
  id: string
  showText?: boolean
}

const DistributionReview: FC<Props> = ({ id, showText = false }) => {
  const { t } = useTranslation('componentCampaignDistribution')

  const [opened, { close, open }] = useDisclosure(false)
  const [tooltipOpened, { close: tooltipClose, open: tooltipOpen }] = useDisclosure(false)

  useWindowEvent('resize', close)

  const handleClick = (event: any) => {
    event.stopPropagation()
    open()
    tooltipClose()
  }

  const handleContentClick = (event: any) => {
    event.stopPropagation()
  }

  return (
    <Popover width={400} position="bottom-end" shadow="md" withinPortal opened={opened}>
      <Popover.Target>
        <Tooltip label={t('name')} opened={tooltipOpened} withArrow withinPortal>
          <Button
            c="dimmed"
            onClick={handleClick}
            onMouseEnter={tooltipOpen}
            onMouseLeave={tooltipClose}
            variant="subtle"
            sx={(theme) => ({
              '&:hover': {
                backgroundColor: getBackgroundColor('gray.1', theme),
              },
            })}
            leftIcon={<Icon name="cursorBox" className="mr-0" />}
            px="sm"
          >
            {showText && <Text>{t('shortname')}</Text>}
          </Button>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown p={0} pb="sm" onClick={handleContentClick}>
        <ReviewContent id={id} close={close} />
      </Popover.Dropdown>
    </Popover>
  )
}

export default DistributionReview
