import React from 'react'
import { Textarea, Text, Stack } from '@mantine/core'
import { size } from 'lodash-es'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  value: string[]
  maxsize?: number
  onChange?: (value: string[]) => void
  maxRows?: number
  minRows?: number
  placeholder?: string | undefined
}

const MTextarea = (props: Props) => {
  const { t } = useTranslation('common')
  const { value, onChange, maxRows, minRows, placeholder, maxsize = 1000 } = props

  return (
    <Stack
      spacing={0}
      sx={(theme) => ({
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: `${theme.radius.sm}`,
      })}
      pb="sm"
    >
      <Textarea
        variant="unstyled"
        {...props}
        value={value.join('\n')}
        placeholder={placeholder}
        maxRows={maxRows}
        minRows={minRows}
        error={size(value) > maxsize ? t('common:batchEdit.maxsizeError', { maxsize }) : null}
        onChange={(e) => onChange?.(e.currentTarget.value.split('\n'))}
        styles={(theme) => ({
          input: {
            padding: `${theme.spacing.sm} !important`,
          },
        })}
      />
      <Text size="xs" color="dimmed" className="self-end" mr="sm">
        {t('common:batchEdit.sizeTips', { size: size(value), maxsize })}
      </Text>
    </Stack>
  )
}

export default MTextarea
