import React from 'react'
import useTranslation from 'next-translate/useTranslation'

import { useBuilderFormContext } from 'components/business/CampaignBuilder'
import { RadioGroup } from 'components/ui'
import { TextAlign } from 'types/domain'

import StyleItem from '../../StyleItem'

const textAlignPath = 'formSetting.textStyle.align'

const TextAlignSetting = () => {
  const { t } = useTranslation('componentCampaignBuilder')
  const form = useBuilderFormContext()

  const options = Object.values(TextAlign)
    .map((value) => ({
      label: t(`styles.textStyle.align.${value}`),
      value,
    }))
    .reverse()

  const { value, onChange } = form.getInputProps(textAlignPath)

  return (
    <StyleItem label={t('styles.textStyle.title')}>
      <RadioGroup data={options} value={value ?? TextAlign.Left} onChange={onChange} />
    </StyleItem>
  )
}

export default TextAlignSetting
