import React, { FC } from 'react'
import { MultiSelect } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { ValueNodeProps } from './type'
import { SelectableField, ConditionOperator } from 'types/domain'
import { isEmpty, pick } from 'lodash-es'
import { filterSelectComponentDropDownStyle } from 'components/business/Filter/FieldFilterPopover/Filter/constants'

const SingleChoiceSelector: FC<ValueNodeProps> = ({ fields = [], condition, onChange, readOnly }) => {
  const { t } = useTranslation('common')
  const firstNodeField = fields.find((field) => field.code === condition.firstNode.value) as SelectableField
  const options = firstNodeField.choices.map((c) => pick(c, ['label', 'value'])) || []

  const handleOnChange = (value: string[]) => {
    const validValue = isEmpty(value) ? undefined : value
    onChange?.(validValue)
  }

  const onlyOneSelected = ![ConditionOperator.Include, ConditionOperator.Exclude].includes(condition.operator)
  const maxSelectedProps = onlyOneSelected
    ? {
        maxSelectedValues: 1,
      }
    : {}

  return (
    <MultiSelect
      withinPortal
      data={options}
      placeholder={t('placeholders.pleaseSelect')}
      value={condition.secondNode?.value ?? []}
      onChange={handleOnChange}
      {...maxSelectedProps}
      {...filterSelectComponentDropDownStyle}
      readOnly={readOnly}
    />
  )
}

export default SingleChoiceSelector
