import { Crisp } from 'crisp-sdk-web'
import { useEffect } from 'react'
import { User } from 'types/domain'
import useCurrentUserCache from 'components/business/hooks/useCurrentUserCache'

function identifyCurrentUser(user: User) {
  const email = user.email ?? `${user.mobile}@howxm.com`
  const accountName = user.accountUser?.account?.name

  Crisp.session.setData({
    user_id: user.id,
    name: user.name,
    account: accountName,
    mobile: user.mobile,
  })

  Crisp.user.setEmail(email)
  Crisp.user.setNickname(user?.name ?? '')
  Crisp.user.setCompany(accountName ?? '', {})
  Crisp.user.setPhone(user?.mobile ?? '')
}

export function useCrisp(autoload = true) {
  const { user } = useCurrentUserCache()
  const crispId = process.env.NEXT_PUBLIC_CRISP_ID

  useEffect(() => {
    if (crispId) {
      Crisp.configure(crispId, {
        autoload: false,
      })
      if (autoload) {
        Crisp.chat?.show()
      }
    }
    return () => {
      if (crispId) {
        Crisp.chat?.hide()
      }
    }
  }, [autoload, crispId])

  if (user) {
    identifyCurrentUser(user)
  }
}

export function openCrisp() {
  Crisp.chat?.open()
}
