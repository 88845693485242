import { Locale } from 'types/domain'
import { cloneDeep } from 'lodash-es'

/**
 * 这里以后会支持自定义，目前是先写死
 */
export const localeMap = {
  [Locale.ZhCn]: {
    branding: '浩客提供技术支持',
    submitButton: '提交',
    nextButton: '下一页',
    ratingDescription: {
      hate: '非常不满意',
      dislike: '不满意',
      neutral: '一般',
      like: '满意',
      love: '非常满意',
    },
    screenshotDescription: {
      screenshot: '截图',
      captured: '已截图',
      inProgress: '截图中',
    },
    otherChoicePlaceholder: '请输入补充信息',
  },
  [Locale.En]: {
    branding: 'Powered by HowXM',
    submitButton: 'Submit',
    nextButton: 'Next',
    ratingDescription: {
      hate: 'Hate',
      dislike: 'Dislike',
      neutral: 'Neutral',
      like: 'Like',
      love: 'Love',
    },
    screenshotDescription: {
      screenshot: 'Screenshot',
      captured: 'Captured',
    },
    otherChoicePlaceholder: 'Please type here...',
  },
  [Locale.Tw]: {
    branding: '浩客提供技术支持',
    submitButton: '提交',
    nextButton: '下一页',
    ratingDescription: {
      hate: '非常不满意',
      dislike: '不满意',
      neutral: '一般',
      like: '满意',
      love: '非常满意',
    },
    screenshotDescription: {
      screenshot: '截图',
      captured: '已截图',
    },
    otherChoicePlaceholder: '请输入补充信息',
  },
}

export default function getDefaultLocaleWords(locale?: Locale) {
  const dupLocaleMap = cloneDeep(localeMap)
  return locale ? dupLocaleMap[locale] : dupLocaleMap[Locale.ZhCn]
}
