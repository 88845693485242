import React, { FC, useContext } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { UrlInput as InputUrl } from 'components/ui'
import ConditionContext from '../ConditionContext'
import { NodeFieldType } from '../../type'

interface Props {
  onChange: (params: NodeFieldType) => void
  className?: string
}

const UrlInput: FC<Props> = ({ onChange, className }) => {
  const { condition, checkUrl } = useContext(ConditionContext)
  const { t } = useTranslation('common')

  const handleChange = (value: string, error?: string) => {
    const { value: originValue, error: originError } = condition.node ?? {}
    if (value !== originValue || error !== originError) {
      onChange({ value, error })
    }
  }

  return (
    <InputUrl
      value={condition.node?.value as string}
      placeholder={t('common:placeholders.pleaseInput')}
      onChange={handleChange}
      allowBlank
      validateFirst
      checkUrl={checkUrl}
      className={className}
    />
  )
}

export default UrlInput
