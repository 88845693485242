import { Translate } from 'next-translate'
import { isEmpty } from 'lodash-es'
import { isBlank, isMobile, isUrl, length } from 'utils'
import { AT_ALL_VALUE, webhookNameMaxLength } from '../constants'

export function validateName(value: string, t: Translate) {
  if (isBlank(value)) {
    return t('common:message.valueRequired')
  }
  if (length(value.trim()) > webhookNameMaxLength * 2) {
    return t('common:message.charTooLong', { size: webhookNameMaxLength })
  }
  return null
}

export function validateUrl(value: string, t: Translate) {
  if (isBlank(value)) {
    return t('common:message.urlRequired')
  }
  if (!isUrl(value?.trim())) {
    return t('common:message.urlInvalid')
  }
  return null
}

export function validateTargets(value: string[] | null | undefined, t: Translate) {
  if (!isEmpty(value) && value!.some((v) => v !== AT_ALL_VALUE && !isMobile(v))) {
    return t('common:message.mobileInvalid')
  }
  return null
}
