import { ProductCode } from 'config/constants/products'
import { useMantineTheme } from '@mantine/core'

export default function useVipColor() {
  const { colors } = useMantineTheme()
  const vipColorMap: any = {
    [ProductCode.Free]: colors.gray[5],
    [ProductCode.Basic]: colors.blue[6],
    [ProductCode.Pro]: colors.yellow[6],
    [ProductCode.Ultimate]: colors.orange[6],
    [ProductCode.Trial]: colors.blue[6],
  }
  return (productCode: ProductCode) => vipColorMap[productCode]
}
