import useTranslation from 'next-translate/useTranslation'
import { isEmail, isMobile } from 'utils'

export default function useFormValidations() {
  const { t } = useTranslation('user')

  const validateIdentifier = (value: string) => {
    if (!value) {
      return t('authentication.form.errors.identifierRequired')
    }
    if (!isMobile(value.trim())) {
      return t('authentication.form.errors.mobileInvalid')
    }
    return null
  }

  const validateEmail = (value: string) => {
    if (!value) {
      return t('authentication.form.errors.emailRequired')
    }
    if (!isEmail(value.trim())) {
      return t('authentication.form.errors.emailInvalid')
    }
    return null
  }

  const validatePassword = (value: string) => {
    if (!value) {
      return t('authentication.form.errors.passwordRequired')
    }
    if (value.trim().length == 0) {
      return t('authentication.form.errors.passwordAllBlank')
    }
    if (value.length < 6) {
      return t('authentication.form.errors.passwordStrengthInvalid')
    }
    return null
  }

  const validateConfirmPassword = (confirmPassword: string, password: string) => {
    if (!confirmPassword) {
      return t('authentication.form.errors.passwordRequired')
    }
    return confirmPassword !== password ? t('authentication.form.errors.confirmPasswordMismatch') : null
  }

  const validateCaptcha = (value: string) => {
    return !!value ? null : t('authentication.form.errors.captchaRequired')
  }

  const validateAccountName = (value: string) => {
    return value.trim().length == 0 ? t('authentication.form.errors.accountNameRequired') : null
  }

  const resetFormValidate = (values: any, form: any) => {
    return {
      identifier: form.isDirty('identifier') ? validateIdentifier(values.identifier) : null,
      captcha: validateCaptcha(values.captcha),
      password: validatePassword(values.password),
      confirmPassword: validateConfirmPassword(values.confirmPassword, values.password),
    }
  }

  const signupFormValidate = (values: any, form: any) => {
    return {
      identifier: form.isDirty('identifier') ? validateIdentifier(values.identifier) : null,
      captcha: validateCaptcha(values.captcha),
      password: validatePassword(values.password),
    }
  }

  return {
    resetFormValidate,
    signupFormValidate,
    validateIdentifier,
    validateEmail,
    validatePassword,
    validateConfirmPassword,
    validateCaptcha,
    validateAccountName,
  }
}
