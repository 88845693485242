import React, { memo, useContext, useMemo } from 'react'
import { isArray } from 'lodash-es'

import CustomerSegmentSelector from 'components/business/Customer/CustomerSegmentSelector'

import ConditionContext from '../ConditionContext'
import { onChangeCallback } from './type'

type Props = {
  onChange: onChangeCallback
}

const CustomerSegmentNode = (props: Props) => {
  const { onChange } = props
  const { condition } = useContext(ConditionContext)

  const secondNodeValue = condition?.secondNode?.value as string | string[]
  const currentValue = useMemo(() => {
    return secondNodeValue ? (isArray(secondNodeValue) ? secondNodeValue : [secondNodeValue]) : []
  }, [secondNodeValue])

  const handleChange = (value: string[]) => {
    const { value: originValue } = condition.secondNode ?? {}
    if (value !== originValue) {
      onChange({
        value,
        error: undefined,
      })
    }
  }

  return <CustomerSegmentSelector value={currentValue} onChange={handleChange} />
}

export default memo(CustomerSegmentNode)
