import React from 'react'
import { HoverCard } from '@mantine/core'
import { Feature } from 'types/domain'
import { FeatureTip } from 'components/business'
import { TranslationQuery } from 'next-translate'
import VipIcon from 'components/business/VipIcon'

interface Props {
  feature: Feature
  transQuery?: TranslationQuery
}

const VipIconWithPopover = ({ feature, transQuery }: Props) => {
  return (
    <HoverCard>
      <HoverCard.Target>
        <div>
          <VipIcon />
        </div>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <FeatureTip feature={feature} transQuery={transQuery} />
      </HoverCard.Dropdown>
    </HoverCard>
  )
}

export default VipIconWithPopover
