import React, { FC, useMemo } from 'react'
import { ValueNodeProps } from './type'
import NumberSelector from './NumberSelector'
import ChoiceSelector from './ChoiceSelector'
import SingleChoiceSelector from './SingleChoiceSelector'
import SubmittedOnSelector from './SubmittedOnSelector'
import RefererSelector from './RefererSelector'
import DeliveryMethodSelector from './DeliveryMethodSelector'
import FormStringInput from './FormStringInput'
import CustomerString from './CustomerString'
import CustomDate from './CustomDate'
import {
  CES_FIELD,
  CHECK_BOX,
  CSAT_FIELD,
  DATETIME_FIELD,
  NPS_FIELD,
  RADIO_BUTTON,
  CTA_FIELD,
  RATING_FIELD,
  TEXT_AREA,
  TEXT_FIELD,
  SystemFilterableField,
} from 'config/constants/fieldType'

const componentMapping = {
  [NPS_FIELD as string]: NumberSelector,
  [CSAT_FIELD as string]: NumberSelector,
  [CES_FIELD as string]: NumberSelector,
  [RATING_FIELD as string]: NumberSelector,
  [CHECK_BOX as string]: ChoiceSelector,
  [RADIO_BUTTON as string]: SingleChoiceSelector,
  [CTA_FIELD as string]: SingleChoiceSelector,
  [TEXT_AREA as string]: FormStringInput,
  [TEXT_FIELD as string]: CustomerString,
  [DATETIME_FIELD as string]: CustomDate,
  [SystemFilterableField.SubmittedOn]: SubmittedOnSelector,
  [SystemFilterableField.Referer]: RefererSelector,
  [SystemFilterableField.DeliveryMethod]: DeliveryMethodSelector,
}
const SecondNode: FC<ValueNodeProps> = (props) => {
  const { condition, fields } = props
  const fieldType = useMemo(
    () => fields.find((field) => field.code === condition.firstNode.value)?.type,
    [fields, condition]
  )

  // For rerender SecondNode components
  const secondNodeKey = `${condition?.firstNode?.type}-${condition?.firstNode?.value}-${condition.operator}`

  const Component = fieldType ? componentMapping[fieldType] || FormStringInput : FormStringInput
  return <Component key={secondNodeKey} {...props} />
}

export default SecondNode
