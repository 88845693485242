import React from 'react'
import { Box, HoverCard, useMantineTheme } from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react'
import { Feature } from 'types/domain'
import Message from './Message'

interface Props {
  feature: Feature
}

const FeatureUnavailableIcon = ({ feature }: Props) => {
  const theme = useMantineTheme()
  return (
    <HoverCard withArrow withinPortal>
      <HoverCard.Target>
        <Box lh={1}>
          <IconAlertCircle size={16} color={theme.colors.red[6]} stroke={1.5} />
        </Box>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Message feature={feature} anchor={{ underline: true }} />
      </HoverCard.Dropdown>
    </HoverCard>
  )
}

export default FeatureUnavailableIcon
