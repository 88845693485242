import { useEffect } from 'react'
import LogRocket from 'logrocket'
import useCurrentUserCache from 'components/business/hooks/useCurrentUserCache'

export default function useLogRocket() {
  const { user } = useCurrentUserCache()

  const logRocketId = process.env.NEXT_PUBLIC_LOGROCKET_ID
  const shouldTrack = logRocketId && user?.createdAt && new Date(user?.createdAt) > new Date(2023, 6, 1)

  useEffect(() => {
    if (shouldTrack) {
      LogRocket.init(logRocketId)
    }
  }, [logRocketId, shouldTrack])

  useEffect(() => {
    if (!user) {
      return
    }

    const { id, name, accountUser } = user
    LogRocket.identify(id, {
      name: name || '',
      accountName: accountUser?.account?.name || '',
    })
  }, [user])

  return {
    shouldTrack,
  }
}
