import { useAbac } from 'react-abac'
import { ApplicationPermission, ApplicationRoleType } from 'types/domain'
import useCurrentUserCache from 'components/business/hooks/useCurrentUserCache'
import useApplicationRoleCache from 'components/business/hooks/useApplicationRoleCache'

export default function useCheckCampaignPermission() {
  const { user } = useCurrentUserCache()

  const { applicationRole } = useApplicationRoleCache()

  const { userHasPermissions } = useAbac()

  return (
    creatorId: string,
    permission: ApplicationPermission.CampaignUpdate | ApplicationPermission.CampaignDelete
  ) => {
    const isCreator = creatorId === user?.id
    const isReader = applicationRole?.type === ApplicationRoleType.ApplicationReader
    return (isCreator || userHasPermissions(permission)) && !isReader
  }
}
