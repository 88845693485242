import { CUSTOMER_EMAIL, CUSTOMER_MOBILE, CUSTOMER_NAME, CUSTOMER_UID } from 'config/constants'
import { forIn } from 'lodash-es'
import { Entry, Field } from 'types/domain'
import Fields from '../fields'

const cellRender = (params: any, field: Field) => {
  const { format } = Fields[field.type] ?? { format: (value) => value }
  return format(params.value, field)
}

export function entryResolver(entry: Entry, fieldCodeMap: any) {
  const entryData = { ...entry.fieldValues }
  forIn(entryData, (value, key) => {
    if (fieldCodeMap[key] != null) {
      entryData[key] = cellRender({ value }, fieldCodeMap[key])
    }
  })

  const customer = entry.customerSnapshot
  const { uid, name, mobile, email } = customer ?? {}

  return {
    id: entry.id,
    customer,
    ...entryData,
    [CUSTOMER_UID]: uid || '匿名用户',
    [CUSTOMER_NAME]: name,
    [CUSTOMER_MOBILE]: mobile,
    [CUSTOMER_EMAIL]: email,
    entry,
  }
}

export function formFieldResolver(entries: Entry[], fieldCodeMap: any) {
  return entries.map((entry) => entryResolver(entry, fieldCodeMap)) ?? []
}
