import { CampaignWidgetButtonType, CampaignWidgetPosition, CampaignWidgetType } from 'types/domain'

export function getDefaultPositionByWidgetType(
  widgetType: CampaignWidgetType,
  widgetButtonType?: CampaignWidgetButtonType
): CampaignWidgetPosition {
  if (widgetType === CampaignWidgetType.Button) {
    return widgetButtonType == CampaignWidgetButtonType.Aside
      ? CampaignWidgetPosition.MiddleRight
      : CampaignWidgetPosition.BottomRight
  } else if (widgetType === CampaignWidgetType.Popup) {
    return CampaignWidgetPosition.BottomRight
  }
  return CampaignWidgetPosition.BottomRight
}
