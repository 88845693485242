import useTranslation from 'next-translate/useTranslation'
import { ConditionNodeType } from 'types/domain'

enum PeriodType {
  Today = 'DAY',
  Yesterday = 'YESTERDAY',
  ThisWeek = 'WEEK',
  LastWeek = 'PREVIOUS_WEEK',
  ThisMonth = 'MONTH',
  LastMonth = 'PREVIOUS_MONTH',
}

export default function usePeriodTypes() {
  const { t } = useTranslation('componentConditionGroup')

  const map = {
    [PeriodType.Today]: t('dateTime.today'),
    [PeriodType.Yesterday]: t('dateTime.yesterday'),
    [PeriodType.ThisWeek]: t('dateTime.thisWeek'),
    [PeriodType.LastWeek]: t('dateTime.lastWeek'),
    [PeriodType.ThisMonth]: t('dateTime.thisMonth'),
    [PeriodType.LastMonth]: t('dateTime.lastMonth'),
  } as { [x: string]: string }

  const getPeriodLabel = (value: string) => {
    return map[value]
  }

  const getPeriodOptions = () => {
    return [
      {
        label: t(`componentConditionGroup:conditionOperator.CUSTOMIZED_DATE`),
        value: ConditionNodeType.CustomizedDate as never,
      },
      ...Object.values(PeriodType).map((value) => ({ value, label: getPeriodLabel(value) })),
    ]
  }

  return {
    getPeriodOptions,
    getPeriodLabel,
  }
}
