import React, { useEffect, useState } from 'react'
import { Group, NumberInput, Slider, Stack, Title } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { RadioGroup } from 'components/ui'
import { ButtonVariant } from 'types/domain'
import { useBuilderFormContext } from 'components/business/CampaignBuilder'
import StyleItem from '../../StyleItem'
import HelpDoc from 'components/business/HelpDoc'

const buttonStylePath = 'formSetting.buttonStyle'
const variantPath = `${buttonStylePath}.variant`
const borderRadiusPath = `${buttonStylePath}.borderRadius`
const radiusMin = 0
const radiusMax = 20

const ButtonStyle = () => {
  const { t } = useTranslation('componentCampaignBuilder')
  const form = useBuilderFormContext()
  const { value: borderRadiusValue, onChange: borderRadiusOnChange } = form.getInputProps(borderRadiusPath)
  const [borderRadiusInputValue, setBorderRadiusInputValue] = useState<number>(borderRadiusValue ?? 0)

  useEffect(() => {
    setBorderRadiusInputValue(borderRadiusValue)
  }, [borderRadiusValue])

  const variantOptions = Object.values(ButtonVariant).map((variant) => ({
    label: t(`styles.buttonStyle.variant.${variant}`),
    value: variant,
  }))

  return (
    <Stack>
      <Group spacing={0}>
        <Title order={6}>{t('styles.buttonStyle.title')}</Title>
        <HelpDoc content={t('styles.buttonStyle.submitButtonTip')} />
      </Group>
      <StyleItem label={t('styles.buttonStyle.variant.title')}>
        <RadioGroup data={variantOptions} {...form.getInputProps(variantPath)} />
      </StyleItem>
      <StyleItem label={t('styles.buttonStyle.borderRadius.title')}>
        <Group spacing="sm">
          <Slider
            min={radiusMin}
            max={radiusMax}
            size="xs"
            thumbSize={10}
            label={(value) => `${value}px`}
            value={borderRadiusInputValue}
            onChange={setBorderRadiusInputValue}
            onChangeEnd={borderRadiusOnChange}
            styles={{ root: { flexGrow: 1 }, thumb: { borderWidth: 3 } }}
          />
          <NumberInput
            min={radiusMin}
            max={radiusMax}
            precision={0}
            autoComplete="off"
            formatter={(value) => `${value || 0}px`}
            parser={(value) => value?.replaceAll(/\D/g, '')}
            value={borderRadiusInputValue}
            onChange={borderRadiusOnChange}
            styles={{ wrapper: { width: 80 } }}
          />
        </Group>
      </StyleItem>
    </Stack>
  )
}

export default ButtonStyle
