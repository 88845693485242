import React, { FC, PropsWithChildren } from 'react'
import { Avatar, Group, Stack, Text, useMantineTheme } from '@mantine/core'
import { EntryComment, User } from 'types/domain'
import useTranslation from 'next-translate/useTranslation'
import { getTextColor } from 'config/themeOverride/getColor'
import { formatTimeByThisYear, getAvatarUrlByUser } from 'utils'
import { differenceInSeconds, parseISO } from 'date-fns'
import CommentContent from './CommentContent'
import Actions from './Actions'
import useCurrentUserCache from 'components/business/hooks/useCurrentUserCache'

type Props = PropsWithChildren & {
  entryId: string
  comment: EntryComment
  onEdit: () => void
  isEditing: boolean
  onSave: () => void
  onCancel: () => void
}

const Comment: FC<Props> = ({ entryId, comment, onEdit, isEditing, onSave, onCancel }) => {
  const { t } = useTranslation('entry')
  const theme = useMantineTheme()
  const { user } = useCurrentUserCache()
  const isCurrentUser = comment.creator.id === user?.id

  const getCreatedAt = () => {
    const createdAt = formatTimeByThisYear(comment.createdAt)
    const isUpdated = differenceInSeconds(parseISO(comment.updatedAt), parseISO(comment.createdAt)) > 1
    return isUpdated ? t('entryModal.comments.createdAt', { createdAt: createdAt }) : createdAt
  }

  return (
    <Group align="start" noWrap>
      <Avatar size={28} radius={14} src={getAvatarUrlByUser(comment.creator as User)} />
      <Stack className="grow" spacing="sm" align="start">
        <Group position="apart" w="100%" noWrap>
          <Text color={getTextColor('gray.7', theme)} className="shrink" lineClamp={1}>
            {isCurrentUser
              ? t('entryModal.comments.creatorName', { name: comment.creator.name })
              : comment.creator.name}
          </Text>
          <Group spacing="xs" className="shrink-0">
            <Text color="dimmed" size="xs">
              {getCreatedAt()}
            </Text>
            {isCurrentUser && <Actions entryId={entryId} onClickEdit={onEdit} comment={comment} />}
          </Group>
        </Group>
        <CommentContent comment={comment} isEditing={isEditing} onSave={onSave} onCancel={onCancel} />
      </Stack>
    </Group>
  )
}

export default Comment
