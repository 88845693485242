import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Box, Group, Stack, Timeline } from '@mantine/core'
import { useSetState } from '@mantine/hooks'
import { useWebhookBuilderFormContext } from '../../formContext'
import Trigger from './Trigger'
import Action from './Action'

const stepTitleHeightPx = 16

const Steps = () => {
  const { t } = useTranslation('integration')
  const form = useWebhookBuilderFormContext()
  const [heights, setHeights] = useSetState({
    trigger: 102,
    action: 122,
  })

  const currentStep = form.values.channel ? 1 : 0
  return (
    <Group align="flex-start" spacing="lg" noWrap>
      <Timeline
        lineWidth={1}
        active={currentStep}
        bulletSize={6}
        align="right"
        color="brandBlue"
        styles={(theme) => ({
          item: {
            paddingRight: theme.spacing.lg,
            ':before': {
              top: 5,
              bottom: -29,
            },
            ':first-of-type': {
              ':before': {
                top: 5,
              },
            },
            '&.last-placeholder': {
              marginTop: 0,
              '.mantine-Timeline-itemBullet': {
                visibility: 'hidden',
              },
            },
          },
          itemBullet: {
            top: 5,
          },
          itemTitle: {
            fontWeight: 400,
            whiteSpace: 'nowrap',
          },
        })}
      >
        <Timeline.Item bullet={<i className="rounded-full" />} title={t('webhook.builder.stepTrigger.title')}>
          <Box h={heights.trigger - stepTitleHeightPx} />
        </Timeline.Item>
        <Timeline.Item bullet={<i className="rounded-full" />} title={t('webhook.builder.stepAction.title')}>
          <Box h={heights.action - stepTitleHeightPx - 28} />
        </Timeline.Item>
        <Timeline.Item className="last-placeholder" />
      </Timeline>
      <Stack className="grow" spacing="xl">
        <Trigger onHeightChange={(height) => setHeights({ trigger: height })} />
        <Action onHeightChange={(height) => setHeights({ action: height })} />
      </Stack>
    </Group>
  )
}

export default Steps
