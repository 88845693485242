import { useEffect } from 'react'
import { useElementSize, usePrevious } from '@mantine/hooks'
import { Paper, px, useMantineTheme } from '@mantine/core'
import { isNil } from 'lodash-es'
import { useWebhookBuilderFormContext } from '../../../formContext'
import Settings from './Settings'
import Channels from './Channels'

interface Props {
  onHeightChange: (height: number) => void
}

const Action = ({ onHeightChange }: Props) => {
  const form = useWebhookBuilderFormContext()
  const theme = useMantineTheme()
  const { ref, height } = useElementSize()
  const previousHeight = usePrevious(height)
  const isEditing = !isNil(form.values.channel)
  const padding = theme.spacing.xl

  useEffect(() => {
    previousHeight !== height && onHeightChange(height + px(padding) * 2 + 2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, previousHeight])

  return (
    <Paper withBorder p={padding} ref={ref}>
      {isEditing ? <Settings /> : <Channels />}
    </Paper>
  )
}

export default Action
