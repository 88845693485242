import { Condition, ConditionGroup } from 'types/domain'
import { isEqual, isNil } from 'lodash-es'

function formatConditionsToCompare(conditions: Condition[]) {
  return conditions.map(
    ({ firstNode, operator, secondNode }) => `${firstNode.value ?? ''}:${operator ?? ''}:${secondNode?.value ?? ''}`
  )
}

export function areConditionGroupsEqual(
  group1: ConditionGroup | null | undefined,
  group2: ConditionGroup | null | undefined
) {
  const group1Nil = isNil(group1)
  const group2Nil = isNil(group2)
  if (group1Nil || group2Nil) {
    return group1Nil && group2Nil
  }
  return (
    group1.operator === group2.operator &&
    group1.conditions.length === group2.conditions.length &&
    isEqual(formatConditionsToCompare(group1.conditions).sort(), formatConditionsToCompare(group2.conditions).sort())
  )
}
