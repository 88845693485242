import { Campaign, useDeleteCampaignMutation } from 'types/domain'
import { message } from 'components/ui'
import { graphqlErrorCatch } from 'utils'
import useTranslation from 'next-translate/useTranslation'
import useCampaignsCountCache from 'components/business/hooks/useCampaignsCountCache'

export default function useDeleteCampaign(appId: string, campaign: Campaign, onSuccess?: () => void) {
  const { id, type } = campaign
  const [deleteCampaignMutation] = useDeleteCampaignMutation()
  const { t } = useTranslation()
  const { decrease } = useCampaignsCountCache(type)

  const deleteCampaign = async () => {
    try {
      const { data } = await deleteCampaignMutation({
        variables: { id },
        onCompleted: () => {
          decrease()
        },
      })
      if (data?.deleteCampaign) {
        message.successMsg(t('common:message.deleteSuccess'))
        onSuccess && onSuccess()
      }
    } catch (e: any) {
      const { graphQLErrors } = e
      graphqlErrorCatch({ graphQLErrors, defaultMessage: t('common:message.deleteError') })
    }
  }

  return {
    deleteCampaign,
  }
}
