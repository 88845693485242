import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { Select } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { isNil } from 'lodash-es'

import { useConditionGroup } from '../../ConditionGroup'
import ConditionContext from '../ConditionContext'
import useOptions from './getOperatorOptions'
import { getOperatorLabel } from './operatorUtils'
import { ConditionOperator } from 'types/domain'

const Operator = () => {
  const { index, condition } = useContext(ConditionContext)
  const { t } = useTranslation('common')
  const { dispatch } = useConditionGroup()
  const options = useOptions()

  const selectOptions = useMemo(() => {
    return (
      options?.map((option) => {
        return {
          value: option,
          label: getOperatorLabel(option as ConditionOperator, t),
        }
      }) || []
    )
  }, [options, t])

  const defaultOperator = useMemo(
    () => (condition?.firstNode?.value ? selectOptions?.[0].value : null),
    [condition?.firstNode?.value, selectOptions]
  )

  const handleChange = useCallback(
    (value: string) => {
      dispatch({
        type: 'conditionOperatorChange',
        index,
        value,
      })
    },
    [dispatch, index]
  )

  useEffect(() => {
    if (!isNil(defaultOperator) && isNil(condition?.operator)) {
      handleChange(defaultOperator)
    }
  }, [condition?.operator, defaultOperator, handleChange])

  return (
    <Select
      placeholder={t('placeholders.pleaseSelect')}
      data={selectOptions}
      value={condition?.operator}
      onChange={handleChange}
    />
  )
}

export default memo(Operator)
