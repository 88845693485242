import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import ExportButton from './components/ExportButton'
import ButtonWithDownload from './components/ButtonWithDownload'
import { useState } from 'react'
import { ExportTargetType, useCompleteExportMutation, useExportUrlQuery } from 'types/domain'

const Export = () => {
  const { t } = useTranslation('entry')
  const router = useRouter()
  const campaignId = router.query.campaignId as string
  const target = { targetId: campaignId, targetType: ExportTargetType.CampaignEntries }
  const [completeExportMutation] = useCompleteExportMutation()
  const [downloadUrl, setDownloadUrl] = useState<string | undefined | null>(null)

  useExportUrlQuery({
    fetchPolicy: 'no-cache',
    variables: target,
    onCompleted: (res) => {
      setDownloadUrl(res.exportUrl)
    },
  })

  const handleDownload = (url: string) => {
    const link = document.createElement('a')
    link.href = url
    link.click()
    completeExportMutation({ variables: target }).then(() => setDownloadUrl(undefined))
  }

  const handleCancel = () => {
    setDownloadUrl(undefined)
  }

  return !!downloadUrl ? (
    <ButtonWithDownload downloadUrl={downloadUrl} onDownload={handleDownload} onCancel={handleCancel} />
  ) : (
    <ExportButton onDownload={handleDownload} />
  )
}
export default Export
