import {
  defaultColorScheme,
  defaultFormSetting,
  defaultLocaleSetting,
  defaultOpenStrategy,
  defaultTargeting,
  defaultWidgetWidth,
} from 'components/business/CampaignBuilder'
import { defaultTriggerOperator } from 'components/business/CampaignBuilder/Distribution/When/constant'
import { BuilderFormDataState } from 'components/business/CampaignBuilder/type'
import getWidgetPosition from 'components/business/CampaignBuilder/utils/getWidgetPosition'
import {
  Align,
  CampaignDistributionChannel,
  CampaignType,
  CampaignWidgetButtonType,
  CampaignWidgetIcon,
  CampaignWidgetType,
  EndingField,
  FieldValidatorType,
  FormFieldType,
  RatingField,
  TextArea,
} from 'types/domain'
import { getTempCode } from 'utils/tempCode'

export const FEEDBACK_RATING_FIELD_CODE = 'field_1'
export const FEEDBACK_TEXT_AREA_CODE = 'field_2'

const getWidgetType = (channel: CampaignDistributionChannel) => {
  switch (channel) {
    case CampaignDistributionChannel.Android:
    case CampaignDistributionChannel.IosSwift:
    case CampaignDistributionChannel.WechatMiniapp:
      return CampaignWidgetType.Popup
    case CampaignDistributionChannel.Web:
      return CampaignWidgetType.Button
    default:
      return CampaignWidgetType.Button
  }
}

export function getDefaultFeedbackWidgetSetting(
  channel: CampaignDistributionChannel = CampaignDistributionChannel.Web
) {
  return {
    position: getWidgetPosition(channel),
    type: getWidgetType(channel),
    colorScheme: defaultColorScheme,
    customizedPosition: {
      align: Align.Right,
      percentageToTop: 60,
    },
    setting: {
      autoOpen: false,
      buttonType: CampaignWidgetButtonType.Aside,
      icon: CampaignWidgetIcon.Comment,
      label: '请给我们评分',
    },
    width: defaultWidgetWidth[CampaignType.Feedback],
  }
}

export const defaultFormData = (
  channel: CampaignDistributionChannel = CampaignDistributionChannel.Web,
  screenshotsEnabled = false
): BuilderFormDataState => {
  return {
    name: '未命名评价',
    type: CampaignType.Feedback,
    enabled: false,
    formFields: [
      {
        code: getTempCode(),
        label: '您如何评价当前的体验？',
        ratingMax: 5,
        ratingMaxDescription: '非常满意',
        ratingMinDescription: '非常不满意',
        ratingType: 'star',
        page: 0,
        type: FormFieldType.RatingField,
        hidden: false,
        labelHidden: false,
        validators: [{ type: FieldValidatorType.Presence }],
      } as RatingField,
      {
        code: getTempCode(),
        label: '请说明满意或不满意的原因',
        page: 1,
        type: FormFieldType.TextArea,
        hidden: false,
        labelHidden: false,
        screenshotsEnabled: channel == CampaignDistributionChannel.Web && screenshotsEnabled,
      } as TextArea,
      {
        code: getTempCode(),
        label: '提交成功',
        page: 2,
        type: FormFieldType.EndingField,
        content: '感谢您的评价！',
        setting: { endingContent: '感谢您的评价！' },
        hidden: false,
        labelHidden: false,
      } as EndingField,
    ],
    formSetting: defaultFormSetting,
    widget: getDefaultFeedbackWidgetSetting(channel),
    targeting: defaultTargeting,
    trigger: {
      conditions: [],
      operator: defaultTriggerOperator,
    },
    openStrategy: defaultOpenStrategy,
    localeSetting: defaultLocaleSetting,
  }
}
