import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Button, Menu, Stack } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { ApplicationSettingsCodeBasic, ApplicationSettingsCodeTracking } from 'config/constants'
import { CampaignDistributionChannel } from 'types/domain'

import RadioBtn from '../RadioBtn'
import Task from './Task'
import useApplicationBaseInfoCache from 'components/business/hooks/useApplicationInfoBaseInfoCache'
import useOnboardingCache from 'components/business/hooks/useOnboardingCache'

const SdkTask = () => {
  const { onboarding } = useOnboardingCache()
  const { t } = useTranslation('componentAppOnboarding')
  const appId = useRouter().query.appId as string
  const { channel } = useApplicationBaseInfoCache()

  const sdkConnected = onboarding?.sdkConnected
  const sdkSettingCompleted = onboarding?.sdkSettingCompleted
  const label: { [key in CampaignDistributionChannel]: string } = {
    [CampaignDistributionChannel.Web]: t('sdk.sdkSetting.web'),
    [CampaignDistributionChannel.IosSwift]: t('sdk.sdkSetting.ios'),
    [CampaignDistributionChannel.Android]: t('sdk.sdkSetting.android'),
    [CampaignDistributionChannel.WechatMiniapp]: t('sdk.sdkSetting.miniapp'),
    [CampaignDistributionChannel.Link]: '',
    [CampaignDistributionChannel.ReactNative]: '',
  }

  return (
    <Task
      value="sdkCompleted"
      checked={!!(sdkConnected && sdkSettingCompleted)}
      title={t('sdk.title')}
      descriptions={t('sdk.descriptions', {}, { returnObjects: true })}
    >
      <Stack mt="lg" spacing="lg">
        <Stack spacing="sm">
          {channel && <RadioBtn checked={sdkSettingCompleted} label={label[channel]} />}
          <Menu.Item ml="2xl" className="w-[fit-content] p-0 hover:!bg-transparent">
            <Link href={{ pathname: ApplicationSettingsCodeBasic, query: { appId } }} legacyBehavior>
              <Button component="a" size="xs">
                {t('sdk.sdkSetting.goSetting')}
              </Button>
            </Link>
          </Menu.Item>
        </Stack>
        <Stack spacing="sm">
          <RadioBtn checked={sdkConnected} label={t('sdk.activeSdk.title')} />
          <Menu.Item ml="2xl" className="w-[fit-content] p-0 hover:!bg-transparent">
            <Link href={{ pathname: ApplicationSettingsCodeTracking, query: { appId } }} legacyBehavior>
              <Button component="a" size="xs">
                {t('sdk.activeSdk.viewCode')}
              </Button>
            </Link>
          </Menu.Item>
        </Stack>
      </Stack>
    </Task>
  )
}

export default SdkTask
