import React, { FC, useMemo } from 'react'
import { Button, Group, Select } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { useBuilderFormContext, useBuilderSharedDataContext } from 'components/business/CampaignBuilder'
import StyleItem from 'components/business/CampaignBuilder/StyleItem'
import { Locale, WordingsInput } from 'types/domain'
import useWordingsModal from './useWordingsModal'
import getDefaultLocaleWords from './utils/getDefaultLocaleWords'

type Props = {}

const availableLocales = [Locale.ZhCn, Locale.En]
const WidgetLocale: FC<Props> = () => {
  const { t } = useTranslation('componentCampaignBuilder')
  const form = useBuilderFormContext()
  const { application, campaignType } = useBuilderSharedDataContext()

  const handleWordingsChange = (wordings: WordingsInput) => {
    const { locale } = form.values.localeSetting!
    form.setFieldValue('localeSetting', { locale, wordings })
  }

  const openModal = useWordingsModal({
    localeSetting: form.values.localeSetting!,
    campaignType: campaignType!,
    application: application!,
    onConfirm: handleWordingsChange,
  })

  const localeOptions = useMemo(
    () =>
      availableLocales.map((value) => ({
        label: t('styles.locale.options.' + value),
        value,
      })),
    [t]
  )

  const handleLocalChange = (value: Locale) => {
    if (form.values.localeSetting?.locale !== value) {
      form.setFieldValue('localeSetting.locale', value)
      form.setFieldValue('localeSetting.wordings', getDefaultLocaleWords(value))
    }
  }

  return (
    <StyleItem label={t('styles.locale.title')}>
      <Group noWrap>
        <Select data={localeOptions} {...form.getInputProps('localeSetting.locale')} onChange={handleLocalChange} />
        <Button variant="subtle" size="xs" onClick={openModal}>
          {t('styles.locale.edit')}
        </Button>
      </Group>
    </StyleItem>
  )
}

export default WidgetLocale
