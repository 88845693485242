import { useBuilderSharedDataContext } from 'components/business/CampaignBuilder'
import { groupBy } from 'lodash-es'
import { useMemo } from 'react'
import { CesField, Field } from 'types/domain'

export default function useEditingPageState(formFields: Field[], readOnly?: boolean) {
  const { editingFieldIndex } = useBuilderSharedDataContext()

  return useMemo(() => {
    if (editingFieldIndex >= 0) {
      const currentField = formFields[editingFieldIndex]
      const formFieldsGroupByPage = groupBy(formFields, 'page')
      const currentPageFormFields = formFieldsGroupByPage[currentField?.page ?? 0]?.map((field) => {
        // when readOnly delete showRules and show all fields
        if (readOnly && (field as CesField).showRules) {
          ;(field as CesField).showRules = []
        }
        return field
      })
      return {
        currentPageFormFields,
      }
    }
    return {}
  }, [editingFieldIndex, formFields, readOnly])
}
