import { createStyles } from '@mantine/core'

const useStyles = createStyles(() => {
  return {
    code: {
      backgroundColor: 'transparent',
      padding: 0,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
    },
  }
})

export default useStyles
