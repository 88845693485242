import React, { FC } from 'react'
import { Center, Text } from '@mantine/core'
import { isNumber } from 'lodash-es'
import { getBackgroundColor } from 'config/themeOverride/getColor'

const NumericCell: FC<any> = ({ renderedCellValue }) => {
  if (!isNumber(renderedCellValue)) {
    return null
  }

  return (
    <Center
      sx={(theme) => ({
        width: 24,
        height: 24,
        background: getBackgroundColor('gray.1', theme),
        borderRadius: theme.radius.sm,
      })}
    >
      <Text>{renderedCellValue}</Text>
    </Center>
  )
}

export default NumericCell
