import React, { FC, ReactNode } from 'react'
import { Box, Center, Container, Stack } from '@mantine/core'

import { Logo, ResponsiveHead } from 'components/ui'
import StepProgress, { Step } from './StepProgress'
import bgImg from './bg.png'
import useHowxmSDK from 'plugins/howxm/useHowxmSDK'
import { HowXMAppId } from 'config'

type LayoutProps = {
  children: ReactNode
  left?: ReactNode
  right?: ReactNode
  responsive?: boolean
  step?: Step
}

const SimpleLayout: FC<LayoutProps> = ({ children, left, right, responsive = true, step }) => {
  useHowxmSDK(HowXMAppId)

  return (
    <>
      {responsive && <ResponsiveHead />}
      <Container
        fluid
        pb="xl"
        sx={{
          backgroundImage: `url(${bgImg.src})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <Box my="2xl" mx="sm" sx={{ position: 'relative' }}>
          <Center className="w-full">
            <Logo />
          </Center>
          {left && <Box sx={{ position: 'absolute', left: 0, top: 0 }}>{left}</Box>}
          {right && <Box sx={{ position: 'absolute', right: 0, top: 0 }}>{right}</Box>}
        </Box>
        {step && <StepProgress current={step.current} total={step.total} />}
        <Stack align="center">{children}</Stack>
      </Container>
    </>
  )
}

export default SimpleLayout
