import React, { useRef, useState } from 'react'
import { Group } from '@mantine/core'

import DesktopPreview from '../../../PlatformPreview/DesktopPreview'
import MobilePreview from '../MobilePreview'
import { useBuilderFormContext } from 'components/business/CampaignBuilder'
import CampaignPreview from 'components/business/CampaignBuilder/CampaignPreview'
import PreviewActions from 'components/business/PlatformPreview/PreviewActions'
import { PlatformType } from 'components/business/PlatformPreview/type'

const TOP_BAR_HEIGHT = 56
const PADDING = 24

type Props = {
  defaultPlatform?: PlatformType
  platformChangeable?: boolean
}
const HybridPreview = ({ defaultPlatform, platformChangeable }: Props) => {
  const campaignPreviewRef = useRef<any>()
  const form = useBuilderFormContext()
  const [platform, setPlatform] = useState<PlatformType>(defaultPlatform ?? 'web')
  const { widget } = form.values

  const handleRefresh = () => {
    campaignPreviewRef?.current?.refresh()
  }

  return (
    <Group
      pos="relative"
      align="center"
      style={{
        height: `calc(100vh - ${TOP_BAR_HEIGHT}px - ${PADDING * 2}px)`,
        width: `${platform === 'web' ? '80%' : ''}`,
      }}
    >
      <PreviewActions
        platformChangeable={platformChangeable}
        widgetType={widget.type}
        onPlatformClick={setPlatform}
        onRefresh={handleRefresh}
        className="top-0 left-[-50px]"
      />
      {platform === 'mobile' ? (
        <MobilePreview>
          <CampaignPreview forcePlatformAs="mobile" campaignPreviewRef={campaignPreviewRef} />
        </MobilePreview>
      ) : (
        <DesktopPreview>
          <CampaignPreview forcePlatformAs="desktop" campaignPreviewRef={campaignPreviewRef} />
        </DesktopPreview>
      )}
    </Group>
  )
}

export default HybridPreview
