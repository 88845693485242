import { FC } from 'react'
import UrlInput from './UrlInput'
import TextInput from './TextInput'

export enum NODE_TYPE {
  TEXT = 'text',
  URL = 'urlInput',
}

export const nodes: { [key in typeof NODE_TYPE[keyof typeof NODE_TYPE]]: FC<any> } = {
  [NODE_TYPE.URL]: UrlInput,
  [NODE_TYPE.TEXT]: TextInput,
}
