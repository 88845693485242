import { Image, Paper } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import ProductIntro, { ProductIntroProps } from './ProductIntro'
import ProductFeatures from './ProductFeatures'
import recommendImg from './recommend.png'
import { recommendBadgeOverflowSize } from './constant'

interface ProductProps extends ProductIntroProps {
  recommend?: boolean
}

const Product = ({ product, fromSite, recommend, ...rest }: ProductProps) => {
  const { t } = useTranslation('product')

  return (
    <Paper
      withBorder
      radius="lg"
      pos="relative"
      sx={{
        boxShadow: '0px 8px 8px -8px rgba(0, 0, 0, 0.1)',
        '&:hover': {
          boxShadow:
            '0px 17px 17px -7px rgba(0, 0, 0, 0.04), 0px 36px 28px -7px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.05)',
        },
      }}
    >
      {recommend && (
        <Image
          src={recommendImg.src}
          alt={t('recommend')}
          width={96}
          pos="absolute"
          top={-recommendBadgeOverflowSize}
          right={-recommendBadgeOverflowSize}
        />
      )}
      <ProductIntro fromSite={fromSite} product={product} {...rest} />
      <ProductFeatures product={product} />
    </Paper>
  )
}

export default Product
