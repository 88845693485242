import { isEmpty } from 'lodash-es'
import React, { forwardRef, useMemo } from 'react'
import { Group, MultiSelect, SelectItem, SelectItemProps, Text, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { IconUser } from '@tabler/icons-react'

import { getTextColor } from 'config/themeOverride/getColor'
import { formatNumberToThousands } from 'utils'
import { useCustomerSegmentsQuery } from 'types/domain'
import { useRouter } from 'next/router'
import { isSystemSegment } from 'components/business/Customer/constant'

interface ItemProps extends SelectItemProps {
  count: number
}

const Item = forwardRef<HTMLDivElement, ItemProps>(({ label, count, ...others }, ref) => {
  const theme = useMantineTheme()
  return (
    <div ref={ref} {...others}>
      <Group position="apart" noWrap>
        <Text>{label}</Text>
        <Group spacing="xs">
          <IconUser size={14} color={getTextColor('gray.6', theme)} />
          <Text c="dimmed">{formatNumberToThousands(count)}</Text>
        </Group>
      </Group>
    </div>
  )
})

type Props = {
  value: string[]
  onChange: (selected: string[]) => void
  disabledSegmentIds?: string[]
}

const CustomerSegmentSelector = (props: Props) => {
  const { t } = useTranslation('pageCustomerSegment')
  const { disabledSegmentIds, value, onChange } = props
  const appId = useRouter().query.appId as string
  const { data } = useCustomerSegmentsQuery({
    variables: {
      id: appId,
    },
  })

  const options: SelectItem[] = useMemo(() => {
    return (
      data?.application?.customerSegments
        .filter((segment) => !isSystemSegment(segment.type))
        .map((segment) => {
          return {
            label: segment.name,
            value: segment.id,
            count: segment.customerCount,
            disabled: disabledSegmentIds?.includes(segment.id),
          }
        }) ?? []
    )
  }, [data?.application?.customerSegments, disabledSegmentIds])

  const disabled = useMemo(() => isEmpty(options), [options])

  return (
    <MultiSelect
      withinPortal
      clearable
      searchable
      data={options}
      disabled={disabled}
      itemComponent={Item}
      value={value}
      onChange={onChange}
      placeholder={disabled ? t('addToSegment.noCustomizedSegmentTip') : t('common:placeholders.pleaseSelect')}
    />
  )
}

export default CustomerSegmentSelector
