import { ItemNavigatorProps } from 'components/ui/ItemNavigator'
import { CampaignType, CampaignWidgetType, Entry, EntryCustomer, Field } from 'types/domain'

export enum AsideType {
  BaseInfo = 'BaseInfo',
  Reply = 'Reply',
  Comments = 'Comments',
}

export type EntryModalData = {
  entry: Entry
  customer: EntryCustomer
}

export type EntryDetailBasicProps = {
  data: EntryModalData
  onClose?: () => void
  onRemove?: () => void
}

export type OpenEntryDetailProps = EntryDetailBasicProps & {
  fields: Field[]
  campaignId: string
  widgetType: CampaignWidgetType
  campaignType: CampaignType
  targetAside?: AsideType
  updateReplyCallBack?: () => void
}

export type EntryDetailProps = ItemNavigatorProps &
  EntryDetailBasicProps & {
    opened: boolean
    targetAside?: AsideType
  }
