import React, { useMemo } from 'react'
import { Group, Stack } from '@mantine/core'
import { isEmpty } from 'lodash-es'
import RemoveCondition from '../RemoveCondition'
import { useConditionGroup } from '../ConditionGroup'
import ConditionContext from './ConditionContext'
import Operator from './Operator'
import Node from './Node'
import classes from './index.module.scss'

interface Props {
  minLength?: number
  checkUrl?: boolean
}

const Conditions = ({ minLength = 1, checkUrl = true }: Props) => {
  const {
    state: { conditions },
  } = useConditionGroup()

  return useMemo(() => {
    return (
      <>
        {!isEmpty(conditions) ? (
          <Stack spacing="sm">
            {conditions.map((condition, index) => (
              <ConditionContext.Provider key={index} value={{ index, condition, checkUrl }}>
                <Group spacing="xs">
                  <div className={classes.condition}>
                    <Operator />
                    <Node />
                  </div>
                  {conditions.length > minLength && <RemoveCondition index={index} />}
                </Group>
              </ConditionContext.Provider>
            ))}
          </Stack>
        ) : null}
      </>
    )
  }, [conditions, checkUrl, minLength])
}

export default Conditions
