import { merge } from 'lodash-es'
import getDefaultTableConfig from 'utils/commonTableConfig'

const entriesTableConfig = {
  mantineTableProps: {
    withColumnBorders: true,
  },
  mantineColumnDragHandleProps: {
    sx: {
      '> svg': {
        rotate: '90deg',
      },
    },
  },
  enableRowSelection: true,
  enableSelectAll: true,
  enableColumnResizing: true,
  enableColumnOrdering: true,
  enableColumnVirtualization: true,
  enableRowVirtualization: true,
  enableStickyHeader: true,
}

export default function getEntriesTableConfig() {
  return merge(getDefaultTableConfig(), entriesTableConfig)
}
