import React, { useCallback, useContext, useMemo } from 'react'

import { useConditionGroup } from '../../ConditionGroup'

import { useSecondNodeType } from './useSecondNodeType'
import ConditionContext from '../ConditionContext'
import { secondsNodes } from './constant'
import { Params } from './type'
import DataContext from 'components/business/Filter/DefaultFilter/DataContext'
import { isArray, isEmpty } from 'lodash-es'

type Props = {
  batchEdit?: boolean
}

const SecondNode = ({ batchEdit = false }: Props) => {
  const { index, condition } = useContext(ConditionContext)
  const { dispatch } = useConditionGroup()

  const {
    secondNode: { rules },
  } = useContext(DataContext)

  const secondNodeType = useSecondNodeType()
  const SecondNodeComponent = secondNodeType && secondsNodes[secondNodeType]

  const getError = (value: Params['value']) => {
    const firstError = rules?.find(({ regex, required }) => {
      if (required) {
        return isEmpty(value)
      }
      return isArray(value) ? value.some((item) => !regex?.test(item.toString())) : !regex?.test(value.toString())
    })
    return firstError ? firstError?.message : undefined
  }

  const handleChange = useCallback(
    ({ value, label, error, type }: Params) => {
      const errorMessage = error ? error : getError(value)
      dispatch({
        type: 'secondNodeChange',
        index,
        secondNode: {
          value,
          label,
          error: errorMessage,
          type,
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, index]
  )

  return (
    <>
      {SecondNodeComponent ? (
        <SecondNodeComponent condition={condition} onChange={handleChange} batchEdit={batchEdit} />
      ) : null}
    </>
  )
}

export default SecondNode
