import React, { useEffect, useRef } from 'react'
import { Center, Loader, ScrollArea } from '@mantine/core'
import { ScrollAreaProps } from '@mantine/core/lib/ScrollArea/ScrollArea'

interface Props extends Omit<ScrollAreaProps, 'onScrollPositionChange' | 'viewportRef'> {
  next: () => void
  hasMore: boolean
  dataLength: number
  scrollThreshold?: number // from 0 to 1, 1 means 100% scrolled
}

const InfiniteScroll = ({ next, hasMore, dataLength, scrollThreshold = 0.8, children, ...rest }: Props) => {
  const innerRef = useRef<HTMLDivElement | null>(null)
  const loading = useRef(false)

  useEffect(() => {
    // reset loading after loaded which changes dataLength
    loading.current = false
  }, [dataLength])

  const handleScrollPositionChange = ({ y }: { y: number }) => {
    if (hasMore && !loading.current) {
      const { scrollHeight, offsetHeight } = innerRef.current!
      if (offsetHeight * scrollThreshold > scrollHeight - y - offsetHeight) {
        loading.current = true
        next()
      }
    }
  }

  return (
    <ScrollArea {...rest} onScrollPositionChange={handleScrollPositionChange} viewportRef={innerRef}>
      {children}
      {hasMore && loading.current && (
        <Center>
          <Loader variant="dots" size="sm" />
        </Center>
      )}
    </ScrollArea>
  )
}

export default InfiniteScroll
