import { keywordCode } from 'config/constants/fieldType'
import React, { ReactNode, useState } from 'react'
import update from 'immutability-helper'
import { concat, debounce, isEqual, isNil } from 'lodash-es'
import { useRouter } from 'next/router'
import omitDeep from 'omit-deep-lodash'
import {
  Condition,
  ConditionGroupInput,
  ConditionGroupOperator,
  ConditionInput,
  UpsertUserCampaignSettingInput,
  useUpsertCampaignSettingMutation,
} from 'types/domain'
import { CampaignFilterContextProps } from './type'
import { CampaignFilterContext, CampaignFilterActionContext } from './CampaignFilterContext'

type CampaignFilterProviderProps = {
  children: ReactNode
  defaultValue: CampaignFilterContextProps & {
    key: 'reportFilter' | 'entriesFilter'
  }
}

const defaultFilter: ConditionGroupInput = {
  operator: ConditionGroupOperator.And,
  conditions: [],
}

const findConditionIndexByCode = (conditions?: Condition[], code?: string) =>
  conditions?.findIndex(({ firstNode }) => firstNode.value === code)!

export default function CampaignFilterProvider(props: CampaignFilterProviderProps) {
  const { defaultValue, children } = props
  const campaignId = useRouter().query.campaignId as string
  const [updateCampaignSettingMutation] = useUpsertCampaignSettingMutation()
  const [filter, setFilter] = useState<ConditionGroupInput>(defaultValue?.filter ?? defaultFilter)

  const onExplicitlyFieldChange = (code: string, condition: ConditionInput | null) => {
    const index = findConditionIndexByCode(filter?.conditions, code)
    let updatedFilter = filter
    if (index > -1) {
      if (isNil(condition)) {
        updatedFilter = update(filter, { conditions: { $splice: [[index, 1]] } })
      } else {
        updatedFilter = update(filter, { conditions: { $splice: [[index, 1, condition]] } })
      }
    } else if (!isNil(condition)) {
      updatedFilter = update(filter, { conditions: { $push: [condition] } })
    }
    setFilter(updatedFilter)
    if (code !== keywordCode) {
      updateUserCampaignSetting(updatedFilter)
    }
  }

  const onFieldsChange = (explicitlyFieldCodes: string[], conditions: ConditionInput[]) => {
    const otherConditions = filter.conditions.filter(({ firstNode }) => explicitlyFieldCodes.includes(firstNode.value))
    const newConditions = omitDeep(concat(otherConditions, conditions), ['firstNode.type'])
    const updatedFilter = update(filter, { conditions: { $set: newConditions } })
    setFilter(updatedFilter)
    updateUserCampaignSetting(updatedFilter)
  }

  const debouncedUpdateFilter = debounce(onFieldsChange, 500)

  const updateUserCampaignSetting = (updatedFilter: ConditionGroupInput) => {
    const finalFilter = isEqual(omitDeep(updatedFilter, '__typename'), omitDeep(defaultFilter, '__typename'))
      ? null
      : updatedFilter

    updateCampaignSettingMutation({
      variables: {
        input: {
          campaignId,
          [defaultValue.key]: finalFilter,
        } as UpsertUserCampaignSettingInput,
      },
    }).then()
  }

  return (
    <CampaignFilterActionContext.Provider
      value={{
        onFieldsChange: debouncedUpdateFilter,
        onExplicitlyFieldChange,
      }}
    >
      <CampaignFilterContext.Provider value={{ filter }}>{children}</CampaignFilterContext.Provider>
    </CampaignFilterActionContext.Provider>
  )
}
