import { useCampaign } from 'components/business/Campaign/CampaignContext'
import getColumns from 'components/business/Campaign/Entries/components/EntriesTable/utils/getColumns'
import { LINK_HIDDEN_FIELDS, MOBILE_HIDDEN_FIELDS } from 'components/business/Campaign/Entries/constants'
import ApplicationContext from 'components/business/Layouts/ApplicationLayout/ApplicationContext'
import { MRT_ColumnDef } from 'mantine-react-table'
import useTranslation from 'next-translate/useTranslation'
import { useContext, useMemo } from 'react'
import { Application, Field } from 'types/domain'
import { isLinkDistribution, isMobileDistribution } from 'utils/applicationUtils'

export default function useColumns(formFields: Field[]) {
  const { t } = useTranslation('entry')
  const { application } = useContext(ApplicationContext)
  const { campaign } = useCampaign()
  const shouldRemoveFields = useMemo(() => {
    if (isMobileDistribution(application as Application)) {
      return MOBILE_HIDDEN_FIELDS
    } else if (isLinkDistribution(application)) {
      return LINK_HIDDEN_FIELDS
    }
    return []
  }, [application])

  return useMemo(() => {
    return getColumns({
      fields: formFields,
      t,
      removedFields: shouldRemoveFields,
      widgetType: campaign?.widget.type!,
    }) as MRT_ColumnDef<any>[]
  }, [campaign?.widget.type, formFields, shouldRemoveFields, t])
}
