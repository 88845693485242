import React from 'react'
import { Box, SimpleGrid, Title } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { Application, CampaignType, CustomApplicationEvent, Entry, EntrySubmittedOn } from 'types/domain'
import { formatISOTime, formatTimeDuration, getDisplayRegion } from 'utils'
import { LabelWithValue, UrlWithTooltip } from 'components/ui'
import useApplicationInfoBaseInfoCache from 'components/business/hooks/useApplicationInfoBaseInfoCache'
import { isLinkDistribution, isWebDistribution } from 'utils/applicationUtils'

type Props = {
  entry: Entry
  customEvents?: CustomApplicationEvent[]
  campaignType: CampaignType
  gridCols?: number
}

const DeviceInfo = ({ entry, customEvents, campaignType, gridCols = 1 }: Props) => {
  const { t } = useTranslation()
  const application = useApplicationInfoBaseInfoCache().application as Application
  const isWebChannel = isWebDistribution(application)
  const isLinkChannel = isLinkDistribution(application)
  const campaignTypeName = t(`common:campaignTypeName.${campaignType}`)

  const triggeredEvent = customEvents?.find((event) => event.code === entry.eventCode)

  return (
    <Box>
      <Title order={6} mb="md">
        {t('entry:entryModal.baseInfo.deviceInfoTitle')}
      </Title>
      <SimpleGrid cols={gridCols} verticalSpacing={12}>
        {!isLinkChannel && (
          <LabelWithValue
            label={t('entry:list.triggeredEvent', { campaignTypeName })}
            value={triggeredEvent?.displayName || triggeredEvent?.code || ''}
          />
        )}
        {entry.referer && (
          <LabelWithValue
            label={t('entry:list.referer')}
            labelClassName="min-w-[56px]"
            value={<UrlWithTooltip url={entry.referer} />}
          />
        )}
        <LabelWithValue label={t('entry:list.createdAt')} value={formatISOTime(entry.createdAt)} />
        <LabelWithValue label={t('entry:list.fillingDuration')} value={formatTimeDuration(t, entry.fillingDuration)} />
        <LabelWithValue label={t('entry:list.os')} value={entry.os} />
        {isWebChannel && (
          <LabelWithValue label={t('entry:list.browser')} labelClassName="min-w-[56px]" value={entry.browser} />
        )}
        <LabelWithValue label={t('entry:list.screenResolution')} value={entry.screenResolution} />
        <LabelWithValue
          label={t('entry:list.submittedOn')}
          value={
            entry.submittedOn === EntrySubmittedOn.Completed
              ? t('entry:list.submittedOnValue.COMPLETE')
              : t('entry:list.submittedOnValue.EXPIRED')
          }
        />
        {!isLinkChannel && entry.deliveryMethod && (
          <LabelWithValue
            label={t('entry:list.deliveryMethod')}
            value={t(`entry:list.deliveryMethodValue.${entry.deliveryMethod}`)}
          />
        )}
        <LabelWithValue label={t('entry:list.ip')} value={entry.ip} />
        <LabelWithValue label={t('entry:list.region')} value={getDisplayRegion(entry.region)} />
      </SimpleGrid>
    </Box>
  )
}

export default DeviceInfo
