import { ActionIcon, Group, ScrollArea, Stack, Text, useMantineTheme } from '@mantine/core'
import { HiddenColumnsProps } from 'components/business/HiddenColumns/type'
import React, { forwardRef, ForwardRefRenderFunction, useRef } from 'react'
import { EntryIcon } from 'components/business'
import { IconEye, IconEyeOff } from '@tabler/icons-react'
import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'
import { debounce } from 'lodash-es'
import { FieldIconCodeType } from 'utils/fieldIcon'

const HiddenColumnContent: ForwardRefRenderFunction<HTMLDivElement, HiddenColumnsProps> = (props, ref) => {
  const theme = useMantineTheme()

  const { value, columns, onChange } = props

  const updateToggle = useRef(
    debounce((field: string, hiddenFields: string[]) => {
      const newHiddenFields = hiddenFields.includes(field)
        ? hiddenFields.filter((f) => f !== field)
        : [...hiddenFields, field]
      onChange(newHiddenFields)
    }, 100)
  ).current

  return (
    <ScrollArea.Autosize mah="calc(50vh - 50px)" ref={ref} scrollbarSize={8}>
      <Stack spacing={0} p="xs">
        {columns.map((column) => {
          // @ts-ignore
          const { id, header, type } = column
          const hidden = value.includes(id!)
          return (
            <Group
              key={id}
              position="apart"
              p="sm"
              noWrap
              sx={{
                '&:hover': {
                  backgroundColor: getBackgroundColor('gray.1', theme),
                  borderRadius: theme.radius.xs,
                },
              }}
            >
              <Group
                spacing="xs"
                noWrap
                sx={{
                  filter: hidden ? 'grayscale(1)' : 'none',
                }}
              >
                <EntryIcon
                  type={type as FieldIconCodeType}
                  fieldCode={id!}
                  useDefault
                  className={hidden ? 'opacity-30' : 'opacity-100'}
                />
                <Text
                  className="shrink break-all"
                  lineClamp={1}
                  color={getTextColor(hidden ? 'gray.5' : 'gray.9', theme)}
                >
                  {header}
                </Text>
              </Group>
              <ActionIcon size="md" onClick={() => updateToggle(id!, value)}>
                {hidden ? (
                  <IconEyeOff size={18} stroke={1.5} color={getTextColor('gray.7', theme)} />
                ) : (
                  <IconEye size={18} stroke={1.5} color={getTextColor('gray.7', theme)} />
                )}
              </ActionIcon>
            </Group>
          )
        })}
      </Stack>
    </ScrollArea.Autosize>
  )
}

export default forwardRef(HiddenColumnContent)
