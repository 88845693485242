import React, { FC, forwardRef, useMemo } from 'react'
import { ActionIcon, Group, Select, Text, Title, useMantineTheme } from '@mantine/core'
import useCreateCampaignGroupModal from './Group/useCreateGroupModal'
import {
  ApplicationPermission,
  CampaignGroup,
  CampaignType,
  useApplicationCampaignGroupsQuery,
  useDeleteCampaignGroupMutation,
} from 'types/domain'
import { useRouter } from 'next/router'
import { IconAlbum, IconEdit, IconPlus, IconTrash, IconUser } from '@tabler/icons-react'
import useEditCampaignGroupModal from 'components/business/Campaign/List/Group/useEditCampaignGroupModal'
import { message } from 'components/ui'
import { graphqlErrorCatch } from 'utils'
import useTranslation from 'next-translate/useTranslation'
import { openConfirmModal } from '@mantine/modals'
import useGroupCache from 'components/business/Campaign/hooks/useGroupCache'
import { getTextColor, pickSchemeColor } from 'config/themeOverride/getColor'
import { useAbac } from 'react-abac'

export enum SYSTEM_GROUP {
  ALL = 'ALL',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export const isSystemGroup = (value: string | null) => {
  if (value) {
    return Object.values(SYSTEM_GROUP).includes(value as SYSTEM_GROUP)
  }
  return true
}

type Props = {
  groupId: string | null
  campaignType: CampaignType
  onChange: (groupId: string | null) => void
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  value: string
  label: string
  group: string
}

const Filter: FC<Props> = (props) => {
  const { t } = useTranslation('componentCampaignList')
  const theme = useMantineTheme()
  const { campaignType, groupId, onChange } = props
  const router = useRouter()
  const { appId } = router.query
  const { userHasPermissions } = useAbac()
  const manageable = userHasPermissions(ApplicationPermission.CampaignGroupManage)
  const { deleteGroup } = useGroupCache()
  const { open: openCreateModal } = useCreateCampaignGroupModal(campaignType)
  const { open: openEditModal } = useEditCampaignGroupModal()

  const [deleteCampaignGroupMutation] = useDeleteCampaignGroupMutation()

  const { data, refetch } = useApplicationCampaignGroupsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: appId as string,
      type: campaignType,
    },
    onCompleted(data: any) {
      if (!isSystemGroup(groupId) && !data?.application?.campaignGroups.find((g: { id: string }) => g.id === groupId)) {
        onChange(SYSTEM_GROUP.ALL as string)
      }
    },
  })

  const groups = data?.application?.campaignGroups as CampaignGroup[]

  const options = useMemo(() => {
    let options = [
      {
        value: SYSTEM_GROUP.ALL as string,
        label: `${t('campaignGroup.filter.all')}${t(campaignType)}`,
        group: t('campaignGroup.filter.defaultGroup'),
      },
      {
        value: SYSTEM_GROUP.ENABLED as string,
        label: `${t('campaignGroup.filter.enabled')}${t(campaignType)}`,
        group: t('campaignGroup.filter.defaultGroup'),
      },
      {
        value: SYSTEM_GROUP.DISABLED as string,
        label: `${t('campaignGroup.filter.disabled')}${t(campaignType)}`,
        group: t('campaignGroup.filter.defaultGroup'),
      },
    ]
    for (let i = 0; i < (groups?.length ?? 0); i++) {
      const group = groups?.[i]!
      options.push({ value: group.id, label: group.name, group: t('campaignGroup.filter.customGroup') })
    }
    manageable && options.push({ value: 'NEW', label: t('campaignGroup.create'), group: '' })
    return options
  }, [groups])

  const handleChange = (selectValue: string) => {
    if (selectValue === 'NEW') {
      openCreateModal((groupId) => onChange(groupId))
      return
    }
    onChange(selectValue)
  }

  const handleEdit = (group: { id: string; name: string }) => {
    openEditModal(group)
  }

  const handleDelete = async (group: { id: string; name: string }) => {
    openConfirmModal({
      title: (
        <Group spacing="xs">
          <Title order={5}>{t('campaignGroup.delete.title')}</Title>
        </Group>
      ),
      children: (
        <Text size="sm">
          {t(`campaignGroup.delete.confirmContent`, {
            name: group.name,
            campaignType: t(campaignType),
          })}
        </Text>
      ),
      labels: { confirm: t('common:actions.delete'), cancel: t('common:actions.cancel') },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        try {
          const { data } = await deleteCampaignGroupMutation({ variables: { id: group.id } })
          if (data?.deleteCampaignGroup) {
            close()
            deleteGroup(campaignType, group.id)
            group.id === groupId && onChange(null)
            message.successMsg(`${t('common:message.deleteSuccess')}`)
            refetch()
          }
        } catch (error: any) {
          const { graphQLErrors } = error
          graphqlErrorCatch({ graphQLErrors, defaultMessage: t('common:message.deleteError') })
        }
      },
      radius: 'sm',
    })
  }

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, value, group, ...others }: ItemProps, ref) => (
    <Group position="apart" className={`group ${pickSchemeColor('hover:bg-gray-1', theme, 'hover:bg-dark-5')}`} noWrap>
      <div ref={ref} {...others}>
        <Group noWrap>
          {group === '' && <IconPlus size={14} color={getTextColor('gray.6', theme)} />}
          {group === t('campaignGroup.filter.customGroup') && (
            <IconAlbum size={14} color={getTextColor('gray.6', theme)} />
          )}
          <Text size="sm" className="truncate" maw={220}>
            {label}
          </Text>
        </Group>
      </div>
      {manageable && group === t('campaignGroup.filter.customGroup') && (
        <Group spacing="0" w={60} className="invisible group-hover:visible">
          <ActionIcon size="sm" onClick={() => handleEdit({ id: value, name: label })}>
            <IconEdit stroke={1.5} size={16} />
          </ActionIcon>
          <ActionIcon size="sm" onClick={() => handleDelete({ id: value, name: label })}>
            <IconTrash stroke={1.5} size={16} color="red" />
          </ActionIcon>
        </Group>
      )}
    </Group>
  ))

  return (
    <Group>
      <Select
        w={350}
        maxDropdownHeight={400}
        value={groupId ?? (SYSTEM_GROUP.ALL as string)}
        onChange={handleChange}
        itemComponent={SelectItem}
        data={options}
        styles={(theme) => ({
          item: {
            '&[data-selected]': {
              '&, &:hover': {
                backgroundColor: 'unset',
                color: getTextColor('brandBlue.7', theme, 'brandBlue.3'),
              },
            },
            '&[data-hovered]': {
              backgroundColor: 'unset',
            },
          },
        })}
      />
    </Group>
  )
}

export default Filter
