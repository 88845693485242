import React, { FC, useMemo, useState } from 'react'
import { Group, Pagination } from '@mantine/core'

type Props = {
  pageSize: number
  totalCount: number
  onChange: (page: number) => void
  className?: string
}

const Footer: FC<Props> = (props) => {
  const { pageSize, totalCount, onChange, className } = props
  const total = Math.ceil(totalCount / pageSize)
  const [activePage, setPage] = useState(1)
  const show = useMemo(() => total > 1, [total])

  const handlePageChange = (value: number) => {
    setPage(value)
    onChange(value)
  }
  if (!show) {
    return null
  }
  return (
    <Group position="center" className={className} pb="2xl">
      <Pagination size="sm" value={activePage} onChange={handlePageChange} total={total} />
    </Group>
  )
}

export default Footer
