import { createStyles } from '@mantine/core'
import bg from './images/bg.png'

export const useStyles = createStyles((theme) => {
  const browserImg = require(`./images/browser-${theme.colorScheme}.png`).default?.src
  return {
    wrapper: {
      background: `url(${bg.src}) top no-repeat`,
      backgroundSize: '100% auto',
      position: 'relative',
    },
    fakeBrowser: {
      position: 'absolute',
    },
    fakeBrowserBg: {
      background: `url(${browserImg}) bottom right no-repeat`,
      backgroundSize: '560px 640px',
      position: 'absolute',
      width: '100%',
      height: '100%',
      transition: 'all .5s',
    },
    fakeBrowserDropdown: {
      position: 'relative',
      transition: 'all .5s',
      transformOrigin: 'top left',
      opacity: 0,
      transform: 'scale(0.7)',
    },
    fakeBrowserDropdownOpen: {
      opacity: 1,
      transform: 'scale(1)',
    },
  }
})
