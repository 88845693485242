import { initializeApollo } from 'plugins/apollo/index'

export const isCacheHit = (variables: any, queryDocument: any): boolean => {
  const client = initializeApollo()
  const { complete } = client.cache.diff({
    query: client.cache.transformDocument(queryDocument),
    variables,
    optimistic: false,
  })
  return !!complete
}

export const updateCache = (variables: any, queryDocument: any, data: any): void => {
  const client = initializeApollo()
  const query = client.cache.transformDocument(queryDocument)
  client.cache.writeQuery({ query, data, variables })
}
