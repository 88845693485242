import { createContext, Dispatch, ReactNode, SetStateAction, useContext } from 'react'
import { noop } from 'lodash-es'
import { Application, CampaignType, User } from 'types/domain'

type BuilderShareActionsType = {
  setEditingFieldIndex: Dispatch<number>
  setAutoOpen?: Dispatch<SetStateAction<boolean>>
  onSave?: (value: any, successMsg?: string, errorMsg?: string) => Promise<void>
  setUrlEnabled: Dispatch<boolean>
}

const BuilderShareActionsContext = createContext<BuilderShareActionsType>({
  setEditingFieldIndex: noop,
  setUrlEnabled: noop,
})

type BuilderSharedData = {
  editingFieldIndex: number
  campaignType: CampaignType | null
  campaignTypeName: string
  autoOpen?: boolean
  urlEnabled: boolean
  application?: Application
  currentUser?: User
}

export const BuilderSharedDataContext = createContext<BuilderSharedData>({
  editingFieldIndex: 0,
  campaignType: null,
  urlEnabled: false,
  campaignTypeName: '',
})

type ProviderProps = {
  value: BuilderSharedData
  actions: BuilderShareActionsType
  children: ReactNode
}

export const BuilderShareProvider = (props: ProviderProps) => {
  const { value, actions, children } = props

  return (
    <BuilderShareActionsContext.Provider value={actions}>
      <BuilderSharedDataContext.Provider value={value}>{children}</BuilderSharedDataContext.Provider>
    </BuilderShareActionsContext.Provider>
  )
}

export const useBuilderSharedDataContext = () => {
  return useContext(BuilderSharedDataContext)
}

export const useBuilderShareActions = () => {
  return useContext(BuilderShareActionsContext)
}
