import React, { ComponentProps } from 'react'
import { Icon } from 'components/ui'
import { Group, Stack, Text, Tooltip } from '@mantine/core'
import { ApplicationAppDistributionSetting, ApplicationWebDistributionSetting } from 'types/domain'
import { ApplicationType } from 'states/applications'

import { channelIconMap } from 'config/constants'

type IconNames = ComponentProps<typeof Icon>['name']

interface Props {
  application: ApplicationType
  tooltipDisabled?: boolean
}

const AppInfo = ({ application, tooltipDisabled }: Props) => {
  const { name, distribution } = application
  const channel = distribution.channel
  const websiteUrl = (distribution.setting as ApplicationWebDistributionSetting)?.websiteUrl
  const appId = (distribution.setting as ApplicationAppDistributionSetting)?.appId

  return (
    <Group spacing="sm" noWrap>
      <Icon name={channelIconMap[channel] as IconNames} size={28} />
      <Stack spacing={0}>
        <Text className="break-all truncate max-w-[210px]">{name}</Text>
        <Tooltip label={websiteUrl ?? appId} openDelay={500} disabled={tooltipDisabled}>
          <Text c="dimmed" size="xs" lineClamp={1} className="break-all">
            {websiteUrl ?? appId}
          </Text>
        </Tooltip>
      </Stack>
    </Group>
  )
}

export default AppInfo
