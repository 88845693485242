import React, { FC } from 'react'
import { Stack, Text } from '@mantine/core'
import { htmlEncodeByRegExp } from 'utils'
import ImageGroup from '../components/ImageGroup'

const TextAreaFieldRenderer: FC<{ value: any }> = ({ value }) => {
  return (
    <Stack>
      <Text className="break-all">
        <div dangerouslySetInnerHTML={{ __html: htmlEncodeByRegExp(value?.text ?? '') }} />
      </Text>
      <ImageGroup images={value?.screenshots} size={40} />
    </Stack>
  )
}

export default TextAreaFieldRenderer
