import React from 'react'
import { Center, Stack, Text, ThemeIcon, useMantineTheme } from '@mantine/core'
import { IconMoodCry } from '@tabler/icons-react'
import useTranslation from 'next-translate/useTranslation'

import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'

const NotSupport = () => {
  const theme = useMantineTheme()
  const { t } = useTranslation('entry')

  return (
    <Center mt="3xl">
      <Stack spacing="md" align="center">
        <ThemeIcon radius="xl" color={getBackgroundColor('gray.0', theme)} size={48}>
          <IconMoodCry size={36} strokeWidth={1.5} color={getTextColor('gray.5', theme)} />
        </ThemeIcon>
        <Text mt="xl" color="dimmed" maw={304} ta="center">
          {t('entryModal.reply.disabled')}
        </Text>
      </Stack>
    </Center>
  )
}

export default NotSupport
