import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { RadioGroup } from 'components/ui'
import { useBuilderFormContext } from 'components/business/CampaignBuilder'
import StyleItem from '../../StyleItem'

enum Mode {
  OFF = 'off',
  ON = 'on',
}

const ProgressBar = () => {
  const { t } = useTranslation('componentCampaignBuilder')
  const form = useBuilderFormContext()

  const options = Object.values(Mode).map((value) => ({
    label: t(`styles.progressBar.${value}`),
    value,
  }))

  const { value, onChange } = form.getInputProps('formSetting.showProgressBar')

  return (
    <StyleItem label={t('styles.progressBar.title')}>
      <RadioGroup
        data={options}
        value={value ? Mode.ON : Mode.OFF}
        onChange={(value) => {
          onChange(value === Mode.ON)
        }}
      />
    </StyleItem>
  )
}

export default ProgressBar
