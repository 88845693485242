import { Group, GroupProps, Text, useMantineTheme } from '@mantine/core'
import classes from './index.module.scss'
import { Icon } from 'components/ui'
import { channelIconMap } from 'config/constants'
import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'
import { IconSelector } from '@tabler/icons-react'
import React, { forwardRef, useContext, useEffect, useState } from 'react'
import { Application, useApplicationBasicInfoQuery } from 'types/domain'
import { useRouter } from 'next/router'
import { useGlobalError } from 'components/business'
import ApplicationContext from 'components/business/Layouts/ApplicationLayout/ApplicationContext'

type Props = GroupProps & {
  onAccountSwitchModalOpen?: () => void
}
const AppName = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const theme = useMantineTheme()
  const router = useRouter()
  const appId = router.query.appId as string
  const { showNotFound } = useGlobalError()
  const application = useContext(ApplicationContext).application!
  const [app, setApp] = useState<Application | null>(application as Application)

  const { data } = useApplicationBasicInfoQuery({
    variables: {
      id: appId,
    },
    onError: (error) => {
      showNotFound(error)
    },
  })

  useEffect(() => {
    const fetched = data?.application
    if (!fetched) {
      return
    }
    setApp(fetched as Application)
  }, [data, application])

  return (
    <Group
      {...props}
      className={classes.current}
      sx={{ ':hover': { backgroundColor: getBackgroundColor('gray.1', theme) } }}
      spacing="xs"
      pl="md"
      pr="sm"
      mih={32}
      noWrap
      ref={ref}
    >
      <Icon name={channelIconMap[app?.distribution.channel!]} className="p-xs" />
      <Text color={getTextColor('gray.9', theme)} size="sm" weight="bold" lineClamp={1} className="break-all">
        {app?.name}
      </Text>
      <IconSelector className="shrink-0" color={getTextColor('gray.7', theme)} size={14} stroke={2} />
    </Group>
  )
})

export default AppName
