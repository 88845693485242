import { FeatureCheck, FeatureText } from './components/Feature'
import { CustomizedFeature } from './constant'

const BasicFeatures = () => {
  return (
    <>
      <FeatureText titleKey={CustomizedFeature.EntriesCount} value="10万条/年" />
      <FeatureText titleKey={CustomizedFeature.ApplicationsCount} value="5个" />
      <FeatureText titleKey={CustomizedFeature.CampaignsCountPerApplication} value="不限量" />
      <FeatureCheck titleKey={CustomizedFeature.WebSupport} />
      <FeatureCheck titleKey={CustomizedFeature.AppApplication} />
      <FeatureText titleKey={CustomizedFeature.Teamwork} value="不限量" />
      <FeatureCheck titleKey={CustomizedFeature.CampaignTargetingCustomer} />
      <FeatureCheck titleKey={CustomizedFeature.CampaignTriggerByEvent} />
      <FeatureCheck titleKey={CustomizedFeature.Customer} />
      <FeatureCheck titleKey={CustomizedFeature.ImPush} />
      <FeatureCheck titleKey={CustomizedFeature.Webhook} />
    </>
  )
}

export default BasicFeatures
