import { ActionIcon, Stack, useMantineTheme } from '@mantine/core'
import { IconDeviceImac, IconDeviceMobile, IconRotateClockwise } from '@tabler/icons-react'
import { getTextColor } from 'config/themeOverride/getColor'
import React from 'react'
import { PlatformType } from 'components/business/PlatformPreview/type'
import { CampaignWidgetType } from 'types/domain'

type Props = {
  widgetType?: CampaignWidgetType
  onPlatformClick?: (platform: PlatformType) => void
  onRefresh?: () => void
  platformChangeable?: boolean
  className?: string
}

type PreviewActionProps = {
  onClick: () => void
  type: 'refresh' | PlatformType
}

const iconMap = {
  ['web']: IconDeviceImac,
  ['mobile']: IconDeviceMobile,
  ['refresh']: IconRotateClockwise,
}
const PreviewActionIcon = ({ onClick, type }: PreviewActionProps) => {
  const theme = useMantineTheme()
  const Component = iconMap[type]
  return (
    <ActionIcon variant="default" onClick={onClick} size="lg">
      <Component color={getTextColor('gray.6', theme)} size={20} stroke={1.5} />
    </ActionIcon>
  )
}

const PreviewActions = ({ widgetType, onRefresh, platformChangeable, onPlatformClick, className }: Props) => {
  const refreshButtonVisible = widgetType !== CampaignWidgetType.Button

  return (
    <Stack pos="absolute" className={className}>
      {platformChangeable && (
        <>
          <PreviewActionIcon type="web" onClick={() => onPlatformClick && onPlatformClick('web')} />
          <PreviewActionIcon type="mobile" onClick={() => onPlatformClick && onPlatformClick('mobile')} />
        </>
      )}
      {refreshButtonVisible && <PreviewActionIcon type="refresh" onClick={() => onRefresh?.()} />}
    </Stack>
  )
}
export default PreviewActions
