import { IconDatabase } from '@tabler/icons-react'
import { useCampaignFilter, useCampaignFilterActions } from 'components/business/Campaign/Filter/CampaignFilterContext'
import { textareaCode } from 'config/constants/fieldType'
import React, { useMemo } from 'react'
import { Select, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { getTextColor } from 'config/themeOverride/getColor'
import { ConditionInput, ConditionOperator } from 'types/domain'
import { subtleSelectStyle } from 'utils/mantineStyles'

const filterAll = 'ALL'

const InputContentSelector = () => {
  const theme = useMantineTheme()
  const { t } = useTranslation('componentEntryFilter')
  const { filter } = useCampaignFilter()
  const { onExplicitlyFieldChange } = useCampaignFilterActions()

  const value = useMemo(() => {
    return (
      (filter?.conditions as ConditionInput[])?.find(({ firstNode }) => firstNode.value === textareaCode)?.operator ??
      filterAll
    )
  }, [filter])

  const options = useMemo(
    () =>
      [filterAll, ConditionOperator.IsNotNull].map((code) => ({
        label: t(`entryContentInputType.${code}`),
        value: code,
      })),
    [t]
  )

  const widthMap = useMemo(
    () => ({
      [ConditionOperator.IsNotNull]: { width: 168, ml: 6 },
      [filterAll]: { width: 98, ml: 42 },
    }),
    []
  )

  const onSelectChange = (freshValue: string) => {
    if (freshValue === ConditionOperator.IsNotNull) {
      onExplicitlyFieldChange(textareaCode, {
        firstNode: { value: textareaCode },
        operator: freshValue,
        secondNode: { value: '' },
      })
    } else {
      onExplicitlyFieldChange(textareaCode, null)
    }
  }

  return (
    <Select
      data={options}
      value={value}
      rightSectionWidth={0}
      icon={<IconDatabase stroke={1.5} size={18} color={getTextColor('gray.9', theme)} />}
      styles={{
        ...subtleSelectStyle(theme),
        root: {
          width: widthMap[value as 'ALL']?.width || 'auto',
        },
        dropdown: { width: '180px !important', marginLeft: widthMap[value as 'ALL']?.ml, borderRadius: 6 },
      }}
      onChange={onSelectChange}
    />
  )
}

export default InputContentSelector
