import { useCampaign } from 'components/business/Campaign/CampaignContext'
import DeliveryGuide from 'components/business/Campaign/Layout/Onboarding/DeliveryGuide'
import React, { FC } from 'react'
import { Box, ColorScheme, Text, useMantineTheme } from '@mantine/core'

import { SdkOnboarding } from 'components/business'
import { onboardingBreakpoints } from 'config/constants'
import { Application, CampaignType } from 'types/domain'
import { Tab } from '../type'
import useApplicationInfoBaseInfoCache from 'components/business/hooks/useApplicationInfoBaseInfoCache'
import { isLinkDistribution } from 'utils/applicationUtils'
import { getXFormUrl } from 'utils/getXFormUrl'
import SdkGuide from 'components/business/Campaign/Layout/Onboarding/SdkGuide'
import { AnchorLink } from 'components/ui'
import Trans from 'next-translate/Trans'

interface Props {
  activeTab: Tab
}

const getImage = (type: CampaignType, activeTab: Tab, size: number, colorScheme: ColorScheme) => {
  return require(`./${type}/${activeTab}/${size}px-${colorScheme}.png`)?.default?.src
}

const Onboarding: FC<Props> = ({ activeTab }) => {
  const { campaign } = useCampaign()
  const { id, type } = campaign!
  const { colorScheme } = useMantineTheme()
  const { application } = useApplicationInfoBaseInfoCache()
  return (
    <Box
      mt={16}
      sx={{
        width: '100%',
        maxWidth: 1600,
      }}
    >
      <picture>
        <source media="(max-width: 1360px)" srcSet={getImage(type, activeTab, onboardingBreakpoints.sm, colorScheme)} />
        <source media="(max-width: 1680px)" srcSet={getImage(type, activeTab, onboardingBreakpoints.md, colorScheme)} />
        <source media="(max-width: 1920px)" srcSet={getImage(type, activeTab, onboardingBreakpoints.lg, colorScheme)} />
        <source media="(min-width: 1920px)" srcSet={getImage(type, activeTab, onboardingBreakpoints.xl, colorScheme)} />
        <img
          width="100%"
          src={getImage(type, activeTab, 1200, colorScheme)}
          alt={`${type} ${activeTab} sdk uninstall`}
        />
      </picture>
      <SdkOnboarding>
        {isLinkDistribution(application as Application) ? (
          <Text c="white">
            <Trans
              i18nKey="layoutApplication:onboarding.content"
              components={{
                link: (
                  <AnchorLink
                    target="_blank"
                    className="text-[white] underline"
                    href={getXFormUrl(campaign?.token!, campaign?.type!, true)}
                  />
                ),
              }}
            />
          </Text>
        ) : (
          <>
            <SdkGuide />
            <DeliveryGuide id={id} />
          </>
        )}
      </SdkOnboarding>
    </Box>
  )
}

export default Onboarding
