import ReplyCell from 'components/business/Campaign/Entries/components/EntriesTable/components/Cells/ReplyCell'
import { DefaultCell } from 'components/ui/TableCell'
import {
  COMMENTS_COUNT,
  CUSTOMER_EMAIL,
  CUSTOMER_MOBILE,
  CUSTOMER_NAME,
  CUSTOMER_UID,
  HAS_REPLY,
  REFERER,
  SERIAL_NUMBER,
} from 'config/constants'
import { AttributeFieldType, CHECK_BOX, CTA_FIELD, DROP_DOWN, RADIO_BUTTON } from 'config/constants/fieldType'
import { isNil } from 'lodash-es'
import { MRT_ColumnDef } from 'mantine-react-table'
import { Translate } from 'next-translate'
import { CampaignWidgetType, EntrySubmittedOn, Field, FormFieldType } from 'types/domain'
import { formatISOTime, formatTimeDuration, getDisplayRegion } from 'utils'
import { groupFields } from 'utils/fieldUtils'
import { COMMENTS_COUNT_CODE, HIDDEN_FIELD_TYPES, REPLY_CODE, SERIAL_NUMBER_CODE } from '../../../constants'
import CommentsCountCell from '../components/Cells/CommentCountCell'
import CsatFieldCell from '../components/Cells/CsatFieldCell'
import DateCell from '../components/Cells/DateCell'
import HeaderComponent from '../components/Cells/HeaderComponent'
import ImageCell from '../components/Cells/ImageCell'
import NumericCell from '../components/Cells/NumericCell'
import RatingCell from '../components/Cells/RatingCell'
import RefererCell from '../components/Cells/RefererCell'
import SelectableFieldCell from '../components/Cells/SelectableFieldCell'
import SerialNumberBodyCell from '../components/Cells/SerialNumberBodyCell'
import SerialNumberHeaderCell from '../components/Cells/SerialNumberHeaderCell'
import TextAreaFieldCell from '../components/Cells/TextAreaFieldCell'

function calcShownFields(fields: Field[]) {
  return fields.filter((filed) => !HIDDEN_FIELD_TYPES.includes(filed.type as FormFieldType))
}

function getBasicDef(field: Field) {
  return {
    header: field.label,
    accessorFn: (row: any) => row?.[field.code],
    id: field.code,
    type: field.type,
    minSize: 120,
    size: 300,
    Cell: DefaultCell,
    Header: HeaderComponent,
  } as MRT_ColumnDef<any>
}

function getCustomCellByFieldType(fieldType: FormFieldType | AttributeFieldType | string) {
  let Cell
  switch (fieldType) {
    case AttributeFieldType.DateTimeField:
      Cell = DateCell
      break
    case FormFieldType.NpsField:
    case FormFieldType.CesField:
      Cell = NumericCell
      break
    case CHECK_BOX:
    case RADIO_BUTTON:
    case DROP_DOWN:
    case CTA_FIELD:
      Cell = SelectableFieldCell
      break
    case FormFieldType.TextArea:
      Cell = TextAreaFieldCell
      break
    case FormFieldType.RatingField:
      Cell = RatingCell
      break
    case FormFieldType.CsatField:
      Cell = CsatFieldCell
      break
    case FormFieldType.ScreenshotField:
      Cell = ImageCell
      break
    default:
      return undefined
  }
  return Cell
}

function fieldsToColumnDefs(fields: Field[]) {
  return calcShownFields(fields).map((field) => {
    const customCell = getCustomCellByFieldType(field.type)
    if (!isNil(customCell)) {
      return {
        ...getBasicDef(field),
        Cell: customCell,
      }
    }
    return getBasicDef(field)
  })
}

const customerId = (t: Translate) => ({
  header: t('customer:customer_uid'),
  id: CUSTOMER_UID,
  accessorKey: CUSTOMER_UID,
  type: CUSTOMER_UID,
  minSize: 100,
  Header: HeaderComponent,
})

const customerName = (t: Translate) => ({
  header: t('customer:customer_name'),
  id: CUSTOMER_NAME,
  accessorKey: CUSTOMER_NAME,
  type: CUSTOMER_NAME,
  minSize: 60,
  Header: HeaderComponent,
})

const customerMobile = (t: Translate) => ({
  header: t('customer:customer_mobile'),
  id: CUSTOMER_MOBILE,
  accessorKey: CUSTOMER_MOBILE,
  type: CUSTOMER_MOBILE,
  minSize: 60,
  Header: HeaderComponent,
})

const customerEmail = (t: Translate) => ({
  header: t('customer:customer_email'),
  id: CUSTOMER_EMAIL,
  accessorKey: CUSTOMER_EMAIL,
  type: CUSTOMER_EMAIL,
  minSize: 60,
  Header: HeaderComponent,
})

const createdAt = (t: Translate, widgetType: CampaignWidgetType) => ({
  header: widgetType === CampaignWidgetType.Banner ? t('entry:list.clickedAt') : t('entry:list.createdAt'),
  id: 'entry.createdAt',
  accessorKey: 'entry.createdAt',
  type: 'entry.createdAt',
  Header: HeaderComponent,
})

const submittedOn = (t: Translate) => ({
  header: t('entry:list.submittedOn'),
  id: 'entry.submittedOn',
  accessorKey: 'entry.submittedOn',
  type: 'entry.submittedOn',
  size: 160,
  Header: HeaderComponent,
})

const deliveryMethod = (t: Translate) => ({
  header: t('entry:list.deliveryMethod'),
  id: 'entry.deliveryMethod',
  accessorKey: 'entry.deliveryMethod',
  type: 'entry.deliveryMethod',
  size: 130,
  Header: HeaderComponent,
})

const region = (t: Translate) => ({
  header: t('entry:list.region'),
  id: 'entry.region',
  accessorKey: 'entry.region',
  field: 'entry.region',
  size: 200,
  Header: HeaderComponent,
})

const browser = (t: Translate) => ({
  header: t('entry:list.browser'),
  id: 'entry.browser',
  accessorKey: 'entry.browser',
  type: 'entry.browser',
  size: 160,
  Header: HeaderComponent,
})

const os = (t: Translate) => ({
  header: t('entry:list.os'),
  id: 'entry.os',
  accessorKey: 'entry.os',
  type: 'entry.os',
  size: 160,
  Header: HeaderComponent,
})

const ip = (t: Translate) => ({
  header: t('entry:list.ip'),
  id: 'entry.ip',
  accessorKey: 'entry.ip',
  type: 'entry.ip',
  size: 160,
  Header: HeaderComponent,
})

const screenResolution = (t: Translate) => ({
  header: t('entry:list.screenResolution'),
  id: 'entry.screenResolution',
  accessorKey: 'entry.screenResolution',
  type: 'entry.screenResolution',
  size: 110,
  Header: HeaderComponent,
})

const referer = (t: Translate) => ({
  header: t('entry:list.referer'),
  id: 'entry.referer',
  type: 'entry.referer',
  Cell: RefererCell,
  Header: HeaderComponent,
  size: 200,
})

const fillingDuration = (t: Translate) => ({
  header: t('entry:list.fillingDuration'),
  id: 'entry.fillingDuration',
  type: 'entry.fillingDuration',
  Header: HeaderComponent,
  size: 130,
})

const commonDisabledColumnConfig = {
  enableColumnDragging: false,
  enableColumnOrdering: false,
  enableResizing: false,
}

type Params = {
  fields: Field[]
  t: Translate
  removedFields: string[]
  widgetType: CampaignWidgetType
}

export default function getColumns(params: Params) {
  const { t, fields, removedFields, widgetType } = params
  const { formFields, customerFields, sceneFields } = groupFields(fields)

  const columns: MRT_ColumnDef<any>[] = [
    {
      id: SERIAL_NUMBER_CODE,
      accessorFn: ({ entry }) => {
        return entry?.[SERIAL_NUMBER]
      },
      header: t('entry:list.serialNumber'),
      size: 66,
      Cell: SerialNumberBodyCell,
      Header: SerialNumberHeaderCell,
      mantineTableBodyCellProps: {
        sx: {
          borderRight: 'none !important',
        },
      },
      mantineTableHeadCellProps: {
        sx: {
          borderRight: 'none !important',
        },
      },
      ...commonDisabledColumnConfig,
    },
    {
      id: REPLY_CODE,
      accessorFn: ({ entry }) => entry?.[HAS_REPLY],
      header: '',
      size: 30,
      minSize: 30,
      Cell: ReplyCell,
      mantineTableBodyCellProps: {
        sx: {
          borderRight: 'none !important',
          paddingRight: '2px !important',
        },
      },
      mantineTableHeadCellProps: {
        sx: {
          borderRight: 'none !important',
          paddingRight: '2px !important',
        },
      },
      ...commonDisabledColumnConfig,
    },
    {
      id: COMMENTS_COUNT_CODE,
      accessorFn: ({ entry }) => entry?.[COMMENTS_COUNT],
      header: '',
      size: 40,
      Cell: CommentsCountCell,
      mantineTableBodyCellProps: {
        sx: {
          borderRight: 'none !important',
          paddingLeft: '0 !important',
        },
      },
      mantineTableHeadCellProps: {
        sx: {
          borderRight: 'none !important',
          paddingLeft: '0 !important',
        },
      },
      ...commonDisabledColumnConfig,
    },
  ]

  columns.push(...fieldsToColumnDefs(formFields))
  columns.push(customerId(t))
  columns.push(customerName(t))
  columns.push(customerMobile(t))
  columns.push(customerEmail(t))
  columns.push(...fieldsToColumnDefs(customerFields))
  columns.push(...fieldsToColumnDefs(sceneFields))
  columns.push({
    accessorFn: ({ entry }) => formatISOTime(entry?.createdAt),
    ...createdAt(t, widgetType),
  })
  columns.push({
    accessorFn: ({ entry }) =>
      entry?.submittedOn === EntrySubmittedOn.Completed
        ? t('entry:list.submittedOnValue.COMPLETE')
        : t('entry:list.submittedOnValue.EXPIRED'),
    ...submittedOn(t),
  })
  columns.push({
    accessorFn: ({ entry }) =>
      entry?.deliveryMethod ? t(`entry:list.deliveryMethodValue.${entry?.deliveryMethod}`) : '',
    ...deliveryMethod(t),
  })
  columns.push({
    accessorFn: ({ entry }) => formatTimeDuration(t, entry?.fillingDuration),
    ...fillingDuration(t),
  })
  columns.push({
    ...referer(t),
    accessorFn: ({ entry }) => {
      return entry?.[REFERER]
    },
  })
  columns.push({
    accessorFn: ({ entry }) => getDisplayRegion(entry?.region),
    ...region(t),
  })
  columns.push(browser(t))
  columns.push(screenResolution(t))
  columns.push(os(t))
  columns.push(ip(t))

  return columns.filter(({ id }) => !removedFields?.includes(id as string))
}
