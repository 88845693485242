import React, { useMemo } from 'react'
import cls from 'classnames'
import { Group, ScrollArea, Stack, Tabs, Text, Tooltip, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { useGetApplicationCustomerAttributes } from 'components/business'
import useApplicationInfoBaseInfoCache from 'components/business/hooks/useApplicationInfoBaseInfoCache'
import { getTextColor } from 'config/themeOverride/getColor'
import CustomerCard from 'components/business/CustomerCard'
import { Icon, LabelWithValue } from 'components/ui'
import { useCustomerDetailModal } from 'components/business/Customer/CustomerDetail'
import {
  Application,
  ApplicationCustomerAttribute,
  CampaignType,
  CustomApplicationEvent,
  DeliveryMethod,
  Entry,
  Field,
} from 'types/domain'
import { formatISOTime, getDisplayRegion } from 'utils'
import { isWebDistribution } from 'utils/applicationUtils'
import { AsideType } from '../../type'
import Comments from './Comments'
import SurveyBaseInfo from './SurveyBaseInfo'
import Reply from './Reply'
import useHowxmEvent from 'service/howxmEvents'

type Props = {
  campaignType: CampaignType
  isDisplayedSurveyForm: boolean
  canAddToSegment: boolean
  entry: Entry
  customerFields: Field[]
  targetAside?: AsideType
  customEvents?: CustomApplicationEvent[]
  updateReplyCallBack?: () => void
}

const Aside = (props: Props) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('entry')
  const {
    canAddToSegment,
    entry,
    targetAside,
    customerFields,
    customEvents,
    updateReplyCallBack,
    isDisplayedSurveyForm,
    campaignType,
  } = props
  const customer = entry?.customerSnapshot
  const { application } = useApplicationInfoBaseInfoCache()
  const { actionEvent } = useHowxmEvent()
  const canReply = isWebDistribution(application as Application)

  const defaultAsideType = useMemo(() => {
    return targetAside
      ? targetAside
      : isDisplayedSurveyForm
      ? AsideType.BaseInfo
      : canReply
      ? AsideType.Reply
      : AsideType.Comments
  }, [isDisplayedSurveyForm, targetAside, canReply])

  const openCustomerDetail = useCustomerDetailModal(canAddToSegment)
  const customerAttributes = useGetApplicationCustomerAttributes() as ApplicationCustomerAttribute[]

  const handleViewCustomerClick = () => {
    if (customer?.customerId) {
      openCustomerDetail({
        id: customer?.customerId,
        customerAttributes,
        manageable: canAddToSegment,
      })
    }
  }

  const userCardClasses = cls('mx-xl my-lg ', {
    'hover:cursor-pointer': customer?.customerId,
    'hover:brightness-90': customer?.customerId,
  })

  return (
    <Stack spacing={0} h="100%" className="overflow-hidden">
      <CustomerCard UID={customer?.uid} className={userCardClasses} onClick={handleViewCustomerClick}>
        <Group spacing={0} noWrap>
          <Text color={getTextColor('gray.7', theme)}>{t('entry:list.region')}</Text>：
          <Tooltip withArrow label={getDisplayRegion(entry.region)}>
            <Text className="truncate max-w-[150px]">{getDisplayRegion(entry.region)}</Text>
          </Tooltip>
        </Group>
        <LabelWithValue label={t('entry:list.createdAt')} value={formatISOTime(entry.createdAt)} />
      </CustomerCard>
      <Tabs
        keepMounted={false}
        styles={(theme) => ({
          root: {
            // header height 45px, user card height 103px
            height: 'calc(100% - 148px)',
          },
          tabsList: {
            paddingLeft: '24px',
          },
          panel: {
            position: 'relative',
            height: `calc(100% - ${theme.spacing.xl})`,
          },
        })}
        defaultValue={defaultAsideType}
      >
        <Tabs.List>
          {isDisplayedSurveyForm && <Tabs.Tab value={AsideType.BaseInfo}>{t('entryModal.baseInfo.tabLabel')}</Tabs.Tab>}
          {canReply && (
            <Tabs.Tab value={AsideType.Reply} onClick={() => actionEvent({ code: 'click_reply_tab' })}>
              <Group noWrap spacing="xs">
                {t('entryModal.reply.tabLabel')}
                <Icon name="newFeature" className="h-[22px] items-center" />
              </Group>
            </Tabs.Tab>
          )}
          <Tabs.Tab value={AsideType.Comments}>{t('entryModal.comments.tabLabel')}</Tabs.Tab>
        </Tabs.List>

        {isDisplayedSurveyForm && (
          <Tabs.Panel value={AsideType.BaseInfo}>
            <ScrollArea p="xl" pb="sm" h="100%">
              <SurveyBaseInfo
                entry={entry}
                campaignType={campaignType}
                customerFields={customerFields}
                customEvents={customEvents}
                manageable={canAddToSegment}
              />
            </ScrollArea>
          </Tabs.Panel>
        )}
        {canReply && (
          <Tabs.Panel value={AsideType.Reply}>
            <Reply
              entryId={entry.id!}
              disabled={entry.deliveryMethod === DeliveryMethod.Url}
              updateReplyCallBack={updateReplyCallBack}
            />
          </Tabs.Panel>
        )}
        <Tabs.Panel value={AsideType.Comments}>
          <Comments entryId={entry.id!} />
        </Tabs.Panel>
      </Tabs>
    </Stack>
  )
}

export default Aside
