import { ConditionGroupType, ConditionType } from 'components/business'
import update from 'immutability-helper'
import { defaultConditions } from '../constants'
import { Action } from './type'

export default function conditionGroupReducer(state: ConditionGroupType, action: Action) {
  switch (action.type) {
    case 'addCondition': {
      return {
        ...state,
        conditions: update(state.conditions, { $push: action.condition ? [action.condition] : defaultConditions }),
      }
    }
    case 'removeCondition': {
      return { ...state, conditions: update(state.conditions, { $splice: [[action.index, 1]] }) }
    }
    case 'conditionGroupOperatorChange':
      return { ...state, operator: action.value }
    case 'firstNodeChange':
      return {
        ...state,
        conditions: update(state.conditions, {
          [action.index]: {
            $set: {
              firstNode: action.firstNode,
              operator: undefined,
              secondNode: undefined,
            } as ConditionType,
          },
        }),
      }
    case 'firstNodeErrorChange':
      return {
        ...state,
        conditions: update(state.conditions, {
          [action.index]: {
            $set: {
              ...state.conditions[action.index],
              firstNode: {
                ...state.conditions[action.index].firstNode,
                error: action.error,
              },
            } as ConditionType,
          },
        }),
      }
    case 'conditionOperatorChange':
      return {
        ...state,
        conditions: update(state.conditions, {
          [action.index]: {
            $set: {
              ...state.conditions[action.index],
              operator: action.value,
              secondNode: undefined,
            } as ConditionType,
          },
        }),
      }
    case 'secondNodeChange':
      return {
        ...state,
        conditions: update(state.conditions, {
          [action.index]: {
            $set: {
              ...state.conditions[action.index],
              secondNode: action.secondNode,
            } as ConditionType,
          },
        }),
      }
    default:
      return state
  }
}
