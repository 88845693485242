import React, { useContext } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Group, Switch } from '@mantine/core'
import { isNil } from 'lodash-es'
import { useUpdateWebhookMutation } from 'types/domain'
import { InlineInput, message, StatusBadge } from 'components/ui'
import { webhookNameMaxLength } from '../constants'
import { useWebhookBuilderFormContext } from './formContext'
import { OnWebhookUpdate } from './type'
import useIsBuilderEditable from './hooks/useIsBuilderEditable'
import WebhookContext from 'components/business/Integration/WebhookContext'

interface Props {
  onUpdate?: OnWebhookUpdate
}

const Header = ({ onUpdate }: Props) => {
  const { t } = useTranslation('integration')
  const form = useWebhookBuilderFormContext()
  const [updateWebhook, { loading }] = useUpdateWebhookMutation()
  const { isAppScope } = useContext(WebhookContext)
  const editable = useIsBuilderEditable()

  const isNew = isNil(form.values.id)

  const handleStatusSwitch = async (enabled: boolean) => {
    const prevEnabled = form.values.enabled
    form.setFieldValue('enabled', enabled)
    try {
      const { data } = await updateWebhook({
        variables: {
          input: {
            id: form.values.id!,
            enabled,
          },
          includeCampaign: isAppScope,
        },
      })
      if (data?.updateCampaignWebhook) {
        message.successMsg(enabled ? t('webhook.builder.toggleSuccess.on') : t('webhook.builder.toggleSuccess.off'))
        onUpdate?.(data.updateCampaignWebhook)
      }
    } catch ({ graphQLErrors }) {
      form.setFieldValue('enabled', prevEnabled)
      message.error(enabled ? t('webhook.builder.toggleFailed.on') : t('webhook.builder.toggleFailed.off'))
    }
  }

  return (
    <Group position="apart">
      <InlineInput
        maxLength={webhookNameMaxLength}
        maxWidth={460}
        enterBlur
        autoFocus={isNew}
        autoRestore
        readOnly={!editable}
        {...form.getInputProps('name')}
        className="grow"
        inputStyle={{
          borderColor: 'transparent',
          backgroundColor: 'transparent',
          fontWeight: 600,
          fontSize: 14,
          paddingLeft: 8,
          paddingRight: 8,
        }}
      />
      {!isNew &&
        (editable ? (
          <Switch
            checked={form.values.enabled}
            onLabel={t('webhook.status.on')}
            offLabel={t('webhook.status.off')}
            size="lg"
            display="flex"
            color="teal.6"
            onChange={(e) => handleStatusSwitch(e.currentTarget.checked)}
            styles={(theme) => ({ trackLabel: { fontSize: theme.fontSizes.xs } })}
            disabled={loading}
          />
        ) : (
          <StatusBadge value={form.values.enabled} onText={t('webhook.status.on')} offText={t('webhook.status.off')} />
        ))}
    </Group>
  )
}

export default Header
