import useApplicationInfoBaseInfoCache from 'components/business/hooks/useApplicationInfoBaseInfoCache'
import { openAlertModal } from 'components/ui'
import useTranslation from 'next-translate/useTranslation'
import { CampaignType } from 'types/domain'
import { isApplicationStatusNormal } from 'utils/applicationUtils'

const useCampaignStatusAlert = (campaignType: CampaignType) => {
  const { t } = useTranslation('componentCampaignList')
  const { application, distribution, channelGroup } = useApplicationInfoBaseInfoCache()

  const open = (onConfirm: () => void) => {
    openAlertModal({
      title: t('common:tip.title'),
      content: t(
        `componentCampaignList:statusSwitcher.confirmContent.${
          isApplicationStatusNormal(distribution?.pingedAt) ? 'normal' : 'abnormal'
        }`,
        {
          channel: t(`common:channel.${channelGroup!}`),
          applicationName: application?.name!,
          campaignType: t(`common:campaignTypeName.${campaignType}`),
        }
      ),
      onConfirm,
    })
  }

  return {
    open,
  }
}

export default useCampaignStatusAlert
