import { useCheckFeature } from 'components/business'
import getCampaignInput from 'components/business/CampaignBuilder/utils/getCampaignInput'
import useApplicationBaseInfoCache from 'components/business/hooks/useApplicationInfoBaseInfoCache'
import useCampaignsCountCache from 'components/business/hooks/useCampaignsCountCache'
import { message } from 'components/ui'
import { ApplicationFeedbackEdit } from 'config/constants'
import { defaultFormData } from 'modules/Application/Feedback/Builder/constants'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { CampaignType, CreateCampaignInput, Feature, useCreateCampaignMutation } from 'types/domain'
import useGroupCache from 'components/business/Campaign/hooks/useGroupCache'

export default function useCreateFeedback() {
  const { t } = useTranslation('common')
  const router = useRouter()
  const { channel } = useApplicationBaseInfoCache()

  const screenshotsEnabled = useCheckFeature()(Feature.ScreenshotField)
  const [createMutation] = useCreateCampaignMutation()
  const [loading, setLoading] = useState(false)
  const { increase } = useCampaignsCountCache(CampaignType.Feedback)
  const { getDefaultGroup } = useGroupCache()

  const createFeedback = async () => {
    const initialFormData = { ...defaultFormData(channel, screenshotsEnabled) }
    const input = {
      ...getCampaignInput(initialFormData),
      applicationId: router.query.appId,
      groupId: getDefaultGroup(CampaignType.Feedback),
    } as CreateCampaignInput

    try {
      setLoading(true)
      const { data } = await createMutation({
        variables: { input },
      })
      const campaignId = data?.createCampaign?.id

      if (campaignId) {
        await router
          .push({
            pathname: ApplicationFeedbackEdit.replace('[appId]', router.query.appId as string).replace(
              '[campaignId]',
              campaignId
            ),
          })
          .then(() => {
            message.successMsg(`${t('actions.create')}${t('message.success')}`)
            increase()
          })
      }
    } catch (e) {
      message.error(`${t('actions.create')}${t('message.failed')}`)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    createFeedback,
  }
}
