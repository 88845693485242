import React from 'react'
import { Button, Group, HoverCard, TextInput } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { Feature } from 'types/domain'
import { FeatureTip, useCheckFeature, useSendWebhookDemo } from 'components/business'
import { useWebhookBuilderFormContext } from '../../../formContext'
import useIsBuilderEditable from '../../../hooks/useIsBuilderEditable'

const UrlInput = () => {
  const { t } = useTranslation('integration')
  const form = useWebhookBuilderFormContext()
  const canUseWebhook = useCheckFeature()(Feature.Webhook)
  const { sendWebhookDemo, loading } = useSendWebhookDemo()
  const editable = useIsBuilderEditable()
  const { channel } = form.values
  const urlIsValid = form.isValid('url')

  const handleSendDemo = async () => {
    if (!canUseWebhook) {
      return
    }
    const { name, url, channel, campaignId, id, targets } = form.values
    await sendWebhookDemo({
      webhookUrl: url.trim(),
      name,
      channel,
      webhookId: id,
      campaignId,
      mentionedTargets: targets,
    })
  }

  const sendDemoDisabled = !urlIsValid || !canUseWebhook
  return (
    <TextInput
      label={
        <Group position="apart">
          {t(`webhook.builder.stepAction.${channel}.url`)}
          {editable && (
            <HoverCard withArrow disabled={!sendDemoDisabled}>
              <HoverCard.Target>
                <div>
                  <Button
                    compact
                    variant="subtle"
                    onClick={handleSendDemo}
                    disabled={sendDemoDisabled}
                    loading={loading}
                  >
                    {t('webhook.sendDemo.action')}
                  </Button>
                </div>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                {urlIsValid ? <FeatureTip feature={Feature.Webhook} /> : t('webhook.sendDemo.tip')}
              </HoverCard.Dropdown>
            </HoverCard>
          )}
        </Group>
      }
      placeholder={t('common:placeholders.pleaseInput')}
      className="w-full"
      styles={{ label: { fontWeight: 400, display: 'block' } }}
      readOnly={!editable}
      {...form.getInputProps('url')}
    />
  )
}

export default UrlInput
