import { createFormContext } from '@mantine/form'
import { CampaignDistributionChannel } from 'types/domain'

export type OnboardingContextData = {
  accountName?: string | null
  appName?: string
  channel?: CampaignDistributionChannel
  appId?: string
  openDropdown?: boolean
  step?: number
  onboarding?: boolean
}

export const [OnboardingLayoutProvider, useOnboardingLayoutContext, useOnboardingLayoutForm] =
  createFormContext<OnboardingContextData>()
