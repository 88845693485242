import { Tabs, useMantineTheme } from '@mantine/core'
import { IconBolt } from '@tabler/icons-react'
import { useCampaign } from 'components/business/Campaign/CampaignContext'
import { Tab } from 'components/business/Campaign/Layout/type'
import { Icon } from 'components/ui'
import {
  ApplicationFeedbackEntries,
  ApplicationFeedbackIntegration,
  ApplicationFeedbackShow,
  ApplicationNotificationEntries,
  ApplicationNotificationIntegration,
  ApplicationNotificationReports,
  ApplicationNotificationShow,
  ApplicationSurveyEntries,
  ApplicationSurveyIntegration,
  ApplicationSurveyReports,
  ApplicationSurveyShow,
} from 'config/constants'
import { getBackgroundColor, getBorderColor } from 'config/themeOverride/getColor'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { CampaignType, CampaignWidgetType } from 'types/domain'

type Props = {
  activeTab: Tab
}

const NotificationTabRouterMap = {
  entries: ApplicationNotificationEntries,
  reports: ApplicationNotificationReports,
  overview: ApplicationNotificationShow,
  integration: ApplicationNotificationIntegration,
} as { [x: string]: string }

const SurveyTabRouterMap = {
  entries: ApplicationSurveyEntries,
  reports: ApplicationSurveyReports,
  overview: ApplicationSurveyShow,
  integration: ApplicationSurveyIntegration,
} as { [x: string]: string }

const FeedbackTabRouterMap = {
  overview: ApplicationFeedbackShow,
  entries: ApplicationFeedbackEntries,
  integration: ApplicationFeedbackIntegration,
} as { [x: string]: string }

function getRouterPath(tab: Tab, type: CampaignType) {
  switch (type) {
    case CampaignType.Notification:
      return NotificationTabRouterMap[tab] ?? ApplicationNotificationShow
    case CampaignType.Feedback:
      return FeedbackTabRouterMap[tab] ?? ApplicationFeedbackShow
    case CampaignType.Survey:
      return SurveyTabRouterMap[tab] ?? ApplicationSurveyShow
  }
}

function getIsShowReportTab(campaignType: CampaignType, widgetType: CampaignWidgetType) {
  return (
    campaignType === CampaignType.Survey ||
    (campaignType === CampaignType.Notification && widgetType === CampaignWidgetType.Popup)
  )
}

const CampaignTabs: FC<Props> = ({ activeTab }) => {
  const { t } = useTranslation('layoutCampaign')
  const theme = useMantineTheme()
  const router = useRouter()
  const { appId, campaignId } = router.query
  const { campaign } = useCampaign()
  const { type } = campaign!
  const isShowReportTab = getIsShowReportTab(type, campaign?.widget.type!)

  const onTabChange = (value: Tab) => {
    router
      .push({
        pathname: getRouterPath(value, type!),
        query: {
          appId,
          campaignId,
        },
      })
      .then()
  }

  return (
    <Tabs value={activeTab} onTabChange={onTabChange}>
      <Tabs.List
        style={{
          borderColor: getBackgroundColor('gray.2', theme),
          borderBottomColor: getBorderColor('gray.3', theme),
        }}
      >
        <Tabs.Tab value="overview" icon={<Icon name={activeTab === 'overview' ? 'tabSummaryFill' : 'tabSummary'} />}>
          {t('tab.overview')}
        </Tabs.Tab>
        {isShowReportTab && (
          <Tabs.Tab value="reports" icon={<Icon name={activeTab === 'reports' ? 'tabChartFill' : 'tabChart'} />}>
            {t('tab.reports')}
          </Tabs.Tab>
        )}
        <Tabs.Tab value="entries" icon={<Icon name={activeTab === 'entries' ? 'tabTableFill' : 'tabTable'} />}>
          {t('tab.entries')}
        </Tabs.Tab>
        <Tabs.Tab
          value="integration"
          icon={
            <IconBolt
              color={activeTab === 'integration' ? theme.colors.brandBlue[7] : theme.colors.gray[7]}
              size={24}
              stroke={1.5}
            />
          }
        >
          {t('tab.integration')}
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  )
}

export default CampaignTabs
