import { isEqual } from 'lodash-es'
import omitDeep from 'omit-deep-lodash'

let initialFormData = {}

export const setInitialFormData = (values: any) => {
  initialFormData = values
}

export const isFormDataChanged = (value: any) => {
  return !isEqual(omitDeep(value, ['__typename']), omitDeep(initialFormData, ['__typename']))
}
