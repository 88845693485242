import React, { FC, useEffect, useMemo, useState } from 'react'
import { SimpleGrid, useMantineTheme } from '@mantine/core'
import { useBuilderFormContext } from 'components/business/CampaignBuilder'
import Swatch from './Swatch'
import CustomSwatch from './CustomSwatch'

const colorSchemePath = 'widget.colorScheme'
const accentPath = `${colorSchemePath}.accent`

type Props = {
  cols?: number
}

const ColorScheme: FC<Props> = (props) => {
  const { cols = 7 } = props
  const theme = useMantineTheme()
  const defaultAccentColors = useMemo(
    () => [
      theme.colors.brandBlue[7],
      theme.colors.blue[6],
      theme.colors.pink[6],
      theme.colors.grape[6],
      theme.colors.violet[6],
      theme.colors.indigo[6],
      theme.colors.cyan[6],
      theme.colors.teal[6],
      theme.colors.green[6],
      theme.colors.lime[6],
      theme.colors.yellow[6],
      theme.colors.orange[6],
      theme.colors.red[6],
    ],
    [
      theme.colors.blue,
      theme.colors.brandBlue,
      theme.colors.cyan,
      theme.colors.grape,
      theme.colors.green,
      theme.colors.indigo,
      theme.colors.lime,
      theme.colors.orange,
      theme.colors.pink,
      theme.colors.red,
      theme.colors.teal,
      theme.colors.violet,
      theme.colors.yellow,
    ]
  )
  const customSwatchIndex = defaultAccentColors.length

  const form = useBuilderFormContext()
  const { value, onChange, error } = form?.getInputProps(accentPath)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

  useEffect(() => {
    if (value) {
      const index = defaultAccentColors.indexOf(value)
      setSelectedIndex(index === -1 ? customSwatchIndex : index)
    }
  }, [customSwatchIndex, defaultAccentColors, value])

  return (
    <SimpleGrid cols={cols} spacing={10}>
      {defaultAccentColors.map((color, index) => (
        <Swatch key={color} color={color} checked={selectedIndex === index} onClick={() => onChange(color)} />
      ))}
      <CustomSwatch
        checked={selectedIndex === customSwatchIndex}
        color={value}
        onChange={onChange}
        onClick={() => setSelectedIndex(customSwatchIndex)}
        error={error}
      />
    </SimpleGrid>
  )
}

export default ColorScheme
