import { isNil, range } from 'lodash-es'
import { Item } from '../../type'

function genNumberOptions(numbers: number[]): Item[] {
  return numbers.map((item) => {
    return {
      label: item,
      value: item,
    }
  })
}

function calcChoices(field: any): Item[] {
  const { type, ratingMax, choices } = field

  if (['RatingField', 'CsatField'].includes(type) && isNil(choices)) {
    return genNumberOptions(range(1, ratingMax + 1))
  }

  if (type === 'NpsField' && isNil(choices)) {
    return genNumberOptions(range(ratingMax + 1))
  }

  return choices ?? []
}

export default function formatFields(fields: any) {
  return fields?.map((field: any) => {
    const { code, label, type, statements } = field
    return {
      code,
      label,
      type,
      choices: calcChoices(field),
      statements,
    }
  })
}
