import { Feature } from 'types/domain'
import useAccountSubscriptionCache from 'components/business/hooks/useAccountSubscriptionCache'
import { isNil } from 'lodash-es'

export default function useCheckFeature(defaultForLoading?: boolean) {
  const { features, loading } = useAccountSubscriptionCache()
  const cacheFeatures = features ?? []
  return (feature: Feature, features?: Feature[]) => {
    if (!isNil(defaultForLoading) && loading) {
      return defaultForLoading
    }
    return (features ?? cacheFeatures).includes(feature)
  }
}
