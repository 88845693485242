import { Menu } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import AppName from './AppName'
import dynamic from 'next/dynamic'

const AppsSelector = dynamic(() => import('./AppsSelector'))

const AppManager = () => {
  const [opened, handles] = useDisclosure(false)

  const handleSwitchModalOpen = () => {
    handles.close()
  }

  return (
    <Menu
      opened={opened}
      onChange={handles.toggle}
      shadow="md"
      width={300}
      withinPortal
      position="bottom-start"
      styles={{
        itemIcon: {
          marginRight: 0,
        },
        itemLabel: {
          flex: '1 !important',
        },
      }}
    >
      <Menu.Target>
        <AppName />
      </Menu.Target>
      <Menu.Dropdown px={0}>
        {opened && <AppsSelector onAccountSwitchModalOpen={handleSwitchModalOpen} />}
      </Menu.Dropdown>
    </Menu>
  )
}

export default AppManager
