import React from 'react'
import { Text } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { htmlEncodeByRegExp } from 'utils'
import MentionedTargets from './MentionedTargets'
import { WebhookRequestDetailProps } from './type'
import DefaultRequestDetail from './DefaultRequestDetail'

interface Props extends WebhookRequestDetailProps {
  contentKey?: string
  targetsKey?: string
}

const IMRequestDetail = ({ requestPayload, contentKey, targetsKey }: Props) => {
  const { t } = useTranslation('integration')
  const content = requestPayload[contentKey || 'content']
  const targets = targetsKey ? requestPayload[targetsKey] : null

  if (content) {
    return (
      <>
        <div>
          <Text>{t('webhook.histories.detail.requestContent')}</Text>
          <Text ml="xl" className="break-all">
            <div dangerouslySetInnerHTML={{ __html: htmlEncodeByRegExp(content) }} />
          </Text>
        </div>
        <MentionedTargets targets={targets} />
      </>
    )
  }
  return <DefaultRequestDetail requestPayload={requestPayload} />
}

export default IMRequestDetail
