import { Translate } from 'next-translate'
import { ConditionOperator } from 'types/domain'

export function isPresentOperator(operator: ConditionOperator): boolean {
  return [ConditionOperator.IsNull, ConditionOperator.IsNotNull].includes(operator)
}

export function getOperatorLabel(operator: ConditionOperator, t: Translate) {
  const isOptionIncludedOrExcluded = [ConditionOperator.Include, ConditionOperator.Exclude].includes(operator)
  return t(
    isOptionIncludedOrExcluded
      ? `componentConditionGroup:conditionOperatorPatch.${operator}`
      : `componentConditionGroup:conditionOperator.${operator}`
  )
}
