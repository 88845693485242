import { useBuilderFormContext } from 'components/business/CampaignBuilder'
import { CampaignTriggerEvent, EventTriggerCondition, PageTriggerCondition } from 'types/domain'

export default function useCampaignTriggerCondition() {
  const form = useBuilderFormContext()
  const trigger = form.values.trigger

  const getTriggerCondition = (event: CampaignTriggerEvent) =>
    trigger.conditions.find((condition) => condition.event === event)

  const getPageTriggerCondition = () => getTriggerCondition(CampaignTriggerEvent.PageVisit) as PageTriggerCondition

  const getEventTriggerCondition = () =>
    getTriggerCondition(CampaignTriggerEvent.EventReceived) as EventTriggerCondition

  const getEventConditions = () => getEventTriggerCondition()?.eventConditions

  return {
    getPageTriggerCondition,
    getEventTriggerCondition,
    getEventConditions,
  }
}
