import React, { FC, useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { compact } from 'lodash-es'

import { CampaignType, CampaignWidgetButtonType, CampaignWidgetType, PageTriggerCondition } from 'types/domain'
import { useBuilderFormContext } from 'components/business/CampaignBuilder'
import { RadioGroup } from 'components/ui'

import { getDefaultPositionByWidgetType } from './getDefaultPositionByWidgetType'
import {
  defaultActiveTriggerCondition,
  defaultNonActiveTriggerCondition,
  defaultTriggerOperator,
} from '../../Distribution/When/constant'
import useCampaignTriggerCondition from '../../utils/useCampaignTriggerCondition'
import { getDefaultSurveyButtonWidgetSetting } from 'components/business/SurveyBuilder/constants'

type Props = {}

const supportButtonWidgetType = [CampaignType.Survey, CampaignType.Notification]

const WidgetType: FC<Props> = () => {
  const { t } = useTranslation('componentCampaignBuilder')
  const form = useBuilderFormContext()
  const { getEventTriggerCondition } = useCampaignTriggerCondition()
  const { value: widgetTypeValue, onChange: widgetTypeOnChange } = form?.getInputProps('widget.type')
  const { value: widgetSettingValue, onChange: widgetSettingOnChange } = form?.getInputProps('widget.setting')
  const { onChange: positionOnChange } = form.getInputProps('widget.position')
  const { onChange: triggerOnChange } = form.getInputProps('trigger')

  const widgetTypes = useMemo(() => {
    return [
      {
        value: CampaignWidgetType.Button,
        label: t('styles.widgetType.asideButton'),
      },
      { value: CampaignWidgetType.Popup, label: t('styles.widgetType.popup') },
    ]
  }, [t])

  const getUpdatedTrigger = (pageTriggerCondition: PageTriggerCondition | null) => {
    return {
      operator: defaultTriggerOperator,
      conditions: compact([pageTriggerCondition, getEventTriggerCondition()]),
    }
  }

  const handleChange = (widgetType: string) => {
    const value = widgetType as CampaignWidgetType
    if (value === CampaignWidgetType.Popup || value === CampaignWidgetType.Banner) {
      widgetTypeOnChange(value)
      positionOnChange(getDefaultPositionByWidgetType(value))
      triggerOnChange(getUpdatedTrigger(defaultActiveTriggerCondition))
    } else {
      triggerOnChange(getUpdatedTrigger(defaultNonActiveTriggerCondition))
      widgetTypeOnChange(CampaignWidgetType.Button)
      positionOnChange(getDefaultPositionByWidgetType(CampaignWidgetType.Button, CampaignWidgetButtonType.Aside))
      if (supportButtonWidgetType.includes(form.values.type!) && !widgetSettingValue) {
        widgetSettingOnChange(getDefaultSurveyButtonWidgetSetting(t))
      }
      form.getInputProps('widget.setting.buttonType').onChange(CampaignWidgetButtonType.Aside)
    }
  }

  return <RadioGroup data={widgetTypes} value={widgetTypeValue} onChange={handleChange} />
}

export default WidgetType
