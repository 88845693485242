import React from 'react'
import { Text } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

const ValueComponent = ({ value, values }: { value: string; values: string[] }) => {
  const { t } = useTranslation('componentEntryFilter')
  const isSelectedAll = values.length === 5
  if (isSelectedAll) {
    return value === '1' ? <Text>{t('allStar')}</Text> : null
  }
  const isLast = values.slice(-1)[0] === value
  return (
    <Text>
      {t('star', { num: value })}
      {isLast ? '' : ','}
    </Text>
  )
}

export const getValueComponent =
  (values: string[]) =>
  ({ value }: { value: string }) =>
    <ValueComponent value={value} values={values} />

export default ValueComponent
