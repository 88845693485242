import React from 'react'
import { useClickOutside } from '@mantine/hooks'
import { Button, CopyButton, Group, Paper, Stack, Text, TextInput, Title } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { QRCodeSVG } from 'qrcode.react'
import { useAbac } from 'react-abac'
import { getXFormUrl } from 'utils/getXFormUrl'
import { message } from 'components/ui'
import useCampaignFormStatusSwitch from '../hooks/useCampaignFormStatusSwitch'
import useCurrentUserCache from 'components/business/hooks/useCurrentUserCache'
import useApplicationRoleCache from 'components/business/hooks/useApplicationRoleCache'
import useCampaignBaseInfoCache from 'components/business/hooks/useCampaignBaseInfoCache'
import CampaignFormStatusSwitch from 'components/business/Campaign/CampaignFormStatusSwitch'

interface Props {
  id: string
  close?: () => void
}

const Content = ({ id, close }: Props) => {
  const { t } = useTranslation('componentCampaignList')
  const { user } = useCurrentUserCache()
  const { userHasPermissions } = useAbac()
  const { applicationRole } = useApplicationRoleCache()
  const ref = useClickOutside(() => close?.())
  const { toggle } = useCampaignFormStatusSwitch(id)
  const { campaign } = useCampaignBaseInfoCache(id)
  if (!campaign) {
    return null
  }

  const url = getXFormUrl(campaign.token, campaign.type, true)
  const handleOpen = () => {
    window.open(url, '_blank')
  }

  const handleCopied = () => {
    message.successMsg(t('common:message.linkCopySuccess'))
  }

  return (
    <Stack ref={ref}>
      <Group position="apart">
        <Title order={4}>{t('deliveryUrl.title')}</Title>
        <CampaignFormStatusSwitch id={campaign.id} />
      </Group>
      <Text>{t('deliveryUrl.desc')}</Text>
      <Group>
        <Paper p="xs" withBorder>
          <QRCodeSVG value={url} size={100} display="block" />
        </Paper>
        <Stack className="grow">
          <TextInput value={url} readOnly />
          <Group>
            <CopyButton value={url}>
              {({ copy }) => (
                <Button
                  onClick={() => {
                    copy()
                    handleCopied()
                  }}
                >
                  {t('deliveryUrl.copy')}
                </Button>
              )}
            </CopyButton>

            <Button variant="default" onClick={handleOpen}>
              {t('deliveryUrl.open')}
            </Button>
          </Group>
        </Stack>
      </Group>
    </Stack>
  )
}

export default Content
