import { CampaignsCountDocument, CampaignType, useCampaignsCountQuery } from 'types/domain'
import { useRouter } from 'next/router'
import { PAGE_SIZE } from 'config/constants'
import { initializeApollo } from 'plugins/apollo'

export default function useCampaignsCountCache(campaignType: CampaignType) {
  const appId = useRouter().query.appId as string
  const { data, variables } = useCampaignsCountQuery({
    variables: {
      id: appId as string,
      pageArguments: { first: PAGE_SIZE.NONE },
      filter: { type: campaignType },
    },
  })
  const client = initializeApollo()

  const updateFn = (callbackFn: () => number) => {
    client.cache.updateQuery(
      {
        query: CampaignsCountDocument,
        variables,
      },
      (prev) => {
        return {
          ...prev,
          application: {
            ...prev.application,
            campaigns: {
              ...prev.application.campaigns,
              totalCount: prev.application.campaigns.totalCount + callbackFn(),
            },
          },
        }
      }
    )
  }

  const increase = (count: number = 1) => {
    updateFn(() => {
      return count
    })
  }

  const decrease = (count: number = 1) => {
    updateFn(() => {
      return -count
    })
  }

  return {
    count: data?.application?.campaigns?.totalCount,
    increase,
    decrease,
  }
}
