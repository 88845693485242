import { Button, ButtonProps } from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'
import React, { ReactNode } from 'react'

type Props = ButtonProps & {
  text: ReactNode
  onClick?: () => void
}

const AddButton = (props: Props) => {
  const { text, onClick, ...restProps } = props

  return (
    <Button
      variant="subtle"
      leftIcon={<IconPlus size={20} strokeWidth={1.5} />}
      onClick={onClick}
      styles={({ spacing }) => ({ root: { paddingLeft: spacing.sm, paddingRight: spacing.sm } })}
      {...restProps}
    >
      {text}
    </Button>
  )
}

export default AddButton
