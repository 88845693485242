import React, { useContext } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Alert, Button, Group, Stack } from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react'
import { isNil } from 'lodash-es'
import { useCreateWebhookMutation, useUpdateWebhookMutation } from 'types/domain'
import { message } from 'components/ui'
import { useWebhookBuilderFormContext } from '../formContext'
import { OnWebhookCreate, OnWebhookUpdate } from '../type'
import Steps from './Steps'
import { getCreateWebhookInput, getUpdateWebhookInput } from './utils'
import useIsBuilderEditable from '../hooks/useIsBuilderEditable'
import WebhookContext from 'components/business/Integration/WebhookContext'

interface Props {
  onCancel: () => void
  onCreate?: OnWebhookCreate
  onUpdate?: OnWebhookUpdate
}

const Content = ({ onCancel, onCreate, onUpdate }: Props) => {
  const { t } = useTranslation('integration')
  const form = useWebhookBuilderFormContext()
  const { isAppScope } = useContext(WebhookContext)
  const [createWebhook, { loading: creating }] = useCreateWebhookMutation()
  const [updateWebhook, { loading: updating }] = useUpdateWebhookMutation()
  const isNew = isNil(form.values.id)
  const editable = useIsBuilderEditable()

  const handleCreate = async () => {
    try {
      const { data } = await createWebhook({
        variables: {
          input: getCreateWebhookInput(form.values),
          includeCampaign: isAppScope,
        },
      })
      if (data?.createCampaignWebhook) {
        form.setFieldValue('id', data.createCampaignWebhook.id)
        message.successMsg(t('webhook.builder.createSuccess'))
        onCreate?.(data.createCampaignWebhook)
      }
    } catch (e) {
      console.error(e)
      message.error(t('webhook.builder.createFailed'))
    }
  }

  const handleUpdate = async () => {
    try {
      const { data } = await updateWebhook({
        variables: {
          input: getUpdateWebhookInput(form.values),
          includeCampaign: isAppScope,
        },
      })
      if (data?.updateCampaignWebhook) {
        message.successMsg(t('webhook.builder.updateSuccess'))
        onUpdate?.(data.updateCampaignWebhook)
      }
    } catch (e) {
      console.error(e)
      message.error(t('webhook.builder.updateFailed'))
    }
  }

  const handleSubmit = async () => {
    if (editable) {
      isNew ? await handleCreate() : await handleUpdate()
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack mx={38} spacing="xl">
        {!editable && <Alert icon={<IconAlertCircle size={16} />}>{t('webhook.builder.readonlyTip')}</Alert>}
        <Steps />
        <Group position="right">
          {isNew ? (
            <>
              <Button variant="default" onClick={onCancel}>
                {t('common:actions.cancel')}
              </Button>
              <Button type="submit" disabled={creating || !form.isValid()}>
                {t('webhook.actions.create')}
              </Button>
            </>
          ) : (
            <>
              <Button variant="default" onClick={onCancel}>
                {editable ? t('common:actions.cancel') : t('common:actions.close')}
              </Button>
              {editable && (
                <Button type="submit" disabled={updating || !form.isValid()}>
                  {t('webhook.actions.update')}
                </Button>
              )}
            </>
          )}
        </Group>
      </Stack>
    </form>
  )
}

export default Content
