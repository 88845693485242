import React, { useContext } from 'react'
import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'
import { Button, Group, HoverCard, Stack, Text, useMantineTheme } from '@mantine/core'
import { ProductCode } from 'config/constants/products'
import useTranslation from 'next-translate/useTranslation'
import { differenceInDays, parseISO } from 'date-fns'
import FreeSubscription from './FreeSubscription'
import SubscriptionHoverDropDown from './SubscriptionHoverDropDown'
import { Icon } from 'components/ui'
import { useVipColor } from 'components/business'
import ApplicationContext from 'components/business/Layouts/ApplicationLayout/ApplicationContext'
import { openProductsModal } from 'components/business/ProductsModal'
import useCheckFeatureAlert from 'components/business/subscription/useCheckFeatureAlert'

const iconWidth = 24

const CurrentSubscription = () => {
  const theme = useMantineTheme()
  const getVipColor = useVipColor()
  const { t } = useTranslation('account')
  const account = useContext(ApplicationContext).account!
  const subscription = account.subscription!

  const productCode = subscription.productCode
  const vasBalance = account.vasBalance!
  const endDate = account.subscriptionLastEndDate!

  const unavailableFeature = useCheckFeatureAlert()

  const { colorScheme } = useMantineTheme()
  const isDarkMode = colorScheme === 'dark'

  return (
    <HoverCard withinPortal position="top-start">
      <HoverCard.Target>
        <Group
          p="sm"
          pr="md"
          mb="sm"
          spacing="xl"
          align="start"
          bg={
            !unavailableFeature
              ? productCode === ProductCode.Trial
                ? getBackgroundColor('brandBlue.0', theme, 'dark.6')
                : getBackgroundColor('gray.0', theme)
              : isDarkMode
              ? 'rgba(224, 49, 49, 0.2)'
              : 'rgba(255, 245, 245, 1)'
          }
          sx={{ borderRadius: theme.radius.md }}
        >
          <Icon name="vip" style={{ color: getVipColor(productCode as ProductCode), width: iconWidth }} />
          <Stack spacing={8} className="flex-1">
            <Group position="apart">
              <Text>{productCode ? t(`subscription.${productCode}.name`) : ''}</Text>
              <Button
                size="xs"
                className="w-[54px] h-[25px]"
                onClick={() => openProductsModal(t(`subscription.${productCode}.upgradeFeature`))}
              >
                {productCode === ProductCode.Free ? t('actions.upgrade') : t('actions.renewal')}
              </Button>
            </Group>
            {productCode === ProductCode.Free && <FreeSubscription vasBalance={vasBalance} />}
            <>
              {productCode === ProductCode.Trial && (
                <Text mt="xs" size="xs" color={getTextColor('gray.7', theme)}>
                  {endDate
                    ? t('subscription.trial.expireAfter', { days: differenceInDays(parseISO(endDate), new Date()) })
                    : t('subscription.trial.expireToday')}
                </Text>
              )}
            </>
          </Stack>
        </Group>
      </HoverCard.Target>
      <HoverCard.Dropdown
        sx={{
          borderRadius: theme.radius.sm,
          maxWidth: 420,
        }}
      >
        <SubscriptionHoverDropDown
          productCode={productCode}
          unavailableFeature={unavailableFeature}
          endDate={endDate}
        />
      </HoverCard.Dropdown>
    </HoverCard>
  )
}

export default CurrentSubscription
