import React from 'react'
import { Group, Image, Text } from '@mantine/core'

import { CsatField } from 'types/domain'
import { csatImages, splitCharCode } from 'config/constants'

const format = (value: any, field: CsatField) => {
  const label = field.ratingDescriptions[value - 1]
  return `${value}${splitCharCode}${label}`
}

const formatDetail = (value: any, field: CsatField) => {
  const label = field.ratingDescriptions[value - 1]
  return (
    <Group spacing="xs">
      <Image width={20} height={20} src={csatImages[value - 1].src} alt={label ?? ''} />
      <Text>{label}</Text>
    </Group>
  )
}

const csatField = {
  format,
  formatDetail,
}

export default csatField
