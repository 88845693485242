import { DistributionChannelGroup } from 'config/constants'
import { Application, ApplicationPermission, CampaignDistributionChannel } from 'types/domain'

export const isApplicationStatusNormal = (pingedAt: string | undefined) => {
  if (!pingedAt) {
    return false
  }
  const now = new Date()
  const diff = now.getTime() - new Date(pingedAt).getTime()
  return diff < 1000 * 60 * 60 * 72
}

export const isMiniappDistribution = (application?: Application) => {
  if (!application) {
    return false
  }
  return application.distribution.channel === CampaignDistributionChannel.WechatMiniapp
}

const mobileDistributionChannels = [
  CampaignDistributionChannel.Android,
  CampaignDistributionChannel.IosSwift,
  CampaignDistributionChannel.WechatMiniapp,
]

export const isMobileDistribution = (application?: Application | null) => {
  if (!application) {
    return false
  }
  return mobileDistributionChannels.includes(application.distribution.channel)
}

export const isLinkDistribution = (application?: Application | null) => {
  if (!application) {
    return false
  }
  return application.distribution.channel == CampaignDistributionChannel.Link
}

export const isWebDistribution = (application?: Application | null) => {
  if (!application) {
    return false
  }
  return CampaignDistributionChannel.Web === application.distribution.channel
}

const appDistributionChannels = [CampaignDistributionChannel.Android, CampaignDistributionChannel.IosSwift]

export const isAppDistribution = (application?: Application | null) => {
  if (!application) {
    return false
  }
  return appDistributionChannels.includes(application.distribution.channel)
}

export const getDistributionChannelGroup = (
  channel?: CampaignDistributionChannel
): DistributionChannelGroup | undefined => {
  switch (channel) {
    case CampaignDistributionChannel.Web:
      return 'web'
    case CampaignDistributionChannel.IosSwift:
    case CampaignDistributionChannel.Android:
      return 'mobileApp'
    case CampaignDistributionChannel.WechatMiniapp:
      return 'miniapp'
    case CampaignDistributionChannel.Link:
      return 'link'
  }
}

export const canUpdateApplication = (application: Application) => {
  return application?.role?.permissions.includes(ApplicationPermission.ApplicationUpdate)
}
