import React from 'react'
import { useDisclosure } from '@mantine/hooks'
import useTranslation from 'next-translate/useTranslation'
import { Button, Group, Textarea, TextInput } from '@mantine/core'
import { hasLength, useForm } from '@mantine/form'
import { CampaignGroup } from 'types/domain'

const initialValues = {
  name: '',
}

type Props = {
  group?: Pick<CampaignGroup, 'id' | 'name'>
  onCancel: () => void
  onSave: (name: string, callback: Function) => void
}

const GroupForm = (props: Props) => {
  const { t } = useTranslation('componentCampaignList')
  const { group, onSave, onCancel } = props
  const [disabled, { open: openDisabled, close: closeDisabled }] = useDisclosure(false)

  const form = useForm<{ name: string }>({
    initialValues: group ? { name: group.name } : initialValues,
    validate: {
      name: hasLength({ min: 2, max: 40 }, t('campaignGroup.nameLengthTip')),
    },
  })

  const handleSubmit = () => {
    openDisabled()
    const values = form.values
    onSave(values.name.trim(), closeDisabled)
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <TextInput
        data-autofocus
        maxLength={40}
        label={t('campaignGroup.name')}
        placeholder={t('common:placeholders.pleaseInput')}
        {...form.getInputProps('name')}
      />
      <Group position="right" mt="xl">
        <Button variant="default" onClick={onCancel}>
          {t('common:actions.cancel')}
        </Button>
        <Button type="submit" disabled={disabled}>
          {group ? t('common:actions.save') : t('common:actions.create')}
        </Button>
      </Group>
    </form>
  )
}

export default GroupForm
