import React from 'react'
import { AspectRatio } from '@mantine/core'
import useSwatchStyles from './swatch.styles'

interface Props {
  color: string
  checked?: boolean
  onClick?: () => void
}

const Swatch = ({ color, checked, onClick }: Props) => {
  const { classes } = useSwatchStyles({ checked })
  return <AspectRatio ratio={1} className={classes.root} sx={{ background: color }} onClick={onClick} />
}

export default Swatch
