import { CampaignTemplateCategory } from 'types/domain'
import {
  IconUxCircle,
  IconArrowGuide,
  IconUserQuestion,
  IconTrendingUp,
  IconArrowIteration,
  IconNotification,
} from '@tabler/icons-react'

export const orderedCategories: CampaignTemplateCategory[] = [
  CampaignTemplateCategory.UserExperience,
  CampaignTemplateCategory.UserGuidance,
  CampaignTemplateCategory.UserStudy,
  CampaignTemplateCategory.OperatingGrowth,
  CampaignTemplateCategory.ProductsIteration,
  CampaignTemplateCategory.BannerNotice,
]

export const categoriesIcons = {
  [CampaignTemplateCategory.UserExperience]: IconUxCircle,
  [CampaignTemplateCategory.UserGuidance]: IconArrowGuide,
  [CampaignTemplateCategory.UserStudy]: IconUserQuestion,
  [CampaignTemplateCategory.OperatingGrowth]: IconTrendingUp,
  [CampaignTemplateCategory.ProductsIteration]: IconArrowIteration,
  [CampaignTemplateCategory.BannerNotice]: IconNotification,
}
