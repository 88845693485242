import React from 'react'
import { TextInput, TextInputProps } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { UseFormReturnType } from '@mantine/form/lib/types'
import { AuthenticationFormValues } from 'components/business/Authentication'
import { SignupFormValues } from 'modules/Signup/formContext'

interface Props extends TextInputProps {
  formContext: UseFormReturnType<AuthenticationFormValues | SignupFormValues>
}

const IdentifierInput = ({ formContext, ...props }: Props) => {
  const { t } = useTranslation('user')

  return (
    <TextInput
      placeholder={t('authentication.form.identifier')}
      data-autofocus
      {...props}
      {...formContext?.getInputProps('identifier')}
    />
  )
}

export default IdentifierInput
