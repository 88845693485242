import { useAccountSubscriptionQuery } from 'types/domain'
import appContext from 'service'
import isServer from 'utils/isServer'

const useAccountSubscriptionCache = () => {
  const { data, loading } = useAccountSubscriptionQuery({ skip: !isServer && !appContext.getSystemToken() })

  return {
    account: data?.account,
    subscription: data?.account?.subscription,
    vasBalance: data?.account?.vasBalance,
    features: data?.account?.features,
    loading: loading,
  }
}

export default useAccountSubscriptionCache
