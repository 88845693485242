import { message } from 'components/ui'
import { AttachmentType, useCreateAttachmentUploadOptionsMutation } from 'types/domain'
import useTranslation from 'next-translate/useTranslation'

interface UploadFileProps {
  ownerId?: string
  fileInfo: any
  attachmentType: AttachmentType
  onProcessChange?: (data: { percent?: number }) => void
  onUploadCompleted?: (() => void) | ((fileKey: string) => void)
}

export default function useUploadFile() {
  const { t } = useTranslation('common')
  const [createAttachmentUploadOptionsMutation] = useCreateAttachmentUploadOptionsMutation()

  return ({ ownerId, fileInfo, attachmentType, onUploadCompleted, onProcessChange }: UploadFileProps) => {
    createAttachmentUploadOptionsMutation({
      variables: { input: { type: attachmentType, fileName: fileInfo?.file?.name, ownerId } },
      onCompleted: (data) => {
        if (!data?.createAttachmentUploadOptions) {
          message.error(t('message.uploadError'))
          return
        }
        const fileKey = data.createAttachmentUploadOptions.key
        const url = data.createAttachmentUploadOptions.url

        let xhr = new XMLHttpRequest()
        xhr.open('PUT', url, true)
        const { file } = fileInfo
        const extname = file.name.split('.').pop()
        switch (extname) {
          case 'xls':
            xhr.setRequestHeader('Content-Type', 'application/vnd.ms-excel')
            break
          case 'xlsx':
            xhr.setRequestHeader('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            break
          default:
            xhr.setRequestHeader('Content-Type', file.type)
        }

        xhr.upload.onprogress = (e: ProgressEvent) => {
          if (e.total > 0) {
            const percent = (e.loaded / e.total) * 100
            onProcessChange?.({ percent })
            percent === 100 && onUploadCompleted?.(fileKey)
          }
        }

        xhr.onerror = () => {
          message.error(t('message.uploadError'))
        }

        xhr.send(file)
      },
      onError: (error) => {
        message.error(t('message.uploadError'))
      },
    }).then()
  }
}
