import React, { FC } from 'react'
import { Tooltip, Text, Group } from '@mantine/core'
import ImageGroup from 'components/business/Campaign/EntryModal/Content/components/ImageGroup'

const TextAreaFieldCell: FC<any> = ({ renderedCellValue }) => {
  return (
    <Group noWrap position="apart" w="100%">
      <Tooltip
        label={
          <Text lineClamp={10} className="max-w-[448px] whitespace-pre-line">
            {renderedCellValue?.text}
          </Text>
        }
        withinPortal
        multiline
        position="bottom-start"
        openDelay={1500}
        offset={-10}
      >
        <Text className="truncate">{renderedCellValue?.text}</Text>
      </Tooltip>
      <ImageGroup images={renderedCellValue?.screenshots} size={32} />
    </Group>
  )
}

export default TextAreaFieldCell
