import { getTextColor } from 'config/themeOverride/getColor'
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Select, SelectItem, useMantineTheme } from '@mantine/core'
import { clone, find, isEmpty, isNil } from 'lodash-es'

import { useConditionGroup } from '../../ConditionGroup'
import DataContext from '../../DataContext'
import ConditionContext from '../ConditionContext'
import useOptions from './getOptions'
import { useEnterBlurSelect } from 'components/business/hooks'

interface Props {
  hideErrorMsg?: boolean
}

const FirstNode = ({ hideErrorMsg }: Props) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('common')
  const { index, condition } = useContext(ConditionContext)
  const { dynamic, firstNode } = useContext(DataContext)
  const { ref, handleKeyDown, handleBlur } = useEnterBlurSelect((createValue) => {
    if (!condition.firstNode.error) {
      const matchedOptionValue = (options as SelectItem[]).find(({ label }: any) => label === createValue)
      if (matchedOptionValue) {
        handleChange(matchedOptionValue.value)
      } else {
        handleChange(createValue)
      }
    }
  })

  const { fields: firstNodeFields, labelIsExists, getCreateLabel, getFieldType, onFirstNodeChange, rules } = firstNode
  const [shadowFirstNodeFields, setShadowFirstNodeFields] = useState(firstNodeFields ?? [])
  const { dispatch } = useConditionGroup()

  useEffect(() => {
    const copyFields = clone(firstNodeFields)
    if (!isNil(condition?.firstNode?.value)) {
      const selectValue = condition?.firstNode?.value as string
      const isExisted = copyFields?.some((field) => field.code === selectValue)
      if (!isExisted) {
        const item = { code: selectValue, label: selectValue }
        copyFields?.unshift(item)
      }
    }
    setShadowFirstNodeFields(copyFields ?? [])
  }, [condition?.firstNode?.value, firstNodeFields])

  const options = useOptions(shadowFirstNodeFields ?? [])

  const calcFieldType = (value?: string | null) => {
    return isEmpty(value) ? undefined : getFieldType?.(value!)
  }

  const handleChange = useCallback(
    (value: string) => {
      if (condition?.firstNode?.value !== value) {
        const selected = find(shadowFirstNodeFields, { code: value })
        dispatch({
          type: 'firstNodeChange',
          index,
          firstNode: {
            value: value,
            label: selected?.label ?? value,
            type: selected ? selected.type : calcFieldType(value),
            error: undefined,
          },
        })
        if (dynamic) {
          onFirstNodeChange?.(selected!)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [condition?.firstNode?.value, shadowFirstNodeFields, index, dynamic, calcFieldType]
  )

  const handleCreate = (search: string) => {
    const query = search.trim()
    setShadowFirstNodeFields((current) => [...current, { code: query, label: query, type: calcFieldType(query) }])
    return { value: query, label: query }
  }

  const shouldCreate = useCallback(
    (search: string, data: SelectItem[]) => {
      const query = search.trim()
      const isExisted = labelIsExists ? labelIsExists(query, data) : data?.some(({ label }) => label === query)
      if (!isEmpty(rules) && !isEmpty(query)) {
        const firstItemError = rules?.find(({ regex }) => !regex?.test(query))
        const error = firstItemError && !isExisted ? firstItemError?.message : undefined
        if (error !== condition.firstNode.error) {
          setTimeout(() => {
            dispatch({
              type: 'firstNodeErrorChange',
              index,
              error,
            })
          }, 300)
        }
        return isNil(firstItemError) && !isExisted
      }
      return !isExisted && !isEmpty(query)
    },
    [condition.firstNode.error, index, rules]
  )

  const error = condition?.firstNode?.error

  return (
    <Select
      ref={ref}
      allowDeselect={false}
      creatable={!!getCreateLabel}
      searchable
      clearable
      data={options}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      defaultValue={condition?.firstNode?.value ?? null}
      placeholder={t('placeholders.pleaseSelect')}
      onChange={handleChange}
      shouldCreate={shouldCreate}
      // @ts-ignore
      getCreateLabel={getCreateLabel}
      onCreate={handleCreate}
      error={error && hideErrorMsg ? ' ' : error}
      filter={(value, item) =>
        (!!item.value && item.value.toLowerCase().includes(value.toLowerCase().trim())) ||
        (!!item.label && item.label.toLowerCase().includes(value.toLowerCase().trim()))
      }
      styles={{
        separatorLabel: {
          color: `${getTextColor('gray.6', theme)} !important`,
        },
      }}
    />
  )
}

export default memo(FirstNode)
