import React, { FC, PropsWithChildren } from 'react'
import { MockedProvider } from '@apollo/client/testing'
import { MantineProvider } from '@mantine/core'
import themeOverride from 'config/themeOverride'
import { XM_COLOR_SCHEME } from 'config/constants/localStorage'
import { MockedProviderProps } from '@apollo/client/testing/react/MockedProvider'

type Props = {
  apolloMocks?: MockedProviderProps['mocks']
}

const TestProvider: FC<PropsWithChildren<Props>> = ({ apolloMocks, children }) => {
  return (
    <MockedProvider mocks={apolloMocks}>
      <MantineProvider
        withCSSVariables
        withGlobalStyles
        withNormalizeCSS
        theme={{
          ...themeOverride,
          colorScheme: {
            key: XM_COLOR_SCHEME,
            defaultValue: 'light',
            getInitialValueInEffect: true,
          } as any,
        }}
      >
        {children}
      </MantineProvider>
    </MockedProvider>
  )
}

export default TestProvider
