import { ConditionGroupOperator, ConditionOperator } from 'types/domain'
import update from 'immutability-helper'
import { isArray, isEmpty, isNumber } from 'lodash-es'
import { isPresentOperator } from '../Conditions/Operator/operatorUtils'
import { ConditionType, SecondNodeFieldType } from '../../type'

export default function filterEmptyCondition(result: {
  operator: ConditionGroupOperator
  conditions: (ConditionType | undefined)[]
}) {
  const validateSecondNodeValue = (value: SecondNodeFieldType['value'], operator: ConditionType['operator']) => {
    if (isPresentOperator(operator as ConditionOperator)) {
      return true
    }
    if (isArray(value)) {
      if (isEmpty(value)) {
        return false
      }
      return (value as (string | number)[]).every((item) => isValid(item))
    }
    return isValid(value)
  }

  return update(result, {
    conditions: {
      $set: result.conditions?.filter((condition) => {
        return (
          condition &&
          validateSecondNodeValue(condition.secondNode?.value, condition.operator) &&
          !isEmpty(condition.firstNode?.value) &&
          !isEmpty(condition.operator)
        )
      }),
    },
  })
}

const isValid = (value: SecondNodeFieldType['value']) => {
  return !isEmpty(value) || isNumber(value)
}
