import React, { useContext } from 'react'
import { Avatar, Text } from '@mantine/core'

import { getAvatarUrlByUser } from 'utils'
import useCurrentUserCache from 'components/business/hooks/useCurrentUserCache'
import MainContext from 'components/business/Layouts/MainLayout/MainContext'

import MenuContent from './MenuContent'
import MenuWrapper from '../MenuWrapper'

const AvatarMenu = () => {
  const { user } = useCurrentUserCache()
  const { me } = useContext(MainContext)
  const currentUser = user ?? me

  return (
    <MenuWrapper
      buttonChildren={
        <>
          <Avatar size={28} radius="xl" src={getAvatarUrlByUser(currentUser)} mr="md" />
          <Text lineClamp={1} className="truncate block">
            {currentUser?.name}
          </Text>
        </>
      }
      dropdownChildren={<MenuContent />}
    />
  )
}

export default AvatarMenu
