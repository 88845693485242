import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { Flex, Stack, Sx, useMantineTheme } from '@mantine/core'
import { useRouter } from 'next/router'
import { ScrollableTable } from 'components/ui'
import { Column, RowData } from 'components/ui/ScrollableTable'
import { getBackgroundColor, getBorderColor } from 'config/themeOverride/getColor'
import { CampaignType, useCampaignsLazyQuery } from 'types/domain'
import { calcPercent, formatNumberToThousands } from 'utils'
import { getAfter } from 'utils/graphqlCursor'
import Footer from './Footer'
import ApplicationContext from 'components/business/Layouts/ApplicationLayout/ApplicationContext'
import { isLinkDistribution } from 'utils/applicationUtils'
import { PAGE_SIZE } from 'config/constants'
import Filter, { isSystemGroup, SYSTEM_GROUP } from 'components/business/Campaign/List/Filter'
import useGroupCache from 'components/business/Campaign/hooks/useGroupCache'
import TableEmpty from 'components/ui/TableEmpty'

type Props = {
  campaignType: CampaignType
  tableColumns: Column[]
  entriesPath: string
}

const pageSize = PAGE_SIZE.SM
const scrollbarOffset = 24

const CampaignList: FC<Props> = ({ campaignType, entriesPath, tableColumns }) => {
  const theme = useMantineTheme()
  const router = useRouter()
  const pageRef = useRef(1)
  const application = useContext(ApplicationContext).application!
  const isLinkApplication = isLinkDistribution(application)
  const { appId, groupId: groupIdFromUrl } = router.query

  const { setDefaultGroup, getDefaultGroup } = useGroupCache()
  const [groupId, setGroupId] = useState<string | null>(null)

  const [fetch, { data }] = useCampaignsLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    const groupId = groupIdFromUrl === (SYSTEM_GROUP.ALL as string) ? null : getDefaultGroup(campaignType)
    setGroupId(groupId)
    setDefaultGroup(campaignType, groupId)

    fetch({
      variables: {
        id: appId as string,
        pageArguments: { first: pageSize },
        filter: { type: campaignType, groupId: isSystemGroup(groupId) ? null : groupId, enabled: getEnabled(groupId) },
      },
    })
  }, [appId, groupIdFromUrl])

  const handlePageChange = (page: number) => {
    if (pageRef.current === page) {
      return
    }

    fetch({
      variables: {
        id: appId as string,
        pageArguments: { first: pageSize, after: getAfter(page, pageSize).after },
        filter: { type: campaignType, groupId: isSystemGroup(groupId) ? null : groupId, enabled: getEnabled(groupId) },
      },
    }).then(() => {
      pageRef.current = page
    })
  }

  const handleFilterChange = async (groupId: string | null) => {
    setGroupId(groupId)
    setDefaultGroup(campaignType, groupId)
    await fetch({
      variables: {
        id: appId as string,
        pageArguments: { first: pageSize },
        filter: { type: campaignType, groupId: isSystemGroup(groupId) ? null : groupId, enabled: getEnabled(groupId) },
      },
    })
  }

  const getEnabled = (groupId: string | null) => {
    if (groupId === (SYSTEM_GROUP.ENABLED as string)) {
      return true
    }
    if (groupId === (SYSTEM_GROUP.DISABLED as string)) {
      return false
    }
    return null
  }

  const handleRowClick = (data: RowData) => {
    router
      .push({
        pathname: entriesPath,
        query: { appId, campaignId: data.rawData.id },
      })
      .then()
  }

  const campaignRowStyle = (data: RowData) => {
    return {
      cursor: 'pointer',
      overflow: 'hidden',
      borderRadius: '6px',
      backgroundColor: getBackgroundColor('white', theme),
      border: `1px solid ${getBorderColor('gray.1', theme)}`,
      boxShadow: '0px 8px 8px -8px rgba(0, 0, 0, 0.1)',
      position: 'relative',
      gap: 0,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: 4,
        backgroundColor: getBackgroundColor(data.enabled ? 'teal.3' : 'gray.3', theme),
      },
    } as Sx
  }

  const tableData =
    data?.application?.campaigns?.nodes?.map((campaign) => {
      const { name, pv, entriesCount, creator, enabled, urlEnabled, createdAt } = campaign
      return {
        name,
        creator,
        createdAt,
        enabled: isLinkApplication ? urlEnabled : enabled,
        urlEnabled,
        pv: formatNumberToThousands(pv),
        entriesCount: formatNumberToThousands(entriesCount),
        ratio: calcPercent(entriesCount, pv),
        rawData: campaign,
        fetch,
      }
    }) ?? []

  return (
    <Stack spacing="lg" className="flex-1" mr={-scrollbarOffset}>
      <Filter campaignType={campaignType} groupId={groupId} onChange={handleFilterChange} />
      {tableData.length > 0 ? (
        <ScrollableTable
          columns={tableColumns}
          data={tableData}
          headerRowStyle={{
            gap: 0,
          }}
          rowStyle={campaignRowStyle}
          onRowClick={handleRowClick}
          scrollbarOffset={scrollbarOffset}
          footer={
            <Flex mt="lg" justify="flex-start">
              <Footer
                pageSize={pageSize}
                totalCount={data?.application?.campaigns?.totalCount ?? 0}
                onChange={handlePageChange}
              />
            </Flex>
          }
        />
      ) : (
        <TableEmpty />
      )}
    </Stack>
  )
}

export default CampaignList
