import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Button, Menu } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { ApplicationMembers } from 'config/constants'
import Task from './Task'
import useOnboardingCache from 'components/business/hooks/useOnboardingCache'

const MemberInvitedTask = () => {
  const { t } = useTranslation('componentAppOnboarding')
  const { onboarding } = useOnboardingCache()

  return (
    <Task
      value="memberInvited"
      checked={!!onboarding?.memberInvited}
      title={t('members.title')}
      descriptions={t('members.descriptions', {}, { returnObjects: true })}
    >
      <Menu.Item className="w-[fit-content] p-0 hover:!bg-transparent">
        <Link
          href={{ pathname: ApplicationMembers, query: { appId: useRouter().query.appId as string } }}
          legacyBehavior
        >
          <Button component="a" size="xs" mt="md">
            {t('members.invitedMember')}
          </Button>
        </Link>
      </Menu.Item>
    </Task>
  )
}

export default MemberInvitedTask
