import React from 'react'
import { TranslationQuery } from 'next-translate'
import useTranslation from 'next-translate/useTranslation'
import { Anchor, Text, useMantineTheme } from '@mantine/core'
import { Feature } from 'types/domain'
import { openProductsModal } from 'components/business/ProductsModal'
import useAccountSubscriptionCache from 'components/business/hooks/useAccountSubscriptionCache'
import { getTextColor } from 'config/themeOverride/getColor'

interface Props {
  feature: Feature
  transQuery?: TranslationQuery
}

const FeatureTip = ({ feature, transQuery }: Props) => {
  const { t } = useTranslation('account')
  const { subscription } = useAccountSubscriptionCache()
  const theme = useMantineTheme()

  return (
    <>
      <Text>
        {t(`featureTip.${feature}`, {
          productName: subscription?.productName,
          ...transQuery,
        })}
      </Text>
      <Anchor
        className="underline"
        onClick={() =>
          openProductsModal(
            t(`product:feature.${feature}.eventAttr`, {
              ...transQuery,
            })
          )
        }
        color={getTextColor('blue', theme)}
      >
        {t('contactUpgrade')}
      </Anchor>
    </>
  )
}

export default FeatureTip
