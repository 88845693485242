import React from 'react'
import { ActionIcon, useMantineColorScheme, useMantineTheme } from '@mantine/core'
import { IconMoonStars, IconSun } from '@tabler/icons-react'

const ThemeSwitcher = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme()
  const theme = useMantineTheme()
  const dark = colorScheme === 'dark'

  return (
    <ActionIcon variant="outline" color={dark ? 'dark.2' : 'brandBlue.3'} onClick={() => toggleColorScheme()}>
      {dark ? (
        <IconSun size={14} color={theme.colors.dark[1]} />
      ) : (
        <IconMoonStars size={14} color={theme.colors.brandBlue[5]} />
      )}
    </ActionIcon>
  )
}

export default ThemeSwitcher
