import { useContext, useEffect, useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Modal } from '@mantine/core'
import { isNil, trim } from 'lodash-es'
import { ConditionGroupInput, WebhookChannel, WebhookEventType } from 'types/domain'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import { isBlank } from 'utils'
import WebhookContext from '../WebhookContext'
import { useWebhookBuilderForm, WebhookBuilderFormProvider } from './formContext'
import { validateName, validateTargets, validateUrl } from './validate'
import { OnWebhookCreate, OnWebhookUpdate } from './type'
import Header from './Header'
import Content from './Content'

interface Props {
  opened: boolean
  onClose: () => void
  onCreated?: OnWebhookCreate
  onUpdated?: OnWebhookUpdate
}

const BuilderModal = ({ opened, onClose, onCreated, onUpdated }: Props) => {
  const { t } = useTranslation('integration')
  const { appId, campaign, webhook } = useContext(WebhookContext)
  const campaignId = campaign?.id
  const campaignName = campaign?.name
  const initialValues = useMemo(
    () => ({
      campaignId,
      name: campaignName ? t('webhook.builder.name.default', { campaignName }) : t('webhook.builder.name.untitled'),
      enabled: false,
      eventTypes: [WebhookEventType.EntrySubmitted],
      url: '',
      conditionGroup: null,
    }),
    [campaignId, campaignName, t]
  )
  const form = useWebhookBuilderForm({
    validateInputOnBlur: true,
    initialValues,
    transformValues: (values) => ({
      ...values,
      name: trim(values.name),
      conditionGroup: (values.conditionGroup?.conditions.length ?? 0) > 0 ? values.conditionGroup : null,
    }),
    validate: {
      name: (value: string) => validateName(value, t),
      url: (value: string) => validateUrl(value, t),
      channel: (value?: WebhookChannel) => (isNil(value) ? t('common:message.valueRequired') : null),
      campaignId: (value?: string) => (isBlank(value) ? t('common:message.valueRequired') : null),
      targets: (value?: string[] | null) => validateTargets(value, t),
    },
  })

  useEffect(() => {
    if (webhook) {
      const { id, name, enabled, campaign, channel, url, targets, eventTypes, conditionGroup } = webhook
      form.setValues({
        id,
        name,
        enabled,
        channel,
        url,
        targets,
        eventTypes,
        campaignId: campaign?.id ?? campaignId,
        conditionGroup: conditionGroup as ConditionGroupInput,
      })
    } else {
      form.setValues(initialValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webhook?.id])

  if (isBlank(appId)) {
    return null
  }

  const handleCancel = () => {
    onClose()
    // rest wait until modal closed
    setTimeout(() => form.reset(), 200)
  }

  return (
    <WebhookBuilderFormProvider form={form}>
      <Modal
        withinPortal
        opened={opened}
        onClose={handleCancel}
        closeOnClickOutside={false}
        closeOnEscape={false}
        trapFocus={false}
        size={1000}
        title={<Header onUpdate={onUpdated} />}
        styles={(theme) => {
          const backgroundColor = getBackgroundColor('gray.0', theme)
          return {
            header: { backgroundColor },
            content: { backgroundColor },
            title: { flexGrow: 1, marginRight: 20 },
          }
        }}
      >
        <Content onCancel={handleCancel} onCreate={onCreated} onUpdate={onUpdated} />
      </Modal>
    </WebhookBuilderFormProvider>
  )
}

export default BuilderModal
