import { getBackgroundColor } from 'config/themeOverride/getColor'
import React from 'react'
import { Group, Title, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import SkipGuide from './SkipGuide'

type Props = {
  onSkip: () => void
}

const OnboardingTitle = (props: Props) => {
  const { onSkip } = props
  const { t } = useTranslation('componentAppOnboarding')
  const theme = useMantineTheme()
  return (
    <Group p="lg" position="apart" bg={getBackgroundColor('brandBlue.0', theme)}>
      <Title order={5}>{t('title')}</Title>
      <SkipGuide onSkip={onSkip} />
    </Group>
  )
}

export default OnboardingTitle
