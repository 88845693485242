import React from 'react'
import { Group } from '@mantine/core'

import { outsideModalBodyHeight } from 'config/constants'

import BasicInfo from './BasicInfo'
import SubmittedRecords from './SubmittedRecords'
import { CustomerDetailBasicProps } from './type'
import Divider from 'components/ui/Divider'

const DetailContent = (props: CustomerDetailBasicProps) => {
  const { id } = props

  return (
    <Group w="100%" align="self-start" spacing={0} noWrap h={`calc(100vh - ${outsideModalBodyHeight}px)`}>
      <BasicInfo {...props} />
      <Divider mx={0} orientation="vertical" />
      <SubmittedRecords id={id} manageable={props.manageable} />
    </Group>
  )
}

export default DetailContent
