import React, { FC } from 'react'
import { CopyButton, Menu, useMantineTheme } from '@mantine/core'
import { useRouter } from 'next/router'
import {
  IconAlbum,
  IconBorderAll,
  IconCopy,
  IconEdit,
  IconId,
  IconPlayerPause,
  IconPlayerPlay,
  IconTrash,
} from '@tabler/icons-react'
import { getTextColor } from 'config/themeOverride/getColor'

import {
  useCampaignPermission,
  useCampaignStatusSwitch,
  useDeleteCampaign,
  useDuplicateCampaignModal,
  useEditCampaignGroupsModal,
} from 'components/business'
import { useDeleteModal } from 'hooks'
import useTranslation from 'next-translate/useTranslation'
import { Application, Campaign } from 'types/domain'
import { message, NextLink } from 'components/ui'
import useCampaignFormStatusSwitch from 'components/business/Campaign/hooks/useCampaignFormStatusSwitch'
import useApplicationInfoBaseInfoCache from 'components/business/hooks/useApplicationInfoBaseInfoCache'
import { isLinkDistribution } from 'utils/applicationUtils'
import useCampaignBaseInfoCache from 'components/business/hooks/useCampaignBaseInfoCache'
import { editPathMapping, entriesPathMapping } from 'config/constants/campaignPathMapping'

type Props = {
  campaign: Campaign
  onRefresh?: () => void
  onDelete?: () => void
}

const ActionMenus: FC<Props> = (props) => {
  const theme = useMantineTheme()
  const { campaign, onRefresh, onDelete } = props
  const { t } = useTranslation('componentCampaignList')
  const router = useRouter()
  const { appId } = router.query
  const { campaign: cachedCampaign } = useCampaignBaseInfoCache(campaign.id)
  const { editable, deletable, groupManageable } = useCampaignPermission(campaign?.creator?.id)
  const { toggle: toggleCampaignStatus } = useCampaignStatusSwitch(campaign)
  const { toggle: toggleCampaignFormStatus } = useCampaignFormStatusSwitch(campaign.id)
  const { application } = useApplicationInfoBaseInfoCache()
  const isLinkApplication = isLinkDistribution(application as Application)
  const openDuplicationModal = useDuplicateCampaignModal(campaign)
  const openEditGroupsModal = useEditCampaignGroupsModal(campaign)
  const { deleteCampaign } = useDeleteCampaign(appId as string, campaign, () => {
    onDelete?.()
  })

  const campaignTypeName = t(`common:campaignTypeName.${campaign.type}`)

  const { openModal } = useDeleteModal(
    `${t('common:actions.delete')}${campaignTypeName}`,
    `${t(`componentCampaignList:delete.confirmContent`, {
      campaignType: campaignTypeName,
    })}`,
    deleteCampaign
  )

  const handleEdit = () => {
    router.push({
      pathname: editPathMapping[campaign.type],
      query: {
        appId,
        campaignId: campaign.id,
      },
    })
  }

  const handleSwitch = () => {
    isLinkApplication ? toggleCampaignFormStatus(onRefresh) : toggleCampaignStatus(onRefresh)
  }

  const handleDelete = openModal

  const handleDuplication = () => {
    openDuplicationModal((targetAppId) => targetAppId == appId && onRefresh?.())
  }

  const handleCopyId = () => {
    message.successMsg(t('list.action.copyId.success', { campaignTypeName: t(campaign.type) }))
  }

  const handleEditGroup = () => {
    openEditGroupsModal(() => onRefresh?.())
  }

  const statusActionMenuEnabled = isLinkApplication ? cachedCampaign?.urlEnabled : cachedCampaign?.enabled

  return (
    <>
      {editable && (
        <Menu.Item icon={<IconEdit size={14} color={getTextColor('gray.7', theme)} />} onClick={handleEdit}>
          {t(`common:actions.edit`)}
          {campaignTypeName}
        </Menu.Item>
      )}
      <Menu.Item
        icon={<IconBorderAll size={14} color={getTextColor('gray.7', theme)} />}
        component={NextLink}
        href={{
          pathname: entriesPathMapping[campaign.type],
          query: {
            appId,
            campaignId: campaign.id,
          },
        }}
      >
        {t('list.action.view')}
      </Menu.Item>
      {editable && (
        <Menu.Item
          icon={<IconCopy size={14} color={getTextColor('gray.7', theme)} />}
          onClick={handleDuplication}
          closeMenuOnClick
        >
          {t(`common:actions.duplicate`)}
          {campaignTypeName}
        </Menu.Item>
      )}
      {groupManageable && (
        <Menu.Item
          icon={<IconAlbum size={14} color={getTextColor('gray.7', theme)} />}
          onClick={handleEditGroup}
          closeMenuOnClick
        >
          {t(`list.action.editGroup`)}
        </Menu.Item>
      )}
      <CopyButton value={campaign.id}>
        {({ copy }) => (
          <Menu.Item
            icon={<IconId size={14} color={getTextColor('gray.7', theme)} />}
            onClick={() => {
              copy()
              handleCopyId()
            }}
            closeMenuOnClick
          >
            {t('list.action.copyId.title', { campaignTypeName: campaignTypeName })}
          </Menu.Item>
        )}
      </CopyButton>
      {editable && (
        <>
          <Menu.Divider my="xs" mx="-xs" />
          <Menu.Item
            icon={
              statusActionMenuEnabled ? (
                <IconPlayerPause size={14} color={getTextColor('gray.7', theme)} />
              ) : (
                <IconPlayerPlay size={14} color={getTextColor('gray.7', theme)} />
              )
            }
            onClick={handleSwitch}
          >
            {t(`list.action.switch.${statusActionMenuEnabled ? 'turnOff' : 'turnOn'}`)}
          </Menu.Item>
        </>
      )}

      {deletable && (
        <>
          <Menu.Divider my="xs" mx="-xs" />
          <Menu.Item color="red" icon={<IconTrash size={14} color="red" />} onClick={handleDelete}>
            {t('common:actions.delete')}
          </Menu.Item>
        </>
      )}
    </>
  )
}

export default ActionMenus
