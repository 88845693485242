import React, { FC } from 'react'
import { isNil } from 'lodash-es'
import useTranslation from 'next-translate/useTranslation'
import { IconEyeFilled } from '@tabler/icons-react'
import { Avatar, Card, Group, Text, ThemeIcon, Timeline, useMantineTheme } from '@mantine/core'

import useCurrentUserCache from 'components/business/hooks/useCurrentUserCache'
import { Icon } from 'components/ui'
import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'
import { Reply } from 'types/domain'
import { formatISOTime, getAvatarUrlByUser } from 'utils'

import MessageContent from './MessageContent'
import { itemCommonProps, useStyles } from './styles'
import Withdraw from './Withdraw'
import CustomerAvatar from 'components/business/Customer/Avatar'

type Props = {
  reply: Reply
  entryId: string
  updateReplyCallBack?: () => void
}

const ReplyItem: FC<Props> = ({ reply, entryId, updateReplyCallBack }) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('entry')
  const { classes } = useStyles()
  const { user } = useCurrentUserCache()
  const { sentContent, sentAt, sender, readAt, thumb, receivedContent, receivedAt } = reply

  const itemClasses = {
    item: classes.item,
    itemBullet: classes.itemBullet,
    itemTitle: classes.itemTitle,
  }

  const displayReceivedContent = thumb ? `${thumb}；${receivedContent ?? ''}` : receivedContent ?? ''

  return (
    <>
      <Timeline.Item
        {...itemCommonProps}
        title={
          <Group spacing="sm" align="self-start">
            <Avatar size={16} radius="xl" src={getAvatarUrlByUser(sender)} />
            <Text lh="16px" className="truncate" maw={258}>
              {user?.id === sender.id ? `${t('entryModal.reply.sent.me', { name: sender.name })}` : sender.name}
              {t('entryModal.reply.sent.title')}
            </Text>
          </Group>
        }
        bullet={
          <ThemeIcon size={16} color="brandBlue.7" radius="xl">
            <Icon name="chatFill" />
          </ThemeIcon>
        }
        classNames={itemClasses}
      >
        <Card bg={getBackgroundColor('brandBlue.0', theme)} px="lg" py="sm" mt="sm">
          <MessageContent content={sentContent} />
        </Card>
        <Text color="dimmed" size="xs">
          {formatISOTime(sentAt)}
        </Text>
      </Timeline.Item>
      <Timeline.Item
        title={
          <Group spacing="sm">
            <Text>
              {t('entryModal.reply.sent.createdAt')} {isNil(readAt) && t('entryModal.reply.sent.notRead')}
            </Text>
            <Withdraw reply={reply} entryId={entryId} updateReplyCallBack={updateReplyCallBack} />
          </Group>
        }
        {...itemCommonProps}
        classNames={itemClasses}
        bullet={
          <ThemeIcon size={16} color="lime.7" radius="xl">
            <Icon
              size={12}
              name="sendFill"
              style={{
                color: getTextColor('white', theme),
                // @ts-ignore
                '--filled-color': getTextColor('lime.7', theme),
              }}
            />
          </ThemeIcon>
        }
      >
        <Text color="dimmed" size="xs">
          {formatISOTime(sentAt)}
        </Text>
      </Timeline.Item>
      {!isNil(readAt) && (
        <Timeline.Item
          {...itemCommonProps}
          classNames={itemClasses}
          title={t('entryModal.reply.sent.readAt')}
          bullet={
            <ThemeIcon size={16} color="yellow.7" radius="xl">
              <IconEyeFilled size={12} />
            </ThemeIcon>
          }
        >
          <Text color="dimmed" size="xs">
            {formatISOTime(readAt)}
          </Text>
        </Timeline.Item>
      )}
      {receivedAt && (
        <Timeline.Item
          {...itemCommonProps}
          classNames={itemClasses}
          title={
            <Group spacing="sm" align="self-start">
              <CustomerAvatar size={16} />
              <Text lh="16px">{t('entryModal.reply.received.title')}</Text>
            </Group>
          }
          bulletSize={16}
          bullet={
            <ThemeIcon size={16} color="orange.7" radius="xl">
              <Icon name="chatFill" />
            </ThemeIcon>
          }
          styles={{
            item: {
              '&:not(:first-of-type)': {
                marginTop: theme.spacing.lg,
              },
            },
          }}
        >
          {displayReceivedContent && (
            <Card bg={getBackgroundColor('orange.0', theme)} px="lg" py="sm" mt="sm">
              <MessageContent content={displayReceivedContent!} />
            </Card>
          )}
          <Text color="dimmed" size="xs">
            {formatISOTime(receivedAt)}
          </Text>
        </Timeline.Item>
      )}
    </>
  )
}

export default ReplyItem
