import useCampaignStatusAlert from 'components/business/Campaign/hooks/useCampaignStatusAlert'
import { message } from 'components/ui'
import { isNil } from 'lodash-es'
import useTranslation from 'next-translate/useTranslation'
import { Campaign, useUpdateCampaignMutation } from 'types/domain'
import { graphqlErrorCatch } from 'utils'

type ToggleCallback = (enabled: boolean) => void

const useCampaignStatusSwitch = (campaign: Campaign) => {
  const { t } = useTranslation('componentCampaignList')
  const [updateMutation] = useUpdateCampaignMutation()
  const { open: openAlertModal } = useCampaignStatusAlert(campaign.type)

  const sendRequest = async (callback?: ToggleCallback) => {
    const input = {
      id: campaign.id,
      enabled: !campaign.enabled,
    }
    try {
      const { data } = await updateMutation({ variables: { input } })
      const enabled = data?.updateCampaign?.enabled!
      callback?.(enabled)
      if (!isNil(enabled)) {
        message.successMsg(
          t(`statusSwitcher.message.${enabled ? 'enabled' : `disabled`}`, {
            campaignType: t(`common:campaignTypeName.${campaign.type}`),
          })
        )
      }
    } catch (e: any) {
      const { graphQLErrors } = e
      graphqlErrorCatch({ graphQLErrors, defaultMessage: t('common:message.updateError') })
    }
  }
  const toggle = async (callback?: ToggleCallback) => {
    if (!campaign.enabled) {
      openAlertModal(() => sendRequest(callback))
    } else {
      await sendRequest(callback)
    }
  }
  return {
    toggle,
  }
}

export default useCampaignStatusSwitch
