import React, { FC, useMemo } from 'react'
import { Select, Stack } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { useBuilderFormContext } from 'components/business/CampaignBuilder'
import { CampaignWidgetPosition, CampaignWidgetType } from 'types/domain'

import positionConfig from './positionConfig'
import StyleItem from 'components/business/CampaignBuilder/StyleItem'
import CustomizedWidgetPosition from './CustomizedPosition'

type Props = {}

const WidgetPosition: FC<Props> = () => {
  const { t } = useTranslation('componentCampaignBuilder')
  const form = useBuilderFormContext()
  const widget = form?.values?.widget
  const widgetType: CampaignWidgetType = widget?.type ?? CampaignWidgetType.Button
  const positions = useMemo(() => {
    const positionKeys = positionConfig[widgetType]
    return positionKeys.map((item) => {
      return { value: item, label: t(`styles.position.${item}`) }
    })
  }, [t, widgetType])

  const isCustomizedPosition = form.getInputProps('widget.position').value === CampaignWidgetPosition.Customized

  return (
    <StyleItem label={t('styles.position.title')} align={isCustomizedPosition ? 'top' : 'center'}>
      <Stack>
        <Select data={positions} {...form.getInputProps('widget.position')} />
        {isCustomizedPosition && <CustomizedWidgetPosition />}
      </Stack>
    </StyleItem>
  )
}

export default WidgetPosition
