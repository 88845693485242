import React from 'react'
import { Group, Title } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { CampaignType, Entry } from 'types/domain'
import { ItemNavigator, ItemNavigatorProps } from 'components/ui/ItemNavigator'

import CopyShareUrl from './CopyShareUrl'
import DeleteEntry from './DeleteEntry'

type Props = ItemNavigatorProps & {
  campaignId: string
  campaignType: CampaignType
  entry: Entry
  enableSwitchEntry?: boolean
  enableRemoveEntry?: boolean
  onRemoveEntry?: () => void
}

const EntryModalHeader = (props: Props) => {
  const {
    campaignId,
    campaignType,
    enableSwitchEntry = true,
    entry,
    enableRemoveEntry = true,
    onRemoveEntry,
    ...restProps
  } = props
  const { t } = useTranslation('entry')

  return (
    <Group position="apart">
      <Title order={5}>{t('entryModal.serialNumber', { serialNumber: entry.serialNumber })}</Title>
      <Group spacing="sm">
        <CopyShareUrl campaignId={campaignId} campaignType={campaignType} serialNumber={entry?.serialNumber} />
        {enableRemoveEntry && <DeleteEntry entryId={entry.id!} onOk={onRemoveEntry} />}
        {enableSwitchEntry && <ItemNavigator {...restProps} />}
      </Group>
    </Group>
  )
}

export default EntryModalHeader
