import React from 'react'
import { isNil } from 'lodash-es'
import useTranslation from 'next-translate/useTranslation'
import { Anchor } from '@mantine/core'
import { Reply, useDeleteEntryReplyMutation } from 'types/domain'
import { message, openAlertModal } from 'components/ui'

type Props = {
  reply: Reply
  entryId: string
  updateReplyCallBack?: () => void
}

const Withdraw = ({ reply, entryId, updateReplyCallBack }: Props) => {
  const { t } = useTranslation('entry')
  const { id, readAt } = reply

  const [deleteEntryReply] = useDeleteEntryReplyMutation()

  const openConfirmModal = () => {
    openAlertModal({
      title: t('entryModal.reply.sent.withdrawConfirm'),
      onConfirm() {
        deleteEntryReply({
          variables: {
            id,
          },
          update: (cache, { data }) => {
            if (!data?.deleteEntryReply) {
              return
            }
            cache.modify({
              id: cache.identify({ __typename: 'Entry', id: entryId }),
              fields: {
                replies: (existingRepliesRef: { __ref: string }[], { readField }) => {
                  return existingRepliesRef.filter((ref) => id !== readField('id', ref))
                },
              },
            })
          },
        }).then(() => {
          updateReplyCallBack?.()
          message.successMsg(`${t('entryModal.reply.sent.withdraw')}${t('common:message.success')}`)
        })
      },
    })
  }

  if (!isNil(readAt)) {
    return null
  }

  return <Anchor onClick={openConfirmModal}>{t('entryModal.reply.sent.withdraw')} </Anchor>
}

export default Withdraw
