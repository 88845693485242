import { size } from 'lodash-es'
import { MRT_RowSelectionState } from 'mantine-react-table'
import { useMemo, useState } from 'react'

export default function useRowSelection() {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})
  const selectedRowsCount = useMemo(() => size(rowSelection), [rowSelection])

  return {
    rowSelection,
    selectedRowsCount,
    onRowSelectionChange: setRowSelection,
  }
}
