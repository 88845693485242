import React, { FC, useEffect, useMemo, useRef } from 'react'
import { Box, ScrollArea, Stack } from '@mantine/core'
import { isEmpty, isNil } from 'lodash-es'

import { Reply, useEntryRepliesQuery } from 'types/domain'

import NotSupport from './NotSupport'
import BlankReply from './Blank'
import Content from './Content'
import ReplyAdder from './ReplyAdder'

type Props = {
  entryId: string
  disabled: boolean
  updateReplyCallBack?: () => void
}

const Reply: FC<Props> = ({ entryId, disabled, updateReplyCallBack }) => {
  const viewport = useRef<HTMLDivElement | null>(null)

  const { data, refetch } = useEntryRepliesQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: entryId },
    skip: disabled,
  })
  const replies = (data?.entry?.replies ?? []) as Reply[]

  const sendBtnDisabled = useMemo(() => {
    return !!replies?.some((reply) => isNil(reply.readAt))
  }, [replies])

  useEffect(() => {
    refetch()
    // eslint-disable-next-line
  }, [entryId])

  const handleSave = () => {
    updateReplyCallBack?.()
    setTimeout(() => {
      viewport.current?.scrollTo({ top: viewport.current?.scrollHeight })
    }, 200)
  }

  return (
    <Stack pb="sm" pt={20} h="100%" spacing="sm" justify="space-between">
      {disabled ? (
        <NotSupport />
      ) : (
        <>
          <Box className="grow shrink contents">
            <ScrollArea px="lg" viewportRef={viewport}>
              {isEmpty(replies) && !isNil(replies) ? (
                <BlankReply />
              ) : (
                <Stack spacing="xl">
                  <Content entryId={entryId} replies={replies} updateReplyCallBack={updateReplyCallBack} />
                </Stack>
              )}
            </ScrollArea>
          </Box>
          <Box px="lg">
            <ReplyAdder disabled={sendBtnDisabled} entryId={entryId} onSave={handleSave} />
          </Box>
        </>
      )}
    </Stack>
  )
}

export default Reply
