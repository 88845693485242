import { SystemFilterableField } from 'config/constants/fieldType'
import { Translate } from 'next-translate'
import { isCustomerField, isFormField, isSceneField } from 'utils/fieldUtils'

const systemFieldCodes = Object.values<string>(SystemFilterableField)

export default function getFieldGroupName(fieldCode: string, t: Translate) {
  if (isFormField(fieldCode)) {
    return t('componentEntryFilter:fieldType.default')
  }
  if (isCustomerField(fieldCode)) {
    return t('componentEntryFilter:fieldType.customerField')
  }
  if (isSceneField(fieldCode)) {
    return t('componentEntryFilter:fieldType.sceneField')
  }
  if (systemFieldCodes.includes(`entry.${fieldCode}`)) {
    return t('componentEntryFilter:fieldType.systemField')
  }
}
