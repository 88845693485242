import { FC, memo, PropsWithChildren } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { useBuilderFormContext, useBuilderSharedDataContext } from 'components/business/CampaignBuilder'
import { entriesPathMapping } from 'config/constants/campaignPathMapping'
import { openAlertModal } from 'components/ui'
import { BackButton } from 'components/ui/Button'
import { isFormDataChanged } from '../utils/formDataUtils'

const PageBack: FC<PropsWithChildren> = () => {
  const router = useRouter()
  const form = useBuilderFormContext()
  const { campaignType } = useBuilderSharedDataContext()
  const { t } = useTranslation('componentCampaignBuilder')
  const appId = router.query.appId as string
  const campaignId = router.query.campaignId as string

  const handleClick = () => {
    if (isFormDataChanged(form.values)) {
      return openAlertModal({
        title: t('common:tip.title'),
        content: t('confirm.message.unSavedChanges')!,
        onConfirm: handleJump,
        labels: {
          confirm: t('confirm.actions.return'),
          cancel: t('confirm.actions.continue'),
        },
      })
    }
    handleJump()
  }

  const handleJump = () => {
    router
      .push({
        pathname: entriesPathMapping[campaignType!],
        query: {
          appId,
          campaignId,
        },
      })
      .then()
  }

  return <BackButton onClick={handleClick} />
}

export default memo(PageBack)
