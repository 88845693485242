import React from 'react'
import { useRouter } from 'next/router'
import { useDisclosure } from '@mantine/hooks'
import { Anchor, Button, Group, Popover, Stack, Text, useMantineTheme } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { getTextColor } from 'config/themeOverride/getColor'
import { useSkipApplicationOnboardingMutation } from 'types/domain'

type Props = {
  onSkip: () => void
}

const SkipGuide = ({ onSkip }: Props) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('componentAppOnboarding')
  const [opened, { open, close }] = useDisclosure(false)
  const [skipMutation] = useSkipApplicationOnboardingMutation({
    variables: {
      appId: useRouter().query.appId as string,
    },
  })

  const handleConfirmSkip = async () => {
    const { data } = await skipMutation()
    if (data?.skipApplicationOnboarding) {
      onSkip()
    }
  }

  return (
    <Popover width={272} position="top" withinPortal shadow="md" opened={opened} withArrow>
      <Popover.Target>
        <Anchor
          className="px-sm py-xs rounded-sm hover:bg-brandBlue-1"
          underline={false}
          component="button"
          c={getTextColor('gray.7', theme)}
          onClick={open}
        >
          {t('skip.title')}
        </Anchor>
      </Popover.Target>
      <Popover.Dropdown p="lg">
        <Stack spacing="lg">
          <Text>{t('skip.description')}</Text>
          <Group spacing="sm" position="right">
            <Button size="xs" variant="default" onClick={close}>
              {t('common:actions.cancel')}
            </Button>
            <Button size="xs" onClick={handleConfirmSkip}>
              {t('skip.confirm')}
            </Button>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}

export default SkipGuide
