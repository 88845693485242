import { useAbac } from 'react-abac'
import { ApplicationPermission, ApplicationRoleType } from 'types/domain'
import useCurrentUserCache from 'components/business/hooks/useCurrentUserCache'
import useApplicationRoleCache from 'components/business/hooks/useApplicationRoleCache'

export default function useCampaignPermission(creatorId: string) {
  const { user } = useCurrentUserCache()

  const { userHasPermissions } = useAbac()
  const { applicationRole } = useApplicationRoleCache()

  const isCreator = creatorId === user?.id
  const isReader = applicationRole?.type === ApplicationRoleType.ApplicationReader
  const editable = (isCreator || userHasPermissions(ApplicationPermission.CampaignUpdate)) && !isReader
  const deletable = (isCreator || userHasPermissions(ApplicationPermission.CampaignDelete)) && !isReader
  const creatable = userHasPermissions(ApplicationPermission.CampaignCreate)
  const groupManageable = userHasPermissions(ApplicationPermission.CampaignGroupManage)

  return {
    creatable,
    editable,
    deletable,
    groupManageable,
  }
}
