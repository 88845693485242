import shortUUID from 'short-uuid'

export default function getUuid() {
  const firstCode = getFirstRandomCode()
  return firstCode + shortUUID.generate()
}

function getFirstRandomCode() {
  const isUpperCase = Math.random() < 0.5
  const offset = Math.floor(Math.random() * 26)
  return String.fromCharCode((isUpperCase ? 65 : 97) + offset)
}
