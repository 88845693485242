import { Box, HoverCard, Stack, Text, useMantineTheme } from '@mantine/core'
import React, { forwardRef, ForwardRefRenderFunction, useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import { ConditionNodeInput, ConditionNodeType } from 'types/domain'
import CustomizeDatePicker from './CustomizeDatePicker'

type Props = {
  value: ConditionNodeInput
  onChange: (value: ConditionNodeInput) => void
  onClose: () => void
}

enum EntryCreatedAt {
  lastTwentyFourHours = 'LAST_TWENTY_FOUR_HOURS',
  lastSevenDays = 'LAST_SEVEN_DAYS',
  lastFourteenDays = 'LAST_FOURTEEN_DAYS',
  lastThirtyDays = 'LAST_THIRTY_DAYS',
  customizedPeriod = 'CUSTOMIZED_PERIOD',
}

const SelectItems: ForwardRefRenderFunction<HTMLDivElement, Props> = ({ onChange, onClose, value }: Props, ref) => {
  const { t } = useTranslation('componentEntryFilter')
  const theme = useMantineTheme()

  const onOk = (value: ConditionNodeInput) => {
    onChange(value)
  }

  const options = useMemo(
    () =>
      [
        'ALL',
        EntryCreatedAt.lastTwentyFourHours,
        EntryCreatedAt.lastSevenDays,
        EntryCreatedAt.lastFourteenDays,
        EntryCreatedAt.lastThirtyDays,
        EntryCreatedAt.customizedPeriod,
      ].map((code) => ({
        label: t(`createdAtOption.${code}`),
        value: code,
      })),
    [t]
  )

  return (
    <Stack spacing={0} ref={ref}>
      {options.map((option) => {
        const isCustomizedPeriod = option.value === EntryCreatedAt.customizedPeriod
        const isCurrentOption =
          option.value === (value.value as string) ||
          (isCustomizedPeriod && value.type === ConditionNodeType.CustomizedPeriod)
        return (
          <Box
            key={option.value}
            px="sm"
            py="5.4px"
            sx={{
              backgroundColor: isCurrentOption ? getBackgroundColor('brandBlue.0', theme) : 'transparent',
              borderRadius: theme.radius.xs,
              '&:hover': {
                backgroundColor: getBackgroundColor(isCurrentOption ? 'brandBlue.0' : 'gray.1', theme),
              },
              cursor: 'pointer',
            }}
          >
            {isCustomizedPeriod ? (
              <HoverCard shadow="md" position="right" offset={16}>
                <HoverCard.Target>
                  <Text>{option.label}</Text>
                </HoverCard.Target>
                <HoverCard.Dropdown p={0}>
                  <CustomizeDatePicker value={value} onChange={onChange} onClose={onClose} />
                </HoverCard.Dropdown>
              </HoverCard>
            ) : (
              <Text onClick={() => onOk({ value: option.value, type: ConditionNodeType.Period })}>{option.label}</Text>
            )}
          </Box>
        )
      })}
    </Stack>
  )
}

export default forwardRef(SelectItems)
