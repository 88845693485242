import { getBackgroundColor, getBorderColor, getTextColor } from 'config/themeOverride/getColor'
import React, { FC } from 'react'
import { ActionIcon, Group } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import { CampaignWidgetIcon } from 'types/domain'
import { useBuilderFormContext } from 'components/business/CampaignBuilder'
import { Icon } from 'components/ui'

import { iconMap } from 'components/ui/Icon'
import StyleItem from '../../StyleItem'

const selectorMap = {
  [CampaignWidgetIcon.Comment]: 'widgetComment',
  [CampaignWidgetIcon.Smile]: 'widgetSmile',
  [CampaignWidgetIcon.Star]: 'widgetStar',
}

const getIconName = (key: string) => {
  return selectorMap[key as CampaignWidgetIcon] as keyof typeof iconMap
}

const WidgetButtonIcon: FC = () => {
  const { t } = useTranslation('componentCampaignBuilder')
  const form = useBuilderFormContext()
  const { value, onChange } = form.getInputProps('widget.setting.icon')

  return (
    <StyleItem label={t('styles.button.icon')}>
      <Group spacing="sm">
        {Object.keys(selectorMap).map((key, i) => (
          <ActionIcon
            key={i}
            variant="outline"
            size={32}
            radius={4}
            onClick={() => {
              onChange(key as CampaignWidgetIcon)
            }}
            sx={(theme) => ({
              background:
                key === value
                  ? getBackgroundColor('brandBlue.0', theme, 'dark.5')
                  : getBackgroundColor('white', theme, 'dark.5'),
              borderColor:
                key === value
                  ? getBorderColor('brandBlue.7', theme, 'brandBlue.7')
                  : getBorderColor('gray.5', theme, 'dark.4'),
              color: key === value ? getTextColor('brandBlue.7', theme, 'brandBlue.7') : getTextColor('gray.5', theme),
              '&:hover': {
                background:
                  key === value
                    ? getBackgroundColor('brandBlue.0', theme)
                    : getBackgroundColor('gray.1', theme, 'dark.7'),
              },
            })}
          >
            <Icon style={{ width: 20, height: 20 }} name={getIconName(key)} />
          </ActionIcon>
        ))}
      </Group>
    </StyleItem>
  )
}

export default WidgetButtonIcon
