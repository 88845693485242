import { isNil } from 'lodash-es'
import React, { useEffect } from 'react'
import { Anchor, Flex, HoverCard, Switch, Text } from '@mantine/core'
import { HelpDocBlock, message } from 'components/ui'
import { useUpdateAccountMutation } from 'types/domain'
import useTranslation from 'next-translate/useTranslation'
import openConfirmModal from './utils/openConfirmModal'
import { useDisclosure } from '@mantine/hooks'
import useCheckHideBranding from 'components/business/Account/hooks/useCheckHideBranding'
import { useBuilderSharedDataContext } from 'components/business/CampaignBuilder'
import useAccountSettingCache from 'components/business/hooks/useAccountSettingCache'

const Branding = () => {
  const { t } = useTranslation('componentCampaignBuilder')
  const [hideBranding, handlers] = useDisclosure(true)
  const { currentUser } = useBuilderSharedDataContext()
  const [updateMutation] = useUpdateAccountMutation()

  const { disabled, disabledTip } = useCheckHideBranding()

  const { hideBranding: hideBrandingState, loading } = useAccountSettingCache()

  useEffect(() => {
    isNil(hideBrandingState) || hideBrandingState ? handlers.open() : handlers.close()
    // eslint-disable-next-line
  }, [hideBrandingState])

  const onSet = () => {
    openConfirmModal(t, currentUser?.accountUser?.account?.name ?? '', async () => {
      const variables = { input: { setting: { hideBranding: true } } }
      updateMutation({ variables })
        .then(() => {
          handlers.open()
          message.successMsg(t('styles.branding.opened'))
        })
        .catch(() => {
          message.error(t('common:message.updateError'))
        })
    })
  }

  if (hideBranding || loading) {
    return null
  }

  return (
    <HelpDocBlock className="m-0" textProps={{ ml: 'xs' }}>
      <Flex justify="space-between" align="flex-start">
        <Text>{t(`styles.branding.${disabled ? 'disableTip' : 'tip'}`)}</Text>
        <HoverCard withArrow disabled={!disabled}>
          <HoverCard.Target>
            <div>{disabled ? <Switch disabled /> : <Anchor onClick={onSet}>{t('common:actions.set')}</Anchor>}</div>
          </HoverCard.Target>
          <HoverCard.Dropdown>{disabledTip}</HoverCard.Dropdown>
        </HoverCard>
      </Flex>
    </HelpDocBlock>
  )
}

export default Branding
