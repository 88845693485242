import React, { memo, useContext } from 'react'
import { Grid, Input, Stack, Text } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { isEmpty } from 'lodash-es'

import RemoveCondition from '../RemoveCondition'
import { useConditionGroup } from '../ConditionGroup'
import DataContext from '../DataContext'
import ConditionContext from './ConditionContext'

import FirstNode from './FirstNode'
import Operator from './Operator'
import SecondNode from './SecondNode'
import { ConditionGroupOperator } from 'types/domain'
import Divider from 'components/ui/Divider'

type Props = {
  collapseCondition?: boolean
  batchEdit?: boolean
}

const Conditions = ({ collapseCondition = false, batchEdit = false }: Props) => {
  const { t } = useTranslation('componentConditionGroup')
  const {
    state: { conditions, operator },
  } = useConditionGroup()
  const {
    firstNode: { hidden },
  } = useContext(DataContext)

  if (isEmpty(conditions)) {
    return null
  }

  return (
    <Stack spacing="sm">
      {conditions.map((condition, index) => (
        <ConditionContext.Provider key={`${condition.firstNode.value}-${index}`} value={{ index, condition }}>
          <Grid gutter="sm" columns={24} align="center">
            {collapseCondition ? (
              <Grid.Col span="auto">
                <Grid gutter="xs" columns={24}>
                  <Grid.Col span={7} hidden={hidden}>
                    <FirstNode hideErrorMsg />
                  </Grid.Col>
                  <Grid.Col span={5}>
                    <Operator />
                  </Grid.Col>
                  <Grid.Col span={conditions.length > 1 ? 11 : 12}>
                    <SecondNode batchEdit={batchEdit} />
                  </Grid.Col>
                  {conditions.length > 1 && (
                    <Grid.Col span={1} mt="xs">
                      <RemoveCondition index={index} />
                    </Grid.Col>
                  )}
                </Grid>
                {condition.firstNode.error && <Input.Error>{condition.firstNode.error}</Input.Error>}
              </Grid.Col>
            ) : (
              <Grid.Col span="auto">
                <Grid gutter="sm" columns={conditions.length > 1 ? 21 : 20} align="center">
                  <Grid.Col span={12} hidden={hidden}>
                    <FirstNode />
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <Operator />
                  </Grid.Col>
                  {conditions.length > 1 && (
                    <Grid.Col span={1}>
                      <RemoveCondition index={index} />
                    </Grid.Col>
                  )}
                  <Grid.Col span={20}>
                    <SecondNode batchEdit={batchEdit} />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            )}
            {conditions.length > 1 && index !== conditions.length - 1 && (
              <Grid.Col span={23}>
                <Divider
                  label={
                    <Text c="dimmed" mx="lg">
                      {operator == ConditionGroupOperator.And
                        ? t('conditionGroupOperator.AND')
                        : t('conditionGroupOperator.OR')}
                    </Text>
                  }
                  labelPosition="center"
                />
              </Grid.Col>
            )}
          </Grid>
        </ConditionContext.Provider>
      ))}
    </Stack>
  )
}

export default memo(Conditions)
