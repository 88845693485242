import { compact, isNil, transform } from 'lodash-es'
import { Field, FieldJumpRule, FieldJumpRuleTargetType, FormFieldType, RuleControllableField } from 'types/domain'

type FieldsTypeMapType = { [x: string]: string }

function updateJumpRule(
  jumpRule: FieldJumpRule | null | undefined,
  allFieldsTypeMap: FieldsTypeMapType
): FieldJumpRule | undefined {
  if (isNil(jumpRule)) {
    return undefined
  }

  const { target, targetType, subRules, ...rest } = jumpRule
  let realTargetType = targetType
  if (targetType === FieldJumpRuleTargetType.SpecificField) {
    if (target) {
      if (allFieldsTypeMap[target] === FormFieldType.EndingField) {
        realTargetType = FieldJumpRuleTargetType.Ending
      }
    } else {
      realTargetType = FieldJumpRuleTargetType.NextField
    }
  }

  return {
    target,
    targetType: realTargetType,
    subRules: compact(subRules?.map((subRule) => updateJumpRule(subRule, allFieldsTypeMap))),
    ...rest,
  }
}

export default function updateWithRealJumpRuleTargetType(pageFields: Field[] | undefined, allFields: Field[]) {
  const allFieldsTypeMap = transform(allFields, (memo, { code, type }) => (memo[code] = type), {} as FieldsTypeMapType)
  return pageFields?.map((field) => {
    const { jumpRule, ...rest } = field as RuleControllableField
    return {
      ...rest,
      jumpRule: updateJumpRule(jumpRule, allFieldsTypeMap),
    }
  })
}
