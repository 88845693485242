import React, { createContext, useEffect, useReducer } from 'react'
import { isEqual } from 'lodash-es'

import { UrlFilterConditionGroupType } from '../type'
import conditionGroupReducer from './conditionGroupReducer'
import { Dispatch } from './type'
import { UrlConditionGroup } from 'types/domain'
import { ConditionGroupOperator } from 'types/domain'

export const ConditionGroupContext = createContext<
  { state: UrlFilterConditionGroupType; dispatch: Dispatch } | undefined
>(undefined)

type ConditionsProviderProps = {
  children: React.ReactNode
  defaultValue: UrlConditionGroup
  onChange: (state: UrlFilterConditionGroupType) => void
}

export function ConditionGroupProvider({ defaultValue, onChange, children }: ConditionsProviderProps) {
  const [state, dispatch] = useReducer(conditionGroupReducer, {
    operator: ConditionGroupOperator.Or,
    conditions: defaultValue.conditions,
  })

  useEffect(() => {
    if (!isEqual(state, defaultValue)) {
      onChange(state)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const value = { state, dispatch }

  return <ConditionGroupContext.Provider value={value}>{children}</ConditionGroupContext.Provider>
}
