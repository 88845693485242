import React from 'react'
import { Application, CampaignType, Feature } from 'types/domain'
import { FeatureTip, useCampaignPermission } from 'components/business'
import useTranslation from 'next-translate/useTranslation'
import useCampaignsQuota from './useCampaignsQuota'
import useCurrentUserCache from 'components/business/hooks/useCurrentUserCache'
import useCampaignsCountCache from 'components/business/hooks/useCampaignsCountCache'
import useApplicationInfoBaseInfoCache from 'components/business/hooks/useApplicationInfoBaseInfoCache'
import { isWebDistribution } from 'utils/applicationUtils'

export default function useCheckCreateCampaign(campaignType: CampaignType) {
  const { t } = useTranslation('permission')
  const { user } = useCurrentUserCache()
  const { creatable } = useCampaignPermission(user?.id!)
  const { count } = useCampaignsCountCache(campaignType)
  const campaignsQuota = useCampaignsQuota()
  const { application } = useApplicationInfoBaseInfoCache()

  if (!isWebDistribution(application as Application) && campaignType === CampaignType.Notification) {
    return {
      disabled: true,
      disabledTip: t('actions.disabledCreationByNotification'),
    }
  }

  if (!creatable) {
    return {
      disabled: true,
      disabledTip: t('actions.disabledCreationByPermission'),
    }
  }

  const disabled = count !== undefined ? count >= campaignsQuota : true
  const disabledTip = disabled ? (
    <FeatureTip
      feature={Feature.CampaignsCountPerApplication}
      transQuery={{ count: campaignsQuota, campaignType: t(`common:campaignTypeName.${campaignType}`) }}
    />
  ) : undefined

  return {
    disabled,
    disabledTip,
  }
}
