import React, { FC, PropsWithChildren } from 'react'
import Nav from './Nav'
import ApplicationContext, { AccountLayoutProps } from './ApplicationContext'
import { Application, User } from 'types/domain'
import MainLayout from 'components/business/Layouts/MainLayout'

export type ApplicationLayoutProps = {
  application: Application
  account: AccountLayoutProps
  me: User
}
const ApplicationLayout: FC<PropsWithChildren<ApplicationLayoutProps>> = ({ children, application, account, me }) => {
  return (
    <ApplicationContext.Provider value={{ application, account, me }}>
      <MainLayout nav={<Nav />} me={me} account={account}>
        {children}
      </MainLayout>
    </ApplicationContext.Provider>
  )
}

export default ApplicationLayout
