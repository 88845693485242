import { Duration } from 'duration-fns/src/types'
import { CampaignTriggerEvent, ConditionGroupOperator } from 'types/domain'

export enum Mode {
  NON_ACTIVE_POPUP = 'nonActivePopup',
  ACTIVE_POPUP = 'activePopup',
  DELAY_POPUP = 'delayPopup',
}

export const defaultTriggerOperator = ConditionGroupOperator.Or

export const defaultNonActiveTriggerCondition = null

export const defaultActiveTriggerCondition = { event: CampaignTriggerEvent.PageVisit }

export const delayTriggerCondition = (duration: Duration) => ({
  delay: duration,
  event: CampaignTriggerEvent.PageVisit,
})
