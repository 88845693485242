import React, { forwardRef, ForwardRefRenderFunction, ReactNode, useImperativeHandle, useState } from 'react'
import { Box, Button, HoverCard, Stack, Text, useMantineTheme } from '@mantine/core'
import { useClickOutside, useDisclosure } from '@mantine/hooks'
import useTranslation from 'next-translate/useTranslation'

import { isBlank } from 'utils'
import { getBackgroundColor, getBorderColor, getTextColor } from 'config/themeOverride/getColor'
import MessageInput from './MessageInput'

type Props = {
  placeholder: string
  onSave: (value: string, afterSave: () => void) => void
  onFocus?: () => void
  disabled?: boolean
  disabledTooltip?: string | ReactNode
  enterSaveEnabled?: boolean
  minRows?: number
}

export type MessageAdderApi = {
  reset: () => void
}

const MessageAdder: ForwardRefRenderFunction<MessageAdderApi, Props> = (props, ref) => {
  const { t } = useTranslation('common')
  const theme = useMantineTheme()
  const { disabled, disabledTooltip, enterSaveEnabled = true, placeholder, onSave, onFocus, minRows } = props
  const [opened, { open, close }] = useDisclosure(false)
  const [value, setValue] = useState<string>()

  const wrapperRef = useClickOutside(() => {
    if (isBlank(value)) {
      close()
    }
  })

  const reset = () => {
    setValue(undefined)
    close()
  }

  useImperativeHandle(ref, () => ({ reset }))

  return (
    <>
      {opened ? (
        <Stack
          ref={wrapperRef}
          spacing={0}
          align="end"
          sx={(theme) => ({
            border: `1px solid ${getBorderColor('gray.3', theme)}`,
            borderRadius: theme.radius.sm,
            '&:focus-within': {
              border: `1px solid ${getBorderColor('brandBlue.7', theme, 'brandBlue.8')}`,
            },
          })}
        >
          <MessageInput
            minRows={minRows}
            placeholder={placeholder}
            value={value}
            onSave={() => enterSaveEnabled && onSave(value!, reset)}
            onChange={(value) => setValue(value)}
            styles={(theme) => ({
              input: {
                border: 0,
                padding: `${theme.spacing.sm} !important`,
                backgroundColor: getBackgroundColor('white', theme, 'dark.7'),
              },
            })}
          />
          <HoverCard withinPortal disabled={!disabled || !disabledTooltip} width={400}>
            <HoverCard.Target>
              <div>
                <Button
                  disabled={disabled || isBlank(value)}
                  onClick={() => onSave(value!, reset)}
                  mx="sm"
                  mb="sm"
                  size="xs"
                >
                  {t('common:actions.send')}
                </Button>
              </div>
            </HoverCard.Target>
            <HoverCard.Dropdown>{disabledTooltip}</HoverCard.Dropdown>
          </HoverCard>
        </Stack>
      ) : (
        <Box
          p="sm"
          sx={(theme) => ({
            border: `1px solid ${getBorderColor('gray.3', theme)}`,
            borderRadius: theme.radius.sm,
            cursor: 'text',
          })}
          onClick={() => {
            open()
            onFocus?.()
          }}
        >
          <Text color={getTextColor('gray.5', theme)}>{placeholder}</Text>
        </Box>
      )}
    </>
  )
}

export default forwardRef(MessageAdder)
