import { noop } from 'lodash-es'
import { createContext } from 'react'
import { CampaignFilterActionProps, CampaignFilterContextProps } from './type'

export const CampaignFilterContext = createContext<CampaignFilterContextProps>({
  filter: null,
})

export const CampaignFilterActionContext = createContext<CampaignFilterActionProps>({
  onFieldsChange: noop,
  onExplicitlyFieldChange: noop,
})
