import React, { useState } from 'react'
import { Button, Group, Switch, Tooltip } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'

import {
  useBuilderFormContext,
  useBuilderShareActions,
  useBuilderSharedDataContext,
} from 'components/business/CampaignBuilder'
import { isEmpty } from 'lodash-es'
import DeliveryUrl from 'components/business/Campaign/DeliveryUrl'
import useCampaignStatusAlert from 'components/business/Campaign/hooks/useCampaignStatusAlert'
import { CampaignWidgetType } from 'types/domain'
import { isLinkDistribution } from 'utils/applicationUtils'
import { Copy, OpenInNewTab, QrCode } from 'components/ui/LinkWithAction'
import CampaignFormStatusSwitch from 'components/business/Campaign/CampaignFormStatusSwitch'
import { getXFormUrl } from 'utils/getXFormUrl'

const HeaderRight = () => {
  const { t } = useTranslation()
  const form = useBuilderFormContext()
  const [loading, setLoading] = useState(false)
  const { open: openAlertModal } = useCampaignStatusAlert(form.values.type!)
  const { onSave } = useBuilderShareActions()
  const { id: campaignId, token, type, widget } = form.values
  const url = getXFormUrl(token!, type!, true)
  const { application } = useBuilderSharedDataContext()
  const isLinkApplication = isLinkDistribution(application)

  const handleSave = () => {
    setLoading(true)
    onSave?.(form.values).finally(() => {
      setLoading(false)
    })
  }

  const handleChange = async () => {
    if (!form.values.enabled) {
      openAlertModal(() => handleSaveWithStatus(true))
    } else {
      await handleSaveWithStatus(false)
    }
  }

  const handleSaveWithStatus = async (enabled: boolean) => {
    const successMsg = t(`componentCampaignList:statusSwitcher.message.${enabled ? 'enabled' : `disabled`}`, {
      campaignType: t(`componentCampaignList:${form.values.type}`),
    })
    const errorMsg = t('common:message.updateError')
    onSave?.({ ...form.values, enabled }, successMsg, errorMsg).then(() => form.setFieldValue('enabled', enabled))
  }

  return (
    <Group spacing="lg">
      {isLinkApplication ? (
        <>
          <Copy url={url} iconContainerSize="lg" iconSize={22} />
          <QrCode url={url} iconContainerSize="lg" iconSize={22} />
          <OpenInNewTab url={url} iconContainerSize="lg" iconSize={22} />
          <CampaignFormStatusSwitch id={campaignId!} />
        </>
      ) : (
        <>
          {widget.type !== CampaignWidgetType.Banner && <DeliveryUrl id={form.values.id!} size={22} />}
          <Tooltip
            disabled={isEmpty(form.errors)}
            withArrow
            label={t('componentCampaignList:form.invalid.tooltip')}
            withinPortal
          >
            <div>
              <Switch
                checked={form.values.enabled}
                onLabel={t('componentCampaignList:statusSwitcher.opened')}
                offLabel={t('componentCampaignList:statusSwitcher.closed')}
                size="lg"
                display="flex"
                color="teal.6"
                onChange={handleChange}
                disabled={!isEmpty(form.errors)}
              />
            </div>
          </Tooltip>
        </>
      )}
      <Tooltip
        disabled={isEmpty(form.errors)}
        withArrow
        label={t('componentCampaignList:form.invalid.tooltip')}
        withinPortal
      >
        <div>
          <Button onClick={handleSave} loading={loading} disabled={!isEmpty(form.errors)}>
            {t('common:actions.save')}
          </Button>
        </div>
      </Tooltip>
    </Group>
  )
}

export default HeaderRight
