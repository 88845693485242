import { noop } from 'lodash-es'
import React, { KeyboardEvent, useState } from 'react'
import { Modal, useMantineTheme } from '@mantine/core'
import { modalConfig } from 'config/constants'

import { useCampaign } from 'components/business/Campaign/CampaignContext'
import { useEntries } from 'components/business/Campaign/Entries/EntriesContext'
import { useAbac } from 'react-abac'
import { ApplicationPermission } from 'types/domain'

import EntryModalHeader from './Header'
import EntryModalContent from './Content'
import { EntryDetailProps } from './type'

const EntryModal = (props: EntryDetailProps) => {
  const { opened, onClose, previousDisabled, nextDisabled, onPrevious, onNext, data, onRemove, targetAside } = props
  const theme = useMantineTheme()
  const { campaign } = useCampaign()
  const { formFields } = useEntries()
  const [keyboardDisabled, setKeyboardDisabled] = useState(false)
  const { userHasPermissions } = useAbac()
  const canAddToSegment = userHasPermissions(ApplicationPermission.CustomerSegmentManage)

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (keyboardDisabled) {
      return
    }
    if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
      e.preventDefault()
      if (!previousDisabled) {
        onPrevious?.()
      }
    }
    if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
      e.preventDefault()
      if (!nextDisabled) {
        onNext?.()
      }
    }
  }

  const handleRemoveEntry = () => {
    onRemove?.()
    onClose?.()
  }

  return (
    <Modal
      {...modalConfig(theme)}
      opened={opened}
      onClose={onClose ?? noop}
      onKeyDown={handleKeyDown}
      closeOnEscape={!keyboardDisabled}
      title={
        <EntryModalHeader
          entry={data?.entry}
          campaignId={campaign?.id!}
          campaignType={campaign?.type!}
          previousDisabled={previousDisabled}
          nextDisabled={nextDisabled}
          onPrevious={onPrevious}
          onNext={onNext}
          onRemoveEntry={handleRemoveEntry}
        />
      }
    >
      <EntryModalContent
        canAddToSegment={canAddToSegment}
        campaignType={campaign?.type!}
        widgetType={campaign?.widget.type!}
        data={data}
        fields={formFields}
        targetAside={targetAside}
        onLightboxToggle={setKeyboardDisabled}
      />
    </Modal>
  )
}

export default EntryModal
