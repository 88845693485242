import React, { FC, PropsWithChildren } from 'react'
import { Box, Image, Stack, Text, Title, useMantineTheme } from '@mantine/core'

import { getTextColor } from 'config/themeOverride/getColor'
import { CampaignType } from 'types/domain'

import iosFeedback from './images/ios-feedback.png'
import iosSurvey from './images/ios-survey.png'
import androidFeedback from './images/android-feedback.png'
import androidSurvey from './images/android-survey.png'
import wechatMiniappFeedback from './images/wechat-miniapp-feedback.png'
import wechatMiniappSurvey from './images/wechat-miniapp-survey.png'

import { MobileAppDistributionChannel } from 'config/constants'

type Props = PropsWithChildren & {
  type: CampaignType
  channel: MobileAppDistributionChannel
  title: string
  description: string[]
}

const ImageLink = {
  IOS_SWIFT: {
    [CampaignType.Survey]: iosSurvey.src,
    [CampaignType.Feedback]: iosFeedback.src,
    [CampaignType.Notification]: iosFeedback.src,
  },
  ANDROID: {
    [CampaignType.Survey]: androidSurvey.src,
    [CampaignType.Feedback]: androidFeedback.src,
    [CampaignType.Notification]: androidFeedback.src,
  },
  WECHAT_MINIAPP: {
    [CampaignType.Survey]: wechatMiniappSurvey.src,
    [CampaignType.Feedback]: wechatMiniappFeedback.src,
    [CampaignType.Notification]: wechatMiniappFeedback.src,
  },
}

const BlankMobileCampaigns: FC<Props> = (props) => {
  const { type, channel, title, description } = props
  const theme = useMantineTheme()

  return (
    <Stack pt={136} spacing={0} align="center" className="flex-1" justify="center">
      <Title order={2}>{title}</Title>
      <Stack spacing={0} mt="md">
        {description.map((item, index) => (
          <Text key={index} color={getTextColor('gray.7', theme)} className="w-[456px] text-center">
            {item}
          </Text>
        ))}
      </Stack>
      <Box mt="xl" mb="xl">
        <Image width={520} height={320} alt="" src={ImageLink[channel][type]} className="rounded-md overflow-hidden" />
      </Box>
      {props.children}
    </Stack>
  )
}

export default BlankMobileCampaigns
