import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { Group } from '@mantine/core'

import { EntryIcon } from 'components/business'
import HelpDoc from 'components/business/HelpDoc'
import TableHeaderCell from 'components/ui/TableHeaderCell'

const submitOnCode = 'entry.submittedOn'
const hideIconCodes = ['entry.serialNumber']

const HeaderComponent = ({ column }: any) => {
  const { t } = useTranslation('componentCampaignList')
  const type = column.columnDef.type
  const fieldCode = column.columnDef.id
  const isSubmitOnField = type === submitOnCode
  const isIconHidden = hideIconCodes.includes(type)

  return (
    <Group spacing="xs" noWrap>
      {!isIconHidden && <EntryIcon type={type} fieldCode={fieldCode} />}
      <TableHeaderCell>{column.columnDef.header}</TableHeaderCell>
      {isSubmitOnField && <HelpDoc content={t('entrySubmittedOnTip')} width={400} />}
    </Group>
  )
}

export default HeaderComponent
