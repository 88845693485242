import React, { PropsWithChildren, useContext } from 'react'
import { useRouter } from 'next/router'
import { Container, Flex, Tabs, Title } from '@mantine/core'

import { ApplicationSettings } from 'config/constants'
import { useCrisp } from 'plugins/crisp'
import useTranslation from 'next-translate/useTranslation'
import ApplicationContext from '../ApplicationLayout/ApplicationContext'
import { canUpdateApplication, getDistributionChannelGroup } from 'utils/applicationUtils'
import SubscriptionAlert from 'components/business/subscription/SubscriptionAlert'

type Tabs = 'basic' | 'tracking' | 'distribution' | 'events' | 'customer-attributes' | 'webhook' | 'api'

type Props = {
  activeTab: Tabs
}

const ApplicationSettingLayout = ({ activeTab, children }: PropsWithChildren<Props>) => {
  useCrisp()
  const router = useRouter()
  const { t } = useTranslation('layoutAppSetting')
  const { application } = useContext(ApplicationContext)
  const appId = router.query.appId as string
  const currentChannelGroup = getDistributionChannelGroup(application?.distribution.channel)
  const applicationUpdate = canUpdateApplication(application!)

  return (
    <Container size={1600} px={0}>
      <Flex h="100%" direction="column">
        <Title order={3} mb="sm">
          {t('title')}
        </Title>
        <SubscriptionAlert />
        <Tabs
          keepMounted={false}
          value={activeTab}
          onTabChange={(value: Tabs) => {
            router.push(ApplicationSettings.replace('[appId]', appId).replace('[settingName]', value))
          }}
          styles={() => {
            return {
              tab: {
                lineHeight: '22px',
              },
              root: {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              },
              panel: {
                flex: 1,
              },
            }
          }}
        >
          <Tabs.List>
            <Tabs.Tab value="basic">{currentChannelGroup ? t(`basic.${currentChannelGroup}`) : ''}</Tabs.Tab>
            {currentChannelGroup !== 'link' && (
              <>
                <Tabs.Tab value="tracking">{currentChannelGroup ? t(`tracking.${currentChannelGroup}`) : ''}</Tabs.Tab>
                {applicationUpdate && <Tabs.Tab value="distribution">{t('distribution')}</Tabs.Tab>}
                <Tabs.Tab value="events">{t('event')}</Tabs.Tab>
                <Tabs.Tab value="customer-attributes">{t('customerAttribute')}</Tabs.Tab>
              </>
            )}
            {applicationUpdate && <Tabs.Tab value="webhook">{t('webhook')}</Tabs.Tab>}
            <Tabs.Tab value="api">{t('api')}</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value={activeTab} pt="lg" pb="2xl">
            {children}
          </Tabs.Panel>
        </Tabs>
      </Flex>
    </Container>
  )
}

export default ApplicationSettingLayout
