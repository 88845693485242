import { FormFieldType } from 'types/domain'
import { CUSTOMER_EMAIL, CUSTOMER_MOBILE, CUSTOMER_NAME } from 'config/constants'

export const MOBILE_HIDDEN_FIELDS = ['entry.browser', 'entry.reply']
export const LINK_HIDDEN_FIELDS = [
  'entry.reply',
  'entry.deliveryMethod',
  CUSTOMER_MOBILE,
  CUSTOMER_NAME,
  CUSTOMER_EMAIL,
]
export const HIDDEN_FIELD_TYPES = [FormFieldType.DescriptionField]
export const UNFILTERABLE_FIELD_TYPES = [FormFieldType.DescriptionField, FormFieldType.ScreenshotField]

export const SERIAL_NUMBER_CODE = 'entry.serialNumber'
export const COMMENTS_COUNT_CODE = 'entry.commentsCount'
export const REPLY_CODE = 'entry.reply'
