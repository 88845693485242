import React, { PropsWithChildren } from 'react'
import { ActionIcon, Menu, useMantineTheme } from '@mantine/core'
import { IconDotsVertical } from '@tabler/icons-react'
import { getTextColor } from 'config/themeOverride/getColor'

const ActionButton = ({ children }: PropsWithChildren) => {
  const theme = useMantineTheme()

  return (
    <Menu shadow="md" position="bottom-start">
      <Menu.Target>
        <ActionIcon>
          <IconDotsVertical stroke={2} size={14} color={getTextColor('gray.9', theme)} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>{children}</Menu.Dropdown>
    </Menu>
  )
}

export default ActionButton
