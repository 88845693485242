import React from 'react'
import { Box, Container, Flex, Paper, Skeleton, Text } from '@mantine/core'
import AppSkeletonItem from './AppSkeletonItem'

import { useStyles } from './styles'
import { useOnboardingLayoutContext } from 'components/business/Layouts/OnboardingLayout/context'

const Preview = () => {
  const { classes, cx } = useStyles()
  const { accountName, appName, channel, appId, openDropdown } = useOnboardingLayoutContext().values
  return (
    <Container className={classes.wrapper} w="100%" h="100%" mih={638} p={0}>
      <Box className={classes.fakeBrowser} p={0} w={420} h={500} bottom={0} right={0}>
        <Box className={classes.fakeBrowserBg} p={0} opacity={openDropdown ? 0.8 : 1} />
        <Paper
          className={cx(classes.fakeBrowserDropdown, openDropdown && classes.fakeBrowserDropdownOpen)}
          ml={24}
          mt={105}
          w={280}
          p="md"
          shadow="md"
        >
          <Flex align="center" mih={20} mb="sm" className="flex-1">
            {accountName ? (
              <Text c="gray.6" lh="20px" className="break-all">
                {accountName}
              </Text>
            ) : (
              <Skeleton animate={false} w={81} height={12} radius="xl" />
            )}
          </Flex>
          <AppSkeletonItem appId={appId} appName={appName} channel={channel} />
          <AppSkeletonItem />
        </Paper>
      </Box>
    </Container>
  )
}

export default Preview
