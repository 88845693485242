import { Field, FormFieldType } from 'types/domain'

export const CHECK_BOX: Field['__typename'] = 'CheckBox'
export const RADIO_BUTTON: Field['__typename'] = 'RadioButton'
export const DROP_DOWN: Field['__typename'] = 'DropDown'
export const TEXT_FIELD: Field['__typename'] = 'TextField'
export const DATETIME_FIELD: Field['__typename'] = 'DateTimeField'
export const TEXT_AREA: Field['__typename'] = 'TextArea'
export const NPS_FIELD: Field['__typename'] = 'NpsField'
export const CTA_FIELD: Field['__typename'] = 'CtaField'
export const RATING_FIELD: Field['__typename'] = 'RatingField'
export const CSAT_FIELD: Field['__typename'] = 'CsatField'
export const CES_FIELD: Field['__typename'] = 'CesField'

export const SELECTABLE_FIELDS: Field['__typename'][] = [CHECK_BOX, RADIO_BUTTON, DROP_DOWN, CTA_FIELD]

export const REPORTABLE_FIELDS: Field['__typename'][] = [
  NPS_FIELD,
  CSAT_FIELD,
  CES_FIELD,
  CHECK_BOX,
  RADIO_BUTTON,
  RATING_FIELD,
  CTA_FIELD,
]

export enum AttributeFieldType {
  DateTimeField = 'DateTimeField',
  TextField = 'TextField',
}

export enum SystemFilterableField {
  SubmittedOn = 'entry.submitted_on',
  Referer = 'entry.referer',
  Commented = 'entry.$commented',
  DeliveryMethod = 'entry.delivery_method',
}

export const ratingCode = 'field_1'
export const textareaCode = 'field_2'
export const submitOnCode = 'submitted_on'
export const deliveryMethodCode = 'delivery_method'
export const createdAtCode = 'created_at'
export const refererCode = 'referer'
export const keywordCode = '$keyword'
export const commentedCode = '$commented'

export type AllFieldType = FormFieldType | AttributeFieldType
