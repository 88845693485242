import React from 'react'
import { Feature, Permission } from 'types/domain'
import { FeatureTip, useCheckFeature } from 'components/business'
import { useAbac } from 'react-abac'
import useTranslation from 'next-translate/useTranslation'

export default function useCheckHideBranding() {
  const { t } = useTranslation('permission')

  const checkFeature = useCheckFeature()
  const { userHasPermissions } = useAbac()
  const editable = userHasPermissions(Permission.AccountAccountManage)
  const canUseHideBranding = checkFeature(Feature.HideBranding)

  if (!editable) {
    return {
      disabled: true,
      disabledTip: t('actions.disabledModificationByPermission'),
    }
  }

  const disabled = !canUseHideBranding
  const disabledTip = disabled ? <FeatureTip feature={Feature.HideBranding} /> : undefined

  return {
    disabled,
    disabledTip,
  }
}
