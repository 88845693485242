import React, { useState } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useForm } from '@mantine/form'
import { closeModal, openModal } from '@mantine/modals'
import { Button, Group, MultiSelect, Stack, Text } from '@mantine/core'

import {
  Campaign,
  CampaignGroup,
  useApplicationCampaignGroupsQuery,
  useUpdateCampaignGroupsMutation,
} from 'types/domain'
import { graphqlErrorCatch, length } from 'utils'
import { message } from 'components/ui'

const editCampaignGroupModalId = 'editCampaignGroupModal'

interface ModalContentProps {
  campaign: Pick<Campaign, 'id' | 'type' | 'groupIds'>
  onSuccess?: () => void
}

const ModalContent = ({ campaign: { id, type, groupIds }, onSuccess }: ModalContentProps) => {
  const { t } = useTranslation('componentCampaignList')
  const [updateCampaignGroups, { loading: editing }] = useUpdateCampaignGroupsMutation()
  const router = useRouter()
  const appId = router.query.appId as string
  const [campaignGroups, setCampaignGroups] = useState<CampaignGroup[]>([])

  useApplicationCampaignGroupsQuery({
    variables: {
      id: appId,
      type,
    },
    onCompleted: (resp) => {
      setCampaignGroups(resp.application?.campaignGroups ?? [])
    },
  })

  const form = useForm({
    initialValues: {
      groupIds: groupIds,
    },
  })

  const handleSubmit = async () => {
    if (editing) {
      return
    }
    try {
      const { data } = await updateCampaignGroups({
        variables: {
          id,
          groupIds: form.values.groupIds,
        },
      })
      if (data?.updateCampaignGroups) {
        closeModal(editCampaignGroupModalId)
        message.successMsg(t('common:message.updateSuccess'))
        onSuccess?.()
      }
    } catch (e: any) {
      const { graphQLErrors } = e
      graphqlErrorCatch({
        graphQLErrors,
        defaultMessage: t('common:message.updateError'),
      })
    }
  }

  const options = campaignGroups.map((group) => {
    return {
      value: group.id,
      label: group.name,
    }
  })

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack spacing="xl">
        <MultiSelect
          withinPortal
          data={options}
          placeholder={options.length > 0 ? t('common:placeholders.pleaseSelect') : t('campaignGroup.empty')}
          {...form.getInputProps('groupIds')}
        />
        <Group position="right">
          <Button variant="default" onClick={() => closeModal(editCampaignGroupModalId)}>
            {t('common:actions.cancel')}
          </Button>
          <Button type="submit" disabled={editing}>
            {t('common:actions.confirm')}
          </Button>
        </Group>
      </Stack>
    </form>
  )
}

export default function useEditCampaignGroupsModal(campaign: Pick<Campaign, 'id' | 'type' | 'groupIds'>) {
  const { t } = useTranslation('componentCampaignList')

  return (onSuccess?: () => void) => {
    openModal({
      modalId: editCampaignGroupModalId,
      withinPortal: true,
      title: (
        <Text weight={600} size="md">
          {t('list.action.editGroup')}
        </Text>
      ),
      children: <ModalContent campaign={campaign} onSuccess={onSuccess} />,
    })
  }
}
