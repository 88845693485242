import { getBackgroundColor } from 'config/themeOverride/getColor'
import React, { FC } from 'react'
import { Center, Group, Text } from '@mantine/core'
import { splitCharCode } from 'config/constants'

type Props = any & {
  allowWrap?: boolean
  choiceHeight?: number
}

const SelectableFieldCell: FC<Props> = (props) => {
  const { renderedCellValue, allowWrap, choiceHeight = 24 } = props
  return (
    <Group noWrap={!allowWrap} spacing="xs">
      {renderedCellValue
        ?.split(splitCharCode)
        ?.filter((item: string) => item)
        ?.map((item: string, index: number) => (
          <Center
            key={index}
            sx={(theme) => ({
              height: choiceHeight,
              background: getBackgroundColor('gray.1', theme),
              borderRadius: theme.radius.sm,
              paddingLeft: theme.spacing.sm,
              paddingRight: theme.spacing.sm,
            })}
          >
            <Text>{item}</Text>
          </Center>
        ))}
    </Group>
  )
}

export default SelectableFieldCell
