import { useEffect } from 'react'
import { Checkbox, Stack, TextInput } from '@mantine/core'
import { useInputState } from '@mantine/hooks'
import useTranslation from 'next-translate/useTranslation'
import { partition } from 'lodash-es'
import { WebhookChannel } from 'types/domain'
import { convertToNonEmptyArray } from 'utils'
import { AT_ALL_VALUE } from 'components/business'
import { useWebhookBuilderFormContext } from '../../../formContext'
import useIsBuilderEditable from '../../../hooks/useIsBuilderEditable'

const TargetsInput = () => {
  const { t } = useTranslation('integration')
  const form = useWebhookBuilderFormContext()
  const editable = useIsBuilderEditable()
  const { channel } = form.values
  const { value, onChange, ...rest } = form.getInputProps('targets')
  const [atAllValues, mobileValues] = partition(value, (elem) => elem === AT_ALL_VALUE)
  const [mobilesString, setMobilesString] = useInputState(mobileValues.join(',') ?? '')
  const [isAtAll, setIsAtAll] = useInputState(atAllValues.length > 0)

  useEffect(() => {
    if (editable) {
      const newValue = convertToNonEmptyArray(mobilesString)
      isAtAll && newValue.push(AT_ALL_VALUE)
      onChange(newValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable, mobilesString, isAtAll])

  return (
    <Stack w="100%" spacing="xs">
      <TextInput
        label={t('webhook.builder.stepAction.targetsLabel')}
        placeholder={t('webhook.builder.stepAction.targetsPlaceholder')}
        styles={{ label: { fontWeight: 400 } }}
        className="w-full"
        value={mobilesString}
        onChange={setMobilesString}
        readOnly={!editable}
        inputWrapperOrder={channel === WebhookChannel.Feishu ? ['label', 'error'] : undefined}
        {...rest}
      />
      <Checkbox
        label={t('webhook.builder.stepAction.targetsAtAll')}
        checked={isAtAll}
        onChange={setIsAtAll}
        readOnly={!editable}
      />
    </Stack>
  )
}

export default TargetsInput
