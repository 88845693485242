import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { IconDots, IconEdit, IconTrash } from '@tabler/icons-react'
import { ActionIcon, Menu, useMantineTheme } from '@mantine/core'
import { getTextColor } from 'config/themeOverride/getColor'
import { message, openAlertModal } from 'components/ui'
import { CustomerSegment, EntryComment, useDeleteEntryCommentMutation } from 'types/domain'

interface Props {
  entryId: string
  comment: EntryComment
  onClickEdit: () => void
}

const Actions = ({ entryId, onClickEdit, comment }: Props) => {
  const { t } = useTranslation('entry')
  const theme = useMantineTheme()
  const [deleteEntryCommentMutation] = useDeleteEntryCommentMutation()

  const handleDelete = async () => {
    try {
      await deleteEntryCommentMutation({
        variables: { id: comment.id! },
        update: (cache, { data }) => {
          if (!data?.deleteEntryComment) {
            return
          }
          cache.modify({
            id: cache.identify({ __typename: 'Entry', id: entryId }),
            fields: {
              comments: function (existingCommentsRef, { readField }) {
                return {
                  ...existingCommentsRef,
                  nodes: existingCommentsRef.nodes.filter(
                    (ref: CustomerSegment) => comment.id !== readField('id', ref)
                  ),
                  totalCount: existingCommentsRef.totalCount - 1,
                }
              },
              commentsCount(existingCommentCountRef) {
                return existingCommentCountRef - 1
              },
            },
          })
        },
      })
    } catch (e) {
      message.error(t('common:message.deleteError'))
    }
  }

  const onClickDelete = () => {
    openAlertModal({
      title: t('entryModal.comments.delete.title'),
      content: t('entryModal.comments.delete.content'),
      onConfirm: handleDelete,
      labels: {
        confirm: t('common:actions.delete'),
        cancel: t('common:actions.cancel'),
      },
      confirmProps: {
        color: 'red',
      },
      zIndex: 1000,
    })
  }

  return (
    <Menu shadow="md" position="bottom-end" withinPortal>
      <Menu.Target>
        <ActionIcon color="gray.0" size={22}>
          <IconDots stroke={1.5} size={14} color={getTextColor('gray.6', theme)} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item icon={<IconEdit size={14} />} onClick={onClickEdit}>
          {t('common:actions.edit')}
        </Menu.Item>
        <Menu.Item icon={<IconTrash size={14} />} onClick={onClickDelete} color="red">
          {t('common:actions.delete')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export default Actions
