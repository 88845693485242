import getUuid from 'utils/getUuid'

export const tempCodePrefix = 'temp-code-'
export const isTempCode = (code: string) => code?.startsWith?.(tempCodePrefix)
export const clearPreCode = (code: string) => {
  if (isTempCode(code)) {
    return code.replace(/^temp-code-/, '')
  }
  return code
}
export const getTempCode = () => tempCodePrefix + getUuid().substring?.(0, 4)
